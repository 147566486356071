import React, { useState } from "react";
import { Col, message, Row } from "antd";
import { TeampicsImage } from "../../../models/EventsModel";
import { useDispatch, useSelector } from "react-redux";
import { List, Button, Checkbox, Table, Tooltip, Popconfirm } from "antd";
import AssignGroupModal from "./AssignGroupModal";
import { dynamicSort } from "../../../helpers/arrays";
import { format, startOfDay } from "date-fns";
import * as rImages from "../../../reducers/images";
import { firestore } from "../../../firebase/firebaseConfig";

const { Column } = Table;

const Unmatched = () => {
  const dispatch = useDispatch();
  const images: TeampicsImage[] = useSelector(
    (state: any) => state.imagesState.images
  );
  const [visible, setVisible] = useState(false);
  const [selectedImageId, setSelectedImageId] = useState("");
  const getChunkedImages = () => {
    const sortedImages: TeampicsImage[] = images
      .slice()
      .filter((image) => !image.hasSubject && !image.hasGroup)
      .sort(dynamicSort("type", "", "asc"))
      .sort(dynamicSort("dateCreated", "", "desc"));
    const chunkedImages: TeampicsImage[][] = [];

    let chunkedIndex = 0;
    sortedImages.forEach((image, index) => {
      if (index === 0) {
        chunkedImages[chunkedIndex] = [image];
      } else {
        if (
          startOfDay(image.dateCreated).getTime() ===
            startOfDay(sortedImages[index - 1].dateCreated).getTime() &&
          image.type === sortedImages[index - 1].type
        ) {
          chunkedImages[chunkedIndex].push(image);
        } else {
          chunkedIndex++;
          chunkedImages[chunkedIndex] = [image];
        }
      }
    });
    return chunkedImages;
  };
  // const onRemoveImage = (image: TeampicsImage) => async () => {
  //   try {
  //     dispatch(rImages.modifyImagesArray("REMOVE", image));
  //     await firestore.collection("uploadedImages").doc(image.imageId).delete();
  //   } catch (error) {
  //     console.log("🚀 ~ file: Unmatched.tsx ~ line 96 ~ onChange ~ error", error)
  //     message.error("Something went wrong, please try again.")
  //   }
  // }
  const onChange = (image: TeampicsImage) => async (e: any) => {
    try {
      const updatedImage: TeampicsImage = {
        ...image,
        isVerified: e.target.checked,
      };
      dispatch(rImages.modifyImagesArray("UPDATE", updatedImage));
      await firestore.collection("uploadedImages").doc(image.imageId).update({
        isVerified: e.target.checked,
      });
    } catch (error) {
      console.log(
        "🚀 ~ file: Unmatched.tsx ~ line 96 ~ onChange ~ error",
        error
      );
      message.error("Something went wrong, please try again.");
    }
  };
  const onOpenManualModal = (imageId: string) => () => {
    setSelectedImageId(imageId);
    setVisible(true);
  };
  const onCloseManualModal = () => {
    setSelectedImageId("");
    setVisible(false);
  };
  return (
    <div>
      <AssignGroupModal
        visible={visible}
        onClose={onCloseManualModal}
        imageId={selectedImageId}
      />
      <List
        locale={{ emptyText: "No Images available." }}
        className="small-list"
        itemLayout="horizontal"
        dataSource={getChunkedImages()}
        renderItem={(images: TeampicsImage[], index) => {
          return (
            <div>
              <div
                style={{
                  marginTop: index === 0 ? 8 : 24,
                  marginBottom: 8,
                  fontWeight: "bold",
                  fontSize: 18,
                }}
              >
                Uploaded on{" "}
                {format(startOfDay(images[0].dateCreated), "MMM do, yyyy")} -{" "}
                {images[0].type === "groups" ? "Group" : "Subject"}
              </div>
              <Table
                locale={{ emptyText: "No Images available." }}
                rowKey={(e) => e.key!}
                dataSource={images}
                pagination={false}
                bordered={true}
                size="small"
              >
                <Column
                  title="Verified"
                  dataIndex="isVerified"
                  key="isVerified"
                  align="center"
                  width={150}
                  render={(isVerified: boolean, image: TeampicsImage) => (
                    <Checkbox
                      onChange={onChange(image)}
                      checked={isVerified}
                      className={
                        isVerified
                          ? "checkbox-green-highlight"
                          : "checkbox-error-highlight"
                      }
                    />
                  )}
                />
                <Column
                  title="File Name"
                  dataIndex="filename"
                  key="filename"
                  render={(filename: string, image: TeampicsImage) => {
                    return (
                      <Row>
                        <Col key={image.key} span={24}>
                          <p style={{ marginBottom: 0 }}>{image.filename}</p>
                        </Col>
                      </Row>
                    );
                  }}
                />
                <Column
                  title={
                    images[0].type === "groups"
                      ? "Assign Group"
                      : "Assign Subject"
                  }
                  dataIndex="primaryImageId"
                  key="attachImage"
                  align="center"
                  width={150}
                  render={(primaryImageId: string, image: TeampicsImage) => (
                    <Button
                      type="primary"
                      shape="circle"
                      icon={"plus"}
                      onClick={onOpenManualModal(image.key!)}
                    />
                  )}
                />
                {/* <Column
                  title='Delete'
                  dataIndex='primaryImageId'
                  key='delete'
                  align="center"
                  width={150}
                  render={(primaryImageId: string, image: TeampicsImage) => {
                    return (
                      <Row>
                        <Col key={image.key} span={24} style={{ marginBottom: 4 }}>
                          <Popconfirm
                            title={`Are you sure to remove ${image.filename} from this group?`}
                            onConfirm={onRemoveImage(image)}
                            okText="Yes"
                            cancelText="No"
                            placement="left"
                          >
                            <Button shape="circle" icon="delete" type="danger" />
                          </Popconfirm>
                        </Col>
                      </Row>
                    )
                  }}
                /> */}
              </Table>
            </div>
          );
        }}
      />
    </div>
  );
};

export default Unmatched;
