import React from 'react';
import { Select } from 'antd';
import { Client } from '../models/ClientsModel';
import { Offering, Package } from '../models/ProductsModel';
import { Contact } from '../models/Commons';
const Option = Select.Option;

export const clientTypeOptions = [
	{ key: '0', value: 'school', text: 'School' },
	{ key: '1', value: 'sports', text: 'Sports' },
	{ key: '2', value: 'family', text: 'Family' },
	{ key: '3', value: 'corporate', text: 'Corporate' },
	{ key: '4', value: 'dance', text: 'Dance' },
	{ key: '5', value: 'other', text: 'Other' }
];

export const getClientTypeOptions = (): Array<any> => {
	return clientTypeOptions.map((type) => {
		return (
			<Option key={type.value} value={type.value}>
				{type.text}
			</Option>
		);
	});
};

export const clientSeasonOptions = [
	{ key: 0, value: 'summer', text: 'Summer' },
	{ key: 1, value: 'winter', text: 'Winter' },
	{ key: 2, value: 'spring', text: 'Spring' },
	{ key: 3, value: 'fall', text: 'Fall' }
];

export const divisionNameOptions = [
	{ key: 0, value: 'initiation', text: 'Initiation' },
	{ key: 1, value: 'timBit', text: 'TimBit' },
	{ key: 2, value: 'novice', text: 'Novice' },
	{ key: 3, value: 'mite', text: 'Mite' },
	{ key: 4, value: 'atom', text: 'Atom' },
	{ key: 5, value: 'squirt', text: 'Squirt' },
	{ key: 6, value: 'peewee', text: 'Peewee' },
	{ key: 7, value: 'bantam', text: 'Bantam' },
	{ key: 8, value: 'midget', text: 'Midget' },
	{ key: 9, value: 'juvenile', text: 'Juvenile' },
	{ key: 10, value: 'junior', text: 'Junior' },
	{ key: 11, value: 'senior', text: 'Senior' },
	{ key: 12, value: 'tyke', text: 'Tyke' },
	{ key: 13, value: 'miniTyke', text: 'Mini Tyke' },
	{ key: 14, value: 'other', text: 'Other' }
];
export const getDivisionText = (division: string, divisionType: string): string => {
	let text = '';
	if (divisionType === 'year') {
		return division;
	} else if (divisionType === 'named') {
		divisionNameOptions.forEach((type) => {
			if (division === type.value) {
				text = type.text;
			}
		});
		return text;
	} else if (divisionType === 'u##') {
		divisionUOptions.forEach((type) => {
			if (division === type.value) {
				text = type.text;
			}
		});
		return text;
	} else if (divisionType === 'other') {
		return division;
	} else {
		return division;
	}
};
export const getDivisionTextNamed = (division: string) => {
	let text = '';
	divisionNameOptions.forEach((type) => {
		if (division === type.value) {
			text = type.text;
		}
	});
	return text;
};
export const getDivisionTextYear = (division: string) => {
	let text = '';
	divisionNameOptions.forEach((type) => {
		if (division === type.value) {
			text = type.text;
		}
	});
	return text;
};
export const getDivisionTextU = (division: string) => {
	let text = '';
	divisionUOptions.forEach((type) => {
		if (division === type.value) {
			text = type.text;
		}
	});
	return text;
};
export const getDivisionNameOptions = (): Array<any> => {
	return divisionNameOptions.map((type) => {
		return (
			<Option key={type.value} value={type.value}>
				{type.text}
			</Option>
		);
	});
};

export const divisionTypeOptions = [
	{ key: 0, value: 'named', text: 'Named: Bantom, Atom etc.' },
	{ key: 1, value: 'u##', text: 'U##: U12, U1 etc.' },
	{ key: 2, value: 'year', text: 'Year' },
	{ key: 3, value: 'other', text: 'Other' }
];

export const getDivisionTypeOptions = (): Array<any> => {
	return divisionTypeOptions.map((type) => {
		return (
			<Option key={type.value} value={type.value}>
				{type.text}
			</Option>
		);
	});
};

export const divisionUOptions = [
	{ key: 0, value: 'U3', text: 'U3' },
	{ key: 1, value: 'U4', text: 'U4' },
	{ key: 2, value: 'U5', text: 'U5' },
	{ key: 3, value: 'U6', text: 'U6' },
	{ key: 4, value: 'U7', text: 'U7' },
	{ key: 5, value: 'U8', text: 'U8' },
	{ key: 6, value: 'U9', text: 'U9' },
	{ key: 7, value: 'U10', text: 'U10' },
	{ key: 8, value: 'U11', text: 'U11' },
	{ key: 9, value: 'U12', text: 'U12' },
	{ key: 10, value: 'U13', text: 'U13' },
	{ key: 11, value: 'U14', text: 'U14' },
	{ key: 12, value: 'U15', text: 'U15' },
	{ key: 13, value: 'U16', text: 'U16' },
	{ key: 14, value: 'U17', text: 'U17' },
	{ key: 15, value: 'U18', text: 'U18' },
	{ key: 16, value: 'U19', text: 'U19' },
	{ key: 17, value: 'U20', text: 'U20' },
	{ key: 18, value: 'other', text: 'Other' }
];
export const getDivisionUOptions = (): Array<any> => {
	return divisionUOptions.map((type) => {
		return (
			<Option key={type.value} value={type.value}>
				{type.text}
			</Option>
		);
	});
};

export const getDivisionYearsOptions = () => {
	const date = new Date();
	const year = date.getFullYear();
	const yearOptions = Array<any>(30).fill({}).map((item: number, i: number) => {
		const newYear = (year - i).toString();
		return (
			<Option key={newYear} value={newYear}>
				{newYear}
			</Option>
		);
	});
	return yearOptions;
};

export const getClientOptionsForEvent = (clients: Array<Client>) => {
	return clients.slice().map((client: Client, index: number) => {
		return (
			<Option key={client.key} value={client.key}>
				{client.name}
			</Option>
		);
	});
};

export const getContactOptionsForEvent = (contacts: Array<Contact>) => {
	return contacts.slice().map((contact: Contact, index: number) => {
		return (
			<Option key={contact.key} value={contact.key}>
				{`${contact.firstName} ${contact.lastName}: ${contactRoleMap[contact.role!] as any}`}
			</Option>
		);
	});
};

export const getEventOptions = (events: any) => {
	const eventsOptions = events.map((event: any, index: number) => {
		return { key: index, value: event.key, text: event.name };
	});
	return eventsOptions;
};

export const getOfferingOptionsForEvent = (offerings: Array<Offering>) => {
	return offerings.slice().map((offering: Offering, index: number) => {
		return (
			<Option key={offering.key} value={offering.key}>
				{offering.name}
			</Option>
		);
	});
};

export const getPackageOptionsForEvent = (packages: Array<Package>) => {
	return packages.slice().map((pack: Package, index: number) => {
		return (
			<Option key={pack.key} value={pack.key}>
				{pack.name}
			</Option>
		);
	});
};

export const contactRoleMap: any = {
	president: 'President',
	secretary: 'Secretary',
	photoDayCoordinator: 'Photo day coordinator',
	treasurer: 'Treasurer',
	yearbookAdvisor: 'Yearbook advisor',
	yearbookContractor: 'Yearbook contractor'
};

export const intervalUnits = [{ key: 0, value: 'mins', text: 'Minutes' }, { key: 1, value: 'hours', text: 'Hours' }];

export const heightUnits = [{ key: 0, value: 'ft', text: 'F' }, { key: 1, value: 'cm', text: 'cm' }];

export const ftOptions = [
	{ key: 0, value: 0, text: '0ft' },
	{ key: 1, value: 1, text: '1ft' },
	{ key: 2, value: 2, text: '2ft' },
	{ key: 3, value: 3, text: '3ft' },
	{ key: 4, value: 4, text: '4ft' },
	{ key: 5, value: 5, text: '5ft' },
	{ key: 6, value: 6, text: '6ft' },
	{ key: 7, value: 7, text: '7ft' }
];

export const inchOptions = [
	{ key: 0, value: 0, text: '0in' },
	{ key: 1, value: 1, text: '1in' },
	{ key: 2, value: 2, text: '2in' },
	{ key: 3, value: 3, text: '3in' },
	{ key: 4, value: 4, text: '4in' },
	{ key: 5, value: 5, text: '5in' },
	{ key: 6, value: 6, text: '6in' },
	{ key: 7, value: 7, text: '7in' },
	{ key: 8, value: 8, text: '8in' },
	{ key: 9, value: 9, text: '9in' },
	{ key: 10, value: 10, text: '10in' },
	{ key: 11, value: 11, text: '11in' }
];

export const staffPositions = [
	{ key: 0, value: 'coach', text: 'Coach' },
	{ key: 1, value: 'assistantCoach', text: 'Assistant coach' },
	{ key: 2, value: 'manager', text: 'Manager' },
	{ key: 3, value: 'trainer', text: 'Trainer' },
	{ key: 4, value: 'treasurer', text: 'Treasurer' },
	{ key: 5, value: 'director', text: 'Director' },
	{ key: 6, value: 'other', text: 'Other' }
];

export const getStaffPositions = () => {
	return staffPositions.map((type) => {
		return (
			<Option key={type.value} value={type.value}>
				{type.text}
			</Option>
		);
	});
};

export const printQueueOptions = [{ key: 0, value: 'local', text: 'Local' }, { key: 1, value: 'lab', text: 'Lab' }];

export const getPrintQueueOptions = () => {
	return printQueueOptions.map((type) => {
		return (
			<Option key={type.value} value={type.value}>
				{type.text}
			</Option>
		);
	});
};

export const productTypeOptions = [
	{ key: 0, value: 'digital', text: 'Digital' },
	{ key: 1, value: 'print', text: 'Print' }
];

export const getProductTypeOptions = () => {
	return productTypeOptions.map((type) => {
		return (
			<Option key={type.value} value={type.value}>
				{type.text}
			</Option>
		);
	});
};

export const productCategoryOptions = [
	{ key: 0, value: 'general', text: 'General' },
	{ key: 1, value: 'special', text: 'Special' },
	{ key: 2, value: 'bonus', text: 'Bonus' },
	{ key: 3, value: 'misc', text: 'Miscellaneous' }
];

export const getProductCategoryOptions = () => {
	return productCategoryOptions.map((type) => {
		return (
			<Option key={type.value} value={type.value}>
				{type.text}
			</Option>
		);
	});
};

export const packageCategoryOptions = [
	{ key: 0, value: 'packages', text: 'Packages' },
	{ key: 1, value: 'digital', text: 'Digital' },
	{ key: 2, value: 'gifts', text: 'Gifts' },
	{ key: 3, value: 'tech', text: 'Tech' },
	{ key: 4, value: 'sheets', text: 'Sheets' }
];

export const getPackageCategoryOptions = () => {
	return packageCategoryOptions.map((type) => {
		return (
			<Option key={type.value} value={type.value}>
				{type.text}
			</Option>
		);
	});
};

export const contactRoleOptions = [
	{ key: 0, value: 'president', text: 'President' },
	{ key: 1, value: 'secretary', text: 'Secretary' },
	{ key: 2, value: 'photoDayCoordinator', text: 'Photo day coordinator' },
	{ key: 3, value: 'treasurer', text: 'Treasurer' },
	{ key: 4, value: 'yearbookAdvisor', text: 'Yearbook advisor' },
	{ key: 5, value: 'yearbookContractor', text: 'Yearbook contractor' }
];

export const getContactRoleOptions = () => {
	return contactRoleOptions.map((type) => {
		return (
			<Option key={type.value} value={type.value}>
				{type.text}
			</Option>
		);
	});
};

export const contactStatusOptions = [
	{ key: 0, value: 'active', text: 'Active' },
	{ key: 1, value: 'inactive', text: 'Inactive' },
	{ key: 2, value: 'lead', text: 'Lead' }
];

export const getContactStatusOptions = () => {
	return contactStatusOptions.map((type) => {
		return (
			<Option key={type.value} value={type.value}>
				{type.text}
			</Option>
		);
	});
};

export const eventTypeOptions = [
	{ key: 0, value: 'sports', text: 'Sports' },
	{ key: 1, value: 'school', text: 'School' },
	{ key: 2, value: 'liveEvent', text: 'Live event' },
	{ key: 3, value: 'dance', text: 'Dance' },
	{ key: 4, value: 'photoBooth', text: 'Photo booth' },
	{ key: 5, value: 'portrait', text: 'Portrait' }
];

export const getEventTypeOptions = (): Array<any> => {
	return eventTypeOptions.map((type) => {
		return (
			<Option key={type.value} value={type.value}>
				{type.text}
			</Option>
		);
	});
};
