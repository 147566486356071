import { combineReducers } from 'redux';
import {
	createReducerWithTaskName,
	dispatchTaskWithState,
	createReducerForFirebaseArray,
	dispatchFirebaseArrayWithState
} from '../helpers/reducers';
import { Client } from '../models/ClientsModel';
import { INITIAL_STATE_EMPTY_TASK, Contact } from '../models/Commons';

/**
 * INITIAL STATES
 */
export const INITIAL_STATE_SELECTED_CLIENT = {
	key: ''
};

/**
 * CONSTANTS
 */
const LOAD_CLIENTS = 'load clients';
const LOAD_CLIENT_CONTACTS = 'load client contacts';
const LOAD_CLIENT_DETAILS = 'load client details';
const UPDATE_CLIENT = 'update client';
const SET_SELECTED_CLIENT = 'set selected client';
const CLEAR_CLIENTS_REDUCER = 'clear clients reducer';

/**
 * ACTIONS
 */
export const setSelectedClient = (selectedClient: Client) => ({
	type: SET_SELECTED_CLIENT,
	selectedClient
});

export const modifyLoadClients = (taskState: string, value: string | boolean) => {
	return dispatchTaskWithState(LOAD_CLIENTS, taskState, value);
};

export const modifyLoadContacts = (taskState: string, value: string | boolean) => {
	return dispatchTaskWithState(LOAD_CLIENT_CONTACTS, taskState, value);
};

export const modifyUpdateClient = (taskState: string, value: string | boolean) => {
	return dispatchTaskWithState(UPDATE_CLIENT, taskState, value);
};

export const modifyLoadClientDetails = (taskState: string, value: string | boolean) => {
	return dispatchTaskWithState(LOAD_CLIENT_DETAILS, taskState, value);
};

export const modifyClientsArray = (arrayState: string, client: Client) => {
	return dispatchFirebaseArrayWithState(LOAD_CLIENTS, arrayState, client);
};

export const modifyContactsArray = (arrayState: string, contact: Contact) => {
	return dispatchFirebaseArrayWithState(LOAD_CLIENT_CONTACTS, arrayState, contact);
};

/**
 * REDUCERS
 */
function selectedClientReducer(state = INITIAL_STATE_SELECTED_CLIENT, action: any) {
	switch (action.type) {
		case SET_SELECTED_CLIENT: {
			return {
				//...state,
				...action.selectedClient
			};
		}
		default:
			return state;
	}
}

const clientsRootReducer = combineReducers({
	selectedClient: selectedClientReducer,
	clients: createReducerForFirebaseArray(LOAD_CLIENTS, []),
	contacts: createReducerForFirebaseArray(LOAD_CLIENT_CONTACTS, []),
	loadClients: createReducerWithTaskName(LOAD_CLIENTS, INITIAL_STATE_EMPTY_TASK),
	updateClient: createReducerWithTaskName(UPDATE_CLIENT, INITIAL_STATE_EMPTY_TASK),
	loadContacts: createReducerWithTaskName(LOAD_CLIENT_CONTACTS, INITIAL_STATE_EMPTY_TASK),
	loadClientDetails: createReducerWithTaskName(LOAD_CLIENT_DETAILS, INITIAL_STATE_EMPTY_TASK)
});

const clientsReducer = (state: any, action: any) => {
	if (action.type === CLEAR_CLIENTS_REDUCER) {
		state = {
			selectedClient: { ...INITIAL_STATE_SELECTED_CLIENT },
			clients: [],
			contacts: [],
			loadClients: { ...INITIAL_STATE_EMPTY_TASK },
			updateClient: { ...INITIAL_STATE_EMPTY_TASK },
			loadContacts: { ...INITIAL_STATE_EMPTY_TASK },
			loadClientDetails: { ...INITIAL_STATE_EMPTY_TASK }
		};
	}

	return clientsRootReducer(state, action);
};

export default clientsReducer;
