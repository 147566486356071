import React, { Component } from 'react';
import withAuthorization from '../../hoc/withAuthorization';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { setPage } from '../../reducers/session';
import * as routes from '../../constants/routeNames';
import RequestList from './RequestList';
import { Row, Col, BackTop } from 'antd';

interface OwnProps extends RouteComponentProps {}

interface StateProps {}

interface DispatchProps {
	onSetPage: Function;
}

type Props = StateProps & DispatchProps & OwnProps;
type State = {};

const mapStateToProps = (state: any) => ({});

const mapDispatchToProps = (dispatch: any) => ({
	onSetPage: (page: any) => {
		dispatch(setPage(page));
	}
});

class PhotographersPage extends Component<Props, State> {
	componentDidMount() {
		this.props.onSetPage(routes.PHOTOGRAPHERS);
		window.scrollTo(0, 0);
	}

	render() {
		return (
			<div className='uk-width-expand'>
				<BackTop />
				<div
					style={{
						minHeight: 'calc(100vh - 157px)',
						padding: '10px',
						backgroundColor: 'white'
					}}
				>
					<Row>
						<Col className='uk-padding uk-padding-remove-bottom' span={24}>
							<h2>Photographer Requests</h2>
						</Col>
					</Row>
					<Row>
						<Col span={24}>
							<RequestList />
						</Col>
					</Row>
				</div>
			</div>
		);
	}
}

const condition = (authUser: any) => !!authUser;

export default compose<any, any>(
	withRouter,
	withAuthorization(condition),
	connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps)
)(PhotographersPage);
