import { firestore } from './firebaseConfig';
import * as rEvents from '../reducers/events';
import * as storage from './storage';
import { ThunkResult, TeamPicsFile } from '../models/Commons';
import { Shoot, Event, Group } from '../models/EventsModel';
import shortid from 'shortid';
import converter from 'json-2-csv';
import fileDownload from 'js-file-download';
import { dynamicSort } from '../helpers/arrays';

let eventsListener: any
export const subscribeToEvents = (uid: string, active: boolean): ThunkResult<void> => {
  return dispatch => {
    const eventRef = firestore
      .collection('events')
      .orderBy('name', 'desc')
      .where('photographerId', '==', uid)
      .where('active', '==', active);

    if (eventsListener) {
      eventsListener();
      dispatch(rEvents.modifyEventsArray("CLEAR", {} as any))
    }
    dispatch(rEvents.modifyLoadEvents('LOADING', true));

    eventsListener = eventRef.onSnapshot(
      snapshot => {
        if (snapshot.empty) {
          dispatch(rEvents.modifyLoadEvents('EMPTY', true));
        } else {
          dispatch(rEvents.modifyLoadEvents('SUCCESS', true));
        }

        snapshot.docChanges().forEach(change => {
          if (change.type === 'added') {
            const event = {
              ...(change.doc.data() as Event),
              key: change.doc.id
            };
            dispatch(rEvents.modifyEventsArray('ADD', event));
          }
          if (change.type === 'modified') {
            const event = {
              ...(change.doc.data() as Event),
              key: change.doc.id
            };
            dispatch(rEvents.modifyEventsArray('UPDATE', event));
          }
          if (change.type === 'removed') {
            const event = {
              ...(change.doc.data() as Event),
              key: change.doc.id
            };
            dispatch(rEvents.modifyEventsArray('REMOVE', event));
          }
        });
      },
      (error: any) => {
        if (error) {
          console.log(error);
          dispatch(
            rEvents.modifyLoadEvents(
              'ERROR',
              'Something went wrong, please refresh the page'
            )
          );
        }
      }
    );
    return eventsListener;
  };
};

export const unSubscribeFromEvents = () => {
  if (eventsListener) {
    eventsListener();
    eventsListener = undefined;
  }
};

export const loadShoots = (eventId: string): ThunkResult<void> => {
  return dispatch => {
    const shootRef = firestore
      .collection('events')
      .doc(eventId)
      .collection('shoots')
      .orderBy('startTime');
    dispatch(rEvents.modifyLoadShoots('LOADING', true));
    return shootRef
      .get()
      .then(snapshots => {
        snapshots.forEach(snapshot => {
          const shoot = {
            ...(snapshot.data() as Shoot),
            key: snapshot.id
          };
          dispatch(rEvents.modifyShootsArray('ADD', shoot));
        });
        return dispatch(rEvents.modifyLoadShoots('SUCCESS', true));
      })
      .catch(error => {
        dispatch(
          rEvents.modifyLoadShoots(
            'ERROR',
            'Something went wrong, please try again.'
          )
        );
      });
  };
};

export const loadEvent = (eventId: string): ThunkResult<void> => {
  return dispatch => {
    const eventRef = firestore.collection('events').doc(eventId);
    dispatch(rEvents.modifyLoadEventDetails('LOADING', true));
    return eventRef
      .get()
      .then(eventSnap => {
        if (!eventSnap.exists) {
          dispatch(rEvents.modifyLoadEventDetails('EMPTY', true));
        }
        const event = { key: eventSnap.id, ...(eventSnap.data() as Event) };
        dispatch(rEvents.setSelectedEvent(event));
        return dispatch(rEvents.modifyLoadEventDetails('SUCCESS', true));
      })
      .catch(error => {
        return dispatch(
          rEvents.modifyLoadEventDetails(
            'ERROR',
            'Something went wrong please try again.'
          )
        );
      });
  };
};

export const newEvent = (
  event: Event,
  shoots: Array<Shoot>,
  photographerId: string,
  logoFile: TeamPicsFile
): ThunkResult<void> => {
  return dispatch => {
    const eventRef = firestore.collection('events').doc();
    const eventId = eventRef.id;
    const batch = firestore.batch();
    shoots.forEach(shoot => {
      const shootRef = firestore
        .collection('events')
        .doc(eventId)
        .collection('shoots')
        .doc();
      batch.set(shootRef, {
        ...shoot,
        eventId: eventId
      });
    });

    dispatch(rEvents.modifyUpdateEvent('LOADING', true));

    if (logoFile.blob) {
      const storagePath = `events/${eventId}/${photographerId}/logos/league-logo-${eventId}`;

      return storage.uploadImage(logoFile, storagePath)
        .then(url => {
          return eventRef.set({
            ...event,
            leagueLogoUrl: url,
          });
        })
        .then(() => {
          return batch.commit();
        })
        .then(() => {
          return dispatch(
            rEvents.modifyUpdateEvent(
              'SUCCESS',
              'Event was successfully created'
            )
          );
        })
        .catch(error => {
          return dispatch(
            rEvents.modifyUpdateEvent(
              'ERROR',
              'Something went wrong, please try again'
            )
          );
        });
    }

    return eventRef
      .set({
        ...event
      })
      .then(() => {
        return batch.commit();
      })
      .then(() => {
        return dispatch(
          rEvents.modifyUpdateEvent('SUCCESS', 'Event was successfully created')
        );
      })
      .catch(error => {
        return dispatch(
          rEvents.modifyUpdateEvent(
            'ERROR',
            'Something went wrong, please try again'
          )
        );
      });
  };
};

export const updateEventGeneral = (
  event: Event,
  eventId: string,
  photographerId: string,
  logoFile: TeamPicsFile
): ThunkResult<void> => {
  return dispatch => {
    const eventRef = firestore.collection('events').doc(eventId);
    const storagePath = `events/${eventId}/${photographerId}/logos/league-logo-${eventId}`;
    dispatch(rEvents.modifyUpdateEvent('LOADING', true));

    if (logoFile.blob) {
      return storage
        .uploadImage(logoFile, storagePath)
        .then(url => {
          return eventRef.update({
            ...event,
            leagueLogoUrl: url
          });
        })
        .then(() => {
          dispatch(loadEvent(eventId));
        })
        .then(() => {
          dispatch(
            rEvents.modifyUpdateEvent('SUCCESS', 'Event successfully updated.')
          );
        })
        .catch(error => {
          dispatch(
            rEvents.modifyUpdateEvent(
              'ERROR',
              'Something went wrong, please try again.'
            )
          );
        });
    }

    return eventRef
      .update({
        ...event
      })
      .then(() => {
        dispatch(loadEvent(eventId));
      })
      .then(() => {
        dispatch(
          rEvents.modifyUpdateEvent('SUCCESS', 'Event successfully updated.')
        );
      })
      .catch(error => {
        dispatch(
          rEvents.modifyUpdateEvent(
            'ERROR',
            'Something went wrong, please try again.'
          )
        );
      });
  };
};

export const updateShoots = (
  eventId: string,
  shoots: Array<Shoot>
): ThunkResult<void> => {
  return dispatch => {
    const batch = firestore.batch();
    dispatch(rEvents.modifyUpdateEvent('LOADING', true));

    shoots.forEach(shoot => {
      const shootRef = firestore
        .collection('events')
        .doc(eventId)
        .collection('shoots')
        .doc();
      batch.set(shootRef, {
        ...shoot
      });
    });

    return batch
      .commit()
      .then(() => {
        dispatch(rEvents.modifyShootsArray('CLEAR', {} as Shoot));
        return dispatch(loadShoots(eventId));
      })
      .then(() => {
        dispatch(
          rEvents.modifyUpdateEvent(
            'SUCCESS',
            'Shoots were successfully updated.'
          )
        );
      })
      .catch(error => {
        dispatch(
          rEvents.modifyUpdateEvent(
            'ERROR',
            'Something went wrong, please try again.'
          )
        );
      });
  };
};

export const exportGroups = (
  eventId: string,
  eventName: string
): ThunkResult<void> => {
  return dispatch => {
    const groupsRef = firestore
      .collection('events')
      .doc(eventId)
      .collection('groups');
    dispatch(rEvents.modifyExportEvent('LOADING', true));
    return groupsRef
      .get()
      .then(groupsSnap => {
        let groups: any = [];
        groupsSnap.forEach(groupSnap => {
          const group = { groupId: groupSnap.id, ...groupSnap.data() };
          groups.push(group);
        });
        converter.json2csv(
          groups,
          (err: any, csv: any) => {
            fileDownload(csv, `groups_${eventName}_${eventId}.csv`);
          },
          { expandArrayObjects: false, emptyFieldValue: '' }
        );
      })
      .then(() => {
        return dispatch(
          rEvents.modifyExportEvent('SUCCESS', 'Successfully exported groups.')
        );
      })
      .catch(error => {
        dispatch(
          rEvents.modifyExportEvent(
            'ERROR',
            'Something went wrong, please try again.'
          )
        );
      });
  };
};

export const exportSubjects = (
  eventId: string,
  eventName: string
): ThunkResult<void> => {
  return dispatch => {
    const groupsRef = firestore
      .collection('events')
      .doc(eventId)
      .collection('groups')
      .orderBy('fullName');
    dispatch(rEvents.modifyExportEvent('LOADING', true));
    return groupsRef
      .get()
      .then(groupsSnap => {
        let groups: Array<Group> = [];
        groupsSnap.forEach(groupSnap => {
          const group = { key: groupSnap.id, ...(groupSnap.data() as Group) };
          groups.push(group);
        });

        let promiseArray: any = [];
        groups.forEach(group => {
          const subjectsRef = firestore
            .collection('subjects')
            .where('groupId', '==', group.key);
          const promise = subjectsRef.get();
          promiseArray.push(promise);
        });
        return Promise.all(promiseArray);
      })
      .then(results => {
        let subjects: Array<any> = [];
        results.forEach((result: any) => {
          result.forEach((subjectSnap: any) => {
            const subject = {
              subjectId: subjectSnap.id,
              ...subjectSnap.data()
            };
            subjects.push(subject);
          });
        });
        let sortedSubjects = subjects.sort(dynamicSort('firstName', "", "asc"));
        converter.json2csv(
          sortedSubjects,
          (err: any, csv: any) => {
            fileDownload(csv, `subjects_${eventName}_${eventId}.csv`);
          },
          { expandArrayObjects: false, emptyFieldValue: '' }
        );
      })
      .then(() => {
        return dispatch(
          rEvents.modifyExportEvent('SUCCESS', 'Successfully exported groups.')
        );
      })
      .catch(error => {
        dispatch(
          rEvents.modifyExportEvent(
            'ERROR',
            'Something went wrong, please try again.'
          )
        );
      });
  };
};
