import React, { Component } from 'react';
import { connect } from 'react-redux';
import { User, UserMeta } from '../../models/UsersModel';
import { StepIntro } from '../../models/TeamBuilderModel';
import { Card, Checkbox, Button, Avatar, message } from 'antd';
import { Event } from '../../models/EventsModel';
import * as rTeambuilder from '../../reducers/teambuilder';
import Animate from 'rc-animate';

interface OwnProps {}
interface StateProps {
	user: User;
	userMeta: UserMeta;
	event: Event;
	stepIntro: StepIntro;
}
interface DispatchProps {
	onSetPageNumber: Function;
	onUpdateStepIntro: Function;
}
type Props = StateProps & DispatchProps & OwnProps;
type State = { show: boolean };

const mapStateToProps = (state: any) => ({
	user: state.usersState.user,
	userMeta: state.usersState.userMeta,
	event: state.teambuilderState.event,
	stepIntro: state.teambuilderState.stepIntro
});

const mapDispatchToProps = (dispatch: any) => ({
	onSetPageNumber: (pageNumber: number) => {
		dispatch(rTeambuilder.setPageNumber(pageNumber));
	},
	onUpdateStepIntro: (stepIntro: StepIntro) => {
		dispatch(rTeambuilder.setStepIntro(stepIntro));
	}
});
const Div = (props: any) => {
	const childrenProps = { ...props };
	delete childrenProps.show;
	return <div {...childrenProps} />;
};
class StepIntroComp extends Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = { show: true };
	}
	componentDidMount() {}

	render() {
		const { stepIntro, event } = this.props;

		return (
			<div
				className='uk-width-expand uk-padding uk-flex uk-flex-center uk-flex-middle'
				style={{
					minHeight: '100vh',
					overflowY: 'auto',
					background: `linear-gradient(to bottom right, ${event.priColor}, ${event.secColor})`
				}}
			>
				<Animate transitionName='fade' transitionAppear>
					<Div show={this.state.show}>
						<Card style={{ borderRadius: '5px', minWidth: '80vw', textAlign: 'center' }}>
							<h2>{`Welcome ${event.name}!`}</h2>
							<Avatar shape='square' size={200} src={event.leagueLogoUrl} />

							<p>
								You will need to know your league's division, colors and basic information for all
								students and staff on your team
							</p>
							<div className='uk-flex uk-flex-column'>
								<Checkbox
									checked={stepIntro.disclaimer}
									onChange={(e) => {
										this.props.onUpdateStepIntro({
											...stepIntro,
											disclaimer: e.target.checked
										});
									}}
								>
									I have all the information I need and ready to get started*
								</Checkbox>
								<div className='uk-flex uk-flex-center uk-margin-top'>
									<Button
										type='primary'
										onClick={() => {
											if (!stepIntro.disclaimer) {
												message.info('Please accept the discalimer to proceed.');
											} else {
												this.props.onSetPageNumber(1);
											}
										}}
									>
										Proceed
									</Button>
								</div>
							</div>
						</Card>
					</Div>
				</Animate>
			</div>
		);
	}
}

export default connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps)(StepIntroComp);
