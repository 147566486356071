import { storage, firebase, firestore } from './firebaseConfig';
import { TeamPicsFile, ThunkResult } from '../models/Commons';

export const uploadImage = (file: TeamPicsFile, path: string) => {
	let storageLocation = path;
	let storageRef = storage.ref(storageLocation);

	let metadata: any = {};

	return storageRef
		.putString(file.blob, 'data_url', {
			customMetadata: metadata,
			contentType: file.mimeType
		})
		.then((snapshot) => {
			return storageRef.getDownloadURL();
		})
		.catch((error) => {});
};

export const uploadImageGroup = (file: TeamPicsFile, path: string, contactId: string) => {
	let storageLocation = path;
	let storageRef = storage.ref(storageLocation);

	let metadata: any = { contactId };
	return storageRef
		.putString(file.blob, 'data_url', {
			customMetadata: metadata
		})
		.then((snapshot) => {
			return storageRef.getDownloadURL();
		})
		.catch((error) => {});
};

function blobToDataURL(blob: Blob) {
	return new Promise((resolve, reject) => {
		var fr = new FileReader();
		fr.onload = () => {
			resolve({ dataURL: fr.result });
		}; // CHANGE to whatever function you want which would eventually call resolve
		fr.readAsDataURL(blob);
	});
}
