import { combineReducers } from 'redux';
import {
  createReducerWithTaskName,
  dispatchTaskWithState,
  createReducerForFirebaseArray,
  dispatchFirebaseArrayWithState
} from '../helpers/reducers';
import { INITIAL_STATE_EMPTY_TASK, Contact } from '../models/Commons';
import { Shoot, Event } from '../models/EventsModel';

/**
 * INITIAL STATE
 */
const INITIAL_STATE_SELECTED_EVENT = {
  key: ''
};

const INITIAL_STATE_ACTIVE_FILTER = true;

/**
 * CONSTANTS
 */
const LOAD_EVENTS = 'load events';
const LOAD_EVENT_DETAILS = 'load event details';
const LOAD_SHOOTS = 'load shoots';
const UPDATE_EVENT = 'update event';
const SET_SELECTED_EVENT = 'set selected event';
const SET_ACTIVE_FILTER = 'set active filter';
const EXPORT_EVENT = 'export event';

const CLEAR_EVENTS_REDUCER = 'clear events reducer';

/**
 * ACTIONS
 */
export const setSelectedEvent = (event: Event) => ({
  type: SET_SELECTED_EVENT,
  event
});

export const setActiveFilter = (active: Boolean) => ({
  type: SET_ACTIVE_FILTER,
  active
});

export const modifyLoadEvents = (
  taskState: string,
  value: string | boolean
) => {
  return dispatchTaskWithState(LOAD_EVENTS, taskState, value);
};

export const modifyLoadEventDetails = (
  taskState: string,
  value: string | boolean
) => {
  return dispatchTaskWithState(LOAD_EVENT_DETAILS, taskState, value);
};

export const modifyLoadShoots = (
  taskState: string,
  value: string | boolean
) => {
  return dispatchTaskWithState(LOAD_SHOOTS, taskState, value);
};

export const modifyUpdateEvent = (
  taskState: string,
  value: string | boolean
) => {
  return dispatchTaskWithState(UPDATE_EVENT, taskState, value);
};

export const modifyExportEvent = (
  taskState: string,
  value: string | boolean
) => {
  return dispatchTaskWithState(EXPORT_EVENT, taskState, value);
};

export const modifyEventsArray = (arrayState: string, event: Event) => {
  return dispatchFirebaseArrayWithState(LOAD_EVENTS, arrayState, event);
};

export const modifyShootsArray = (arrayState: string, shoot: Shoot) => {
  return dispatchFirebaseArrayWithState(LOAD_SHOOTS, arrayState, shoot);
};

export const clearEventsReducer = () => ({
  type: CLEAR_EVENTS_REDUCER
});

/**
 * REDUCERS
 */
function selectedEventReducer(
  state = INITIAL_STATE_SELECTED_EVENT,
  action: any
) {
  switch (action.type) {
    case SET_SELECTED_EVENT: {
      return {
        ...state,
        ...action.event
      };
    }
    default:
      return state;
  }
}

function activeFilterReducer(
  state = INITIAL_STATE_ACTIVE_FILTER,
  action: any
) {
  switch (action.type) {
    case SET_ACTIVE_FILTER: {
      return action.active;
    }
    default:
      return state;
  }
}

const eventsRootReducer = combineReducers({
  selectedEvent: selectedEventReducer,
  active: activeFilterReducer,
  exportEvent: createReducerWithTaskName(
    EXPORT_EVENT,
    INITIAL_STATE_EMPTY_TASK
  ),
  events: createReducerForFirebaseArray(LOAD_EVENTS, []),
  shoots: createReducerForFirebaseArray(LOAD_SHOOTS, []),
  loadShoots: createReducerWithTaskName(LOAD_SHOOTS, INITIAL_STATE_EMPTY_TASK),
  loadEvents: createReducerWithTaskName(LOAD_EVENTS, INITIAL_STATE_EMPTY_TASK),
  updateEvent: createReducerWithTaskName(
    UPDATE_EVENT,
    INITIAL_STATE_EMPTY_TASK
  ),
  loadEventDetails: createReducerWithTaskName(
    LOAD_EVENT_DETAILS,
    INITIAL_STATE_EMPTY_TASK
  )
});

const eventsReducer = (state: any, action: any) => {
  if (action.type === CLEAR_EVENTS_REDUCER) {
    state = {
      active: INITIAL_STATE_ACTIVE_FILTER,
      selectedEvent: { ...INITIAL_STATE_SELECTED_EVENT },
      events: [],
      shoots: [],
      loadShoots: { ...INITIAL_STATE_EMPTY_TASK },
      loadEvents: { ...INITIAL_STATE_EMPTY_TASK },
      updateEvent: { ...INITIAL_STATE_EMPTY_TASK },
      loadEventDetails: { ...INITIAL_STATE_EMPTY_TASK }
    };
  }

  return eventsRootReducer(state, action);
};

export default eventsReducer;
