import React, { Component } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import * as routes from '../../constants/routeNames';
import { Row, Col, Card, Form, Icon, Input, Button, Checkbox, Spin, Alert } from 'antd';

import { auth as fAuth } from '../../firebase';
import withLoggedIn from '../../hoc/withLoggedIn';

interface OwnProps extends RouteComponentProps {}
interface StateProps {}
interface DispatchProps {}
type Props = StateProps & DispatchProps & OwnProps;
type State = {
	email: string;
	password: string;
	forgotEmail: string;
	rememberMe: boolean;
	loginPageMode: string;
	error: string;
	loading: boolean;
	success: string;
};
const INITIAL_STATE = {
	email: '',
	password: '',
	forgotEmail: '',
	rememberMe: false,
	loginPageMode: 'login',
	success: '',
	error: '',
	loading: false
};

const mapStateToProps = (state: any) => ({});
const mapDispatchToProps = (dispatch: any) => ({});

class LoginPage extends Component<Props, State> {
	constructor(props: Props) {
		super(props);

		this.state = {
			...INITIAL_STATE
		};
		this.enterPress = this.enterPress.bind(this);
	}
	enterPress(event: any) {
		if (event.keyCode === 13) {
			//Do whatever when esc is pressed
			if (this.state.email !== '' && this.state.password !== '') {
				this.onLogin();
			}
		}
	}
	componentDidMount() {
		document.addEventListener('keydown', this.enterPress, false);
	}
	componentWillUnmount() {
		document.removeEventListener('keydown', this.enterPress, false);
	}
	onChangeLoginMode = (mode: string) => {
		this.setState({
			loginPageMode: mode,
			error: '',
			success: '',
			loading: false
		});
	};
	onLogin = () => {
		const { email, password, rememberMe } = this.state;
		const { history } = this.props;
		this.setState({ loading: true, success: '', error: '' });

		if (rememberMe) {
			fAuth
				.doSignInWithEmailAndPasswordLocal(email, password)
				.then(() => {
					this.setState({ ...INITIAL_STATE });
					return history.push(routes.DASHBOARD);
				})
				.catch((error: any) => {
					this.setState({
						error: fAuth.handleLoginError(error.code),
						loading: false
					});
				});
		} else {
			fAuth
				.doSignInWithEmailAndPasswordSession(email, password)
				.then(() => {
					this.setState({ ...INITIAL_STATE });
					return history.push(routes.DASHBOARD);
				})
				.catch((error: any) => {
					this.setState({
						error: fAuth.handleLoginError(error.code),
						loading: false
					});
				});
		}
	};

	onForgotPassword = () => {
		const { forgotEmail } = this.state;
		this.setState({ loading: true, success: '', error: '' });
		fAuth
			.doPasswordReset(forgotEmail)
			.then(() => {
				this.setState({ ...INITIAL_STATE });
				return this.setState({
					success: `An mail reset error was sent to ${forgotEmail}`
				});
			})
			.catch((error: any) => {
				this.setState({
					error: fAuth.handleForgotPasswordError(error.code),
					loading: false
				});
			});
	};

	googleLogin = () => {
		const { history } = this.props;

		fAuth
			.doRegisterWithGoogle()
			.then(() => {
				this.setState({ ...INITIAL_STATE });
				return history.push(routes.DASHBOARD);
			})
			.catch((error: any) => {
				this.setState({
					error: fAuth.handleGoogleError(error.code, error.email),
					loading: false
				});
			});
	};

	facebookLogin = () => {
		const { history } = this.props;

		fAuth
			.doRegisterWithFacebook()
			.then(() => {
				this.setState({ ...INITIAL_STATE });
				return history.push(routes.DASHBOARD);
			})
			.catch((error: any) => {
				this.setState({
					error: fAuth.handleFacebookError(error.code, error.email),
					loading: false
				});
			});
	};
	render() {
		const { email, password, loginPageMode, rememberMe, forgotEmail, error, loading, success } = this.state;

		const loginIsInvalid = password === '' || email === '';
		const forgotIsInvalid = forgotEmail === '';

		return (
			<div className='uk-flex uk-flex-center' style={{ height: '100vh', overflowY: 'auto' }}>
				<Row type='flex' justify='space-between' align='middle' style={{ height: '100%', maxWidth: '1200px' }}>
					<Col sm={24} md={10}>
						<div className='uk-padding'>
							<h1>Team Pictures Done Easy</h1>
							<p>
								TeamPics is a photography platform that lets you manage events, clients and teams. Sign
								up today to save on precious time, money and energy.
							</p>
							<Button
								type='primary'
								onClick={() => {
									this.props.history.push(routes.REGISTER);
								}}
							>
								Sign Up
							</Button>
						</div>
					</Col>
					<Col sm={24} md={14}>
						<div className='uk-flex uk-flex-center'>
							<Card className='rounded-card login-card' style={{ maxWidth: '450px' }}>
								<Card
									style={{
										background: '#4DC5D5',
										marginTop: '-50px',
										marginBottom: '12px'
									}}
									className='rounded-card'
								>
									<div className='uk-flex uk-flex-center'>
										<div className='uk-flex uk-flex-column'>
											<h2 style={{ color: 'white' }}>Continue With</h2>
											<div className='uk-flex uk-flex-row uk-flex-center'>
												<Button shape='circle' icon='facebook' onClick={this.facebookLogin} />
												<div style={{ width: '24px' }} />
												<Button shape='circle' icon='google' onClick={this.googleLogin} />
											</div>
										</div>
									</div>
								</Card>

								<Spin
									indicator={<Icon type='loading' style={{ fontSize: 24 }} spin />}
									spinning={loading}
								>
									{loginPageMode === 'login' ? (
										<Form className='login-form'>
											<Row className='uk-margin-top'>
												<Input
													prefix={<Icon type='user' style={{ color: 'rgba(0,0,0,.25)' }} />}
													placeholder='Email'
													value={email}
													onChange={(e) => {
														this.setState({ email: e.target.value.trim() });
													}}
												/>
											</Row>
											<Row className='uk-margin-small-top'>
												<Input
													prefix={<Icon type='lock' style={{ color: 'rgba(0,0,0,.25)' }} />}
													type='password'
													placeholder='Password'
													value={password}
													onChange={(e) => {
														this.setState({ password: e.target.value });
													}}
												/>
											</Row>

											<Row
												className='uk-margin-small-top uk-margin-small-bottom'
												type='flex'
												justify='space-around'
												align='middle'
											>
												<Checkbox
													checked={this.state.rememberMe}
													onChange={(e) => {
														this.setState({ rememberMe: e.target.checked });
													}}
												>
													Remember me
												</Checkbox>
											</Row>
										</Form>
									) : (
										<Form className='login-form'>
											<Row className='uk-margin-top'>
												<Input
													prefix={<Icon type='user' style={{ color: 'rgba(0,0,0,.25)' }} />}
													placeholder='Email'
													value={forgotEmail}
													onChange={(e) => {
														this.setState({
															forgotEmail: e.target.value.trim()
														});
													}}
												/>
											</Row>
										</Form>
									)}

									<Row className='uk-margin-top'>
										{loginPageMode === 'login' ? (
											<Button
												disabled={loginIsInvalid}
												type='primary'
												className='login-form-button'
												style={{ width: '100%' }}
												onClick={this.onLogin}
											>
												Log in
											</Button>
										) : (
											<Button
												disabled={forgotIsInvalid}
												type='primary'
												className='login-form-button'
												style={{ width: '100%' }}
												onClick={this.onForgotPassword}
											>
												Send Reset Email
											</Button>
										)}
									</Row>
									<div className='uk-margin-small-top uk-margin-small-bottom'>
										{error && <Alert className='uk-text-center' message={error} type='error' />}
									</div>
									<div className='uk-margin-small-top uk-margin-small-bottom'>
										{success && (
											<Alert className='uk-text-center' message={success} type='success' />
										)}
									</div>
									<div className='uk-card-footer'>
										<Row
											style={{ marginTop: '12px' }}
											type='flex'
											justify='space-around'
											align='middle'
										>
											{loginPageMode === 'login' ? (
												<a onClick={() => this.onChangeLoginMode('forgot')}>Forgot Password?</a>
											) : (
												<a onClick={() => this.onChangeLoginMode('login')}>
													Already a member? Log in.
												</a>
											)}
										</Row>
									</div>
								</Spin>
							</Card>
						</div>
					</Col>
				</Row>
			</div>
		);
	}
}

//add withloggedin
export default compose<any, any>(
	withRouter,
	withLoggedIn(),
	connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps)
)(LoginPage);
