import React, { Component } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { setPage } from '../../reducers/session';
import * as routes from '../../constants/routeNames';
import { List, Avatar, Button, Skeleton, Tag, message, Spin, Icon } from 'antd';
import { PhotographerRequest } from '../../models/PhotographerRequestModel';
import { TaskState } from '../../models/Commons';

import * as rPhotographers from '../../reducers/photographers';
import { photographers as fPhotographers } from '../../firebase';
import { User, UserMeta } from '../../models/UsersModel';

interface OwnProps extends RouteComponentProps {}

interface StateProps {
  user: User;
  userMeta: UserMeta;
  requests: Array<PhotographerRequest>;
  loadRequests: TaskState;
  approveRequest: TaskState;
}

interface DispatchProps {
  onLoadRequests: Function;
  onApproveRequest: Function;
}

type Props = StateProps & DispatchProps & OwnProps;
type State = {};

const mapStateToProps = (state: any) => ({
  user: state.usersState.user,
  userMeta: state.usersState.userMeta,
  requests: state.photographersState.requests,
  loadRequests: state.photographersState.loadRequests,
  approveRequest: state.photographersState.approveRequest
});

const mapDispatchToProps = (dispatch: any) => ({
  onLoadRequests: () => {
    dispatch(fPhotographers.subscribePhotographerRequests());
  },
  onApproveRequest: (
    photographerId: string,
    requestId: string,
    currentValue: boolean
  ) => {
    dispatch(
      fPhotographers.updatePhotographerStatus(
        photographerId,
        requestId,
        currentValue
      )
    );
  }
});

class RequestList extends Component<Props, State> {
  componentDidMount() {
    this.props.onLoadRequests();
  }
  componentWillReceiveProps(nextProps: Props) {
    if (
      nextProps.approveRequest.success &&
      !this.props.approveRequest.success
    ) {
      message.success(nextProps.approveRequest.success);
    }
  }
  render() {
    const {
      user,
      userMeta,
      requests,
      loadRequests,
      approveRequest
    } = this.props;

    return (
      <div
        className='uk-width-expand uk-padding uk-padding-remove-top'
        style={{ minHeight: '50vh', maxHeight: '70vh', overflowY: 'auto' }}
      >
        <List
          locale={{ emptyText: 'No Requests available.' }}
          className='small-list'
          itemLayout='horizontal'
          loading={{
            indicator: <Icon type='loading' style={{ fontSize: 36 }} spin />,
            spinning: loadRequests.loading as boolean
          }}
          dataSource={requests}
          renderItem={(request: PhotographerRequest) => (
            <List.Item
              actions={[
                <a
                  onClick={() => {
                    this.props.onApproveRequest(
                      request.userId,
                      request.key,
                      request.accepted
                    );
                  }}
                >
                  {request.accepted ? 'Revoke' : 'Approve'}
                </a>
              ]}
            >
              <Skeleton
                avatar
                title={false}
                loading={(request as any).loading}
                active
              >
                <List.Item.Meta
                  title={<p>{request.name}</p>}
                  description={`${
                    request.name
                  } would like to register as a photographer`}
                />
                <div className='uk-flex uk-flex-center uk-flex-middle'>
                  <span>
                    <Tag
                      color={request.accepted ? 'green' : 'red'}
                      key={'active'}
                    >
                      {request.accepted ? 'Approved' : 'Pending'}
                    </Tag>
                  </span>{' '}
                </div>
              </Skeleton>
            </List.Item>
          )}
        />
      </div>
    );
  }
}

export default compose<any, any>(
  withRouter,
  connect<StateProps, DispatchProps, OwnProps>(
    mapStateToProps,
    mapDispatchToProps
  )
)(RequestList);
