import React, { Component } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { UserMeta } from '../../models/UsersModel';
import { TaskState } from '../../models/Commons';
import { Button, Table, Icon } from 'antd';
import { Package } from '../../models/ProductsModel';
import PackageModal from './PackageModal';
import { getArrayItemByKey } from '../../helpers/arrays';
import * as rProducts from '../../reducers/products';
import emptyview from '../../assets/emptyview_photo.png';
import { firebase } from '../../firebase'

interface OwnProps extends RouteComponentProps { }

interface StateProps {
	user: firebase.firebase.User;
	userMeta: UserMeta;
	loadPackages: TaskState;
	packages: Array<Package>;
	newItem: TaskState;
	updateItem: TaskState;
}

interface DispatchProps {
	onSetSelectedPackage: Function;
	onClearItemState: Function;
}

type Props = StateProps & DispatchProps & OwnProps;
type State = { visible: boolean };

const mapStateToProps = (state: any) => ({
	user: state.usersState.user,
	userMeta: state.usersState.userMeta,
	loadPackages: state.productsState.loadPackages,
	packages: state.productsState.packages,
	newItem: state.productsState.newItem,
	updateItem: state.productsState.updateItem
});

const mapDispatchToProps = (dispatch: any) => ({
	onSetSelectedPackage: (pack: Package) => {
		dispatch(rProducts.setSelectedPackage(pack));
	},
	onClearItemState: () => {
		dispatch(rProducts.modifyNewItem('LOADING', false));
		dispatch(rProducts.modifyUpdateItem('LOADING', false));
	}
});

const INITIAL_STATE = {
	visible: false
};

class PackagesList extends Component<Props, State> {
	state = { ...INITIAL_STATE };
	openModal = () => {
		this.props.onClearItemState();
		this.setState({ visible: true });
	};
	componentDidMount() { }
	componentWillReceiveProps(nextProps: Props) {
		if (nextProps.newItem.success && !this.props.newItem.success) {
			this.setState({ visible: false });
		}
		if (nextProps.updateItem.success && !this.props.updateItem.success) {
			this.setState({ visible: false });
		}
	}
	render() {
		const { packages, loadPackages, user, userMeta } = this.props;
		const columns = [
			{
				title: 'Image',
				dataIndex: 'imageUrl',
				className: 'column-image',
				render: (logoUrl: string) => <img className='col-image' src={logoUrl} />
			},
			{
				title: 'Name',
				dataIndex: 'name'
			},
			{
				title: 'SKU',
				dataIndex: 'sku'
			},
			{
				title: 'Price',
				dataIndex: 'price',
				render: (price: number) => <span>{Math.round(price) / 100}</span>
			},
			{
				title: 'Type',
				dataIndex: 'type'
			}
			// {
			// 	title: 'Action',
			// 	dataIndex: 'key',
			// 	render: (key: string) => (
			// 		<a
			// 			onClick={() => {
			// 				this.props.onSetSelectedPackage(getArrayItemByKey(key, packages));
			// 				this.openModal();
			// 			}}
			// 		>
			// 			Edit
			// 		</a>
			// 	)
			// }
		];
		const empty = (
			<div className='uk-flex uk-flex-center uk-text-center uk-width-expand'>
				<div className='uk-flex uk-flex-column uk-flex-center uk-width-large'>
					<div className='uk-width-expand uk-flex uk-flex-center'>
						<img src={emptyview} className='empty-view' />
					</div>
					<span className='uk-margin-top uk-margin-bottom'>You haven't set up any packages yet.</span>
					<Button
						onClick={() => {
							this.openModal();
						}}
						type='primary'
					>
						Set up your first Package
					</Button>
				</div>
			</div>
		);
		const header = (
			<div className='uk-flex uk-flex-right uk-width-expand'>
				<Button
					onClick={() => {
						this.openModal();
					}}
					type='primary'
				>
					New
				</Button>
			</div>
		);
		return (
			<div className='uk-width-expand'>
				<Table
					locale={{ emptyText: loadPackages.loading && empty }}
					loading={{
						indicator: <Icon type='loading' style={{ fontSize: 36 }} spin />,
						spinning: loadPackages.loading as boolean
					}}
					onRow={(pack: Package, index: number) => {
						return {
							onClick: (event: any) => {
								this.props.onSetSelectedPackage(getArrayItemByKey(pack.key!, packages));
								this.openModal();
							} // click row
						};
					}}
					className='table-row-clickable'
					columns={columns}
					dataSource={packages}
					showHeader
					bordered
					title={() => header}
					pagination={{ pageSize: 10 }}
					scroll={{ x: '500px' }}
				/>
				<PackageModal
					visible={this.state.visible}
					onCancel={() => {
						this.setState({ visible: false });
					}}
				/>
			</div>
		);
	}
}

const condition = (authUser: any) => !!authUser;

export default compose<any, any>(
	withRouter,
	connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps)
)(PackagesList);
