import { combineReducers } from 'redux';
import {
  createReducerWithTaskName,
  dispatchTaskWithState,
  createReducerForFirebaseArray,
  dispatchFirebaseArrayWithState
} from '../helpers/reducers';
import { INITIAL_STATE_EMPTY_TASK } from '../models/Commons';
import { PhotographerRequest } from '../models/PhotographerRequestModel';

/**
 * INITIAL STATES
 */

/**
 * CONSTANTS
 */
const LOAD_REQUESTS = 'load requests';
const APPROVE_REQUEST = 'approve request';
const CLEAR_PHOTOGRAPHERS_REDUCER = 'clear photographers reducer';

/**
 * ACTIONS
 */
export const modifyLoadRequests = (
  taskState: string,
  value: string | boolean
) => {
  return dispatchTaskWithState(LOAD_REQUESTS, taskState, value);
};

export const modifyApproveRequest = (
  taskState: string,
  value: string | boolean
) => {
  return dispatchTaskWithState(APPROVE_REQUEST, taskState, value);
};

export const modifyRequestsArray = (
  arrayState: string,
  request: PhotographerRequest
) => {
  return dispatchFirebaseArrayWithState(LOAD_REQUESTS, arrayState, request);
};

const photographersRootReducer = combineReducers({
  requests: createReducerForFirebaseArray(LOAD_REQUESTS, []),
  loadRequests: createReducerWithTaskName(LOAD_REQUESTS, {
    ...INITIAL_STATE_EMPTY_TASK,
    loading: true
  }),
  approveRequest: createReducerWithTaskName(
    APPROVE_REQUEST,
    INITIAL_STATE_EMPTY_TASK
  )
});

const photographersReducer = (state: any, action: any) => {
  if (action.type === CLEAR_PHOTOGRAPHERS_REDUCER) {
    state = {
      requests: [],
      loadRequests: { ...INITIAL_STATE_EMPTY_TASK },
      approveRequest: { ...INITIAL_STATE_EMPTY_TASK }
    };
  }

  return photographersRootReducer(state, action);
};

export default photographersReducer;
