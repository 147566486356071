import React, { Component } from 'react';
import { SketchPicker } from 'react-color';

import * as colorHelpers from '../../helpers/colors';
import { Color } from '../../models/Commons';
import { Dropdown } from 'antd';

type Props = { setHex: Function; hex: string; style?: any; className?: string };
type State = { displayColorPicker: boolean; color: Color };

class ColorPicker extends Component<Props, State> {
	constructor(props: Props) {
		super(props);

		this.state = {
			displayColorPicker: false,
			color: {
				r: 0,
				g: 0,
				b: 0,
				a: 1
			}
		};
	}
	handleClick = () => {
		this.setState({ displayColorPicker: !this.state.displayColorPicker });
	};
	handleClose = () => {
		this.setState({ displayColorPicker: false });
	};
	handleChange = (color: any) => {
		this.setState({ color: color.rgb });
	};
	onChangeComplete = (color: any) => {
		this.setState({ displayColorPicker: false });
		this.props.setHex(color);
	};

	render() {
		const { hex, className, style } = this.props;
		const rgbaArray = colorHelpers.hexToRgbA(hex);

		const colorPicker = (
			<SketchPicker
				color={this.state.color}
				onChange={this.handleChange}
				onChangeComplete={this.onChangeComplete}
			/>
		);
		return (
			<div style={style} className={className}>
				<Dropdown overlayClassName='overlay' overlay={colorPicker} trigger={[ 'hover' ]}>
					<div className='swatch'>
						<div
							style={{
								background: rgbaArray
							}}
							className='color'
						/>
					</div>
				</Dropdown>
			</div>
		);
	}
}

export default ColorPicker;
