import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';

import { auth } from '../firebase';
import * as routes from '../constants/routeNames';

// https://www.robinwieruch.de/react-firebase-authorization-roles-permissions/

const withLoggedIn = () => (Component) => {
	class WithLoggedIn extends React.Component {
		constructor(props) {
			super(props);
			this.state = { loggedIn: false };
		}
		componentDidMount() {
			if (this.props.authUser) {
				this.props.history.push(routes.DASHBOARD);
			}
			this.authListener = auth.onAuthUserListener(
				(authUser) => {
					if (authUser) {
						this.props.history.push(routes.DASHBOARD);
						this.setState({ loggedIn: true });
					} else {
						this.setState({ loggedIn: false });
					}
				},
				() => {}
			);
		}
		componentWillUnmount() {
			this.authListener();
		}

		render() {
			return !this.state.loggedIn ? <Component {...this.props} /> : <div>Loading..</div>;
		}
	}

	const mapStateToProps = (state) => ({
		authUser: state.sessionState.authUser
	});

	const mapDispatchToProps = (dispatch) => ({
		onSetAuthUser: (authUser) => dispatch({ type: 'AUTH_USER_SET', authUser })
	});

	return compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(WithLoggedIn);
};

export default withLoggedIn;
