import React, { Component } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { UserMeta, User } from '../../models/UsersModel';
import { TaskState, TeamPicsFile } from '../../models/Commons';
import {
	Empty,
	Button,
	Table,
	Modal,
	Form,
	Spin,
	Input,
	Select,
	Upload,
	Icon,
	Alert,
	Divider,
	Row,
	Col,
	message
} from 'antd';
import {
	Product,
	Package,
	Offering,
	ProductTypes,
	ProductCategories,
	PrintQueueOptions
} from '../../models/ProductsModel';
import {
	productCategoryOptions,
	getProductTypeOptions,
	getProductCategoryOptions,
	getPrintQueueOptions
} from '../../constants/selectOptions';
import { products as fProducts } from '../../firebase';
import * as rProducts from '../../reducers/products';
import { RcFile } from 'antd/lib/upload/interface';
import {
	getFileType,
	checkFileSize,
	getBase64,
	getMimeType,
	dummyRequest,
	checkIfValidImage
} from '../../constants/fileTypes';
import TextArea from 'antd/lib/input/TextArea';
import { titleCase } from '../../helpers/string';

interface OwnProps extends RouteComponentProps {
	visible: boolean;
	onCancel: Function;
}

interface StateProps {
	user: User;
	userMeta: UserMeta;
	newItem: TaskState;
	updateItem: TaskState;
	selectedProduct: Product;
}

interface DispatchProps {
	onNewItem: Function;
	onUpdateItem: Function;
	onClearSelectedProduct: Function;
}

type Props = StateProps & DispatchProps & OwnProps;
type State = {
	error: string;
	pageNumber: number;
	extras: Array<any>;
	sku: string;
	name: string;
	type: ProductTypes;
	category: ProductCategories;
	printQueue: PrintQueueOptions;
	description: string;
	imageUrl: string;
	imageFile: TeamPicsFile;
};

const INITIAL_STATE = {
	pageNumber: 0,
	sku: '',
	name: '',
	type: '' as ProductTypes,
	category: '' as ProductCategories,
	printQueue: '' as PrintQueueOptions,
	description: '',
	imageUrl: '',
	imageFile: {} as TeamPicsFile,
	extras: [ { key: '', value: '' } ],
	error: ''
};
const formItemLayout = {
	labelCol: {
		xs: { span: 24 },
		sm: { span: 8 },
		md: { span: 4 }
	},
	wrapperCol: {
		xs: { span: 24 },
		sm: { span: 16 }
	}
};
const tailFormItemLayout = {
	wrapperCol: {
		xs: {
			span: 24,
			offset: 0
		},
		sm: {
			span: 16,
			offset: 8
		},
		md: {
			span: 16,
			offset: 4
		}
	}
};
const mapStateToProps = (state: any) => ({
	user: state.usersState.user,
	userMeta: state.usersState.userMeta,
	newItem: state.productsState.newItem,
	updateItem: state.productsState.updateItem,
	selectedProduct: state.productsState.selected.selectedProduct
});

const mapDispatchToProps = (dispatch: any) => ({
	onNewItem: (uid: string, collectionKey: string, imageFile: TeamPicsFile, item: Product) => {
		dispatch(fProducts.newItem(uid, collectionKey, imageFile, item));
	},
	onUpdateItem: (uid: string, collectionKey: string, imageFile: TeamPicsFile, item: Product) => {
		dispatch(fProducts.updateItem(uid, collectionKey, imageFile, item));
	},
	onClearSelectedProduct: () => {
		dispatch(rProducts.setSelectedProduct({ key: '' } as Product));
	}
});

class ProductModal extends Component<Props, State> {
	state = { ...INITIAL_STATE };
	componentDidMount() {}
	componentWillReceiveProps(nextProps: Props) {
		if (!!nextProps.selectedProduct.key && !nextProps.updateItem.loading && !nextProps.newItem.loading) {
			const extras = nextProps.selectedProduct.extras;
			const extrasArray = [];
			if (extras) {
				Object.keys(extras).map((key) => {
					extrasArray.push({ key: key, value: extras[key] });
				});
			}

			if (extrasArray.length === 0) {
				extrasArray.push({ key: '', value: '' });
			}

			this.setState({
				...nextProps.selectedProduct as any,
				extras: extrasArray
			});
		}
		if (nextProps.newItem.error !== this.props.newItem.error) {
			message.error(nextProps.newItem.error);
		}
		if (nextProps.updateItem.error !== this.props.updateItem.error) {
			message.error(nextProps.updateItem.error);
		}
	}
	updateExtra = (extraIndex: number) => (e: any) => {
		let newExtras = this.state.extras.map((newExtra, newIndex) => {
			if (newIndex !== extraIndex) {
				// This isn't the item we care about - keep it as-is
				return newExtra;
			}
			return {
				...newExtra,
				key: titleCase(e.target.value)
			};
		});
		this.setState({ extras: newExtras });
	};
	removeExtra = (index: number) => (e: any) => {
		const newExtras = this.state.extras.filter((newItem, newIndex) => newIndex !== index);
		this.setState({ extras: newExtras });
	};
	addExtra = (e: any) => {
		const newExtra = { key: '', extra: '' };
		const newExtras = [ ...this.state.extras, newExtra ];
		this.setState({ extras: newExtras });
	};
	handleUploadChange = (file: any) => {};
	beforeUpload = (file: RcFile, FileList: RcFile[]): boolean | PromiseLike<any> => {
		const fileType = getFileType(file.name);
		if (!checkFileSize(file.size, 1)) {
			this.setState({ error: 'Max file size is 1MB' });
			message.error('Max file size is 1MB');
			return false;
		}
		var fileReader = new FileReader();
		fileReader.onloadend = (e: any) => {
			var arr = new Uint8Array(e.target.result).subarray(0, 4);
			var header = '';
			for (var i = 0; i < arr.length; i++) {
				header += arr[i].toString(16);
			}
			// Check the file signature against known types
			if (!checkIfValidImage(header, getMimeType(fileType))) {
				message.error('Please upload a png or a jpg/jpeg');
			} else {
				getBase64(file, (imageUrl: string) =>
					this.setState({
						imageUrl: imageUrl,
						imageFile: {
							fileType: fileType,
							mimeType: getMimeType(fileType),
							blob: imageUrl,
							fileSize: file.size
						}
					})
				);
			}
		};
		fileReader.readAsArrayBuffer(file.slice(0, 4));
		return true;
	};
	newProduct = () => {
		const { sku, name, type, category, printQueue, description, imageUrl, extras, imageFile } = this.state;

		let newExtras: any = {};
		extras.forEach((extra) => {
			if (extra.key !== '') {
				newExtras[extra.key] = '';
			}
		});

		const newProduct: Product = {
			userId: this.props.user.userId as string,
			sku,
			name,
			category,
			type,
			printQueue,
			description,
			extras: newExtras,
			imageUrl,
			timestamp: new Date().getTime()
		};

		if (this.props.selectedProduct.key) {
			newProduct.key = this.props.selectedProduct.key;
		}

		if (this.props.selectedProduct.key) {
			this.props.onUpdateItem(this.props.user.userId, 'products', imageFile, newProduct);
		} else {
			this.props.onNewItem(this.props.user.userId, 'products', imageFile, newProduct);
		}
	};
	render() {
		const { selectedProduct, updateItem, newItem, user, userMeta, visible, onCancel } = this.props;
		const { sku, name, type, category, printQueue, description, imageUrl, extras, imageFile, error } = this.state;
		const isInvalid =
			sku === '' ||
			name === '' ||
			printQueue === '' ||
			description === '' ||
			category === '' ||
			type === '' ||
			imageUrl === '';
		const uploadButton = (
			<div>
				<Icon type={'plus'} />
				<div className='ant-upload-text'>Upload Product Image</div>
			</div>
		);
		return (
			<div className='uk-width-expand'>
				<Modal
					keyboard={false}
					centered
					bodyStyle={{ height: '90vh', borderRadius: 0 }}
					width={'90vw'}
					visible={visible}
					title={selectedProduct.key ? `${selectedProduct.name}` : 'Set up a new Product'}
					okText='Create'
					footer={null}
					afterClose={() => {
						this.setState({ ...INITIAL_STATE });
						this.props.onClearSelectedProduct();
					}}
					onCancel={() => {
						onCancel();
					}}
					onOk={() => {}}
				>
					<Form className='single-step'>
						<Spin
							indicator={<Icon type='loading' style={{ fontSize: 24 }} spin />}
							spinning={(newItem.loading as boolean) || (updateItem.loading as boolean)}
						>
							<Form.Item label='Product Name' {...formItemLayout}>
								<Input
									placeholder='Name'
									onChange={(e) => {
										if (e.target.value.length <= 30) {
											this.setState({ name: titleCase(e.target.value) });
										}
									}}
									value={name}
								/>
							</Form.Item>
							<Form.Item label='Product SKU' {...formItemLayout}>
								<Input
									placeholder='SKU'
									onChange={(e) => {
										this.setState({ sku: e.target.value });
									}}
									value={sku}
								/>
							</Form.Item>
							<Form.Item label='Product Type' {...formItemLayout}>
								<Select
									placeholder={'Select Type'}
									labelInValue
									value={type ? { key: type } : undefined}
									onChange={(e: any) => {
										this.setState({
											type: e.key
										});
									}}
								>
									{getProductTypeOptions()}
								</Select>
							</Form.Item>
							<Form.Item label='Product Category' {...formItemLayout}>
								<Select
									placeholder={'Select Category'}
									labelInValue
									value={category ? { key: category } : undefined}
									onChange={(e: any) => {
										this.setState({
											category: e.key
										});
									}}
								>
									{getProductCategoryOptions()}
								</Select>
							</Form.Item>
							<Form.Item label='Print Queue' {...formItemLayout}>
								<Select
									placeholder={'Print Queue'}
									labelInValue
									value={printQueue ? { key: printQueue } : undefined}
									onChange={(e: any) => {
										this.setState({
											printQueue: e.key
										});
									}}
								>
									{getPrintQueueOptions()}
								</Select>
							</Form.Item>
							<Form.Item label='Product Description' {...formItemLayout}>
								<TextArea
									placeholder='Enter Description...'
									value={description}
									onChange={(e) => {
										if (e.target.value.length < 400) {
											this.setState({ description: e.target.value });
										}
									}}
									rows={4}
								/>
							</Form.Item>
							<Form.Item label='Product Image' {...formItemLayout}>
								<Upload
									name='avatar'
									listType='picture-card'
									className='avatar-uploader'
									showUploadList={false}
									beforeUpload={this.beforeUpload}
									onChange={this.handleUploadChange}
									customRequest={dummyRequest}
								>
									{imageUrl ? (
										<img style={{ maxWidth: '300px' }} src={imageUrl} alt='avatar' />
									) : (
										uploadButton
									)}
								</Upload>
							</Form.Item>
							<Form.Item label='Extras' {...formItemLayout}>
								{extras.map((extra, index) => {
									return (
										<Row key={`extra ${index}`}>
											<Col span={20}>
												<Input
													placeholder='Ex) Goals'
													value={extra.key}
													onChange={this.updateExtra(index)}
												/>
											</Col>
											<Col span={4}>
												<div className='uk-margin-left'>
													{extras.length > 1 ? (
														<Icon
															className='dynamic-delete-button'
															type='minus-circle-o'
															//disabled={(contacts.length === 1) as boolean}
															onClick={this.removeExtra(index)}
														/>
													) : null}
												</div>
											</Col>
										</Row>
									);
								})}
								<Row>
									<Button type='dashed' onClick={this.addExtra} style={{ minWidth: '30%' }}>
										<Icon type='plus' /> Add Extra
									</Button>
								</Row>
							</Form.Item>
						</Spin>
					</Form>
					<div className='uk-position-bottom uk-padding-small'>
						<Divider />
						<div className='uk-flex uk-flex-row'>
							<div className='uk-flex uk-flex-left'>
								<Button onClick={() => onCancel()}>Cancel</Button>
							</div>
							<div className='uk-flex uk-flex-right uk-width-expand'>
								<Button onClick={this.newProduct} disabled={isInvalid} type='primary'>
									{selectedProduct.key ? 'Update' : 'Create'}
								</Button>
							</div>
						</div>
					</div>
				</Modal>
			</div>
		);
	}
}

export default compose<any, any>(
	withRouter,
	connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps)
)(ProductModal);
