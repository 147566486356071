import { TaskState } from '../models/Commons';

export function insertItem(array: Array<any>, item: any, index: number): Array<any> {
	let newArray = array.slice();
	newArray.splice(index, 0, item);
	return newArray;
}

export function removeItem(array: Array<any>, index: number): Array<any> {
	return array.filter((item, newIndex) => newIndex !== index);
}

export function updateObjectInArray(array: Array<any>, item: any, index: number): Array<any> {
	return array.map((oldItem, oldIndex) => {
		if (index !== oldIndex) {
			// This isn't the item we care about - keep it as-is
			return oldItem;
		}

		// Otherwise, this is the one we want - return an updated value
		return {
			...oldItem,
			...item
		};
	});
}

export const insertItemWithFirebaseKey = (array: Array<any>, object: any): Array<any> => {
	return [{ ...object }, ...array];
};

export const insertItemWithFirebaseKeyBottom = (array: Array<any>, object: any): Array<any> => {
	return [...array, { ...object }];
};

export const removeItemWithFirebaseKey = (array: Array<any>, object: any): Array<any> => {
	return array.filter((item, index) => item.key !== object.key);
};

export const updateItemWithFirebaseKey = (array: Array<any>, object: any): Array<any> => {
	return array.map((item) => {
		if (item.key !== object.key) {
			// This isn't the item we care about - keep it as-is
			return item;
		}

		// Otherwise, this is the one we want - return an updated value
		return {
			...item,
			...object
		};
	});
};

export const insertArray = (
	array: Array<any>,
	newArray: any
): Array<any> => {
	return [...newArray, ...array];
};

// insert array into bottom of array immutably
export const insertArrayBottom = (
	array: Array<any>,
	newArray: any
): Array<any> => {
	return [...array, ...newArray];
};

/**
 * @param {boolean} loading Is the function loading?
 */
export const taskLoading = (loading: string | boolean): TaskState => ({
	loading: loading,
	empty: false,
	error: null,
	success: null
});

/**
 * @param {boolean} error What was the error?
 */
export const taskErrored = (error: string | boolean): TaskState => ({
	loading: false,
	empty: false,
	error: error,
	success: null
});

/**
 * @param {string} success What was the success message?
 */
export const taskSucceeded = (success: string | boolean): TaskState => ({
	loading: false,
	empty: false,
	error: null,
	success: success
});

/**
 * @param {boolean} empty Was the snapshot empty?
 */
export const taskResultsEmpty = (empty: string | boolean): TaskState => ({
	loading: false,
	empty: empty,
	error: null,
	success: null
});

export const taskCleared = () => ({
	loading: false,
	empty: null,
	error: null,
	success: null
});

/**
 *Clear a populated array
 */
export const taskClearArray = () => [];

/**
 * @param {Object} INITIAL_STATE reset to initial state
 */
export const resetToInitialState = (INITIAL_STATE: TaskState) => ({
	...INITIAL_STATE
});

export const createReducerWithTaskName = (taskConstant = '', INITIAL_STATE: TaskState) => {
	return function tasks(state = INITIAL_STATE, action: any): TaskState {
		switch (action.type) {
			case `${taskConstant}_LOADING`: {
				return {
					...taskLoading(action.value)
				};
			}
			case `${taskConstant}_EMPTY`: {
				return {
					...taskResultsEmpty(action.value)
				};
			}
			case `${taskConstant}_SUCCESS`: {
				return {
					...taskSucceeded(action.value)
				};
			}
			case `${taskConstant}_ERROR`: {
				return {
					...taskErrored(action.value)
				};
			}
			case `${taskConstant}_CLEAR`: {
				return {
					...taskCleared()
				};
			}
			default:
				return state;
		}
	};
};

/**
 * @param {string} taskConstant constant for the reducer(task) action
 * @param {string} taskState state of the task, ie LOADING, ERROR, SUCCESS, EMPTY
 * @param {any} value the new value for the state of the task
 */
export const dispatchTaskWithState = (taskConstant: string, taskState: string, value: any) => ({
	type: `${taskConstant}_${taskState}`,
	value
});

export const createReducerForFirebaseArray = (arrayConstant = '', INITIAL_STATE: Array<any>) => {
	return function array(state = INITIAL_STATE ? [...INITIAL_STATE] : [], action: any): Array<any> {
		switch (action.type) {
			case `ADD_${arrayConstant}`: {
				return [...insertItemWithFirebaseKey(state, action.object)];
			}
			case `ADD_REVERSE_${arrayConstant}`: {
				return [...insertItemWithFirebaseKeyBottom(state, action.object)];
			}
			case `ADD_ARRAY_${arrayConstant}`: {
				return [...insertArray(state, action.object)];
			}
			case `ADD_ARRAY_REVERSE_${arrayConstant}`: {
				return [...insertArrayBottom(state, action.object)];
			}
			case `REMOVE_${arrayConstant}`: {
				return [...removeItemWithFirebaseKey(state, action.object)];
			}
			case `UPDATE_${arrayConstant}`: {
				return [...updateItemWithFirebaseKey(state, action.object)];
			}
			case `CLEAR_${arrayConstant}`: {
				return [...taskClearArray()];
			}
			default:
				return state;
		}
	};
};

/**
 * @param {string} arrayConstant constant for the reducer(array) action
 * @param {string} arrayState state of the task, ie ADD, REMOVE, UPDATE, CLEAR
 * @param {array} array the array to be updated
 * @param {Object} object the object for the update
 */
export const dispatchFirebaseArrayWithState = (arrayConstant: string, arrayState: string, object: any) => ({
	type: `${arrayState}_${arrayConstant}`,
	object
});

export const createReducerForArray = (arrayConstant = '', INITIAL_STATE: Array<any>) => {
	return function array(state = INITIAL_STATE ? [...INITIAL_STATE] : [], action: any): Array<any> {
		switch (action.type) {
			case `ADD_${arrayConstant}`: {
				return [...insertItem(state, action.item, action.index)];
			}
			case `REMOVE_${arrayConstant}`: {
				return [...removeItem(state, action.index)];
			}
			case `ADD_ARRAY_${arrayConstant}`: {
				return [...insertArray(state, action.object)];
			}
			case `ADD_ARRAY_REVERSE_${arrayConstant}`: {
				return [...insertArrayBottom(state, action.object)];
			}
			case `UPDATE_${arrayConstant}`: {
				return [...updateObjectInArray(state, action.item, action.index)];
			}
			case `CLEAR_${arrayConstant}`: {
				return [...taskClearArray()];
			}
			case `RESET_${arrayConstant}`: {
				return [...INITIAL_STATE];
			}
			default:
				return state;
		}
	};
};

/**
 * @param {string} arrayConstant constant for the reducer(array) action
 * @param {string} arrayState state of the task, ie ADD, REMOVE, UPDATE, CLEAR
 * @param {array} array the array to be updated
 * @param {item} item the new object of the array at index for the update
 * @param {index} index the index of the array to be updated
 */
export const dispatchArrayWithState = (arrayConstant: string, arrayState: string, item: object, index: number) => ({
	type: `${arrayState}_${arrayConstant}`,
	item,
	index
});
