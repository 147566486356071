import React, { Component } from 'react';
import { connect } from 'react-redux';
import { User, UserMeta } from '../../models/UsersModel';
import { Subject } from '../../models/EventsModel';
import * as rTeambuilder from '../../reducers/teambuilder';
import Animate from 'rc-animate';
import { Card, Table, Icon, Input, Button, InputNumber, Select } from 'antd';
import { getStaffPositions } from '../../constants/selectOptions';
import PhoneInput from 'react-phone-number-input/basic-input';
import { dedupe } from '../../helpers/arrays';
import { titleCase } from '../../helpers/string';

const { Column } = Table;

interface OwnProps {}
interface StateProps {
	user: User;
	userMeta: UserMeta;
	staff: Array<Subject>;
	event: Event;
	pageNumber: number;
}
interface DispatchProps {
	onAddStaff: Function;
	onRemoveStaff: Function;
	onUpdateStaff: Function;
	onAddToDeletePlayer: Function;
}
type Props = StateProps & DispatchProps & OwnProps;
type State = {};

const mapStateToProps = (state: any) => ({
	user: state.usersState.user,
	userMeta: state.usersState.userMeta,
	staff: state.teambuilderState.staff,
	toDeleteSubjects: state.teambuilderState.toDeleteSubjects,
	event: state.teambuilderState.event,
	pageNumber: state.teambuilderState.pageNumber
});

const mapDispatchToProps = (dispatch: any) => ({
	onAddStaff: (staffMember: Subject) => {
		dispatch(rTeambuilder.modifyStaffArray('ADD', staffMember));
	},
	onUpdateStaff: (staffMember: Subject) => {
		dispatch(rTeambuilder.modifyStaffArray('UPDATE', staffMember));
	},
	onRemoveStaff: (staffMember: Subject) => {
		dispatch(rTeambuilder.modifyStaffArray('REMOVE', staffMember));
	},
	onAddToDeletePlayer: (player: Subject) => {
		dispatch(rTeambuilder.modifyToDeleteSubjectsArray('ADD', player));
	}
});
const Div = (props: any) => {
	const childrenProps = { ...props };
	delete childrenProps.show;
	return <div {...childrenProps} />;
};
class StepStaffComp extends Component<Props, State> {
	componentDidMount() {}

	render() {
		const { staff, event, pageNumber } = this.props;

		return (
			<Animate transitionName='fade' transitionAppear>
				<Div style={{ height: '80%' }} show={pageNumber === 7}>
					<div style={{ height: '100%' }} className='uk-flex uk-flex-center uk-margin-top'>
						<Card
							style={{
								borderRadius: '5px',
								minWidth: '80vw',
								overflowY: 'auto'
							}}
						>
							<div className='uk-text-center'>
								<h2>Who's part of your staff?</h2>
								<span>
									<p style={{ fontWeight: 500 }}>
										All the staff that you want on the roster, or included in photos must be added
										here. Staff who are not added here will not appear on the roster, even if they
										are photographed.
									</p>
								</span>
							</div>

							<div style={{ minHeight: '400px' }} className='uk-padding-small'>
								<Table
									rowKey={(e) => e.key!}
									pagination={false}
									dataSource={dedupe(staff.slice().reverse(), 'key')}
								>
									<Column
										title='#'
										dataIndex='key'
										key='number'
										render={(name, player, index) => <span>{index + 1}</span>}
									/>
									<Column
										title='First Name*'
										dataIndex='firstName'
										key='firstName'
										render={(firstName, staffMember: Subject, index) => (
											<Input
												placeholder='First Name'
												value={firstName}
												onChange={(e) => {
													const newStaffMember = {
														...staffMember as Subject,
														firstName: titleCase(e.target.value)
													};
													if (e.target.value.length <= 30) {
														this.props.onUpdateStaff(newStaffMember);
													}
												}}
											/>
										)}
									/>
									<Column
										title='Last Name*'
										dataIndex='lastName'
										key='lastName'
										render={(lastName, staffMember: Subject, index) => (
											<Input
												placeholder='Last Name'
												value={lastName}
												onChange={(e) => {
													const newStaffMember = {
														...staffMember as Subject,
														lastName: titleCase(e.target.value)
													};
													if (e.target.value.length <= 30) {
														this.props.onUpdateStaff(newStaffMember);
													}
												}}
											/>
										)}
									/>
									<Column
										title='Email*'
										dataIndex='email'
										key='email'
										render={(email, staffMember: Subject, index) => (
											<Input
												placeholder='Email'
												autoComplete='new-password'
												value={email}
												onChange={(e) => {
													const newStaffMember = {
														...staffMember as Subject,
														email: e.target.value.trim()
													};
													this.props.onUpdateStaff(newStaffMember);
												}}
											/>
										)}
									/>
									<Column
										title='Phone Number'
										dataIndex='phoneNumber'
										key='phoneNumber'
										render={(phoneNumber, staffMember: Subject, index) => (
											<PhoneInput
												autoComplete='new-password'
												className='ant-input'
												country='CA'
												placeholder='Phone Number'
												value={phoneNumber}
												onChange={(e: any) => {
													const newStaff = {
														...staffMember,
														phoneNumber: e
													};
													this.props.onUpdateStaff(newStaff);
												}}
											/>
										)}
									/>
									<Column
										title='Role'
										dataIndex='role'
										key='role'
										render={(role, staffMember: Subject, index) => (
											<Select
												style={{ minWidth: '150px' }}
												placeholder={'Select Role..'}
												labelInValue
												value={role ? { key: role } : undefined}
												onChange={(e: any) => {
													const newStaff = {
														...staffMember,
														role: e.key
													};
													this.props.onUpdateStaff(newStaff);
												}}
											>
												{getStaffPositions()}
											</Select>
										)}
									/>
									{staff.length > 1 ? (
										<Column
											title='Action'
											dataIndex='key'
											key='action'
											render={(endTime, staffMember: Subject, index) => (
												<div className='uk-padding-small'>
													{staff.length > 1 ? (
														<Icon
															className='dynamic-delete-button'
															type='minus-circle-o'
															onClick={() => {
																this.props.onRemoveStaff(staffMember);
																this.props.onAddToDeletePlayer(staffMember);
															}}
														/>
													) : null}
												</div>
											)}
										/>
									) : null}
								</Table>
								<div className='uk-flex uk-flex-center uk-margin-top uk-margin-bottom'>
									<Button
										type='dashed'
										onClick={() =>
											this.props.onAddStaff({
												key: rTeambuilder.generateKey(),
												...rTeambuilder.INITIAL_STATE_SUBJECT
											})}
										style={{ width: '60%' }}
									>
										<Icon type='plus' /> Add Staff
									</Button>
								</div>
							</div>
						</Card>
					</div>
				</Div>
			</Animate>
		);
	}
}

export default connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps)(StepStaffComp);
