import React, { useCallback, useEffect } from "react";
import { message, Tabs, Spin, Icon } from "antd";
import Groups from "./Groups";
import Subjects from "./Subjects";
import Unmatched from "./Unmatched";
import { useDispatch, useSelector } from "react-redux";
import { Event } from "../../../models/EventsModel";
import { User } from "../../../models/UsersModel";
import { images as fImages } from "../../../firebase";
import { TaskState } from "../../../models/Commons";

const TabPane = Tabs.TabPane;

const ImagesRoot = () => {
  const dispatch = useDispatch();
  const selectedEvent: Event = useSelector(
    (state: any) => state.eventsState.selectedEvent
  );
  const user: User = useSelector((state: any) => state.usersState.user);
  const loadImagesState: TaskState = useSelector(
    (state: any) => state.imagesState.loadImages
  );
  const fetchImages = useCallback(async () => {
    if (user.userId && selectedEvent.key) {
      dispatch(fImages.fetchImages(selectedEvent.key, user.userId));
    }
  }, [dispatch, user.userId, selectedEvent.key]);
  useEffect(() => {
    fetchImages();
  }, [fetchImages]);
  useEffect(() => {
    if (loadImagesState.error) {
      message.error("Unable to load images, please try again");
    }
  }, [loadImagesState.error]);

  return (
    <div>
      <Spin
        indicator={<Icon type="loading" style={{ fontSize: 24 }} spin />}
        spinning={loadImagesState.loading as boolean}
      >
        <Tabs defaultActiveKey="1" tabPosition={"top"} size={"small"}>
          <TabPane tab="Group Images" key="1">
            <Groups />
          </TabPane>
          <TabPane tab="Subject Images" key="2">
            <Subjects />
          </TabPane>
          <TabPane tab="Unmatched Images" forceRender={true} key="3">
            <Unmatched />
          </TabPane>
        </Tabs>
      </Spin>
    </div>
  );
};

export default ImagesRoot;
