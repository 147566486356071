import { combineReducers } from 'redux';
import {
	createReducerWithTaskName,
	dispatchTaskWithState,
	createReducerForFirebaseArray,
	dispatchFirebaseArrayWithState
} from '../helpers/reducers';
import { INITIAL_STATE_EMPTY_TASK, Contact } from '../models/Commons';
import { Timeslot, Shoot } from '../models/EventsModel';

/**
 * INITIAL STATE
 */
const INITIAL_STATE_SELECTED_TIMESLOT = {
	key: ''
};
const INITIAL_STATE_SELECTED_SHOOT = {
	key: ''
};

/**
 * CONSTANTS
 */
const LOAD_TIMESLOTS = 'load timeslots';
const UPDATE_TIMESLOT = 'update timeslot';
const SET_SELECTED_TIMESLOT = 'set selected timeslot';
const SET_SELECTED_SHOOT = 'set selected shoot';

const CLEAR_TIMESLOTS_REDUCER = 'clear timeslots reducer';

/**
 * ACTIONS
 */
export const setSelectedTimeslot = (timeslot: Timeslot) => ({
	type: SET_SELECTED_TIMESLOT,
	timeslot
});
export const setSelectedShoot = (shoot: Shoot) => ({
	type: SET_SELECTED_SHOOT,
	shoot
});

export const modifyLoadTimeslots = (taskState: string, value: string | boolean) => {
	return dispatchTaskWithState(LOAD_TIMESLOTS, taskState, value);
};

export const modifyUpdateTimeslot = (taskState: string, value: string | boolean) => {
	return dispatchTaskWithState(UPDATE_TIMESLOT, taskState, value);
};

export const modifyTimeslotsArray = (arrayState: string, timeslot: Timeslot) => {
	return dispatchFirebaseArrayWithState(LOAD_TIMESLOTS, arrayState, timeslot);
};

export const clearTimeslotsReducer = () => ({
	type: CLEAR_TIMESLOTS_REDUCER
});

/**
 * REDUCERS
 */
function selectedTimeslotReducer(state = INITIAL_STATE_SELECTED_TIMESLOT, action: any) {
	switch (action.type) {
		case SET_SELECTED_TIMESLOT: {
			return {
				...state,
				...action.timeslot
			};
		}
		default:
			return state;
	}
}

function selectedShootReducer(state = INITIAL_STATE_SELECTED_SHOOT, action: any) {
	switch (action.type) {
		case SET_SELECTED_SHOOT: {
			return {
				...state,
				...action.shoot
			};
		}
		default:
			return state;
	}
}

const timeslotsRootReducer = combineReducers({
	selectedTimeslot: selectedTimeslotReducer,
	selectedShoot: selectedShootReducer,
	timeslots: createReducerForFirebaseArray(LOAD_TIMESLOTS, []),
	loadTimeslots: createReducerWithTaskName(LOAD_TIMESLOTS, INITIAL_STATE_EMPTY_TASK),
	updateTimeslot: createReducerWithTaskName(UPDATE_TIMESLOT, INITIAL_STATE_EMPTY_TASK)
});

const timeslotsReducer = (state: any, action: any) => {
	if (action.type === CLEAR_TIMESLOTS_REDUCER) {
		state = {
			selectedTimeslot: { ...INITIAL_STATE_SELECTED_TIMESLOT },
			selectedShoot: { ...INITIAL_STATE_SELECTED_SHOOT },
			timeslots: [],
			loadTimeslots: { ...INITIAL_STATE_EMPTY_TASK },
			updateTimeslot: { ...INITIAL_STATE_EMPTY_TASK }
		};
	}

	return timeslotsRootReducer(state, action);
};

export default timeslotsReducer;
