import React, { useState } from "react";
import {
  Col,
  Row,
  Button,
  Radio,
  Popconfirm,
  Table,
  message,
  Tooltip,
} from "antd";
import { Event, Group, TeampicsImage } from "../../../models/EventsModel";
import { useDispatch, useSelector } from "react-redux";
import * as rImages from "../../../reducers/images";
import { firestore } from "../../../firebase/firebaseConfig";
import AssignImageModal from "./AssignImageModal";

const { Column } = Table;

const Groups = () => {
  const dispatch = useDispatch();
  const groups: Group[] = useSelector((state: any) => state.imagesState.groups);
  const images: TeampicsImage[] = useSelector(
    (state: any) => state.imagesState.images
  );
  const selectedEvent: Event = useSelector(
    (state: any) => state.eventsState.selectedEvent
  );
  const [selectedGroup, setSelectedGroup] = useState("");
  const [visible, setVisible] = useState(false);

  const onToggleValid = (group: Group) => async (e: any) => {
    try {
      const updatedGroup: Group = {
        ...group,
        hasValidImage: !group.hasValidImage,
      };
      dispatch(rImages.modifyImagesGroupsArray("UPDATE", updatedGroup));
      await firestore
        .collection("events")
        .doc(selectedEvent.key!)
        .collection("groups")
        .doc(group.key)
        .update({
          hasValidImage: !group.hasValidImage,
        } as Group);
    } catch (error) {
      console.log(
        "🚀 ~ file: Groups.tsx ~ line 57 ~ onPrimaryUpdated ~ error",
        error
      );
      message.error("Something went wrong, please try again");
    }
  };
  const onPrimaryUpdated = (group: Group) => async (e: any) => {
    try {
      const updatedGroup: Group = {
        ...group,
        primaryImageId: e.target.value,
        hasValidImage: true,
      };
      dispatch(rImages.modifyImagesGroupsArray("UPDATE", updatedGroup));
      await firestore
        .collection("events")
        .doc(selectedEvent.key!)
        .collection("groups")
        .doc(group.key)
        .update({
          primaryImageId: e.target.value,
          hasValidImage: true,
        } as Group);
    } catch (error) {
      console.log(
        "🚀 ~ file: Groups.tsx ~ line 57 ~ onPrimaryUpdated ~ error",
        error
      );
      message.error("Something went wrong, please try again");
    }
  };
  const onPrimaryUpdatedClick = (group: Group) => async (e: any) => {
    try {
      if (group.primaryImageId) {
        const updatedGroup: Group = {
          ...group,
          primaryImageId: "",
          hasValidImage: false,
        };
        dispatch(rImages.modifyImagesGroupsArray("UPDATE", updatedGroup));
        await firestore
          .collection("events")
          .doc(selectedEvent.key!)
          .collection("groups")
          .doc(group.key)
          .update({
            primaryImageId: "",
            hasValidImage: false,
          } as Group);
      }
    } catch (error) {
      console.log(
        "🚀 ~ file: Groups.tsx ~ line 57 ~ onPrimaryUpdated ~ error",
        error
      );
      message.error("Something went wrong, please try again");
    }
  };
  const onRemoveImage = (group: Group, image: TeampicsImage) => async () => {
    try {
      const batch = firestore.batch();
      const updatedGroup: Group = {
        ...group,
        imageIds: group.imageIds
          ? group.imageIds?.slice().filter((id) => id !== image.imageId)
          : [],
      };
      if (group.primaryImageId === image.imageId) {
        updatedGroup.primaryImageId = "";
        updatedGroup.hasValidImage = false;
      }
      batch.update(
        firestore
          .collection("events")
          .doc(selectedEvent.key!)
          .collection("groups")
          .doc(group.key),
        updatedGroup
      );
      dispatch(rImages.modifyImagesGroupsArray("UPDATE", updatedGroup));
      const updatedImage: TeampicsImage = {
        ...image,
        hasGroup: false,
        groups: image.groups.slice().filter((id) => id !== group.key),
      };
      batch.update(firestore.collection("uploadedImages").doc(image.imageId), {
        hasGroup: false,
        groups: image.groups.slice().filter((id) => id !== group.key),
      });
      dispatch(rImages.modifyImagesArray("UPDATE", updatedImage));
      await batch.commit();
    } catch (error) {
      console.log(
        "🚀 ~ file: Groups.tsx ~ line 96 ~ onRemoveImage ~ error",
        error
      );
      message.error("Something went wrong, please try again");
    }
  };
  const onOpenManualModal = (groupId: string) => () => {
    setSelectedGroup(groupId);
    setVisible(true);
  };
  const onCloseManualModal = () => {
    setSelectedGroup("");
    setVisible(false);
  };
  return (
    <div>
      <AssignImageModal
        visible={visible}
        onClose={onCloseManualModal}
        groupId={selectedGroup}
        subjectId=""
      />
      <Table
        locale={{ emptyText: "No Images available." }}
        rowKey={(e) => e.key!}
        dataSource={groups}
        pagination={false}
        bordered={true}
      >
        <Column
          title="Valid"
          dataIndex="primaryImageId"
          key="valid"
          align="center"
          render={(primaryImageId: string, group: Group) => (
            <Tooltip title={group.hasValidImage ? "Valid" : "Invalid"}>
              <Button
                type="primary"
                icon={group.hasValidImage ? "check" : "close"}
                size="small"
                style={{
                  backgroundColor: group.hasValidImage ? "#30CE72" : "#FC0202",
                  border: 0,
                }}
                onClick={onToggleValid(group)}
              />
            </Tooltip>
          )}
        />
        <Column
          title="Attach Image"
          dataIndex="primaryImageId"
          key="attachGroup"
          align="center"
          render={(primaryImageId: string, group: Group) => (
            <Button
              type="primary"
              shape="circle"
              icon={"plus"}
              onClick={onOpenManualModal(group.key!)}
            />
          )}
        />
        <Column
          title="Group Name"
          dataIndex="fullName"
          key="fullName"
          align="center"
          render={(fullName: string) => <p>{`${fullName}`}</p>}
        />
        <Column
          title="Primary"
          dataIndex="primaryImageId"
          key="primaryImageId"
          align="right"
          width="100px"
          render={(primaryImageId: string, group: Group) => {
            const imagesToUse = images
              .slice()
              .filter(
                (image) => image.hasGroup && image.groups.includes(group.key!)
              );
            return (
              <Row>
                <Radio.Group value={group.primaryImageId}>
                  {imagesToUse.map((image) => {
                    return (
                      <Col
                        key={image.key}
                        span={24}
                        style={{ height: 20, marginBottom: 8 }}
                      >
                        <Radio
                          className={
                            group.hasValidImage
                              ? "radio-green-highlight"
                              : "radio-error-highlight"
                          }
                          name={image.key!}
                          onChange={onPrimaryUpdated(group)}
                          onClick={onPrimaryUpdatedClick(group)}
                          value={image.key!}
                        />
                      </Col>
                    );
                  })}
                </Radio.Group>
              </Row>
            );
          }}
        />
        <Column
          title="File Name"
          dataIndex="primaryImageId"
          key="filename"
          render={(primaryImageId: string, group: Group) => {
            const imagesToUse = images
              .slice()
              .filter(
                (image) => image.hasGroup && image.groups.includes(group.key!)
              );
            return (
              <Row>
                {imagesToUse.map((image) => {
                  return (
                    <Col
                      key={image.key}
                      span={24}
                      style={{
                        height: 20,
                        marginBottom: 8,
                        display: "flex",
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                      }}
                    >
                      <p
                        style={{
                          color: group.hasValidImage ? "unset" : "#FC0202",
                          fontSize: 16,
                          lineHeight: "20px",
                          marginBottom: 0,
                        }}
                      >
                        {image.filename}
                      </p>
                    </Col>
                  );
                })}
              </Row>
            );
          }}
        />
        <Column
          title="Unmatch"
          dataIndex="primaryImageId"
          key="delete"
          align="center"
          render={(primaryImageId: string, group: Group) => {
            const imagesToUse = images
              .slice()
              .filter(
                (image) => image.hasGroup && image.groups.includes(group.key!)
              );
            return (
              <Row>
                {imagesToUse.map((image) => {
                  return (
                    <Col key={image.key} span={24} style={{ marginBottom: 4 }}>
                      <Popconfirm
                        title={`Are you sure to remove ${image.filename} from this group?`}
                        onConfirm={onRemoveImage(group, image)}
                        okText="Yes"
                        cancelText="No"
                        placement="left"
                      >
                        <Button
                          shape="circle"
                          icon={"minus"}
                          type="danger"
                          size="small"
                        />
                      </Popconfirm>
                    </Col>
                  );
                })}
              </Row>
            );
          }}
        />
      </Table>
    </div>
  );
};

export default Groups;
