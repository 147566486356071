import React, { Component } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { setPage } from '../../reducers/session';
import * as routes from '../../constants/routeNames';
import { User, UserMeta } from '../../models/UsersModel';
import { Form, Input, Button, Icon, Upload, Spin, Alert, Empty, InputNumber, message } from 'antd';
import { RcFile } from 'antd/lib/upload/interface';
import {
	getFileType,
	getMimeType,
	formatBytes,
	getBase64,
	checkFileSize,
	checkIfValidImage
} from '../../constants/fileTypes';
import { TeamPicsFile, TaskState } from '../../models/Commons';
import { users as fUsers } from '../../firebase';
import emptyview from '../../assets/emptyview_photo.png';
import { validateEmail, titleCase } from '../../helpers/string';

interface OwnProps extends RouteComponentProps {}

interface StateProps {
	user: User;
	userMeta: UserMeta;
	loadUser: TaskState;
	updateUser: TaskState;
	sendRequest: TaskState;
}

interface DispatchProps {
	onUpdateUser: Function;
	onSendRequest: Function;
}

type Props = StateProps & DispatchProps & OwnProps;
type State = {
	company: string;
	website: string;
	logoUrl: string;
	logoFile: TeamPicsFile | undefined;
	taxIdNumber: string;
	taxPercentage: number;
	helpDeskEmail: string;
	error: string;
	success: string;
	loading: boolean;
};

const formItemLayout = {
	labelCol: {
		xs: { span: 24 },
		sm: { span: 8 },
		md: { span: 4 }
	},
	wrapperCol: {
		xs: { span: 24 },
		sm: { span: 16 }
	}
};
const tailFormItemLayout = {
	wrapperCol: {
		xs: {
			span: 24,
			offset: 0
		},
		sm: {
			span: 16,
			offset: 8
		},
		md: {
			span: 16,
			offset: 4
		}
	}
};

const INITIAL_STATE = {
	company: '',
	website: '',
	logoUrl: '',
	logoFile: {},
	taxIdNumber: '',
	taxPercentage: 0,
	helpDeskEmail: '',
	error: '',
	success: '',
	loading: false
};

const dummyRequest = ({ file, onSuccess }: { file: any; onSuccess: any }) => {
	setTimeout(() => {
		onSuccess('ok');
	}, 0);
};

const mapStateToProps = (state: any) => ({
	user: state.usersState.user,
	userMeta: state.usersState.userMeta,
	loadUser: state.usersState.loadUser,
	updateUser: state.usersState.updateUser,
	sendRequest: state.usersState.sendRequest
});

const mapDispatchToProps = (dispatch: any) => ({
	onUpdateUser: (user: User, logoFile: TeamPicsFile) => {
		dispatch(fUsers.updateUser(user, logoFile));
	},
	onSendRequest: (user: User) => {
		dispatch(fUsers.sendPhotographerRequest(user));
	}
});

class PhotographerForm extends Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = { ...INITIAL_STATE };
	}
	componentDidMount() {
		if (this.props.user) {
			this.setState({
				company: this.props.user.company,
				website: this.props.user.website,
				logoUrl: this.props.user.logoUrl ? this.props.user.logoUrl : '',
				error: '',
				success: '',
				loading: false
			});
		}
	}
	componentWillReceiveProps(nextProps: Props) {
		if (nextProps.user && !nextProps.updateUser.loading) {
			this.setState({
				company: nextProps.user.company,
				website: nextProps.user.website,
				logoUrl: nextProps.user.logoUrl ? nextProps.user.logoUrl : '',
				error: '',
				success: '',
				loading: false
			});
		}
	}
	renderUploadButton = () => {
		return (
			<div>
				<Icon type={this.state.loading ? 'loading' : 'plus'} />
				<div className='ant-upload-text'>Upload</div>
			</div>
		);
	};
	handleUploadChange = (file: any) => {};
	beforeUpload = (file: RcFile, FileList: RcFile[]): boolean | PromiseLike<any> => {
		const fileType = getFileType(file.name);
		if (!checkFileSize(file.size, 2)) {
			this.setState({ error: 'Max file size is 2MB' });
			return false;
		}

		var fileReader = new FileReader();
		fileReader.onloadend = (e: any) => {
			var arr = new Uint8Array(e.target.result).subarray(0, 4);
			var header = '';
			for (var i = 0; i < arr.length; i++) {
				header += arr[i].toString(16);
			}
			// Check the file signature against known types
			if (!checkIfValidImage(header, getMimeType(fileType))) {
				message.error('Please upload a png or a jpg/jpeg');
			} else {
				getBase64(file, (imageUrl: string) =>
					this.setState({
						logoUrl: imageUrl,
						logoFile: {
							fileType: fileType,
							mimeType: getMimeType(fileType),
							blob: imageUrl,
							fileSize: file.size
						}
					})
				);
			}
		};
		fileReader.readAsArrayBuffer(file.slice(0, 4));
		return true;
	};
	updateUser = () => {
		const { company, website, logoUrl, logoFile, helpDeskEmail, taxIdNumber, taxPercentage } = this.state;

		if (!validateEmail(helpDeskEmail)) {
			message.error('Please enter a valid help desk email');
			return;
		}

		const user = {
			userId: this.props.user.userId,
			company,
			website,
			taxIdNumber,
			taxPercentage,
			helpDeskEmail
		};

		if (logoFile) {
			this.props.onUpdateUser(user, logoFile);
		} else {
			this.props.onUpdateUser(user, {});
		}
	};
	sendRequest = () => {
		const { user } = this.props;

		this.props.onSendRequest(user);
	};
	render() {
		const { userMeta, loadUser, updateUser, sendRequest } = this.props;
		const { company, website, logoUrl, error, taxIdNumber, taxPercentage, helpDeskEmail } = this.state;

		return (
			<div className='uk-width-expand' style={{ minHeight: '1px', overflowY: 'auto' }}>
				{userMeta.photographer ? (
					<Form className='settings-form'>
						<Spin
							indicator={<Icon type='loading' style={{ fontSize: 24 }} spin />}
							spinning={(loadUser.loading as boolean) || (updateUser.loading as boolean)}
						>
							<Form.Item label='Company' {...formItemLayout}>
								<Input
									placeholder='Company'
									value={company}
									onChange={(e) => {
										this.setState({ company: titleCase(e.target.value) });
									}}
								/>
							</Form.Item>
							<Form.Item label='Help Desk Email' {...formItemLayout}>
								<Input
									placeholder='Email'
									value={helpDeskEmail}
									onChange={(e) => {
										this.setState({ helpDeskEmail: e.target.value.trim() });
									}}
								/>
							</Form.Item>
							<Form.Item label='Website' {...formItemLayout}>
								<Input
									placeholder='Website'
									value={website}
									onChange={(e) => {
										this.setState({ website: e.target.value });
									}}
								/>
							</Form.Item>
							<Form.Item label='Tax Id Number' {...formItemLayout}>
								<Input
									placeholder='Tax Id'
									value={taxIdNumber}
									onChange={(e) => {
										this.setState({ taxIdNumber: e.target.value });
									}}
								/>
							</Form.Item>
							<Form.Item label='Tax Percentage' {...formItemLayout}>
								<InputNumber
									step={1}
									min={0}
									max={50}
									placeholder='%'
									value={taxPercentage}
									onChange={(e) => {
										this.setState({ taxPercentage: e! });
									}}
								/>
							</Form.Item>

							{error && (
								<Form.Item {...tailFormItemLayout}>
									<Alert className='uk-text-center' message={error} type='error' />
								</Form.Item>
							)}
							<Form.Item label='Company Logo' {...formItemLayout}>
								<Upload
									name='avatar'
									listType='picture-card'
									className='avatar-uploader'
									showUploadList={false}
									beforeUpload={this.beforeUpload}
									onChange={this.handleUploadChange}
									customRequest={dummyRequest}
								>
									{logoUrl ? (
										<img style={{ maxWidth: '800px' }} src={logoUrl} alt='avatar' />
									) : (
										this.renderUploadButton()
									)}
								</Upload>
							</Form.Item>
							<Form.Item {...tailFormItemLayout}>
								<Button type='primary' onClick={this.updateUser}>
									Update
								</Button>
							</Form.Item>
						</Spin>
					</Form>
				) : (
					<Spin
						indicator={<Icon type='loading' style={{ fontSize: 24 }} spin />}
						spinning={sendRequest.loading as boolean}
					>
						<div className='uk-flex uk-flex-center uk-text-center uk-width-expand'>
							<div className='uk-flex uk-flex-column uk-flex-center uk-width-large'>
								<div className='uk-width-expand uk-flex uk-flex-center'>
									<img src={emptyview} className='empty-view' />
								</div>
								<span className='uk-margin-top uk-margin-bottom'>
									You are not verified as a photographer.
								</span>
								<Button type='primary' onClick={this.sendRequest}>
									Request to be a photographer
								</Button>
								<div className='uk-margin-top'>
									{sendRequest.error && (
										<Form.Item {...tailFormItemLayout}>
											<Alert
												className='uk-text-center'
												message={sendRequest.error}
												type='error'
											/>
										</Form.Item>
									)}
									{sendRequest.success && (
										<Form.Item {...tailFormItemLayout}>
											<Alert
												className='uk-text-center'
												message={sendRequest.success}
												type='success'
											/>
										</Form.Item>
									)}
								</div>
							</div>
						</div>
					</Spin>
				)}

				<div className='settings-form'>
					{(loadUser.error || updateUser.error) && (
						<Form.Item {...tailFormItemLayout}>
							<Alert
								className='uk-text-center'
								message={loadUser.error || updateUser.error}
								type='error'
							/>
						</Form.Item>
					)}
					{(loadUser.success || updateUser.success) && (
						<Form.Item {...tailFormItemLayout}>
							<Alert
								className='uk-text-center'
								message={loadUser.success || updateUser.success}
								type='success'
							/>
						</Form.Item>
					)}
				</div>
			</div>
		);
	}
}

const condition = (authUser: any) => !!authUser;

export default compose<any, any>(
	withRouter,
	connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps)
)(PhotographerForm);
