import React, { Component } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import {
	Modal,
	Steps,
	Button,
	Divider,
	Form,
	Input,
	Select,
	Radio,
	Upload,
	Icon,
	Table,
	Row,
	Col,
	Spin,
	Alert,
	message,
	Empty,
	InputNumber,
	DatePicker,
	TimePicker
} from 'antd';
import { UserMeta, User } from '../../models/UsersModel';
import { TaskState, TeamPicsFile, TeamPicsLocation, Season, Contact } from '../../models/Commons';
import { events as fEvents, timeslots as fTimeslots } from '../../firebase';
import TeampicsPlaces from '../../components/TeampicsPlaces/TeampicsPlaces';
import * as rEvents from '../../reducers/events';
import * as rTeambuilder from '../../reducers/teambuilder';
import * as rTimeslots from '../../reducers/timeslots';
import { Event, Shoot, Timeslot } from '../../models/EventsModel';
import moment from 'moment';
import { getDate, getTimeHrsMin } from '../../helpers/time';
import TimeslotListModal from './TimeslotListModal';
import LocationNotesModal from './LocationNotesModal';
import { titleCase } from '../../helpers/string';
const { Column } = Table;

interface OwnProps { }

interface StateProps {
	user: User;
	userMeta: UserMeta;
	updateEvent: TaskState;
	loadShoots: TaskState;
	shoots: Array<Shoot>;
	selectedEvent: Event;
}

interface DispatchProps {
	onUpdateShoots: Function;
	onClearUpdateEvent: Function;
	onSetSelectedShoot: Function;
}

type Props = StateProps & DispatchProps & OwnProps;
type State = {
	eventId: string;
	shoots: Array<Shoot>;
	error: string;
	visible: boolean;
	locationNotesVisible: boolean;
	shootIndex: number;
};
const INITIAL_STATE_EMPTY_SHOOT = {
	key: 'shoot 0',
	photographerId: '',
	eventId: '',
	name: '',
	startTime: new Date(new Date().setHours(0, 0, 0, 0)).getTime(),
	endTime: new Date(new Date().setHours(0, 0, 0, 0)).getTime(),
	intervalMins: 10,
	address: '',
	location: '' as TeamPicsLocation,
	locationNotes: ''
};
const INITIAL_STATE = {
	eventId: '',
	shoots: [
		{
			...INITIAL_STATE_EMPTY_SHOOT
		}
	],
	error: '',
	visible: false,
	locationNotesVisible: false,
	shootIndex: 0
};
const mapStateToProps = (state: any) => ({
	user: state.usersState.user,
	userMeta: state.usersState.userMeta,
	updateEvent: state.eventsState.updateEvent,
	loadShoots: state.eventsState.loadShoots,
	shoots: state.eventsState.shoots,
	selectedEvent: state.eventsState.selectedEvent
});
const mapDispatchToProps = (dispatch: any) => ({
	onUpdateShoots: (eventId: string, shoots: Array<Shoot>) => {
		dispatch(fEvents.updateShoots(eventId, shoots));
	},
	onClearUpdateEvent: () => {
		dispatch(rEvents.modifyUpdateEvent('LOADING', false));
	},
	onSetSelectedShoot: (shoot: Shoot) => {
		fTimeslots.unSubscribeFromTimeslots();
		dispatch(rTimeslots.modifyTimeslotsArray('CLEAR', {} as Timeslot));
		dispatch(rTimeslots.setSelectedShoot(shoot));
		dispatch(fTimeslots.subscribeToTimeslots(shoot.eventId, shoot.key!));
	}
});
class ShootsList extends Component<Props, State> {
	state = { ...INITIAL_STATE };
	componentDidMount() {
		if (this.props.shoots.length !== 0) {
			this.setState({
				shoots: this.props.shoots
			});
		}
		this.setState({
			eventId: this.props.selectedEvent.key!
		});
	}
	componentWillReceiveProps(nextProps: Props) {
		if (nextProps.loadShoots.success && !this.props.loadShoots.success) {
			this.setState({
				shoots: nextProps.shoots,
				eventId: nextProps.selectedEvent.key!
			});
		}
		if (nextProps.selectedEvent.key && !this.props.selectedEvent.key) {
			this.setState({
				eventId: nextProps.selectedEvent.key!
			});
		}
	}
	addShoot = () => {
		const { shoots } = this.state;
		const newShoots: Array<Shoot> = [
			...shoots,
			{ ...INITIAL_STATE_EMPTY_SHOOT, key: rTeambuilder.generateKey() }
		];
		this.setState({ shoots: newShoots });
	};
	removeShoot = (index: number) => {
		const { shoots } = this.state;
		const newShoots = shoots.filter((shoot, newIndex) => newIndex !== index);
		this.setState({ shoots: newShoots });
	};
	updateShoot = (newShoot: Shoot, index: number) => {
		const newShoots = this.state.shoots.map((oldShoot, oldIndex) => {
			if (index !== oldIndex) {
				// This isn't the item we care about - keep it as-is
				return oldShoot;
			}
			// Otherwise, this is the one we want - return an updated value
			return {
				...oldShoot,
				...newShoot
			};
		});
		this.setState({ shoots: newShoots });
	};
	calculateNumberTimeslots = (startTime: number, endTime: number, interval: number) => {
		const diff = endTime - startTime;
		let intervalMS = 60000 * interval;
		let numberOfShoots = diff / intervalMS;
		numberOfShoots = Math.round(numberOfShoots);

		return numberOfShoots;
	};
	updateShoots = () => {
		const { shoots, eventId } = this.state;
		const { user, selectedEvent } = this.props;
		this.setState({ error: '' });
		this.props.onClearUpdateEvent();

		let shootError = '';
		let newShoots: Array<Shoot> = [];
		shoots.some((shoot, index): boolean => {
			const startTime = shoot.startTime;
			const endTime = shoot.endTime;
			const interval = shoot.intervalMins;

			if (shoot.name === '') {
				this.setState({
					error: `Shoot ${index + 1}: Please enter a name for the shoot.`
				});
				return !!shootError;
			} else if (shoot.address === '') {
				this.setState({
					error: `Shoot ${index + 1}: Please enter a location for the shoot.`
				});
				return !!shootError;
			} else if (shoot.intervalMins < 0 || shoot.intervalMins > 999) {
				this.setState({
					error: `Shoot ${index + 1}: Interval time must be between 0 and 999 mins`
				});
				return !!shootError;
			} else if (startTime === endTime) {
				this.setState({
					error: `Shoot ${index + 1}: Start time can't be same as end time.`
				});
				return !!shootError;
			} else if (startTime > endTime) {
				this.setState({
					error: `Shoot ${index + 1}: Start time can't be after end time.`
				});
				return !!shootError;
			} else {
				const diff = endTime - startTime;
				let intervalMS = 60000 * interval;

				if (intervalMS > diff) {
					this.setState({
						error: `Shoot ${index + 1}: The interval does not fit in between the start and end time.`
					});
					return !!shootError;
				}
			}
			if (!shoot.photographerId) {
				newShoots.push({
					...shoot,
					photographerId: user.userId!,
					eventId: selectedEvent.key!
				});
			}
			return false;
		});
		if (shootError !== '') {
			this.setState({
				error: shootError
			});
			return;
		}

		if (newShoots.length !== 0) {
			this.props.onUpdateShoots(this.props.selectedEvent.key!, newShoots);
		}
	};
	render() {
		const { user, userMeta, updateEvent, loadShoots } = this.props;
		const { shoots, error, locationNotesVisible, shootIndex } = this.state;

		return (
			<div>
				<Spin
					indicator={<Icon type='loading' style={{ fontSize: 36 }} spin />}
					spinning={(updateEvent.loading as boolean) || (loadShoots.loading as boolean)}
				>
					<Form style={{ overflowX: 'auto' }}>
						<Table rowKey={(e) => e.key} pagination={false} dataSource={shoots}>
							<Column
								title='#'
								dataIndex='key'
								key='number'
								render={(name, shoot, index) => <span>{index + 1}</span>}
							/>
							<Column
								title='Name'
								dataIndex='name'
								key='name'
								render={(name, shoot: Shoot, index) => (
									<div>
										{shoot.photographerId ? (
											<span>{name}</span>
										) : (
											<Input
												placeholder='Name'
												value={name}
												onChange={(e) => {
													const newShoot = {
														...shoot as Shoot,
														name: titleCase(e.target.value)
													};
													this.updateShoot(newShoot, index);
												}}
											/>
										)}
									</div>
								)}
							/>
							<Column
								title='Location'
								dataIndex='address'
								key='address'
								render={(address, shoot: Shoot, index) => (
									<div>
										{shoot.photographerId ? (
											<span>{shoot.location.name}</span>
										) : (
											<TeampicsPlaces
												setAddress={(address: string) => {
													const newShoot = {
														...shoot as Shoot,
														address
													};
													this.updateShoot(newShoot, index);
												}}
												setLocation={(location: TeamPicsLocation) => {
													const newShoot = {
														...shoot as Shoot,
														location
													};
													this.updateShoot(newShoot, index);
												}}
												address={address}
												disabled={false}
												placeholder='Search Location...'
											/>
										)}
									</div>
								)}
							/>
							<Column
								title='Location Notes'
								dataIndex='key'
								key='locationNotes'
								render={(locationNotes, shoot, index) => (
									<a
										onClick={() => {
											this.setState({
												locationNotesVisible: true,
												shootIndex: index
											});
										}}
									>
										Notes
									</a>
								)}
							/>
							<Column
								title='Date'
								dataIndex='startTime'
								key='startTimeDate'
								render={(startTime, shoot: Shoot, index) => (
									<div>
										{shoot.photographerId ? (
											<span>{getDate(shoot.startTime)}</span>
										) : (
											<DatePicker
												style={{ minWidth: '150px' }}
												allowClear={false}
												value={moment(startTime)}
												onChange={(e) => {
													if (e) {
														const newShoot = {
															...shoot as Shoot,
															startTime: e.valueOf(),
															endTime: e.valueOf()
														};
														this.updateShoot(newShoot, index);
													}
												}}
											/>
										)}
									</div>
								)}
							/>
							<Column
								title='Start Time'
								dataIndex='startTime'
								key='startTime'
								render={(startTime, shoot: Shoot, index) => (
									<div>
										{shoot.photographerId ? (
											<span>{getTimeHrsMin(shoot.startTime)}</span>
										) : (
											<TimePicker
												allowClear={false}
												onChange={(e) => {
													const newShoot = {
														...shoot as Shoot,
														startTime: e.valueOf()
													};
													this.updateShoot(newShoot, index);
												}}
												value={moment(startTime)}
												placeholder={'Start Time'}
												minuteStep={10}
												secondStep={60}
												format={'HH:mm'}
											/>
										)}
									</div>
								)}
							/>
							<Column
								title='End Time'
								dataIndex='endTime'
								key='endTime'
								render={(endTime, shoot: Shoot, index) => (
									<div>
										{shoot.photographerId ? (
											<span>{getTimeHrsMin(shoot.endTime)}</span>
										) : (
											<TimePicker
												allowClear={false}
												onChange={(e) => {
													const newShoot = {
														...shoot as Shoot,
														endTime: e.valueOf()
													};
													this.updateShoot(newShoot, index);
												}}
												value={moment(endTime)}
												placeholder={'Start Time'}
												minuteStep={10}
												secondStep={60}
												format={'HH:mm'}
											/>
										)}
									</div>
								)}
							/>
							<Column
								width={'100px'}
								title='Interval (mins)'
								dataIndex='intervalMins'
								key='intervalMins'
								render={(intervalMins, shoot: Shoot, index) => (
									<div>
										{shoot.photographerId ? (
											<span>{shoot.intervalMins}</span>
										) : (
											<InputNumber
												style={{ width: '100%' }}
												min={1}
												max={999}
												step={1}
												placeholder='Intervals (mins)'
												onChange={(e) => {
													const newShoot = {
														...shoot as Shoot,
														intervalMins: e!
													};
													this.updateShoot(newShoot, index);
												}}
												value={intervalMins}
											/>
										)}
									</div>
								)}
							/>
							<Column
								width={'100px'}
								title='# of Groups'
								dataIndex='endTime'
								key='numberGroups'
								render={(endTime, shoot: Shoot, index) => (
									<div>
										{shoot.photographerId ? (
											<span>
												{this.calculateNumberTimeslots(
													shoot.startTime!,
													shoot.endTime!,
													shoot.intervalMins
												)}
											</span>
										) : (
											<InputNumber
												style={{ width: '100%' }}
												min={0}
												max={999}
												step={10}
												placeholder='Intervals (mins)'
												onChange={(e) => { }}
												value={this.calculateNumberTimeslots(
													shoot.startTime!,
													shoot.endTime!,
													shoot.intervalMins
												)}
											/>
										)}
									</div>
								)}
							/>
							<Column
								title='Action'
								dataIndex='key'
								key='action'
								render={(endTime, shoot: Shoot, index) => (
									<div>
										{shoot.photographerId ? (
											<a
												onClick={() => {
													this.props.onSetSelectedShoot(shoot);
													this.setState({ visible: true });
												}}
											>
												Timeslots
											</a>
										) : (
											<div className='uk-padding-small'>
												{shoots.length > 1 ? (
													<Icon
														className='dynamic-delete-button'
														type='minus-circle-o'
														onClick={() => this.removeShoot(index)}
													/>
												) : null}
											</div>
										)}
									</div>
								)}
							/>
						</Table>
						<div className='uk-flex uk-flex-center uk-margin-bottom uk-margin-bottom uk-margin-top'>
							<Button type='dashed' onClick={this.addShoot} style={{ width: '60%' }}>
								<Icon type='plus' /> Add Shoot
							</Button>
						</div>
						<div className='uk-margin-bottom uk-flex uk-flex-center'>
							{(updateEvent.error || error) && (
								<Alert className='uk-text-center' message={updateEvent.error || error} type='error' />
							)}
							{updateEvent.success && (
								<Alert className='uk-text-center' message={updateEvent.success} type='success' />
							)}
						</div>
						<div className='uk-flex uk-flex-center uk-margin-bottom'>
							<Button
								onClick={this.updateShoots}
								disabled={updateEvent.loading as boolean}
								type='primary'
							>
								Update
							</Button>
						</div>
					</Form>
				</Spin>
				<TimeslotListModal
					visible={this.state.visible}
					onCancel={() => {
						this.setState({ visible: false });
					}}
				/>
				<LocationNotesModal
					disabled={!!shoots[shootIndex].photographerId}
					visible={locationNotesVisible}
					onCancel={() => {
						this.setState({ locationNotesVisible: false });
					}}
					locationNotes={shoots[shootIndex].locationNotes}
					onUpdateLocationNotes={(value: string) => {
						const newShoot = {
							...shoots[shootIndex],
							locationNotes: value
						};
						this.updateShoot(newShoot, shootIndex);
					}}
				/>
			</div>
		);
	}
}

export default compose<any, any>(connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps))(
	ShootsList
);
