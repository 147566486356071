import React, { Component } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { setPage } from '../../reducers/session';
import * as routes from '../../constants/routeNames';
import { User, UserMeta } from '../../models/UsersModel';
import { users as fUsers } from '../../firebase';

import PhoneInput from 'react-phone-number-input/basic-input';
import { Input, Form, Button, Spin, Alert } from 'antd';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { TaskState, TeamPicsFile, TeamPicsLocation } from '../../models/Commons';
import TeampicsPlaces from '../../components/TeampicsPlaces/TeampicsPlaces';
import { titleCase } from '../../helpers/string';

interface OwnProps extends RouteComponentProps {}

interface StateProps {
	authUser: any;
	user: User;
	userMeta: UserMeta;
	loadUser: TaskState;
	updateUser: TaskState;
}

interface DispatchProps {
	onUpdateUser: Function;
}

type Props = StateProps & DispatchProps & OwnProps;
type State = {
	firstName: string;
	lastName: string;
	address: string;
	location: TeamPicsLocation;
	city: string;
	email: string;
	phoneNumber: string;
	postal: string;
	error: string;
	success: string;
	loading: boolean;
};

const INITIAL_STATE = {
	firstName: '',
	lastName: '',
	address: '',
	location: {},
	city: '',
	email: '',
	phoneNumber: '',
	postal: '',
	error: '',
	success: '',
	loading: false
};

const formItemLayout = {
	labelCol: {
		xs: { span: 24 },
		sm: { span: 8 },
		md: { span: 4 }
	},
	wrapperCol: {
		xs: { span: 24 },
		sm: { span: 16 }
	}
};
const tailFormItemLayout = {
	wrapperCol: {
		xs: {
			span: 24,
			offset: 0
		},
		sm: {
			span: 16,
			offset: 8
		},
		md: {
			span: 16,
			offset: 4
		}
	}
};

const mapStateToProps = (state: any) => ({
	authUser: state.sessionState.authUser,
	user: state.usersState.user,
	userMeta: state.usersState.userMeta,
	loadUser: state.usersState.loadUser,
	updateUser: state.usersState.updateUser
});

const mapDispatchToProps = (dispatch: any) => ({
	onUpdateUser: (user: User, logoFile: TeamPicsFile) => {
		dispatch(fUsers.updateUser(user, logoFile));
	}
});

class GeneralForm extends Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = { ...INITIAL_STATE };
	}
	componentDidMount() {}
	componentWillReceiveProps(nextProps: Props) {
		if (nextProps.user && !nextProps.updateUser.loading) {
			this.setState({
				...nextProps.user,
				error: '',
				success: '',
				loading: false
			});
		}
	}
	updateUser = () => {
		const { firstName, lastName, address, location, city, phoneNumber, email, postal } = this.state;

		if (firstName === '') {
			this.setState({
				error: 'Please enter your first name.'
			});
			return;
		}
		if (lastName === '') {
			this.setState({
				error: 'Please enter your last name.'
			});
			return;
		}
		if (!isValidPhoneNumber('+1' + phoneNumber)) {
			this.setState({
				error: 'Please enter a valid phone number.'
			});
			return;
		}
		if (address !== '' && location.address === '') {
			this.setState({
				error: 'Please select an address from the dropdown..'
			});
			return;
		}

		const user = {
			userId: this.props.user.userId,
			firstName,
			lastName,
			address,
			city,
			phoneNumber,
			email,
			postal
		};

		const logoFile = {};

		this.props.onUpdateUser(user, logoFile);
	};
	render() {
		const { authUser, user, userMeta, loadUser, updateUser } = this.props;
		const { firstName, lastName, address, location, city, email, phoneNumber, postal, error, success } = this.state;

		return (
			<div className='uk-width-expand' style={{ minHeight: '1px', overflowY: 'auto' }}>
				<Form className='settings-form'>
					<Spin spinning={(loadUser.loading as boolean) || (updateUser.loading as boolean)}>
						<Form.Item label='First Name' {...formItemLayout}>
							<Input
								placeholder='First Name'
								value={firstName}
								onChange={(e) => {
									this.setState({ firstName: titleCase(e.target.value) });
								}}
							/>
						</Form.Item>
						<Form.Item label='Last Name' {...formItemLayout}>
							<Input
								placeholder='Last Name'
								value={lastName}
								onChange={(e) => {
									this.setState({ lastName: titleCase(e.target.value) });
								}}
							/>
						</Form.Item>
						<Form.Item label='Email' {...formItemLayout}>
							<Input
								disabled
								placeholder='Email'
								value={email}
								onChange={(e) => {
									this.setState({ email: e.target.value.trim() });
								}}
							/>
						</Form.Item>
						<Form.Item label='Phone Number' {...formItemLayout}>
							<PhoneInput
								className='ant-input'
								country='CA'
								placeholder='Phone Number'
								value={phoneNumber}
								onChange={(e: any) => {
									this.setState({ phoneNumber: e });
								}}
							/>
						</Form.Item>
						{/* <Form.Item label='Address' {...formItemLayout}>
              <Input
                placeholder='Address'
                value={address}
                onChange={e => {
                  this.setState({ address: e.target.value });
                }}
              />
            </Form.Item> */}
						<Form.Item label='Address' {...formItemLayout}>
							<TeampicsPlaces
								setAddress={(address: string) => {
									this.setState({ address });
								}}
								setLocation={(location: TeamPicsLocation) => {
									this.setState({ location: location });
								}}
								searchOptions={[]}
								address={address}
								disabled={false}
								placeholder='Search Location...'
							/>
						</Form.Item>
						{/* <Form.Item label='City' {...formItemLayout}>
              <Input
                placeholder='City'
                value={city}
                onChange={e => {
                  this.setState({ city: e.target.value });
                }}
              />
            </Form.Item>
            <Form.Item label='Postal Code' {...formItemLayout}>
              <Input
                placeholder='Postal Code'
                value={postal}
                onChange={e => {
                  this.setState({ postal: e.target.value });
                }}
              />
            </Form.Item> */}
						{(loadUser.error || updateUser.error) && (
							<Form.Item {...tailFormItemLayout}>
								<Alert
									className='uk-text-center'
									message={loadUser.error || updateUser.error}
									type='error'
								/>
							</Form.Item>
						)}
						{(loadUser.success || updateUser.success) && (
							<Form.Item {...tailFormItemLayout}>
								<Alert
									className='uk-text-center'
									message={loadUser.success || updateUser.success}
									type='success'
								/>
							</Form.Item>
						)}
						<Form.Item {...tailFormItemLayout}>
							<Button type='primary' onClick={this.updateUser}>
								Update
							</Button>
						</Form.Item>
					</Spin>
				</Form>
			</div>
		);
	}
}

export default compose<any, any>(
	withRouter,
	connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps)
)(GeneralForm);
