import React, { useState } from "react";
import { Modal, Button, message, Form, Select } from "antd";
import { firestore } from "../../../firebase/firebaseConfig";
import { useDispatch, useSelector } from "react-redux";
import {
  Event,
  Group,
  ImageType,
  Subject,
  TeampicsImage,
} from "../../../models/EventsModel";
import * as rImages from "../../../reducers/images";
import { checkIfSubjectImageValid } from "./Subjects";
const { Option } = Select;

interface Props {
  imageId: string;
  visible: boolean;
  onClose(): any;
}

const AssignGroupModal: React.FC<Props> = ({ imageId, visible, onClose }) => {
  const [loading, setLoading] = useState(false);
  const selectedEvent: Event = useSelector(
    (state: any) => state.eventsState.selectedEvent
  );
  const dispatch = useDispatch();
  const groups: Group[] = useSelector((state: any) => state.imagesState.groups);
  const subjects: Subject[] = useSelector(
    (state: any) => state.imagesState.subjects
  );
  const images: TeampicsImage[] = useSelector(
    (state: any) => state.imagesState.images
  );
  const [selectedGroupId, setSelectedGroupId] = useState(
    groups.length > 0 ? groups[0].key! : ""
  );
  const [selectedSubjectId, setSelectedSubjectId] = useState("");
  const selectedImage = images.find((image) => image.key === imageId);

  const onAfterClose = () => {
    setSelectedGroupId("");
    setSelectedSubjectId("");
  };
  const onGroupSelected = (e: any) => {
    setSelectedGroupId(e);
    setSelectedSubjectId("");
  };
  const onSubjectSelected = (e: any) => {
    setSelectedSubjectId(e);
  };
  const onSubmit = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    try {
      if (selectedImage) {
        if (selectedSubjectId !== "") {
          const selectedSubject: Subject | undefined = subjects.find(
            (subject) => subject.key === selectedSubjectId
          );
          if (selectedSubject) {
            const updatedImage: TeampicsImage = {
              ...selectedImage,
              hasGroup: false,
              hasSubject: true,
              subjects: [selectedSubjectId],
              groups: [],
            };
            const updatedSubject: Subject = {
              ...selectedSubject,
            };
            updatedSubject.imageIds?.push(imageId);
            if (
              updatedSubject.imageIds &&
              updatedSubject.imageIds?.length > 1
            ) {
              updatedSubject.primaryImageId = "";
            } else if (
              !updatedSubject.primaryImageId ||
              updatedSubject.primaryImageId === ""
            ) {
              updatedSubject.primaryImageId = imageId;
            } else {
              updatedSubject.primaryImageId = "";
            }
            if (
              checkIfSubjectImageValid(updatedSubject, updatedSubject.imageIds)
            ) {
              updatedSubject.hasValidImage = true;
            } else {
              updatedSubject.hasValidImage = false;
            }
            const batch = firestore.batch();
            batch.update(
              firestore.collection("uploadedImages").doc(imageId),
              updatedImage
            );
            batch.update(
              firestore.collection("subjects").doc(selectedSubjectId),
              updatedSubject
            );
            await batch.commit();
            dispatch(rImages.modifyImagesArray("UPDATE", updatedImage));
            dispatch(
              rImages.modifyImagesSubjectsArray("UPDATE", updatedSubject)
            );
          }
        } else if (selectedGroupId !== "") {
          const selectedGroup: Group | undefined = groups.find(
            (group) => group.key === selectedGroupId
          );
          if (selectedGroup) {
            const updatedImage: TeampicsImage = {
              ...selectedImage,
              hasGroup: true,
              hasSubject: false,
              subjects: [],
              groups: [selectedGroupId],
            };
            const updatedGroup: Group = {
              ...selectedGroup,
            };
            updatedGroup.imageIds?.push(imageId);
            if (updatedGroup.imageIds && updatedGroup.imageIds.length > 1) {
              updatedGroup.primaryImageId = "";
              updatedGroup.hasValidImage = false;
            } else if (
              !updatedGroup.primaryImageId ||
              updatedGroup.primaryImageId === ""
            ) {
              updatedGroup.primaryImageId = imageId;
              updatedGroup.hasValidImage = true;
            } else {
              updatedGroup.primaryImageId = "";
              updatedGroup.hasValidImage = false;
            }
            const batch = firestore.batch();
            batch.update(
              firestore.collection("uploadedImages").doc(imageId),
              updatedImage
            );
            batch.update(
              firestore
                .collection("events")
                .doc(selectedEvent.key!)
                .collection("groups")
                .doc(selectedGroupId),
              updatedGroup
            );
            await batch.commit();
            dispatch(rImages.modifyImagesArray("UPDATE", updatedImage));
            dispatch(rImages.modifyImagesGroupsArray("UPDATE", updatedGroup));
          }
        }
        setLoading(false);
        onClose();
      }
    } catch (error) {
      console.log(
        "file: AssignGroupModal.tsx ~ line 62 ~ onSubmit ~ error",
        error
      );
      message.error("Something went wrong, please try again");
      setLoading(false);
    }
  };
  const isButtonDisabled = () => {
    if (selectedGroupId === "") {
      return true;
    } else {
      return false;
    }
  };
  return (
    <Modal
      afterClose={onAfterClose}
      title="Assign Image"
      visible={visible}
      onOk={undefined}
      onCancel={onClose}
      footer={null}
    >
      <Form onSubmit={onSubmit}>
        <Form.Item label="Please Select a Group" required>
          <Select
            placeholder="Groups"
            value={selectedGroupId}
            onChange={onGroupSelected}
          >
            <Option key="empty-groups" value=""></Option>
            {groups.map((group) => {
              return (
                <Option value={group.key} key={group.key}>
                  {group.fullName}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item label="Please Select a Subject (optional)">
          <Select
            placeholder="Subjects"
            defaultValue={""}
            value={selectedSubjectId}
            onChange={onSubjectSelected}
            disabled={selectedGroupId === ""}
          >
            <Option key="empty-subject" value=""></Option>
            {subjects
              .slice()
              .filter((subject) => subject.groupId === selectedGroupId)
              .map((subject) => {
                return (
                  <Option
                    value={subject.key}
                    key={subject.key}
                  >{`${subject.firstName} ${subject.lastName}`}</Option>
                );
              })}
          </Select>
        </Form.Item>
        <Form.Item>
          <Button
            loading={loading}
            type="primary"
            htmlType="submit"
            disabled={isButtonDisabled()}
          >
            Assign
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AssignGroupModal;
