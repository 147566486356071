import React, { Component } from 'react';
import { connect } from 'react-redux';
import { User, UserMeta } from '../../models/UsersModel';
import { StepAuth } from '../../models/TeamBuilderModel';
import { Row, Col, Button, Card, Spin, Form, Input, Icon, Alert } from 'antd';
import { Event } from '../../models/EventsModel';
import Animate from 'rc-animate';
import { auth as fAuth, teambuilder as fTeambuilder } from '../../firebase';
import * as rTeambuilder from '../../reducers/teambuilder';
import {
	checkNumbers,
	checkUpperCaseCharacters,
	checkLowerCaseCharacters,
	validateEmail,
	titleCase
} from '../../helpers/string';
import { TaskState } from '../../models/Commons';
import { RouteComponentProps, withRouter } from 'react-router';
import { compose } from 'recompose';

interface OwnProps extends RouteComponentProps { }
interface StateProps {
	user: User;
	userMeta: UserMeta;
	event: Event;
	stepAuth: StepAuth;
	loginUser: TaskState;
}
interface DispatchProps {
	onLoginUserLoading: Function;
	onLoginUser: Function;
	onSetPageNumber: Function;
	onLoadGroups: Function;
}
type Props = StateProps & DispatchProps & OwnProps;
type State = {
	email: string;
	password: string;
	forgotEmail: string;
	rememberMe: boolean;
	loginPageMode: string;
	registerEmail: string;
	registerPassword: string;
	firstName: string;
	lastName: string;
	success: string;
	error: string;
	loading: boolean;
	show: boolean;
};
const mapStateToProps = (state: any) => ({
	user: state.usersState.user,
	userMeta: state.usersState.userMeta,
	event: state.teambuilderState.event,
	stepAuth: state.teambuilderState.stepAuth,
	loginUser: state.teambuilderState.loginUser
});
const mapDispatchToProps = (dispatch: any) => ({
	onLoginUserLoading: () => { },
	onLoginUser: (userId: string) => {
		dispatch(fTeambuilder.loadUser(userId));
	},
	onSetPageNumber: (pageNumber: number) => {
		dispatch(rTeambuilder.setPageNumber(pageNumber));
	},
	onLoadGroups: (eventId: string, contactId: string) => {
		dispatch(fTeambuilder.subscribeToGroups(eventId, contactId));
	}
});
const INITIAL_STATE = {
	email: '',
	password: '',
	forgotEmail: '',
	rememberMe: false,
	loginPageMode: 'register',
	registerEmail: '',
	registerPassword: '',
	firstName: '',
	lastName: '',
	success: '',
	error: '',
	loading: false,
	show: false
};
const Div = (props: any) => {
	const childrenProps = { ...props };
	delete childrenProps.show;
	return <div {...childrenProps} />;
};
class StepAuthComp extends Component<Props, State> {
	constructor(props: Props) {
		super(props);

		this.state = {
			...INITIAL_STATE,
			show: true
		};
		this.enterPress = this.enterPress.bind(this);
	}
	enterPress(event: any) {
		if (event.keyCode === 13) {
			//Do whatever when esc is pressed
			if (this.state.loginPageMode === 'login' && this.state.email !== '' && this.state.password !== '') {
				this.login();
			}
			if (
				this.state.loginPageMode === 'register' &&
				this.state.registerEmail !== '' &&
				this.state.registerPassword !== '' &&
				this.state.firstName !== '' &&
				this.state.lastName !== ''
			) {
				this.register();
			}
			if (this.state.loginPageMode === 'forgot' && this.state.forgotEmail !== '') {
				this.forgotPassword();
			}
		}
	}
	componentDidMount() {
		document.addEventListener('keydown', this.enterPress, false);
	}
	componentWillUnmount() {
		document.removeEventListener('keydown', this.enterPress, false);
	}
	onChangeLoginMode = (mode: string) => {
		this.setState({
			loginPageMode: mode,
			error: '',
			success: '',
			loading: false
		});
	};
	login = () => {
		const { email, password } = this.state;
		this.setState({ loading: true, success: '', error: '' });

		fAuth
			.doSignInWithEmailAndPasswordLocal(email, password)
			.then((userId) => {
				this.props.onLoginUser(userId);
				this.setState({ ...INITIAL_STATE });
				this.props.onLoadGroups((this.props.match.params as any).eventId, userId);
				return this.props.onSetPageNumber(2);
			})
			.catch((error: any) => {
				this.setState({
					error: fAuth.handleLoginError(error.code),
					loading: false
				});
			});
	};
	facebookLogin = () => {
		this.setState({ loading: true, success: '', error: '' });

		fAuth
			.doRegisterWithFacebook()
			.then((userId) => {
				this.props.onLoginUser(userId);
				this.setState({ ...INITIAL_STATE });
				this.props.onLoadGroups((this.props.match.params as any).eventId, userId);
				return this.props.onSetPageNumber(2);
			})
			.catch((error: any) => {
				this.setState({
					error: fAuth.handleFacebookError(error.code, error.email),
					loading: false
				});
			});
	};
	googleLogin = () => {
		this.setState({ loading: true, success: '', error: '' });

		fAuth
			.doRegisterWithGoogle()
			.then((userId) => {
				this.props.onLoginUser(userId);
				this.setState({ ...INITIAL_STATE });
				this.props.onLoadGroups((this.props.match.params as any).eventId, userId);
				return this.props.onSetPageNumber(2);
			})
			.catch((error: any) => {
				this.setState({
					error: fAuth.handleGoogleError(error.code, error.email),
					loading: false
				});
			});
	};
	register = () => {
		const { registerEmail, registerPassword, firstName, lastName } = this.state;
		//this.setState({ loading: true, success: '', error: '' });

		// if (!validateEmail(registerEmail)) {
		// 	this.setState({ error: 'Please enter a valid email.' });
		// 	return;
		// }

		// if (!checkLowerCaseCharacters(registerPassword)) {
		// 	this.setState({
		// 		error: 'Password must contain at least one lowercase letter.'
		// 	});
		// 	return;
		// }

		// if (!checkUpperCaseCharacters(registerPassword)) {
		// 	this.setState({
		// 		error: 'Password must contain at least one uppercase letter.'
		// 	});
		// 	return;
		// }

		// if (!checkNumbers(registerPassword)) {
		// 	this.setState({ error: 'Password must contain at least one number.' });
		// 	return;
		// }

		if (registerPassword.length <= 6) {
			this.setState({ error: 'Password must be at least 6 characters long' });
			return;
		}

		this.setState({ error: '', success: '', loading: true });

		fAuth
			.doRegisterWithEmailAndPassword(registerEmail, registerPassword, firstName, lastName, false)
			.then((userId) => {
				this.props.onLoginUser(userId);
				this.props.onLoadGroups((this.props.match.params as any).eventId, userId);
				return this.setState({ ...INITIAL_STATE });
			})
			.catch((error) => {
				this.setState({
					error: fAuth.handleRegistrationError(error.code),
					loading: false,
					success: ''
				});
			});
	};
	forgotPassword = () => {
		const { forgotEmail } = this.state;
		this.setState({ loading: true, success: '', error: '' });
		fAuth
			.doPasswordReset(forgotEmail)
			.then(() => {
				this.setState({ ...INITIAL_STATE });
				return this.setState({
					success: `A mail reset error was sent to ${forgotEmail}`
				});
			})
			.catch((error: any) => {
				this.setState({
					error: fAuth.handleForgotPasswordError(error.code),
					loading: false
				});
			});
	};
	render() {
		const {
			email,
			password,
			loginPageMode,
			rememberMe,
			forgotEmail,
			firstName,
			lastName,
			registerEmail,
			registerPassword,
			error,
			loading,
			success
		} = this.state;
		const { event, loginUser } = this.props;

		const loginIsInvalid = password === '' || email === '';
		const forgotIsInvalid = forgotEmail === '';
		const registerIsInvalid =
			registerEmail === '' || registerPassword === '' || firstName === '' || lastName === '';

		return (
			<div
				className='uk-width-expand uk-padding uk-flex uk-flex-center uk-flex-middle'
				style={{
					minHeight: '100vh',
					overflowY: 'auto',
					background: `linear-gradient(to bottom right, ${event.priColor}, ${event.secColor})`
				}}
			>
				<Row type='flex' justify='space-around' align='middle' style={{ height: '100%' }}>
					<Col sm={24} md={12}>
						<div className='uk-flex uk-flex-center'>
							<Animate transitionName='fade' transitionAppear>
								<Div show={this.state.show}>
									<Card className='rounded-card login-card'>
										<Card
											style={{
												background: '#4DC5D5',
												marginTop: '-50px',
												marginBottom: '12px'
											}}
											className='rounded-card'
										>
											<div className='uk-flex uk-flex-center'>
												<div className='uk-flex uk-flex-column'>
													<h2 style={{ color: 'white' }}>Continue With</h2>
													<div className='uk-flex uk-flex-row uk-flex-center'>
														<Button
															shape='circle'
															icon='facebook'
															disabled={event.key === ''}
															onClick={this.facebookLogin}
														/>
														<div style={{ width: '24px' }} />
														<Button
															shape='circle'
															icon='google'
															disabled={event.key === ''}
															onClick={this.googleLogin}
														/>
													</div>
												</div>
											</div>
										</Card>

										<Spin
											indicator={<Icon type='loading' style={{ fontSize: 24 }} spin />}
											spinning={loading || (loginUser.loading as boolean)}
										>
											{loginPageMode === 'login' && (
												<Animate transitionName='fade' transitionAppear>
													<Div show={loginPageMode === 'login'}>
														<Form className='login-form'>
															<Row className='uk-margin-top'>
																<Input
																	prefix={
																		<Icon
																			type='user'
																			style={{ color: 'rgba(0,0,0,.25)' }}
																		/>
																	}
																	placeholder='Email'
																	value={email}
																	onChange={(e) => {
																		this.setState({
																			email: e.target.value.trim()
																		});
																	}}
																/>
															</Row>
															<Row className='uk-margin-small-top'>
																<Input
																	prefix={
																		<Icon
																			type='lock'
																			style={{ color: 'rgba(0,0,0,.25)' }}
																		/>
																	}
																	type='password'
																	placeholder='Password'
																	value={password}
																	onChange={(e) => {
																		this.setState({
																			password: e.target.value
																		});
																	}}
																/>
															</Row>
														</Form>
													</Div>
												</Animate>
											)}
											{loginPageMode === 'forgot' && (
												<Animate transitionName='fade' transitionAppear>
													<Div show={loginPageMode === 'forgot'}>
														<Form className='login-form'>
															<Row className='uk-margin-top'>
																<Input
																	prefix={
																		<Icon
																			type='user'
																			style={{ color: 'rgba(0,0,0,.25)' }}
																		/>
																	}
																	placeholder='Email'
																	value={forgotEmail}
																	onChange={(e) => {
																		this.setState({
																			forgotEmail: e.target.value
																		});
																	}}
																/>
															</Row>
														</Form>
													</Div>
												</Animate>
											)}
											{loginPageMode === 'register' && (
												<Animate transitionName='fade' transitionAppear>
													<Div show={loginPageMode === 'register'}>
														<Form className='login-form'>
															<Row className='uk-margin-top'>
																<h3 className='uk-text-center'>
																	Let's get you started with Teampics!
																</h3>
															</Row>
															<Row className='uk-margin-top'>
																<Input
																	prefix={
																		<Icon
																			type='user'
																			style={{ color: 'rgba(0,0,0,.25)' }}
																		/>
																	}
																	placeholder='Email'
																	value={registerEmail}
																	onChange={(e) => {
																		this.setState({
																			registerEmail: e.target.value
																		});
																	}}
																/>
															</Row>
															<Row className='uk-margin-small-top'>
																<Input
																	prefix={
																		<Icon
																			type='user'
																			style={{ color: 'rgba(0,0,0,.25)' }}
																		/>
																	}
																	type='text'
																	placeholder='First Name'
																	value={firstName}
																	onChange={(e) => {
																		this.setState({
																			firstName: titleCase(e.target.value)
																		});
																	}}
																/>
															</Row>
															<Row className='uk-margin-small-top'>
																<Input
																	prefix={
																		<Icon
																			type='user'
																			style={{ color: 'rgba(0,0,0,.25)' }}
																		/>
																	}
																	type='text'
																	placeholder='Last Name'
																	value={lastName}
																	onChange={(e) => {
																		this.setState({
																			lastName: titleCase(e.target.value)
																		});
																	}}
																/>
															</Row>
															<Row className='uk-margin-small-top'>
																<Input
																	prefix={
																		<Icon
																			type='lock'
																			style={{ color: 'rgba(0,0,0,.25)' }}
																		/>
																	}
																	type='password'
																	placeholder='Password'
																	value={registerPassword}
																	onChange={(e) => {
																		this.setState({
																			registerPassword: e.target.value
																		});
																	}}
																/>
															</Row>
														</Form>
													</Div>
												</Animate>
											)}

											<Row className='uk-margin-top'>
												{loginPageMode === 'login' && (
													<Button
														disabled={loginIsInvalid || event.key === ''}
														type='primary'
														className='login-form-button'
														style={{ width: '100%' }}
														onClick={this.login}
													>
														Log in
													</Button>
												)}
												{loginPageMode === 'forgot' && (
													<Button
														disabled={forgotIsInvalid}
														type='primary'
														className='login-form-button'
														style={{ width: '100%' }}
														onClick={this.forgotPassword}
													>
														Send Reset Email
													</Button>
												)}
												{loginPageMode === 'register' && (
													<Button
														disabled={registerIsInvalid}
														type='primary'
														className='login-form-button'
														style={{ width: '100%' }}
														onClick={this.register}
													>
														Sign Up
													</Button>
												)}
											</Row>
											<div className='uk-margin-small-top uk-margin-small-bottom'>
												{(error || loginUser.error) && (
													<Alert
														className='uk-text-center'
														message={error || loginUser.error}
														type='error'
													/>
												)}
											</div>
											<div className='uk-margin-small-top uk-margin-small-bottom'>
												{success && (
													<Alert
														className='uk-text-center'
														message={success}
														type='success'
													/>
												)}
											</div>
											<div className='uk-card-footer'>
												<Row
													style={{ marginTop: '12px' }}
													type='flex'
													justify='space-around'
													align='middle'
												>
													{loginPageMode === 'login' && (
														<div className='uk-flex uk-flex-column uk-text-center'>
															<a
																className='uk-margin-top'
																onClick={() => this.onChangeLoginMode('forgot')}
															>
																Forgot Password?
															</a>
															<a
																className='uk-margin-top'
																onClick={() => this.onChangeLoginMode('register')}
															>
																Don't have an account? Sign up!
															</a>
														</div>
													)}
													{loginPageMode === 'register' && (
														<div className='uk-flex uk-flex-column uk-text-center'>
															<a
																className='uk-margin-top'
																onClick={() => this.onChangeLoginMode('forgot')}
															>
																Forgot Password?
															</a>
															<a
																className='uk-margin-top'
																onClick={() => this.onChangeLoginMode('login')}
															>
																Already have an account? Log in!
															</a>
														</div>
													)}
													{loginPageMode === 'forgot' && (
														<div className='uk-flex uk-flex-column uk-text-center'>
															<a
																className='uk-margin-top'
																onClick={() => this.onChangeLoginMode('login')}
															>
																Know your password? Log in!
															</a>
															<a
																className='uk-margin-top'
																onClick={() => this.onChangeLoginMode('register')}
															>
																Don't have an account? Sign up!
															</a>
														</div>
													)}
												</Row>
											</div>
										</Spin>
									</Card>
								</Div>
							</Animate>
						</div>
					</Col>
				</Row>
			</div>
		);
	}
}

export default compose<any, any>(
	withRouter,
	connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps)
)(StepAuthComp);
