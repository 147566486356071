import React, { Component } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import {
	Modal,
	Steps,
	Button,
	Divider,
	Form,
	Input,
	Select,
	Upload,
	Icon,
	Row,
	Col,
	Spin,
	message,
	Checkbox
} from 'antd';
import ColorPicker from '../../components/ColorPicker/ColorPicker';
import TeampicsPlaces from '../../components/TeampicsPlaces/TeampicsPlaces';
import { TeamPicsLocation, Season, TeamPicsFile, TaskState, Contact } from '../../models/Commons';
import { ClientTypes, ContactTypes, ContactStatusTypes, Client } from '../../models/ClientsModel';
import { User } from '../../models/UsersModel';
import PhoneInput from 'react-phone-number-input/basic-input';
import { getClientTypeOptions, getContactStatusOptions, getContactRoleOptions } from '../../constants/selectOptions';
import {
	dummyRequest,
	getFileType,
	checkFileSize,
	getBase64,
	getMimeType,
	checkIfValidImage
} from '../../constants/fileTypes';
import { RcFile } from 'antd/lib/upload/interface';

import { clients as fClients } from '../../firebase';
import * as rClients from '../../reducers/clients';

import * as stringHelpers from '../../helpers/string';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { updateClient } from '../../firebase/clients';

const { TextArea } = Input;
const Step = Steps.Step;

interface OwnProps {
	visible: boolean;
	onCancel: Function;
}

interface StateProps {
	updateClient: TaskState;
	user: User;
}

interface DispatchProps {
	onNewClient: Function;
}

type Props = StateProps & DispatchProps & OwnProps;
type State = {
	pageNumber: number;
	active: boolean;
	email: string;
	name: string;
	phoneNumber: string;
	priColor: string;
	secColor: string;
	type: ClientTypes;
	seasons: Season;
	website: string;
	address: string;
	location: TeamPicsLocation;
	logoFile: TeamPicsFile;
	logoUrl: string;
	notes: string;
	contacts: Array<Contact>;
	error: string;
};
const INITIAL_STATE_EMPTY_CONTACT = {
	firstName: '',
	lastName: '',
	email: '',
	phoneNumber: '',
	role: '' as ContactTypes,
	status: '' as ContactStatusTypes,
	registered: false
};
const INITIAL_STATE = {
	pageNumber: 0,
	active: true,
	email: '',
	name: '',
	phoneNumber: '',
	priColor: '#000000',
	secColor: '#000000',
	type: '' as ClientTypes,
	seasons: { spring: false, summer: false, fall: false, winter: false },
	website: '',
	address: '',
	location: {} as TeamPicsLocation,
	logoFile: {} as TeamPicsFile,
	logoUrl: '',
	notes: '',
	contacts: [
		{
			...INITIAL_STATE_EMPTY_CONTACT
		}
	],
	error: ''
};

const mapStateToProps = (state: any) => ({
	user: state.usersState.user,
	updateClient: state.clientsState.updateClient
});

const mapDispatchToProps = (dispatch: any) => ({
	onNewClient: (uid: string, client: Client, contacts: Array<Contact>, logoFile: TeamPicsFile) => {
		dispatch(fClients.newClient(uid, client, contacts, logoFile));
	}
});

const formItemLayout = {
	labelCol: {
		xs: { span: 24 },
		sm: { span: 8 },
		md: { span: 4 }
	},
	wrapperCol: {
		xs: { span: 24 },
		sm: { span: 16 }
	}
};
const formColorLayout = {
	labelCol: {
		xs: { span: 24 },
		sm: { span: 8 },
		md: { span: 4 }
	},
	wrapperCol: {
		xs: { span: 24 },
		sm: { span: 6 }
	}
};
const steps = [
	{
		title: 'General'
	},
	{
		title: 'Logo'
	},
	{
		title: 'Contacts'
	}
];

class NewClient extends Component<Props, State> {
	state = { ...INITIAL_STATE };
	componentDidMount() { }
	componentWillReceiveProps(nextProps: Props) {
		if (nextProps.updateClient.error && !this.props.updateClient.error) {
			message.error(nextProps.updateClient.error);
		}
	}
	next() {
		const pageNumber = this.state.pageNumber + 1;
		this.setState({ pageNumber });
	}
	prev() {
		const pageNumber = this.state.pageNumber - 1;
		this.setState({ pageNumber });
	}
	cancel() {
		this.setState({ ...INITIAL_STATE });
		this.props.onCancel();
	}
	handleUploadChange = (file: any) => { };
	beforeUpload = (file: RcFile, FileList: RcFile[]): boolean | PromiseLike<any> => {
		const fileType = getFileType(file.name);
		if (!checkFileSize(file.size, 1)) {
			this.setState({ error: 'Max file size is 1MB' });
			message.error('Max file size is 1MB');
			return false;
		}
		var fileReader = new FileReader();
		fileReader.onloadend = (e: any) => {
			var arr = new Uint8Array(e.target.result).subarray(0, 4);
			var header = '';
			for (var i = 0; i < arr.length; i++) {
				header += arr[i].toString(16);
			}
			// Check the file signature against known types
			if (!checkIfValidImage(header, getMimeType(fileType))) {
				this.setState({ error: 'Please upload a jpeg or png!' });
			} else {
				getBase64(file, (imageUrl: string) =>
					this.setState({
						logoUrl: imageUrl,
						logoFile: {
							fileType: fileType,
							mimeType: getMimeType(fileType),
							blob: imageUrl,
							fileSize: file.size
						}
					})
				);
			}
		};
		fileReader.readAsArrayBuffer(file.slice(0, 4));
		return true;
	};
	addContact = () => {
		const { contacts } = this.state;
		const newContacts: Array<Contact> = [...contacts, { ...INITIAL_STATE_EMPTY_CONTACT }];
		this.setState({ contacts: newContacts });
	};
	removeContact = (index: number) => {
		const { contacts } = this.state;
		const newContacts = contacts.filter((contact, newIndex) => newIndex !== index);
		this.setState({ contacts: newContacts });
	};
	updateContact = (newContact: Contact, index: number) => {
		const newContacts = this.state.contacts.map((oldContact, oldIndex) => {
			if (index !== oldIndex) {
				// This isn't the item we care about - keep it as-is
				return oldContact;
			}
			// Otherwise, this is the one we want - return an updated value
			return {
				...oldContact,
				...newContact
			};
		});
		this.setState({ contacts: newContacts });
	};
	validatedGeneral = () => {
		const { email, phoneNumber } = this.state;
		this.setState({ error: '' });

		if (!stringHelpers.validateEmail(email)) {
			this.setState({
				error: 'Please enter a valid email for the client'
			});
			message.error('Please enter a valid email for the client');
			return;
		}

		if (!isValidPhoneNumber('+1' + phoneNumber)) {
			this.setState({
				error: 'Please enter a valid phone number for the client'
			});
			message.error('Please enter a valid phone number for the client');
			return;
		}
		this.next();
	};
	validateLogo = () => {
		const { logoFile } = this.state;
		this.setState({ error: '' });
		if (!logoFile.blob) {
			this.setState({
				error: 'Please upload a logo for the client'
			});
			message.error('Please upload a logo for the client');
			return;
		}
		this.next();
	};
	newClient = () => {
		const {
			pageNumber,
			active,
			email,
			logoFile,
			logoUrl,
			name,
			phoneNumber,
			priColor,
			secColor,
			type,
			seasons,
			website,
			address,
			location,
			contacts,
			notes
		} = this.state;
		const { user } = this.props;
		this.setState({ error: '' });

		if (name.length > 100) {
			message.error('Maxmium lenght for client name is 100 character');
			return;
		}

		let error = '';
		contacts.some((contact, index): boolean => {
			if (!stringHelpers.validateEmail(contact.email)) {
				error = `Contact ${index + 1}: Please enter a valid email for the contact.`;
				return !!error;
			}
			if (!isValidPhoneNumber('+1' + contact.phoneNumber)) {
				error = `Contact ${index + 1}: Please enter a valid phone number for the contact.`;
				return !!error;
			}
			if (contact.firstName === '') {
				error = `Contact ${index + 1}: Please enter contact's first name.`;
				return !!error;
			}
			if (contact.role === '') {
				error = `Contact ${index + 1}: Please select the contact's role.`;
				return !!error;
			}
			if (contact.status === '') {
				error = `Contact ${index + 1}: Please select the contact's status.`;
				return !!error;
			}
			return false;
		});
		if (error !== '') {
			this.setState({
				error: error
			});
			message.error(error);
			return;
		}

		const client: Client = {
			userId: user.userId!,
			active,
			email,
			name,
			phoneNumber,
			priColor,
			secColor,
			type,
			seasons,
			website,
			location,
			logoUrl: '',
			notes,
			timestamp: new Date().getTime()
		};

		this.props.onNewClient(user.userId, client, contacts, logoFile);
	};
	render() {
		const { visible, onCancel } = this.props;
		const {
			pageNumber,
			active,
			email,
			logoFile,
			logoUrl,
			name,
			phoneNumber,
			priColor,
			secColor,
			type,
			seasons,
			website,
			error,
			address,
			location,
			contacts,
			notes
		} = this.state;
		const { updateClient } = this.props;

		const generalIsInvalid =
			email === '' ||
			name === '' ||
			phoneNumber === '' ||
			priColor === '' ||
			secColor === '' ||
			type === '' ||
			(!seasons.summer && !seasons.winter && !seasons.spring && !seasons.fall) ||
			location.placeId === '' ||
			website === '';
		const uploadIsInvalid = !logoFile.blob;
		const contactsAreInvalid = contacts[0].firstName === '';

		const uploadButton = (
			<div>
				<Icon type={'plus'} />
				<div className='ant-upload-text'>Upload Client Logo</div>
			</div>
		);

		return (
			<Modal
				keyboard={false}
				centered
				bodyStyle={{ height: '90vh', borderRadius: 0 }}
				width={'90vw'}
				visible={visible}
				title='Set up a new Client'
				okText='Create'
				footer={null}
				afterClose={() => {
					this.setState({ ...INITIAL_STATE });
				}}
				onCancel={() => {
					this.setState({ ...INITIAL_STATE });
					onCancel();
				}}
				onOk={() => { }}
			>
				<div>
					<Steps size='small' current={this.state.pageNumber}>
						{steps.map((item) => <Step key={item.title} title={item.title} />)}
					</Steps>

					<div className='steps-content'>
						<Form>
							<Spin
								indicator={<Icon type='loading' style={{ fontSize: 24 }} spin />}
								spinning={updateClient.loading as boolean}
							>
								{pageNumber === 0 && (
									<div>
										<Form.Item label='Client Name' {...formItemLayout}>
											<Input
												placeholder='Name'
												onChange={(e) => {
													if (e.target.value.length <= 100) {
														this.setState({
															name: stringHelpers.titleCase(e.target.value)
														});
													}
												}}
												value={name}
											/>
										</Form.Item>
										<Form.Item label='Email' {...formItemLayout}>
											<Input
												placeholder='Email'
												value={email}
												onChange={(e) => {
													this.setState({ email: e.target.value.trim() });
												}}
											/>
										</Form.Item>
										<Form.Item label='Client Phone #' {...formItemLayout}>
											<PhoneInput
												className='ant-input'
												country='CA'
												placeholder='Phone Number'
												value={phoneNumber}
												onChange={(e: any) => {
													this.setState({ phoneNumber: e });
												}}
											/>
										</Form.Item>
										<Form.Item label='Primary Color' {...formColorLayout}>
											<ColorPicker
												setHex={(color: any) => {
													this.setState({ priColor: color.hex });
												}}
												hex={priColor}
											/>
										</Form.Item>
										<Form.Item label='Secondary Color' {...formColorLayout}>
											<ColorPicker
												setHex={(color: any) => {
													this.setState({ secColor: color.hex });
												}}
												hex={secColor}
											/>
										</Form.Item>
										<Form.Item label='Website' {...formItemLayout}>
											<Input
												placeholder='Website'
												value={website}
												onChange={(e) => {
													this.setState({ website: e.target.value });
												}}
											/>
										</Form.Item>
										<Form.Item label='Location' {...formItemLayout}>
											<TeampicsPlaces
												setAddress={(address: string) => {
													this.setState({ address });
												}}
												setLocation={(location: TeamPicsLocation) => {
													this.setState({ location: location });
												}}
												address={address}
												disabled={false}
												placeholder='Search Location...'
											/>
										</Form.Item>
										<Form.Item label='Client Type' {...formItemLayout}>
											<Select
												placeholder={'Select Type'}
												labelInValue
												onChange={(e: any) => {
													this.setState({
														type: e.key as ClientTypes
													});
												}}
											>
												{getClientTypeOptions()}
											</Select>
										</Form.Item>
										<Form.Item label='Seasons' {...formItemLayout}>
											<Checkbox
												name='spring'
												checked={seasons.spring}
												onClick={(e) => {
													this.setState({
														seasons: {
															...seasons,
															spring: !seasons.spring
														}
													});
												}}
											>
												Spring
											</Checkbox>
											<Checkbox
												name='summer'
												checked={seasons.summer}
												onClick={(e) => {
													this.setState({
														seasons: {
															...seasons,
															summer: !seasons.summer
														}
													});
												}}
											>
												Summer
											</Checkbox>
											<Checkbox
												name='fall'
												checked={seasons.fall}
												onClick={(e) => {
													this.setState({
														seasons: {
															...seasons,
															fall: !seasons.fall
														}
													});
												}}
											>
												Fall
											</Checkbox>
											<Checkbox
												name='winter'
												checked={seasons.winter}
												onClick={(e) => {
													this.setState({
														seasons: {
															...seasons,
															winter: !seasons.winter
														}
													});
												}}
											>
												Winter
											</Checkbox>
										</Form.Item>
										<Form.Item label='Notes' {...formItemLayout}>
											<TextArea
												placeholder='Enter Notes...'
												value={notes}
												onChange={(e) => {
													this.setState({ notes: e.target.value });
												}}
												rows={5}
											/>
										</Form.Item>
									</div>
								)}
								{pageNumber === 1 && (
									<div
										style={{ minHeight: '500px' }}
										className='uk-flex uk-flex-center uk-flex-middle'
									>
										<Upload
											name='avatar'
											listType='picture-card'
											className='avatar-uploader'
											showUploadList={false}
											beforeUpload={this.beforeUpload}
											onChange={this.handleUploadChange}
											customRequest={dummyRequest}
											style={{ height: '300px', minWidth: '300px' }}
										>
											{logoUrl ? (
												<img style={{ maxWidth: '800px' }} src={logoUrl} alt='avatar' />
											) : (
												uploadButton
											)}
										</Upload>
									</div>
								)}
								{pageNumber === 2 && (
									<div>
										<ul className='list'>
											{contacts.map((contact, index) => {
												return (
													<Row key={`contact ${index}`}>
														<Col sm={24} md={1}>
															<div className='uk-padding-small'>
																<p className='uk-margin-remove'>{index + 1}</p>
															</div>
														</Col>
														<Col sm={24} md={3}>
															<div className='uk-padding-small'>
																<Input
																	placeholder='First Name'
																	value={contact.firstName}
																	onChange={(e) => {
																		const newContact = {
																			...contact,
																			firstName: stringHelpers.titleCase(
																				e.target.value
																			)
																		};
																		this.updateContact(newContact, index);
																	}}
																/>
															</div>
														</Col>
														<Col sm={24} md={3}>
															<div className='uk-padding-small'>
																<Input
																	placeholder='Last Name'
																	value={contact.lastName}
																	onChange={(e) => {
																		const newContact = {
																			...contact,
																			lastName: stringHelpers.titleCase(
																				e.target.value
																			)
																		};
																		this.updateContact(newContact, index);
																	}}
																/>
															</div>
														</Col>
														<Col sm={24} md={4}>
															<div className='uk-padding-small'>
																<Input
																	placeholder='Email'
																	value={contact.email}
																	onChange={(e) => {
																		const newContact = {
																			...contact,
																			email: e.target.value
																		};
																		this.updateContact(newContact, index);
																	}}
																/>
															</div>
														</Col>

														<Col sm={24} md={4}>
															<div className='uk-padding-small'>
																<PhoneInput
																	className='ant-input'
																	country='CA'
																	placeholder='Phone Number'
																	value={contact.phoneNumber}
																	onChange={(e: any) => {
																		const newContact = {
																			...contact,
																			phoneNumber: e
																		};
																		this.updateContact(newContact, index);
																	}}
																/>
															</div>
														</Col>
														<Col sm={24} md={4}>
															<div className='uk-padding-small'>
																<Select
																	value={
																		contact.role ? { key: contact.role } : undefined
																	}
																	placeholder={'Select Contact Role..'}
																	labelInValue
																	onChange={(e: { key: string }) => {
																		const newContact = {
																			...contact,
																			role: e.key
																		} as Contact;
																		this.updateContact(newContact, index);
																	}}
																>
																	{getContactRoleOptions()}
																</Select>
															</div>
														</Col>
														<Col sm={24} md={4}>
															<div className='uk-padding-small'>
																<Select
																	value={
																		contact.status ? (
																			{ key: contact.status }
																		) : (
																			undefined
																		)
																	}
																	placeholder={'Select Contact Status...'}
																	labelInValue
																	onChange={(e: { key: string }) => {
																		const newContact = {
																			...contact,
																			status: e.key
																		} as Contact;
																		this.updateContact(newContact, index);
																	}}
																>
																	{getContactStatusOptions()}
																</Select>
															</div>
														</Col>
														<Col sm={24} md={1}>
															<div className='uk-padding-small'>
																{contacts.length > 1 ? (
																	<Icon
																		className='dynamic-delete-button'
																		type='minus-circle-o'
																		//disabled={(contacts.length === 1) as boolean}
																		onClick={() => this.removeContact(index)}
																	/>
																) : null}
															</div>
														</Col>
													</Row>
												);
											})}
										</ul>
										<div className='uk-flex uk-flex-center uk-margin-bottom'>
											<Button type='dashed' onClick={this.addContact} style={{ width: '60%' }}>
												<Icon type='plus' /> Add contact
											</Button>
										</div>
									</div>
								)}
							</Spin>
						</Form>
					</div>
					<div className='uk-position-bottom uk-padding-small'>
						<Divider />
						<div className='uk-flex uk-flex-row'>
							<div className='uk-flex uk-flex-left'>
								<Button onClick={() => onCancel()}>Cancel</Button>
							</div>
							<div className='uk-flex uk-flex-right uk-width-expand'>
								{this.state.pageNumber > 0 && <Button onClick={() => this.prev()}>Previous</Button>}
								{this.state.pageNumber < steps.length - 1 && (
									<Button
										style={{ marginLeft: 8 }}
										type='primary'
										onClick={() => {
											if (pageNumber === 0) {
												this.validatedGeneral();
											} else if (pageNumber === 1) {
												this.validateLogo();
											}
										}}
										disabled={
											(pageNumber === 0 && generalIsInvalid) ||
											(pageNumber === 1 && uploadIsInvalid) ||
											(pageNumber === 3 && contactsAreInvalid)
										}
									>
										Next
									</Button>
								)}
								{this.state.pageNumber === steps.length - 1 && (
									<Button
										style={{ marginLeft: 8 }}
										type='primary'
										disabled={updateClient.loading as boolean}
										onClick={() => {
											this.newClient();
										}}
									>
										Done
									</Button>
								)}
							</div>
						</div>
					</div>
				</div>
			</Modal>
		);
	}
}

export default compose<any, any>(connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps))(
	NewClient
);
