import React, { Component } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { setPage } from '../../reducers/session';
import * as routes from '../../constants/routeNames';
import { auth } from '../../firebase';
import {
	validateEmail,
	checkLowerCaseCharacters,
	checkUpperCaseCharacters,
	checkNumbers,
	titleCase
} from '../../helpers/string';
import { Row, Col, Card, Form, Icon, Input, Button, Checkbox, Spin, Alert } from 'antd';

const INITIAL_STATE = {
	email: '',
	password: '',
	firstName: '',
	lastName: '',
	photographer: false,
	success: '',
	error: '',
	loading: false
};

interface OwnProps extends RouteComponentProps {}

interface StateProps {}

interface DispatchProps {}

type Props = StateProps & DispatchProps & OwnProps;
type State = {
	email: string;
	password: string;
	firstName: string;
	lastName: string;
	photographer: boolean;
	success: string;
	error: string;
	loading: boolean;
};

const mapStateToProps = (state: any) => ({});

const mapDispatchToProps = (dispatch: any) => ({
	onSetPage: (page: any) => {
		dispatch(setPage(page));
	}
});

class RegistrationPage extends Component<Props, State> {
	constructor(props: Props) {
		super(props);

		this.state = {
			...INITIAL_STATE
		};
		this.enterPress = this.enterPress.bind(this);
	}
	enterPress(event: any) {
		if (event.keyCode === 13) {
			//Do whatever when esc is pressed
			if (this.state.email !== '' && this.state.password !== '') {
				this.onRegister();
			}
		}
	}
	componentDidMount() {
		document.addEventListener('keydown', this.enterPress, false);
	}
	componentWillUnmount() {
		document.removeEventListener('keydown', this.enterPress, false);
	}

	onRegister = () => {
		const { email, password, firstName, lastName, photographer } = this.state;
		const { history } = this.props;

		if (!validateEmail(email)) {
			this.setState({ error: 'Please enter a valid email.' });
			return;
		}

		// if (!checkLowerCaseCharacters(password)) {
		//   this.setState({
		//     error: 'Password must contain at least one lowercase letter.'
		//   });
		//   return;
		// }

		// if (!checkUpperCaseCharacters(password)) {
		//   this.setState({
		//     error: 'Password must contain at least one uppercase letter.'
		//   });
		//   return;
		// }

		// if (!checkNumbers(password)) {
		//   this.setState({ error: 'Password must contain at least one number.' });
		//   return;
		// }

		if (password.length <= 6) {
			this.setState({ error: 'Password must be at least 6 characters long' });
			return;
		}

		this.setState({ error: '', success: '', loading: true });

		auth
			.doRegisterWithEmailAndPassword(email, password, firstName, lastName, photographer)
			.then(() => {
				this.setState({ ...INITIAL_STATE });
				return history.push(routes.DASHBOARD);
			})
			.catch((error) => {
				this.setState({
					error: auth.handleRegistrationError(error.code),
					loading: false,
					success: ''
				});
			});
	};
	facebookLogin = () => {
		const { history } = this.props;

		auth
			.doRegisterWithFacebook()
			.then(() => {
				this.setState({ ...INITIAL_STATE });
				return history.push(routes.DASHBOARD);
			})
			.catch((error) => {
				this.setState({
					error: auth.handleFacebookError(error.code, error.email),
					loading: false,
					success: ''
				});
			});
	};

	googleLogin = () => {
		const { history } = this.props;

		auth
			.doRegisterWithGoogle()
			.then(() => {
				this.setState({ ...INITIAL_STATE });
				return history.push(routes.DASHBOARD);
			})
			.catch((error) => {
				this.setState({
					error: auth.handleGoogleError(error.code, error.email),
					loading: false,
					success: ''
				});
			});
	};
	render() {
		const { email, password, firstName, lastName, photographer, success, error, loading } = this.state;
		const { history } = this.props;
		const isInvalid = email === '' || password === '' || firstName === '' || lastName === '';

		return (
			<div style={{ height: '100vh', overflowY: 'auto' }}>
				<Row type='flex' justify='space-around' align='middle' style={{ height: '100%' }}>
					<Col sm={24} md={12}>
						<div className='uk-flex uk-flex-center'>
							<Card className='rounded-card login-card'>
								<Card
									style={{
										background: '#4DC5D5',
										marginTop: '-50px',
										marginBottom: '12px'
									}}
									className='rounded-card'
								>
									<div className='uk-flex uk-flex-center'>
										<div className='uk-flex uk-flex-column'>
											<h2 style={{ color: 'white' }}>Continue With</h2>
											<div className='uk-flex uk-flex-row uk-flex-center'>
												<Button shape='circle' icon='facebook' onClick={this.facebookLogin} />
												<div style={{ width: '24px' }} />
												<Button shape='circle' icon='google' onClick={this.googleLogin} />
											</div>
										</div>
									</div>
								</Card>

								<Spin
									indicator={<Icon type='loading' style={{ fontSize: 24 }} spin />}
									spinning={loading}
								>
									<Form className='login-form'>
										<Row className='uk-margin-top'>
											<h3 className='uk-text-center'>Let's get you started with Teampics!</h3>
										</Row>
										<Row className='uk-margin-top'>
											<Input
												prefix={<Icon type='user' style={{ color: 'rgba(0,0,0,.25)' }} />}
												placeholder='Email'
												value={email}
												onChange={(e) => {
													this.setState({ email: e.target.value.trim() });
												}}
											/>
										</Row>
										<Row className='uk-margin-small-top'>
											<Input
												prefix={<Icon type='user' style={{ color: 'rgba(0,0,0,.25)' }} />}
												type='text'
												placeholder='First Name'
												value={firstName}
												onChange={(e) => {
													this.setState({ firstName: titleCase(e.target.value) });
												}}
											/>
										</Row>
										<Row className='uk-margin-small-top'>
											<Input
												prefix={<Icon type='user' style={{ color: 'rgba(0,0,0,.25)' }} />}
												type='text'
												placeholder='Last Name'
												value={lastName}
												onChange={(e) => {
													this.setState({ lastName: titleCase(e.target.value) });
												}}
											/>
										</Row>
										<Row className='uk-margin-small-top'>
											<Input
												prefix={<Icon type='lock' style={{ color: 'rgba(0,0,0,.25)' }} />}
												type='password'
												placeholder='Password'
												value={password}
												onChange={(e) => {
													this.setState({ password: e.target.value });
												}}
											/>
										</Row>
										<Row
											className='uk-margin-small-top uk-margin-small-bottom'
											type='flex'
											justify='space-around'
											align='middle'
										>
											<Checkbox
												checked={photographer}
												onChange={(e) => {
													this.setState({ photographer: e.target.checked });
												}}
											>
												Are you a photographer?
											</Checkbox>
										</Row>
									</Form>

									<Row className='uk-margin-top'>
										<Button
											disabled={isInvalid}
											type='primary'
											className='login-form-button'
											style={{ width: '100%' }}
											onClick={this.onRegister}
										>
											Register
										</Button>
									</Row>
									<div className='uk-margin-small-top uk-margin-small-bottom'>
										{error && <Alert className='uk-text-center' message={error} type='error' />}
									</div>
									<div className='uk-card-footer'>
										<Row
											style={{ marginTop: '12px' }}
											type='flex'
											justify='space-around'
											align='middle'
										>
											<a
												onClick={() => {
													history.push(routes.LOGIN);
												}}
											>
												Already a member? Log in.
											</a>
										</Row>
									</div>
								</Spin>
							</Card>
						</div>
					</Col>
				</Row>
			</div>
		);
	}
}

export default compose<any, any>(
	withRouter,
	connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps)
)(RegistrationPage);
