import { LoginPage as Login, RegisterPage as Register } from '../pages';

var authRoutes = [
  {
    path: '/login',
    name: 'Login',
    icon: 'design_app',
    component: Login
  },
  {
    path: '/register',
    name: 'Register',
    icon: 'design_app',
    component: Register
  }
];

export default authRoutes;
