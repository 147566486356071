import React, { Component } from 'react';
import { Provider } from 'react-redux';
import Routes from './routes';
import { store, persistor } from './store';
import { BrowserRouter as Router } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import * as Sentry from '@sentry/browser';

Sentry.init({
	dsn: 'https://5c8fb2cf63f3497aa55c58a8a45b2a47@sentry.io/1432558'
});

//polyfill for allowing splashscreen.remove()
(function (arr) {
	arr.forEach(function (item) {
		if (item.hasOwnProperty('remove')) {
			return;
		}
		Object.defineProperty(item, 'remove', {
			configurable: true,
			enumerable: true,
			writable: true,
			value: function remove() {
				if (this.parentNode !== null) this.parentNode.removeChild(this);
			}
		});
	});
})([Element.prototype, CharacterData.prototype, DocumentType.prototype]);

class Root extends Component {
	componentDidMount() {
		const splashScreen = document.getElementById('splash-screen');
		splashScreen.remove();
	}
	componentDidCatch(error, errorInfo) {
		this.setState({ error });
		Sentry.withScope((scope) => {
			Object.keys(errorInfo).forEach((key) => {
				scope.setExtra(key, errorInfo[key]);
			});
			Sentry.captureException(error);
		});
	}
	render() {
		return (
			<Provider store={store}>
				<PersistGate loading={<div style={{ backgroundColor: 'black' }} />} persistor={persistor}>
					<Router>
						<Routes />
					</Router>
				</PersistGate>
			</Provider>
		);
	}
}

export default Root;
