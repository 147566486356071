import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import sessionReducer from './session';
import usersReducer from './users';
import clientsReducer from './clients';
import photographersReducer from './photographers';
import productsReducer from './products';
import eventsReducer from './events';
import groupsReducer from './groups';
import timeslotsReducer from './timeslots';
import purchasesReducer from './purchases';
import teambuilderReducer from './teambuilder';
import imagesReducer from './images';

const USER_LOGOUT = 'user logout';
export const userLogout = () => ({ type: USER_LOGOUT });

const usersPersistConfig = {
	key: 'usersState',
	storage: storage,
	whitelist: ['user', 'userMeta']
};

const teambuilderPersistConfig = {
	key: 'teambuilderState',
	storage: storage,
	whitelist: [
		'event',
		'group',
		'pageNumber',
		'stepIntro',
		'stepAuth',
		'stepContact',
		'stepTeamInfo',
		'players',
		'staff',
		'toDeleteSubjects'
	]
};

const appReducer = combineReducers({
	sessionState: sessionReducer,
	usersState: persistReducer(usersPersistConfig, usersReducer),
	clientsState: clientsReducer,
	photographersState: photographersReducer,
	productsState: productsReducer,
	eventsState: eventsReducer,
	groupsState: groupsReducer,
	timeslotsState: timeslotsReducer,
	purchasesState: purchasesReducer,
	teambuilderState: persistReducer(teambuilderPersistConfig, teambuilderReducer),
	imagesState: imagesReducer
});

const rootReducer = (state, action) => {
	if (action.type === USER_LOGOUT) {
		state = undefined;
	}

	return appReducer(state, action);
};

export default rootReducer;
