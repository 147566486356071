var websiteRoutes = [
  {
    path: '/team-builder/:eventId',
    name: 'Team Builder',
    icon: 'design_app',
    component: 'TeamBuilder'
  }
];

export default websiteRoutes;
