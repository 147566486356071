import React, { Component } from 'react';
import withAuthorization from '../../hoc/withAuthorization';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { setPage } from '../../reducers/session';
import * as routes from '../../constants/routeNames';
import { Tabs, Card, BackTop } from 'antd';
import GeneralForm from './GeneralForm';
import PhotographerForm from './PhotographerForm';
const TabPane = Tabs.TabPane;

interface OwnProps extends RouteComponentProps {}

interface StateProps {
	authUser: any;
}

interface DispatchProps {
	onSetPage: Function;
}

type Props = StateProps & DispatchProps & OwnProps;
type State = {};

const mapStateToProps = (state: any) => ({
	authUser: state.sessionState.authUser
});

const mapDispatchToProps = (dispatch: any) => ({
	onSetPage: (page: any) => {
		dispatch(setPage(page));
	}
});

class SettingsPage extends Component<Props, State> {
	componentDidMount() {
		this.props.onSetPage(routes.SETTINGS);
		window.scrollTo(0, 0);
	}

	render() {
		const { authUser } = this.props;

		return (
			<div className='uk-width-expand' style={{ minHeight: '1px', overflowY: 'auto' }}>
				<BackTop />
				<Card>
					<Tabs defaultActiveKey='1' tabPosition={'top'}>
						<TabPane tab='General' key='1'>
							<GeneralForm />
						</TabPane>
						<TabPane tab='Photographer' key='2'>
							<PhotographerForm />
						</TabPane>
					</Tabs>
				</Card>
			</div>
		);
	}
}

const condition = (authUser: any) => !!authUser;

export default compose<any, any>(
	withRouter,
	withAuthorization(condition),
	connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps)
)(SettingsPage);
