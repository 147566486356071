import React, { useState } from "react";
import { Button, message } from "antd";
import { useSelector } from "react-redux";
import { Event } from "../../models/EventsModel";
import { Purchase, PurchasePackage } from "../../models/PurchaseModel";
import { purchases as fPurchases } from "../../firebase";
import { User } from "../../models/UsersModel";
import { camelize, capitalizeFirstLetter } from "../../helpers/string";
import { format } from "date-fns";
import converter from "json-2-csv";
import fileDownload from "js-file-download";
import { functions } from "../../firebase/firebaseConfig";

const onCompileLeagueBuyPurchases = functions.httpsCallable(
  "onCompileLeagueBuyPurchases"
);
const ExportPurchasesButton = () => {
  const [loading, setLoading] = useState(false);
  const selectedEvent: Event = useSelector(
    (state: any) => state.eventsState.selectedEvent
  );
  const user: User = useSelector((state: any) => state.usersState.user);

  const exportPurchases = () => {
    setLoading(true);
    message.loading("Downloading purchases", 5);
    let purchases: Array<Purchase> = [];
    fPurchases
      .getAllPurchases(
        user.userId!,
        selectedEvent.key!,
        new Date().getTime(),
        new Date(0).getTime(),
        "all",
        "",
        ""
      )
      .then((selectedPurchases) => {
        purchases = [...purchases, ...selectedPurchases];
        return onCompileLeagueBuyPurchases({ eventId: selectedEvent.key });
      })
      .then((result) => {
        purchases = [...purchases, ...result.data.purchases];
        if (purchases.length === 0) {
          throw Error("no purchases");
        }
        let exportArray: any = [];
        purchases.forEach((selectedPurchase, index) => {
          const { customerInfo, packages } = selectedPurchase;
          const fullSubject = selectedPurchase.fullSubject;

          let amountPackages: Array<PurchasePackage> = [];
          packages.forEach((pack) => {
            const flattenedPacks = Array(pack.count).fill(pack);
            amountPackages = [...flattenedPacks, ...amountPackages];
          });

          const newPackages = amountPackages.map((pack) => {
            // const newPack = { ...pack, ...subject, ...customerInfo };
            const extras: any = {};
            pack.products.forEach((product) => {
              Object.keys(product.extras).forEach((key) => {
                extras[`order${capitalizeFirstLetter(camelize(key))}`] =
                  product.extras[key];
              });
            });

            const newPack = {
              orderSku: pack.sku,
              orderLeagueBuy: selectedPurchase.leagueBuy ? "Yes" : "No",
              orderOfferingId: selectedPurchase.offeringId,
              orderPaid: selectedPurchase.paid,
              orderPaymentType: selectedPurchase.paymentType,
              orderPhotographerId: selectedPurchase.photographerId,
              orderShipping: selectedPurchase.shipping,
              orderDate: format(
                new Date(selectedPurchase.timestamp),
                "MM, d, yyyy"
              ),
              orderTransactionId: selectedPurchase.transactionId,
              orderCost: selectedPurchase.totalCost / 100,
              ...extras,
              customerFirstName: customerInfo.firstName,
              customerLastName: customerInfo.lastName,
              customerEmail: customerInfo.email,
              customerPhoneNumber: customerInfo.phoneNumber,
              customerAddressLine1: customerInfo.shippingAddress.addressLine1,
              customerAddressLine2: customerInfo.shippingAddress.addressLine2,
              customerCity: customerInfo.shippingAddress.city,
              customerCountry: customerInfo.shippingAddress.country,
              customerPostalCode: customerInfo.shippingAddress.postalCode,
              customerProvince: customerInfo.shippingAddress.province,
            };

            if (selectedPurchase.fullSubject) {
              Object.keys(selectedPurchase.fullSubject!).forEach((key) => {
                newPack[`subject${capitalizeFirstLetter(key)}`] = (
                  selectedPurchase as any
                ).fullSubject[key];
              });
            }
            newPack.subjectMissing = fullSubject?.position
              ? checkIfMissing(fullSubject.position!)
              : "no";
            newPack.subjectRowNumber = fullSubject?.position
              ? getSubjectRowNumber(fullSubject!.position!)
              : 0;

            if (selectedPurchase.fullGroup) {
              Object.keys(selectedPurchase.fullGroup!).forEach((key) => {
                newPack[`group${capitalizeFirstLetter(key)}`] = (
                  selectedPurchase as any
                ).fullGroup[key];
              });
            }

            newPack[`groupFirstRow`] = selectedPurchase.subjectRows?.row1;
            newPack[`groupSecondRow`] = selectedPurchase.subjectRows?.row2;
            newPack[`groupThirdRow`] = selectedPurchase.subjectRows?.row3;
            newPack[`groupFourthRow`] = selectedPurchase.subjectRows?.row4;
            newPack[`groupFifthRow`] = selectedPurchase.subjectRows?.row5;
            newPack[`groupMissingDeletedRow`] =
              selectedPurchase.subjectRows?.row0;

            return newPack;
          });
          exportArray = [...newPackages, ...exportArray];
        });
        converter.json2csv(
          [...exportArray],
          (err: any, csv: any) => {
            fileDownload(csv, `purchases.csv`);
            message.destroy();
          },
          { expandArrayObjects: true, emptyFieldValue: "" }
        );
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        message.destroy();
        if (error.message === "no purchases") {
          message.error("No purchases to export");
        } else {
          message.error(error.message);
        }
      });
  };
  const getSubjectRowNumber = (position: string) => {
    switch (position.charAt(0)) {
      case "A":
        return 1;
      case "B":
        return 2;
      case "C":
        return 3;
      case "D":
        return 4;
      case "E":
        return 5;
      case "M":
        return 0;
      case "X":
        return 0;
    }
  };
  const checkIfMissing = (position: string) => {
    switch (position.charAt(0)) {
      case "M":
        return "yes";
      case "X":
        return "yes";
      default:
        return "no";
    }
  };

  return (
    <div>
      <Button
        loading={loading}
        type="primary"
        onClick={exportPurchases}
        disabled={!selectedEvent.key}
      >
        Export Purchases
      </Button>
    </div>
  );
};

export default ExportPurchasesButton;
