import React from 'react';
import { Icon } from 'antd';

const IconHolder = ({ collapsed, toggle }) => (
  <Icon
    style={{ width: '40px' }}
    className='trigger'
    type={collapsed ? 'menu-unfold' : 'menu-fold'}
    onClick={() => {
      toggle();
    }}
  />
);

export default IconHolder;
