import React, { Component } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { Tabs, Card, Spin, Form, Alert, Button, Row, Col, Input, Select, Icon } from 'antd';
import { User, UserMeta } from '../../models/UsersModel';
import { TaskState, Contact } from '../../models/Commons';
import { ContactTypes, ContactStatusTypes, Client } from '../../models/ClientsModel';
import * as rClients from '../../reducers/clients';
import { clients as fClients } from '../../firebase';
import { getContactRoleOptions, getContactStatusOptions } from '../../constants/selectOptions';
import PhoneInput from 'react-phone-number-input/basic-input';
import * as stringHelpers from '../../helpers/string';
import { isValidPhoneNumber } from 'react-phone-number-input';

interface OwnProps { }

interface StateProps {
	user: User;
	userMeta: UserMeta;
	updateClient: TaskState;
	loadContacts: TaskState;
	contacts: Array<Contact>;
	selectedClient: Client;
}

interface DispatchProps {
	onUpdateContacts: Function;
	onClearUpdateClient: Function;
}

type Props = StateProps & DispatchProps & OwnProps;
type State = {
	clientId: string;
	contacts: Array<Contact>;
	error: string;
};
const INITIAL_STATE_EMPTY_CONTACT = {
	firstName: '',
	lastName: '',
	email: '',
	phoneNumber: '',
	role: '' as ContactTypes,
	status: '' as ContactStatusTypes,
	registered: false
};
const INITIAL_STATE = {
	clientId: '',
	contacts: [
		{
			...INITIAL_STATE_EMPTY_CONTACT
		}
	],
	error: ''
};
const mapStateToProps = (state: any) => ({
	user: state.usersState.user,
	userMeta: state.usersState.userMeta,
	updateClient: state.clientsState.updateClient,
	loadContacts: state.clientsState.loadContacts,
	contacts: state.clientsState.contacts,
	selectedClient: state.clientsState.selectedClient
});
const mapDispatchToProps = (dispatch: any) => ({
	onUpdateContacts: (uid: string, clientId: string, contacts: Array<Contact>) => {
		dispatch(fClients.updateContacts(uid, clientId, contacts));
	},
	onClearUpdateClient: () => {
		dispatch(rClients.modifyUpdateClient('LOADING', false));
	}
});
class ClientContactsList extends Component<Props, State> {
	state = { ...INITIAL_STATE };
	componentDidMount() {
		if (this.props.contacts.length !== 0) {
			this.setState({
				contacts: this.props.contacts
			});
		}
		this.setState({
			clientId: this.props.selectedClient.key!
		});
	}
	componentWillReceiveProps(nextProps: Props) {
		if (
			nextProps.loadContacts.success &&
			!this.props.loadContacts.success &&
			!nextProps.updateClient.loading &&
			!nextProps.loadContacts.loading
		) {
			this.setState({
				contacts: nextProps.contacts,
				clientId: nextProps.selectedClient.key!
			});
		}
		if (nextProps.selectedClient.key && !this.props.selectedClient.key) {
			this.setState({
				clientId: nextProps.selectedClient.key!
			});
		}
	}
	addContact = () => {
		const { contacts } = this.state;
		const newContacts: Array<Contact> = [...contacts, { ...INITIAL_STATE_EMPTY_CONTACT }];
		this.setState({ contacts: newContacts });
	};
	removeContact = (index: number) => {
		const { contacts } = this.state;
		const newContacts = contacts.filter((contact, newIndex) => newIndex !== index);
		this.setState({ contacts: newContacts });
	};
	updateContact = (newContact: Contact, index: number) => {
		const newContacts = this.state.contacts.map((oldContact, oldIndex) => {
			if (index !== oldIndex) {
				// This isn't the item we care about - keep it as-is
				return oldContact;
			}
			// Otherwise, this is the one we want - return an updated value
			return {
				...oldContact,
				...newContact
			};
		});
		this.setState({ contacts: newContacts });
	};
	updateContacts = () => {
		const { contacts, clientId } = this.state;
		const { user } = this.props;
		this.setState({ error: '' });
		this.props.onClearUpdateClient();

		let error = '';
		contacts.some((contact, index): boolean => {
			if (!stringHelpers.validateEmail(contact.email)) {
				error = `Contact ${index + 1}: Please enter a valid email for the contact.`;
				return !!error;
			}
			if (!isValidPhoneNumber('+1' + contact.phoneNumber)) {
				error = `Contact ${index + 1}: Please enter a valid phone number for the contact.`;
				return !!error;
			}
			if (contact.firstName === '') {
				error = `Contact ${index + 1}: Please enter contact's first name.`;
				return !!error;
			}
			if (contact.role === '') {
				error = `Contact ${index + 1}: Please select the contact's role.`;
				return !!error;
			}
			if (contact.status === '') {
				error = `Contact ${index + 1}: Please select the contact's status.`;
				return !!error;
			}
			return false;
		});
		if (error !== '') {
			this.setState({
				error: error
			});
			return;
		}

		this.props.onUpdateContacts(user.userId, clientId, contacts);
	};
	render() {
		const { user, userMeta, updateClient, loadContacts } = this.props;
		const { contacts, error } = this.state;
		return (
			<div>
				<Spin
					indicator={<Icon type='loading' style={{ fontSize: 24 }} spin />}
					spinning={(updateClient.loading as boolean) || (loadContacts.loading as boolean)}
				>
					<Form style={{ overflowX: 'auto' }}>
						<ul className='list'>
							{contacts.map((contact, index) => {
								return (
									<Row key={`contact ${index}`}>
										<Col sm={24} md={1}>
											<div className='uk-padding-small'>
												<p>{index + 1}</p>
											</div>
										</Col>
										<Col sm={24} md={3}>
											<div className='uk-padding-small'>
												<Input
													value={contact.firstName}
													onChange={(e) => {
														const newContact = {
															...contact,
															firstName: stringHelpers.titleCase(e.target.value)
														};
														this.updateContact(newContact, index);
													}}
												/>
											</div>
										</Col>
										<Col sm={24} md={3}>
											<div className='uk-padding-small'>
												<Input
													placeholder='Last Name'
													value={contact.lastName}
													onChange={(e) => {
														const newContact = {
															...contact,
															lastName: stringHelpers.titleCase(e.target.value)
														};
														this.updateContact(newContact, index);
													}}
												/>
											</div>
										</Col>
										<Col sm={24} md={4}>
											<div className='uk-padding-small'>
												<Input
													placeholder='Email'
													value={contact.email}
													onChange={(e) => {
														const newContact = {
															...contact,
															email: e.target.value
														};
														this.updateContact(newContact, index);
													}}
												/>
											</div>
										</Col>

										<Col sm={24} md={4}>
											<div className='uk-padding-small'>
												<PhoneInput
													className='ant-input'
													country='CA'
													placeholder='Phone Number'
													value={contact.phoneNumber}
													onChange={(e: any) => {
														const newContact = {
															...contact,
															phoneNumber: e
														};
														this.updateContact(newContact, index);
													}}
												/>
											</div>
										</Col>
										<Col sm={24} md={4}>
											<div className='uk-padding-small'>
												<Select
													value={contact.role ? { key: contact.role } : undefined}
													placeholder={'Select Contact Role..'}
													labelInValue
													onChange={(e: { key: string }) => {
														const newContact = {
															...contact,
															role: e.key
														} as Contact;;
														this.updateContact(newContact, index);
													}}
												>
													{getContactRoleOptions()}
												</Select>
											</div>
										</Col>
										<Col sm={24} md={4}>
											<div className='uk-padding-small'>
												<Select
													value={contact.status ? { key: contact.status } : undefined}
													placeholder={'Select Contact Status...'}
													labelInValue
													onChange={(e: { key: string }) => {
														const newContact = {
															...contact,
															status: e.key
														} as Contact;
														this.updateContact(newContact, index);
													}}
												>
													{getContactStatusOptions()}
												</Select>
											</div>
										</Col>
										<Col sm={24} md={1}>
											<div className='uk-padding-small'>
												{contacts.length > 1 ? (
													<Icon
														className='dynamic-delete-button'
														type='minus-circle-o'
														//disabled={(contacts.length === 1) as boolean}
														onClick={() => this.removeContact(index)}
													/>
												) : null}
											</div>
										</Col>
									</Row>
								);
							})}
						</ul>
						<div className='uk-flex uk-flex-center uk-margin-bottom'>
							<Button type='dashed' onClick={this.addContact} style={{ width: '60%' }}>
								<Icon type='plus' /> Add contact
							</Button>
						</div>
						<div className='uk-margin-bottom uk-flex uk-flex-center'>
							{(updateClient.error || error) && (
								<Alert className='uk-text-center' message={updateClient.error || error} type='error' />
							)}
							{updateClient.success && (
								<Alert className='uk-text-center' message={updateClient.success} type='success' />
							)}
						</div>
						<div className='uk-flex uk-flex-center uk-margin-bottom'>
							<Button
								onClick={this.updateContacts}
								disabled={updateClient.loading as boolean}
								type='primary'
							>
								Update
							</Button>
						</div>
					</Form>
				</Spin>
			</div>
		);
	}
}

export default compose<any, any>(connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps))(
	ClientContactsList
);
