import React, { Component } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { UserMeta } from '../../models/UsersModel';
import { TaskState } from '../../models/Commons';
import { Empty, Button, Table, Icon } from 'antd';
import { Product } from '../../models/ProductsModel';
import ProductModal from './ProductModal';
import * as rProducts from '../../reducers/products';
import { getArrayItemByKey } from '../../helpers/arrays';
import { firebase } from '../../firebase'

interface OwnProps extends RouteComponentProps { }

interface StateProps {
	user: firebase.firebase.User;
	userMeta: UserMeta;
	loadProducts: TaskState;
	newItem: TaskState;
	updateItem: TaskState;
	products: Array<Product>;
}

interface DispatchProps {
	onSetSelectedProduct: Function;
	onClearItemState: Function;
}

type Props = StateProps & DispatchProps & OwnProps;
type State = { visible: boolean };

const mapStateToProps = (state: any) => ({
	user: state.usersState.user,
	userMeta: state.usersState.userMeta,
	loadProducts: state.productsState.loadProducts,
	newItem: state.productsState.newItem,
	updateItem: state.productsState.updateItem,
	products: state.productsState.products
});

const mapDispatchToProps = (dispatch: any) => ({
	onSetSelectedProduct: (product: Product) => {
		dispatch(rProducts.setSelectedProduct(product));
	},
	onClearItemState: () => {
		dispatch(rProducts.modifyNewItem('LOADING', false));
		dispatch(rProducts.modifyUpdateItem('LOADING', false));
	}
});

const INITIAL_STATE = {
	visible: false
};

class ProductsList extends Component<Props, State> {
	state = { ...INITIAL_STATE };

	openModal = () => {
		this.props.onClearItemState();
		this.setState({ visible: true });
	};
	componentDidMount() { }
	componentWillReceiveProps(nextProps: Props) {
		if (nextProps.newItem.success && !this.props.newItem.success) {
			this.setState({ visible: false });
		}
		if (nextProps.updateItem.success && !this.props.updateItem.success) {
			this.setState({ visible: false });
		}
	}
	render() {
		const { products, loadProducts, user, userMeta } = this.props;
		const columns = [
			{
				title: 'Image',
				dataIndex: 'imageUrl',
				className: 'column-image',
				render: (logoUrl: string) => <img className='col-image' src={logoUrl} />
			},
			{
				title: 'Name',
				dataIndex: 'name'
			},
			{
				title: 'SKU',
				dataIndex: 'sku'
			},
			{
				title: 'Print Queue',
				dataIndex: 'printQueue'
			},
			{
				title: 'Type',
				dataIndex: 'type'
			}
			// {
			// 	title: 'Action',
			// 	dataIndex: 'key',
			// 	render: (key: string) => (
			// 		<a
			// 			onClick={() => {
			// 				this.props.onSetSelectedProduct(getArrayItemByKey(key, products));
			// 				this.openModal();
			// 			}}
			// 		>
			// 			Edit
			// 		</a>
			// 	)
			// }
		];
		const noData = (
			<Empty
				image='https://gw.alipayobjects.com/mdn/miniapp_social/afts/img/A*pevERLJC9v0AAAAAAAAAAABjAQAAAQ/original'
				description={<span>You haven't set up any products yet</span>}
			>
				<Button
					onClick={() => {
						this.openModal();
					}}
					type='primary'
				>
					Set up your first Product
				</Button>
			</Empty>
		);
		const header = (
			<div className='uk-flex uk-flex-right uk-width-expand'>
				<Button
					onClick={() => {
						this.openModal();
					}}
					type='primary'
				>
					New
				</Button>
			</div>
		);
		return (
			<div className='uk-width-expand'>
				<Table
					locale={{ emptyText: loadProducts.loading && noData }}
					loading={{
						indicator: <Icon type='loading' style={{ fontSize: 36 }} spin />,
						spinning: loadProducts.loading as boolean
					}}
					onRow={(product: Product, index: number) => {
						return {
							onClick: (event: any) => {
								this.props.onSetSelectedProduct(getArrayItemByKey(product.key!, products));
								this.openModal();
							}
						};
					}}
					className='table-row-clickable'
					columns={columns}
					dataSource={products}
					showHeader
					bordered
					title={() => header}
					pagination={{ pageSize: 10 }}
					scroll={{ x: '500px' }}
				/>
				<ProductModal
					visible={this.state.visible}
					onCancel={() => {
						this.setState({ visible: false });
					}}
				/>
			</div>
		);
	}
}

const condition = (authUser: any) => !!authUser;

export default compose<any, any>(
	withRouter,
	connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps)
)(ProductsList);
