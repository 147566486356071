import React, { Component } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { User, UserMeta } from '../../models/UsersModel';
import { TaskState, TeamPicsFile } from '../../models/Commons';
import {
	Modal,
	Steps,
	Button,
	Divider,
	Form,
	Input,
	Select,
	Radio,
	Upload,
	Icon,
	Table,
	Row,
	Col,
	Spin,
	Alert,
	message,
	InputNumber,
	Transfer,
	List
} from 'antd';
import { Product, Package, Offering, PackageTypes, ProductInPackage } from '../../models/ProductsModel';
import { products as fProducts } from '../../firebase';
import * as rProducts from '../../reducers/products';
import { RcFile } from 'antd/lib/upload/interface';
import {
	getFileType,
	checkFileSize,
	getBase64,
	getMimeType,
	dummyRequest,
	checkIfValidImage
} from '../../constants/fileTypes';
import { getPackageCategoryOptions } from '../../constants/selectOptions';
import { getArrayItemByKey, dedupe } from '../../helpers/arrays';
const Step = Steps.Step;
const { TextArea } = Input;

interface OwnProps extends RouteComponentProps {
	visible: boolean;
	onCancel: Function;
}
interface StateProps {
	user: User;
	userMeta: UserMeta;
	newItem: TaskState;
	updateItem: TaskState;
	selectedPackage: Package;
	products: Array<Product>;
}
interface DispatchProps {
	onNewItem: Function;
	onUpdateItem: Function;
	onClearSelectedPackage: Function;
}
type Props = StateProps & DispatchProps & OwnProps;
type State = {
	error: string;
	pageNumber: number;
	sku: string;
	name: string;
	price: number;
	type: PackageTypes;
	description: string;
	selectedProducts: Array<ProductInPackage>;
	selectedKeys: Array<string>;
	imageUrl: string;
	imageFile: TeamPicsFile;
};
const INITIAL_STATE = {
	error: '',
	pageNumber: 0,
	sku: '',
	name: '',
	price: 0,
	type: '' as PackageTypes,
	description: '',
	selectedProducts: [] as Array<ProductInPackage>,
	selectedKeys: [] as Array<string>,
	imageUrl: '',
	imageFile: {} as TeamPicsFile
};
const formItemLayout = {
	labelCol: {
		xs: { span: 24 },
		sm: { span: 8 },
		md: { span: 4 }
	},
	wrapperCol: {
		xs: { span: 24 },
		sm: { span: 16 }
	}
};
const tailFormItemLayout = {
	wrapperCol: {
		xs: {
			span: 24,
			offset: 0
		},
		sm: {
			span: 16,
			offset: 8
		},
		md: {
			span: 16,
			offset: 4
		}
	}
};
const steps = [
	{
		title: 'General'
	},
	{
		title: 'Image'
	},
	{
		title: 'Products'
	},
	{
		title: 'Amounts'
	}
];
const mapStateToProps = (state: any) => ({
	user: state.usersState.user,
	userMeta: state.usersState.userMeta,
	newItem: state.productsState.newItem,
	updateItem: state.productsState.updateItem,
	selectedPackage: state.productsState.selected.selectedPackage,
	products: state.productsState.products
});

const mapDispatchToProps = (dispatch: any) => ({
	onNewItem: (uid: string, collectionKey: string, imageFile: TeamPicsFile, item: Package) => {
		dispatch(fProducts.newItem(uid, collectionKey, imageFile, item));
	},
	onUpdateItem: (uid: string, collectionKey: string, imageFile: TeamPicsFile, item: Package) => {
		dispatch(fProducts.updateItem(uid, collectionKey, imageFile, item));
	},
	onClearSelectedPackage: () => {
		dispatch(rProducts.setSelectedPackage({ key: '' } as Package));
	}
});

class PackageModal extends Component<Props, State> {
	state = { ...INITIAL_STATE };
	componentDidMount() { }
	componentWillReceiveProps(nextProps: Props) {
		if (!!nextProps.selectedPackage.key && !nextProps.newItem.loading && !nextProps.updateItem.loading) {
			const pack = {
				...nextProps.selectedPackage,
				price: nextProps.selectedPackage.price / 100,
				selectedProducts: nextProps.selectedPackage.products,
				selectedKeys: nextProps.selectedPackage.products.slice().map((product) => {
					return product.key;
				})
			} as any;
			delete pack.products;
			this.setState({ ...pack });
		}
		if (
			(nextProps.newItem.error && !this.props.newItem.error) ||
			(nextProps.updateItem.error && !this.props.updateItem.error)
		) {
			if (nextProps.newItem.error) {
				message.error(nextProps.newItem.error);
			} else if (nextProps.updateItem.error) {
				message.error(nextProps.updateItem.error);
			}
		}
	}
	handleUploadChange = (file: any) => { };
	beforeUpload = (file: RcFile, FileList: RcFile[]): boolean | PromiseLike<any> => {
		const fileType = getFileType(file.name);
		if (!checkFileSize(file.size, 1)) {
			this.setState({ error: 'Max file size is 1MB' });
			message.error('Max file size is 1MB');
			return false;
		}
		var fileReader = new FileReader();
		fileReader.onloadend = (e: any) => {
			var arr = new Uint8Array(e.target.result).subarray(0, 4);
			var header = '';
			for (var i = 0; i < arr.length; i++) {
				header += arr[i].toString(16);
			}
			// Check the file signature against known types
			if (!checkIfValidImage(header, getMimeType(fileType))) {
				message.error('Please upload a png or a jpg/jpeg');
			} else {
				getBase64(file, (imageUrl: string) =>
					this.setState({
						imageUrl: imageUrl,
						imageFile: {
							fileType: fileType,
							mimeType: getMimeType(fileType),
							blob: imageUrl,
							fileSize: file.size
						}
					})
				);
			}
		};
		fileReader.readAsArrayBuffer(file.slice(0, 4));
		return true;
	};
	nextPage = () => {
		if (this.state.pageNumber < 3) {
			this.setState({ pageNumber: this.state.pageNumber + 1 });
		}
	};
	previousPage = () => {
		if (this.state.pageNumber > 0) {
			this.setState({ pageNumber: this.state.pageNumber - 1 });
		}
	};
	validateDetails = () => {
		const { price, sku, name, type, imageFile, description } = this.state;
		this.setState({
			error: ''
		});

		if (!name) {
			this.setState({
				error: 'Please enter a name for the Package'
			});
			message.error('Please enter a name for the Package');
			return;
		}
		if (!sku) {
			this.setState({
				error: 'Please enter a valid SKU for the package.'
			});
			message.error('Please enter a valid SKU for the package.');
			return;
		}
		if (price < 0) {
			this.setState({
				error: 'Please enter a valid dollar amount in the format 99.99'
			});
			message.error('Please enter a valid dollar amount in the format 99.99');
			return;
		}
		if (!type) {
			this.setState({
				error: 'Please select the type of package.'
			});
			message.error('Please select the type of package.');
			return;
		}
		if (!description || description.length < 10) {
			this.setState({
				error: 'Please enter a description for the package, minimum 10 characters'
			});
			message.error('Please enter a description for the package, minimum 10 characters');
			return;
		}
		if (!imageFile) {
			this.setState({
				error: 'Please upload an image for the package.'
			});
			message.error('Please upload an image for the package.');
			return;
		}

		this.nextPage();
	};
	validateSelectedProducts = () => {
		const { selectedProducts } = this.state;
		this.setState({
			error: ''
		});

		if (selectedProducts.length <= 0) {
			this.setState({
				error: 'Please select at least one product for the package.'
			});
			message.error('Please select at least one product for the package.');
			return;
		}

		this.newPackage();
	};
	renderItem = (item: Product) => {
		const customLabel = <span className='custom-item'>{item.name}</span>;

		return {
			label: customLabel, // for displayed item
			value: item.name // for title and filter matching
		};
	};
	handleChange = (targetKeys: Array<string>, direction: any, moveKeys: Array<ProductInPackage>) => {
		this.setState({ selectedKeys: targetKeys });
	};
	setUpSelectedProducts = () => {
		const newSelectedProducts = this.state.selectedKeys.slice().map((key, index) => {
			const product = getArrayItemByKey(key, this.props.products);
			let packageProduct: ProductInPackage = {
				key,
				amount: 1,
				type: product.type,
				name: product.name
			};
			this.state.selectedProducts.forEach((selectedProduct) => {
				if (key === selectedProduct.key) {
					packageProduct = {
						key: selectedProduct.key,
						amount: selectedProduct.amount,
						type: selectedProduct.type,
						name: product.name
					};
				}
			});
			return packageProduct;
		});
		this.setState({ selectedProducts: newSelectedProducts });
		this.nextPage();
	};
	updatedAmount = (index: number) => (e: any) => {
		let newSelectedProducts = this.state.selectedProducts.slice().map((newProduct, newIndex) => {
			if (newIndex !== index) {
				// This isn't the item we care about - keep it as-is
				return newProduct;
			}
			return {
				...newProduct,
				amount: e
			};
		});
		this.setState({ selectedProducts: newSelectedProducts });
	};
	newPackage = () => {
		const { sku, name, price, type, description, pageNumber, selectedProducts, imageUrl, imageFile } = this.state;
		const pack: Package = {
			userId: this.props.user.userId!,
			sku,
			name,
			type,
			price: price * 100,
			description,
			products: selectedProducts,
			imageUrl,
			timestamp: new Date().getTime()
		};

		if (this.props.selectedPackage.key) {
			pack.key = this.props.selectedPackage.key;
		}

		if (this.props.selectedPackage.key) {
			this.props.onUpdateItem(this.props.user.userId, 'packages', imageFile, pack);
		} else {
			this.props.onNewItem(this.props.user.userId, 'packages', imageFile, pack);
		}
	};
	render() {
		const { selectedPackage, updateItem, newItem, products, user, userMeta, visible, onCancel } = this.props;
		const {
			sku,
			name,
			price,
			type,
			description,
			pageNumber,
			selectedProducts,
			selectedKeys,
			imageUrl,
			error
		} = this.state;
		const generalIsInvalid = sku === '' || name === '' || type === '' || price < 0 || description === '';
		const imageIsInvalid = imageUrl === '';
		const selectedAreInvalid = selectedKeys.length === 0;
		const uploadButton = (
			<div>
				<Icon type={'plus'} />
				<div className='ant-upload-text'>Upload Package Image</div>
			</div>
		);
		return (
			<div className='uk-width-expand'>
				<Modal
					keyboard={false}
					centered
					bodyStyle={{ height: '90vh', borderRadius: 0 }}
					width={'90vw'}
					visible={visible}
					title={selectedPackage.key ? `${selectedPackage.name}` : 'Set up a new Package'}
					okText='Create'
					footer={null}
					afterClose={() => {
						this.setState({ ...INITIAL_STATE });
						this.props.onClearSelectedPackage();
					}}
					onCancel={() => {
						onCancel();
					}}
					onOk={() => { }}
				>
					<div>
						<Steps size='small' current={this.state.pageNumber}>
							{steps.map((item) => <Step key={item.title} title={item.title} />)}
						</Steps>
						<div className='steps-content-events uk-margin-bottom'>
							<Form>
								<Spin
									indicator={<Icon type='loading' style={{ fontSize: 24 }} spin />}
									spinning={(newItem.loading as boolean) || (updateItem.loading as boolean)}
								>
									{pageNumber === 0 && (
										<div>
											<Form.Item label='Package Name' {...formItemLayout}>
												<Input
													placeholder='Name'
													onChange={(e) => {
														if (e.target.value.length <= 30) {
															this.setState({ name: e.target.value });
														}
													}}
													value={name}
												/>
											</Form.Item>
											<Form.Item label='SKU' {...formItemLayout}>
												<Input
													placeholder='SKU'
													onChange={(e) => {
														this.setState({ sku: e.target.value });
													}}
													value={sku}
												/>
											</Form.Item>
											<Form.Item label='Price' {...formItemLayout}>
												<InputNumber
													style={{ width: '100%' }}
													min={0}
													max={5000}
													step={0.01}
													placeholder='Price'
													onChange={(e) => {
														this.setState({ price: e as number });
													}}
													value={price}
													formatter={(value) =>
														`$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
													parser={(value) => value!.replace(/\$\s?|(,*)/g, '') as any}
												/>
											</Form.Item>
											<Form.Item label='Package Type' {...formItemLayout}>
												<Select
													placeholder={'Select Type'}
													labelInValue
													value={type ? { key: type } : undefined}
													onChange={(e: any) => {
														this.setState({
															type: e.key
														});
													}}
												>
													{getPackageCategoryOptions()}
												</Select>
											</Form.Item>
											<Form.Item label='Package Description' {...formItemLayout}>
												<TextArea
													placeholder='Enter Description...'
													value={description}
													onChange={(e) => {
														if (e.target.value.length < 400) {
															this.setState({ description: e.target.value });
														}
													}}
													rows={4}
												/>
											</Form.Item>
										</div>
									)}
									{pageNumber === 1 && (
										<div
											style={{ minHeight: '500px' }}
											className='uk-flex uk-flex-center uk-flex-middle'
										>
											<Upload
												name='avatar'
												listType='picture-card'
												className='avatar-uploader'
												showUploadList={false}
												beforeUpload={this.beforeUpload}
												onChange={this.handleUploadChange}
												customRequest={dummyRequest}
												style={{ height: '300px', minWidth: '300px' }}
											>
												{imageUrl ? (
													<img style={{ maxWidth: '800px' }} src={imageUrl} alt='avatar' />
												) : (
													uploadButton
												)}
											</Upload>
										</div>
									)}
									{pageNumber === 2 && (
										<div className='uk-margin-bottom'>
											<Transfer
												titles={['All Products', 'Selected Products']}
												showSearch
												dataSource={products as any}
												listStyle={{
													minWidth: 450,
													height: 450
												}}
												targetKeys={selectedKeys as any}
												onChange={this.handleChange as any}
												render={this.renderItem as any}
											/>
										</div>
									)}
									{pageNumber === 3 && (
										<div className='uk-width-expand uk-padding uk-flex uk-flex-center'>
											<div style={{ minWidth: '50vw' }} className='uk-flex uk-flex-center'>
												<List
													bordered
													header={<div>Product Amounts</div>}
													itemLayout={'horizontal'}
													dataSource={this.state.selectedProducts}
													renderItem={(product: ProductInPackage, index: number) => (
														<List.Item>
															<div className='uk-flex uk-flex-row uk-width-expand'>
																<div className='uk-flex uk-flex-center uk-flex-middle uk-margin-right'>
																	<p className='uk-margin-remove'>{product.name}</p>
																</div>
																<div className='uk-flex uk-flex-right uk-width-expand'>
																	<InputNumber
																		step={1}
																		min={0}
																		max={100}
																		value={product.amount}
																		onChange={this.updatedAmount(index)}
																	/>
																</div>
															</div>
														</List.Item>
													)}
												/>
											</div>
										</div>
									)}
								</Spin>
							</Form>
						</div>
						<div className='uk-position-bottom uk-padding-small'>
							<Divider />
							<div className='uk-flex uk-flex-row'>
								<div className='uk-flex uk-flex-left'>
									<Button onClick={() => onCancel()}>Cancel</Button>
								</div>
								<div className='uk-flex uk-flex-right uk-width-expand'>
									{this.state.pageNumber > 0 && (
										<Button onClick={() => this.previousPage()}>Previous</Button>
									)}
									{this.state.pageNumber < steps.length - 1 && (
										<Button
											style={{ marginLeft: 8 }}
											type='primary'
											onClick={() => {
												if (pageNumber === 0) {
													this.validateDetails();
												} else if (pageNumber === 2) {
													this.setUpSelectedProducts();
												} else {
													this.nextPage();
												}
											}}
											disabled={
												(pageNumber === 0 && generalIsInvalid) ||
												(pageNumber === 1 && imageIsInvalid) ||
												(pageNumber === 2 && selectedAreInvalid)
											}
										>
											Next
										</Button>
									)}
									{this.state.pageNumber === steps.length - 1 && (
										<Button
											style={{ marginLeft: 8 }}
											type='primary'
											disabled={(newItem.loading as boolean) || (updateItem.loading as boolean)}
											onClick={() => {
												this.validateSelectedProducts();
											}}
										>
											{selectedPackage.key ? 'Update' : 'Save'}
										</Button>
									)}
								</div>
							</div>
						</div>
					</div>
				</Modal>
			</div>
		);
	}
}

export default compose<any, any>(
	withRouter,
	connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps)
)(PackageModal);
