import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import "firebase/functions";

var config = {
  apiKey: "AIzaSyB-SgCEOsPx-E7jPCoVsWRfQosuCh6DtCw",
  authDomain: "teampics-staging.firebaseapp.com",
  databaseURL: "https://teampics-staging-default-rtdb.firebaseio.com",
  projectId: "teampics-staging",
  storageBucket: "teampics-staging.appspot.com",
  messagingSenderId: "675947128353",
  appId: "1:675947128353:web:12ec5df7d95cad6b426560",
};
if (process.env.REACT_APP_ENV === "development") {
  config = {
    apiKey: "AIzaSyB-SgCEOsPx-E7jPCoVsWRfQosuCh6DtCw",
    authDomain: "teampics-staging.firebaseapp.com",
    databaseURL: "https://teampics-staging-default-rtdb.firebaseio.com",
    projectId: "teampics-staging",
    storageBucket: "teampics-staging.appspot.com",
    messagingSenderId: "675947128353",
    appId: "1:675947128353:web:12ec5df7d95cad6b426560",
  };
}

if (process.env.REACT_APP_ENV === "production") {
  config = {
    apiKey: "AIzaSyC8KC9I7P7lFA8iO26LOdeMsL4u_DJlZpk",
    authDomain: "teampics-22bc6.firebaseapp.com",
    databaseURL: "https://teampics-22bc6.firebaseio.com",
    projectId: "teampics-22bc6",
    storageBucket: "teampics-22bc6.appspot.com",
    messagingSenderId: "732437663825",
  };
}

// console.log(config);

// var config = {
//   apiKey: 'AIzaSyC8KC9I7P7lFA8iO26LOdeMsL4u_DJlZpk',
//   authDomain: 'teampics-22bc6.firebaseapp.com',
//   databaseURL: 'https://teampics-22bc6.firebaseio.com',
//   projectId: 'teampics-22bc6',
//   storageBucket: 'teampics-22bc6.appspot.com',
//   messagingSenderId: '732437663825'
// };

if (!firebase.apps.length) {
  firebase.initializeApp(config);
}

const auth = firebase.auth();
const functions = firebase.functions();
const firestore = firebase.firestore();
const storage = firebase.storage();
const googleProvider = new firebase.auth.GoogleAuthProvider();
const facebookProvider = new firebase.auth.FacebookAuthProvider();

export {
  firestore,
  firebase,
  functions,
  storage,
  auth,
  googleProvider,
  facebookProvider,
};
