import { ThunkAction } from 'redux-thunk';
import { ContactTypes, ContactStatusTypes } from './ClientsModel';

export interface TaskState {
	loading: boolean | string | null;
	empty: boolean | string | null;
	error: boolean | string | null;
	success: string | boolean | null;
}

export interface SelectOption {
	value: any;
	label: string;
}

export type ThunkResult<R> = ThunkAction<R, undefined, undefined, any>;

export interface TeamPicsFile {
	createdDate?: number;
	blob?: any;
	fileType?: string;
	mimeType?: string;
	fileSize?: number;
	fileName?: string;
}

export interface TeamPicsLocation {
	address?: string;
	city?: string;
	country?: string;
	lat?: string;
	lng?: string;
	name?: string;
	postal?: string;
	placeId?: string;
	province?: string;
}

export interface Season {
	fall: boolean;
	winter: boolean;
	summer: boolean;
	spring: boolean;
}

export const INITIAL_STATE_EMPTY_TASK = {
	loading: false,
	error: null,
	success: null,
	empty: false
};

export interface Color {
	r: number;
	g: number;
	b: number;
	a: number;
}

export interface Contact {
	key?: string;
	email: string;
	firstName: string;
	lastName: string;
	phoneNumber: string;
	role?: ContactTypes;
	status?: ContactStatusTypes;
	registered?: boolean;
}

export interface Doc<T> {
	data: () => T;
	id: string;
}
