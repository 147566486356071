import { combineReducers } from 'redux';
import {
	createReducerWithTaskName,
	dispatchTaskWithState,
	createReducerForFirebaseArray,
	dispatchFirebaseArrayWithState
} from '../helpers/reducers';
import { INITIAL_STATE_EMPTY_TASK } from '../models/Commons';
import { Purchase } from '../models/PurchaseModel';

/**
 * INITIAL STATES
 */
const INITIAL_STATE_SELECTED_PURCHASE = {
	key: ''
};

/**
 * CONSTANTS
 */
const LOAD_PURCHASES = 'load purchases';
const SET_SELECTED_PURCHASE = 'set selected purchase';
const GET_PURCHASE_CSV = 'get purchase csv';
const CLEAR_PURCHASES_REDUCER = 'clear purchases reducer';
/**
 * ACTIONS
 */
export const setSelectedPurchase = (purchase: Purchase) => ({
	type: SET_SELECTED_PURCHASE,
	purchase
});

export const modifyLoadPurchases = (taskState: string, value: string | boolean) => {
	return dispatchTaskWithState(LOAD_PURCHASES, taskState, value);
};

export const getPurchaseCsv = (taskState: string, value: string | boolean) => {
	return dispatchTaskWithState(GET_PURCHASE_CSV, taskState, value);
};

export const modifyPurchasesArray = (arrayState: string, purchase: Purchase) => {
	return dispatchFirebaseArrayWithState(LOAD_PURCHASES, arrayState, purchase);
};

/**
 * REDUCERS
 */
function selectedPurchaseReducer(state = INITIAL_STATE_SELECTED_PURCHASE, action: any) {
	switch (action.type) {
		case SET_SELECTED_PURCHASE: {
			return {
				...state,
				...action.purchase
			};
		}
		default:
			return state;
	}
}

const purchasesRootReducer = combineReducers({
	selectedPurchase: selectedPurchaseReducer,
	loadPurchases: createReducerWithTaskName(LOAD_PURCHASES, INITIAL_STATE_EMPTY_TASK),
	getPurchaseCsv: createReducerWithTaskName(GET_PURCHASE_CSV, INITIAL_STATE_EMPTY_TASK),
	purchases: createReducerForFirebaseArray(LOAD_PURCHASES, [])
});

const purchasesReducer = (state: any, action: any) => {
	if (action.type === CLEAR_PURCHASES_REDUCER) {
		state = {
			selectedPurchase: { ...INITIAL_STATE_SELECTED_PURCHASE },
			purchases: [],
			getPurchaseCsv: { ...INITIAL_STATE_EMPTY_TASK },
			loadPurchases: { ...INITIAL_STATE_EMPTY_TASK }
		};
	}

	return purchasesRootReducer(state, action);
};

export default purchasesReducer;
