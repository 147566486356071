import React, { Component } from 'react';
import PlacesAutocomplete from 'react-places-autocomplete';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { AutoComplete, Spin, Empty, Icon } from 'antd';

type Props = {
	setAddress: Function;
	setLocation: Function;
	address: string;
	placeholder: string;
	disabled: boolean;
	searchOptions?: Array<string>;
};
type State = { noResults: boolean };

class TeampicsPlaces extends Component<Props, State> {
	state = { noResults: false };
	handlePlacesChange = (address: string) => {
		this.setState({ noResults: false });
		if (address) {
			this.props.setAddress(address);
		} else {
			this.props.setLocation('');
			this.props.setAddress('');
		}
	};

	handlePlacesSelect = (address: string) => {
		this.props.setAddress(address);
		this.setState({ noResults: false });

		let location: any;
		geocodeByAddress(address)
			.then((results) => {
				location = results[0];
				return getLatLng(location);
			})
			.then((latLng) => {
				let city = '';
				let province = '';
				let postal = '';
				let country = '';
				location.address_components.forEach((address: any) => {
					if (address.types[0] === 'locality') {
						city = address.long_name;
					} else if (address.types[0] === 'administrative_area_level_1') {
						province = address.long_name;
					} else if (address.types[0] === 'country') {
						country = address.long_name;
					} else if (address.types[0] === 'postal_code') {
						postal = address.long_name;
					}
				});

				this.props.setLocation({
					address: location.formatted_address,
					name: address,
					city: city,
					province: province,
					country: country,
					postal: postal,
					placeId: location.place_id,
					lat: latLng.lat,
					lng: latLng.lng
				});
			})
			.catch((error) => console.error('Error', error));
	};

	render() {
		const { placeholder, disabled, searchOptions } = this.props;
		const { noResults } = this.state;

		return (
			<PlacesAutocomplete
				value={this.props.address}
				debounce={200}
				searchOptions={
					searchOptions ? (
						{ types: searchOptions }
					) : (
						{
							types: [ 'establishment' ]
						}
					)
				}
				highlightFirstSuggestion
				onChange={this.handlePlacesChange}
				onSelect={this.handlePlacesSelect}
				onError={(status, clearSuggestion) => {
					if (status === 'ZERO_RESULTS') {
						this.setState({ noResults: true });
						clearSuggestion();
					}
				}}
			>
				{({
					getInputProps,
					suggestions,
					getSuggestionItemProps,
					loading
				}: {
					getInputProps: any;
					suggestions: any;
					getSuggestionItemProps: any;
					loading: boolean;
				}) => (
					<div className='autocomplete uk-width-expand'>
						<input
							{...getInputProps({
								placeholder: placeholder,
								className: 'ant-input uk-width-expand',
								disabled: disabled ? true : false
							})}
						/>
						{((suggestions.length !== 0 && !loading) || noResults) && (
							<div className='ant-select-selection autocomplete-items'>
								{noResults && (
									<div className='uk-padding'>
										<Empty description={`Couldn't find anything.`} />
									</div>
								)}
								{loading && (
									<Spin indicator={<Icon type='loading' style={{ fontSize: 24 }} spin />} spinning />
								)}
								{suggestions.map((suggestion: any) => {
									const className = suggestion.active
										? 'autocomplete-item autocomplete-active'
										: 'autocomplete-item';
									return (
										<div
											{...getSuggestionItemProps(suggestion, {
												className
											})}
										>
											<div>{suggestion.description}</div>
										</div>
									);
								})}
							</div>
						)}
					</div>
				)}
			</PlacesAutocomplete>
		);
	}
}

export default TeampicsPlaces;
