import { combineReducers } from 'redux';
import {
  createReducerWithTaskName,
  dispatchTaskWithState,
  createReducerForFirebaseArray,
  dispatchFirebaseArrayWithState
} from '../helpers/reducers';
import { User, UserMeta } from '../models/UsersModel';

/**
 * INITIAL STATES
 */
const INITIAL_STATE_USER = {
  firstName: '',
  lastName: '',
  address: '',
  email: '',
  city: '',
  company: '',
  phoneNumber: '',
  postal: '',
  website: '',
  logoUrl: ''
};

const INITIAL_STATE_USER_META = {
  photographer: false,
  admin: false,
  key: ''
};

const INITIAL_STATE_EMPTY_TASK = {
  loading: false,
  error: null,
  success: null,
  empty: false
};

/**
 * CONSTANTS
 */
const LOAD_USER = 'load user';
const LOAD_USER_META = 'load user meta';
const SET_USER = 'set user';
const SET_USER_META = 'set user meta';

const UPDATE_USER = 'update user';
const SEND_REQUEST = 'send request';

const CLEAR_USERS_REDUCER = 'clear users reducer';

/**
 * ACTIONS
 */
export const setUser = (user: User) => ({
  type: SET_USER,
  user
});

export const setUserMeta = (userMeta: UserMeta) => ({
  type: SET_USER_META,
  userMeta
});

export const modifyLoadUser = (taskState: string, value: string | boolean) => {
  return dispatchTaskWithState(LOAD_USER, taskState, value);
};

export const modifyLoadUserMeta = (
  taskState: string,
  value: string | boolean
) => {
  return dispatchTaskWithState(LOAD_USER_META, taskState, value);
};

export const modifyUpdateUser = (
  taskState: string,
  value: string | boolean
) => {
  return dispatchTaskWithState(UPDATE_USER, taskState, value);
};

export const modifySendRequest = (
  taskState: string,
  value: string | boolean
) => {
  return dispatchTaskWithState(SEND_REQUEST, taskState, value);
};

export const clearUsersReducer = () => ({
  type: CLEAR_USERS_REDUCER
});

/**
 * REDUCERS
 */
function userReducer(state = INITIAL_STATE_USER, action: any) {
  switch (action.type) {
    case SET_USER: {
      return {
        ...state,
        ...action.user
      };
    }
    case SET_USER_META: {
      return {
        ...state,
        ...action.userMeta
      };
    }
    default:
      return state;
  }
}

function userMetaReducer(state = INITIAL_STATE_USER_META, action: any) {
  switch (action.type) {
    case SET_USER_META: {
      return {
        ...state,
        ...action.userMeta
      };
    }
    default:
      return state;
  }
}

const usersRootReducer = combineReducers({
  user: userReducer,
  userMeta: userMetaReducer,
  loadUser: createReducerWithTaskName(LOAD_USER, INITIAL_STATE_EMPTY_TASK),
  loadUserMeta: createReducerWithTaskName(
    LOAD_USER_META,
    INITIAL_STATE_EMPTY_TASK
  ),
  sendRequest: createReducerWithTaskName(
    SEND_REQUEST,
    INITIAL_STATE_EMPTY_TASK
  ),
  updateUser: createReducerWithTaskName(UPDATE_USER, INITIAL_STATE_EMPTY_TASK)
});

const usersReducer = (state: any, action: any) => {
  if (action.type === CLEAR_USERS_REDUCER) {
    state = {
      user: { ...INITIAL_STATE_USER },
      userMeta: { ...INITIAL_STATE_USER_META },
      loadUser: { ...INITIAL_STATE_EMPTY_TASK },
      loadUserMeta: { ...INITIAL_STATE_EMPTY_TASK },
      sendRequest: { ...INITIAL_STATE_EMPTY_TASK },
      updateUser: { ...INITIAL_STATE_EMPTY_TASK }
    };
  }

  return usersRootReducer(state, action);
};

export default usersReducer;
