import React, { Component } from 'react';
import withAuthorization from '../../hoc/withAuthorization';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { setPage } from '../../reducers/session';
import * as routes from '../../constants/routeNames';

import { products as fProducts } from '../../firebase';
import * as rProducts from '../../reducers/products';
import { User, UserMeta } from '../../models/UsersModel';
import { Tabs, Card, Row, Col, message, BackTop } from 'antd';
import ProductsList from './ProductsList';
import PackagesList from './PackagesList';
import OfferingsList from './OfferingsList';
import { TaskState } from '../../models/Commons';
const TabPane = Tabs.TabPane;

interface OwnProps extends RouteComponentProps {}

interface StateProps {
	user: User;
	userMeta: UserMeta;
	newItem: TaskState;
	updateItem: TaskState;
}

interface DispatchProps {
	onSetPage: Function;
	onLoadAll: Function;
}

type Props = StateProps & DispatchProps & OwnProps;
type State = {};

const mapStateToProps = (state: any) => ({
	user: state.usersState.user,
	userMeta: state.usersState.userMeta,
	newItem: state.productsState.newItem,
	updateItem: state.productsState.updateItem
});

const mapDispatchToProps = (dispatch: any) => ({
	onSetPage: (page: any) => {
		dispatch(setPage(page));
	},
	onLoadAll: (uid: string) => {
		dispatch(fProducts.subscribePacks(uid));
		dispatch(fProducts.subscribeProducts(uid));
		dispatch(fProducts.subscribeOfferings(uid));
	}
});

class ProductsPage extends Component<Props, State> {
	componentDidMount() {
		this.props.onSetPage(routes.PRODUCTS);
		this.props.onLoadAll(this.props.user.userId);
		window.scrollTo(0, 0);
	}
	componentWillReceiveProps(nextProps: Props) {
		if (nextProps.newItem.success && !this.props.newItem.success) {
			message.success(nextProps.newItem.success);
		}
		if (nextProps.updateItem.success && !this.props.updateItem.success) {
			message.success(nextProps.updateItem.success);
		}
	}
	render() {
		return (
			<div className='uk-width-expand' style={{ minHeight: '1px', overflowY: 'auto' }}>
				<BackTop />
				<Card
					style={{
						minHeight: 'calc(100vh - 157px)'
					}}
				>
					<Row className='uk-margin-bottom'>
						<Col className='' span={24}>
							<h2>Products</h2>
						</Col>
					</Row>
					<Tabs defaultActiveKey='1' tabPosition={'top'}>
						<TabPane tab='Products' key='1'>
							<ProductsList />
						</TabPane>
						<TabPane tab='Packages' key='2'>
							<PackagesList />
						</TabPane>
						<TabPane tab='Offerings' key='3'>
							<OfferingsList />
						</TabPane>
					</Tabs>
				</Card>
			</div>
		);
	}
}

const condition = (authUser: any) => !!authUser;

export default compose<any, any>(
	withRouter,
	withAuthorization(condition),
	connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps)
)(ProductsPage);
