import React, { Component } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { Tabs, Button, Form, Input, Select, Radio, Upload, Icon, Spin, Alert, Checkbox, message } from 'antd';
import { UserMeta, User } from '../../models/UsersModel';
import { TaskState, TeamPicsFile, TeamPicsLocation, Season } from '../../models/Commons';
import { Client, ClientTypes } from '../../models/ClientsModel';
import { clients as fClients } from '../../firebase';
import TeampicsPlaces from '../../components/TeampicsPlaces/TeampicsPlaces';
import { getClientTypeOptions } from '../../constants/selectOptions';
import ColorPicker from '../../components/ColorPicker/ColorPicker';
import { RcFile } from 'antd/lib/upload/interface';
import {
	getFileType,
	checkFileSize,
	getBase64,
	getMimeType,
	dummyRequest,
	checkIfValidImage
} from '../../constants/fileTypes';
import * as stringHelpers from '../../helpers/string';
import { isValidPhoneNumber } from 'react-phone-number-input';
import PhoneInput from 'react-phone-number-input/basic-input';
import * as rClients from '../../reducers/clients';
const { TextArea } = Input;

interface OwnProps {}

interface StateProps {
	user: User;
	userMeta: UserMeta;
	updateClient: TaskState;
	loadClientDetails: TaskState;
	selectedClient: Client;
}

interface DispatchProps {
	onUpdateClient: Function;
	onClearUpdateClient: Function;
}

type Props = StateProps & DispatchProps & OwnProps;
type State = {
	clientId: string;
	active: boolean;
	email: string;
	name: string;
	phoneNumber: string;
	priColor: string;
	secColor: string;
	type: ClientTypes;
	seasons: Season;
	website: string;
	address: string;
	notes: string;
	location: TeamPicsLocation;
	logoFile: TeamPicsFile;
	logoUrl: string;
	error: string;
};

const mapStateToProps = (state: any) => ({
	user: state.usersState.user,
	userMeta: state.usersState.userMeta,
	updateClient: state.clientsState.updateClient,
	loadClientDetails: state.clientsState.loadClientDetails,
	selectedClient: state.clientsState.selectedClient
});

const mapDispatchToProps = (dispatch: any) => ({
	onUpdateClient: (uid: string, clientId: string, client: Client, logoFile: TeamPicsFile) => {
		dispatch(fClients.updateClient(uid, clientId, client, logoFile));
	},
	onClearUpdateClient: () => {
		dispatch(rClients.modifyUpdateClient('LOADING', false));
	}
});
const formItemLayout = {
	labelCol: {
		xs: { span: 24 },
		sm: { span: 8 },
		md: { span: 4 }
	},
	wrapperCol: {
		xs: { span: 24 },
		sm: { span: 16 }
	}
};
const tailFormItemLayout = {
	wrapperCol: {
		xs: {
			span: 24,
			offset: 0
		},
		sm: {
			span: 16,
			offset: 8
		},
		md: {
			span: 16,
			offset: 4
		}
	}
};
const INITIAL_STATE = {
	clientId: '',
	active: false,
	email: '',
	name: '',
	phoneNumber: '',
	priColor: '#000000',
	secColor: '#000000',
	type: '' as ClientTypes,
	seasons: { spring: false, summer: false, fall: false, winter: false },
	website: '',
	address: '',
	notes: '',
	location: {} as TeamPicsLocation,
	logoFile: {} as TeamPicsFile,
	logoUrl: '',
	error: ''
};
class ClientGeneralForm extends Component<Props, State> {
	state = { ...INITIAL_STATE };
	componentDidMount() {
		if (this.props.selectedClient.key) {
			this.setState({
				...this.props.selectedClient as any,
				address: this.props.selectedClient.location.name,
				clientId: this.props.selectedClient.key
			});
		}
	}
	componentWillReceiveProps(nextProps: Props) {
		if (
			nextProps.selectedClient.key &&
			!nextProps.updateClient.loading &&
			!nextProps.updateClient.error &&
			!nextProps.loadClientDetails.loading
		) {
			this.setState({
				...nextProps.selectedClient as any,
				address: nextProps.selectedClient.location.name,
				clientId: nextProps.selectedClient.key
			});
		}
	}
	handleUploadChange = (file: any) => {};
	beforeUpload = (file: RcFile, FileList: RcFile[]): boolean | PromiseLike<any> => {
		const fileType = getFileType(file.name);
		if (!checkFileSize(file.size, 1)) {
			this.setState({ error: 'Max file size is 1MB' });
			return false;
		}

		var fileReader = new FileReader();
		fileReader.onloadend = (e: any) => {
			var arr = new Uint8Array(e.target.result).subarray(0, 4);
			var header = '';
			for (var i = 0; i < arr.length; i++) {
				header += arr[i].toString(16);
			}
			// Check the file signature against known types
			if (!checkIfValidImage(header, getMimeType(fileType))) {
				message.error('Please upload a png or a jpg/jpeg');
			} else {
				getBase64(file, (imageUrl: string) =>
					this.setState({
						logoUrl: imageUrl,
						logoFile: {
							fileType: fileType,
							mimeType: getMimeType(fileType),
							blob: imageUrl,
							fileSize: file.size
						}
					})
				);
			}
		};
		fileReader.readAsArrayBuffer(file.slice(0, 4));
		return true;
	};
	updateClientGeneral = () => {
		const {
			clientId,
			active,
			email,
			logoFile,
			logoUrl,
			name,
			phoneNumber,
			priColor,
			secColor,
			type,
			seasons,
			website,
			address,
			location,
			notes
		} = this.state;
		const { user } = this.props;
		this.setState({ error: '' });
		this.props.onClearUpdateClient();

		if (name.length > 30) {
			this.setState({
				error: 'Maxmium length for client name is 30 characters'
			});
			return;
		}

		if (!stringHelpers.validateEmail(email)) {
			this.setState({
				error: 'Please enter a valid email for the client'
			});
			return;
		}

		if (!isValidPhoneNumber('+1' + phoneNumber)) {
			this.setState({
				error: 'Please enter a valid phone number for the client'
			});
			return;
		}

		const client: Client = {
			userId: user.userId!,
			active,
			email,
			name,
			phoneNumber,
			priColor,
			secColor,
			type,
			seasons,
			website,
			location,
			logoUrl,
			notes,
			timestamp: new Date().getTime()
		};

		if (logoFile.blob) {
			this.props.onUpdateClient(user.userId, clientId, client, logoFile);
		} else {
			this.props.onUpdateClient(user.userId, clientId, client, {});
		}
	};
	render() {
		const { user, userMeta, updateClient, loadClientDetails, selectedClient } = this.props;
		const {
			active,
			email,
			logoFile,
			logoUrl,
			name,
			phoneNumber,
			priColor,
			secColor,
			type,
			seasons,
			website,
			error,
			address,
			location,
			notes
		} = this.state;
		const generalIsInvalid =
			email === '' ||
			name === '' ||
			phoneNumber === '' ||
			priColor === '' ||
			secColor === '' ||
			type === '' ||
			(!seasons.summer && !seasons.winter && !seasons.spring && !seasons.fall) ||
			location.placeId === '' ||
			website === '';
		const uploadButton = (
			<div>
				<Icon type={'plus'} />
				<div className='ant-upload-text'>Upload Client Logo</div>
			</div>
		);
		return (
			<div>
				<Spin
					indicator={<Icon type='loading' style={{ fontSize: 24 }} spin />}
					spinning={(updateClient.loading as boolean) || (loadClientDetails.loading as boolean)}
				>
					<Form className='settings-form'>
						<Form.Item label='Active Client' {...formItemLayout}>
							<Checkbox
								checked={active}
								onChange={(e) => {
									this.setState({ active: e.target.checked });
								}}
							/>
						</Form.Item>
						<Form.Item label='Client Name' {...formItemLayout}>
							<Input
								placeholder='Name'
								onChange={(e) => {
									if (e.target.value.length <= 100) {
										this.setState({ name: e.target.value });
									}
								}}
								value={name}
							/>
						</Form.Item>
						<Form.Item label='Email' {...formItemLayout}>
							<Input
								placeholder='Email'
								value={email}
								onChange={(e) => {
									this.setState({ email: e.target.value.trim() });
								}}
							/>
						</Form.Item>
						<Form.Item label='Client Phone #' {...formItemLayout}>
							<PhoneInput
								className='ant-input'
								country='CA'
								placeholder='Phone Number'
								value={phoneNumber}
								onChange={(e: any) => {
									this.setState({ phoneNumber: e });
								}}
							/>
						</Form.Item>
						<Form.Item label='Website' {...formItemLayout}>
							<Input
								placeholder='Website'
								value={website}
								onChange={(e) => {
									this.setState({ website: e.target.value });
								}}
							/>
						</Form.Item>
						<Form.Item label='Location' {...formItemLayout}>
							<TeampicsPlaces
								setAddress={(address: string) => {
									this.setState({ address });
								}}
								setLocation={(location: TeamPicsLocation) => {
									this.setState({ location: location });
								}}
								address={address}
								disabled={false}
								placeholder='Search Location...'
							/>
						</Form.Item>
						<Form.Item label='Client Type' {...formItemLayout}>
							<Select
								placeholder={'Select Type'}
								labelInValue
								value={{ key: type }}
								onChange={(e: any) => {
									this.setState({
										type: e.key as ClientTypes
									});
								}}
							>
								{getClientTypeOptions()}
							</Select>
						</Form.Item>
						<Form.Item label='Seasons' {...formItemLayout}>
							<Checkbox
								name='spring'
								checked={seasons.spring}
								onClick={(e) => {
									this.setState({
										seasons: {
											...seasons,
											spring: !seasons.spring
										}
									});
								}}
							>
								Spring
							</Checkbox>
							<Checkbox
								name='summer'
								checked={seasons.summer}
								onClick={(e) => {
									this.setState({
										seasons: {
											...seasons,
											summer: !seasons.summer
										}
									});
								}}
							>
								Summer
							</Checkbox>
							<Checkbox
								name='fall'
								checked={seasons.fall}
								onClick={(e) => {
									this.setState({
										seasons: {
											...seasons,
											fall: !seasons.fall
										}
									});
								}}
							>
								Fall
							</Checkbox>
							<Checkbox
								name='winter'
								checked={seasons.winter}
								onClick={(e) => {
									this.setState({
										seasons: {
											...seasons,
											winter: !seasons.winter
										}
									});
								}}
							>
								Winter
							</Checkbox>
						</Form.Item>
						<Form.Item label='Notes' {...formItemLayout}>
							<TextArea
								placeholder='Enter Notes...'
								value={notes}
								onChange={(e) => {
									this.setState({ notes: e.target.value });
								}}
								rows={5}
							/>
						</Form.Item>
						<Form.Item label='Primary Color' {...formItemLayout}>
							<ColorPicker
								setHex={(color: any) => {
									this.setState({ priColor: color.hex });
								}}
								hex={priColor}
							/>
						</Form.Item>
						<Form.Item label='Secondary Color' {...formItemLayout}>
							<ColorPicker
								setHex={(color: any) => {
									this.setState({ secColor: color.hex });
								}}
								hex={secColor}
							/>
						</Form.Item>
						<Form.Item label='Client Logo' {...formItemLayout}>
							<Upload
								name='avatar'
								listType='picture-card'
								className='avatar-uploader'
								showUploadList={false}
								beforeUpload={this.beforeUpload}
								onChange={this.handleUploadChange}
								customRequest={dummyRequest}
							>
								{logoUrl ? (
									<img style={{ maxWidth: '300px' }} src={logoUrl} alt='avatar' />
								) : (
									uploadButton
								)}
							</Upload>
						</Form.Item>
						{(updateClient.error || error) && (
							<Form.Item {...tailFormItemLayout}>
								<Alert className='uk-text-center' message={updateClient.error || error} type='error' />
							</Form.Item>
						)}
						{updateClient.success && (
							<Form.Item {...tailFormItemLayout}>
								<Alert className='uk-text-center' message={updateClient.success} type='success' />
							</Form.Item>
						)}
						<Form.Item {...tailFormItemLayout}>
							<Button
								onClick={this.updateClientGeneral}
								disabled={generalIsInvalid || (updateClient.loading as boolean)}
								type='primary'
							>
								Update
							</Button>
						</Form.Item>
					</Form>
				</Spin>
			</div>
		);
	}
}

export default compose<any, any>(connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps))(
	ClientGeneralForm
);
