import { combineReducers } from 'redux';
import {
  createReducerWithTaskName,
  dispatchTaskWithState,
  createReducerForFirebaseArray,
  dispatchFirebaseArrayWithState
} from '../helpers/reducers';
import { INITIAL_STATE_EMPTY_TASK } from '../models/Commons';
import { Group, Subject, TeampicsImage } from '../models/EventsModel';

/**
 * CONSTANTS
 */
const LOAD_IMAGES = 'load_images';
const LOAD_IMAGES_GROUPS = 'load_images_groups';
const LOAD_IMAGES_SUBJECTS = 'load_images_subjects';

const CLEAR_IMAGES_REDUCER = 'clear_images_reducer';

/**
 * ACTIONS
 */
export const modifyLoadImages = (taskState: string, value: string | boolean) => {
  return dispatchTaskWithState(LOAD_IMAGES, taskState, value);
};

export const modifyLoadImagesGroups = (taskState: string, value: string | boolean) => {
  return dispatchTaskWithState(LOAD_IMAGES_GROUPS, taskState, value);
};

export const modifyLoadImagesSubjects = (taskState: string, value: string | boolean) => {
  return dispatchTaskWithState(LOAD_IMAGES_SUBJECTS, taskState, value);
};

export const modifyImagesArray = (arrayState: string, image: TeampicsImage | TeampicsImage[]) => {
  return dispatchFirebaseArrayWithState(LOAD_IMAGES, arrayState, image);
};

export const modifyImagesGroupsArray = (arrayState: string, group: Group | Group[]) => {
  return dispatchFirebaseArrayWithState(LOAD_IMAGES_GROUPS, arrayState, group);
};

export const modifyImagesSubjectsArray = (arrayState: string, subject: Subject | Subject[]) => {
  return dispatchFirebaseArrayWithState(LOAD_IMAGES_SUBJECTS, arrayState, subject);
};

export const clearImagesReducer = () => ({
  type: CLEAR_IMAGES_REDUCER
});

/**
 * REDUCERS
 */
const imagesRootReducer = combineReducers({
  images: createReducerForFirebaseArray(LOAD_IMAGES, []),
  loadImages: createReducerWithTaskName(LOAD_IMAGES, INITIAL_STATE_EMPTY_TASK),
  groups: createReducerForFirebaseArray(LOAD_IMAGES_GROUPS, []),
  loadGroups: createReducerWithTaskName(LOAD_IMAGES_GROUPS, INITIAL_STATE_EMPTY_TASK),
  subjects: createReducerForFirebaseArray(LOAD_IMAGES_SUBJECTS, []),
  loadSubjects: createReducerWithTaskName(LOAD_IMAGES_SUBJECTS, INITIAL_STATE_EMPTY_TASK),
});

const imagesReducer = (state: any, action: any) => {
  if (action.type === CLEAR_IMAGES_REDUCER) {
    state = {
      images: [],
      groups: [],
      subjects: [],
      loadImages: { ...INITIAL_STATE_EMPTY_TASK },
      loadSubjects: { ...INITIAL_STATE_EMPTY_TASK },
      loadGroups: { ...INITIAL_STATE_EMPTY_TASK },
    };
  }

  return imagesRootReducer(state, action);
};

export default imagesReducer;
