import React, { Component } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { User, UserMeta } from '../../models/UsersModel';
import { TaskState, TeamPicsFile } from '../../models/Commons';
import {
	Modal,
	Steps,
	Button,
	Divider,
	Form,
	Input,
	Select,
	Radio,
	Upload,
	Icon,
	Table,
	Row,
	Col,
	Spin,
	Alert,
	message,
	InputNumber,
	Transfer,
	List
} from 'antd';
import { Product, Package, Offering, PackageInOffering } from '../../models/ProductsModel';
import { products as fProducts } from '../../firebase';
import * as rProducts from '../../reducers/products';
import { getArrayItemByKey } from '../../helpers/arrays';
import { titleCase } from '../../helpers/string';
const Step = Steps.Step;
const { TextArea } = Input;

interface OwnProps extends RouteComponentProps {
	visible: boolean;
	onCancel: Function;
}

interface StateProps {
	user: User;
	userMeta: UserMeta;
	newItem: TaskState;
	updateItem: TaskState;
	selectedOffering: Offering;
	packages: Array<Package>;
}

interface DispatchProps {
	onNewItem: Function;
	onUpdateItem: Function;
	onClearSelectedOffering: Function;
}

type Props = StateProps & DispatchProps & OwnProps;
type State = {
	error: string;
	pageNumber: number;
	name: string;
	description: string;
	selectedPackages: Array<PackageInOffering>;
	selectedKeys: Array<string>;
};

const INITIAL_STATE = {
	error: '',
	pageNumber: 0,
	name: '',
	description: '',
	selectedPackages: [] as Array<PackageInOffering>,
	selectedKeys: [] as Array<string>
};
const formItemLayout = {
	labelCol: {
		xs: { span: 24 },
		sm: { span: 8 },
		md: { span: 4 }
	},
	wrapperCol: {
		xs: { span: 24 },
		sm: { span: 16 }
	}
};
const tailFormItemLayout = {
	wrapperCol: {
		xs: {
			span: 24,
			offset: 0
		},
		sm: {
			span: 16,
			offset: 8
		},
		md: {
			span: 16,
			offset: 4
		}
	}
};
const steps = [
	{
		title: 'General'
	},
	{
		title: 'Packages'
	}
];
const mapStateToProps = (state: any) => ({
	user: state.usersState.user,
	userMeta: state.usersState.userMeta,
	newItem: state.productsState.newItem,
	updateItem: state.productsState.updateItem,
	selectedOffering: state.productsState.selected.selectedOffering,
	packages: state.productsState.packages
});

const mapDispatchToProps = (dispatch: any) => ({
	onNewItem: (uid: string, collectionKey: string, imageFile: TeamPicsFile, item: Offering) => {
		dispatch(fProducts.newItem(uid, collectionKey, imageFile, item));
	},
	onUpdateItem: (uid: string, collectionKey: string, imageFile: TeamPicsFile, item: Offering) => {
		dispatch(fProducts.updateItem(uid, collectionKey, imageFile, item));
	},
	onClearSelectedOffering: () => {
		dispatch(rProducts.setSelectedOffering({ key: '' } as Offering));
	}
});

class OfferingModal extends Component<Props, State> {
	state = { ...INITIAL_STATE };
	componentDidMount() { }
	componentWillReceiveProps(nextProps: Props) {
		if (!!nextProps.selectedOffering.key && !nextProps.newItem.loading && !nextProps.updateItem.loading) {
			const pack = {
				...nextProps.selectedOffering,
				selectedOfferings: nextProps.selectedOffering.packages,
				selectedKeys: nextProps.selectedOffering.packages.slice().map((pack) => {
					return pack.key;
				})
			} as any;
			delete pack.packages;
			this.setState({ ...pack });
		}
		if (
			(nextProps.newItem.error && !this.props.newItem.error) ||
			(nextProps.updateItem.error && !this.props.updateItem.error)
		) {
			if (nextProps.newItem.error) {
				message.error(nextProps.newItem.error);
			} else if (nextProps.updateItem.error) {
				message.error(nextProps.updateItem.error);
			}
		}
	}
	nextPage = () => {
		if (this.state.pageNumber < 1) {
			this.setState({ pageNumber: this.state.pageNumber + 1 });
		}
	};
	previousPage = () => {
		if (this.state.pageNumber > 0) {
			this.setState({ pageNumber: this.state.pageNumber - 1 });
		}
	};
	validateDetails = () => {
		const { name, description } = this.state;
		this.setState({
			error: ''
		});

		if (!name) {
			this.setState({
				error: 'Please enter a name for the offering'
			});
			message.error('Please enter a name for the offering');
			return;
		}

		this.nextPage();
	};
	newOffering = () => {
		const { name, selectedPackages, description } = this.state;
		const offering: Offering = {
			name,
			description,
			packages: this.state.selectedKeys.slice().map((key, index) => {
				const product = getArrayItemByKey(key, this.props.packages);

				return {
					key,
					amount: 1,
					type: product.type,
					name: product.name
				};
			}),
			userId: this.props.user.userId!,
			timestamp: new Date().getTime()
		};

		if (this.props.selectedOffering.key) {
			offering.key = this.props.selectedOffering.key;
		}

		if (this.props.selectedOffering.key) {
			this.props.onUpdateItem(this.props.user.userId, 'offerings', { blob: '' }, offering);
		} else {
			this.props.onNewItem(this.props.user.userId, 'offerings', { blob: '' }, offering);
		}
	};
	renderItem = (item: Package) => {
		const customLabel = <span className='custom-item'>{item.name}</span>;

		return {
			label: customLabel, // for displayed item
			value: item.name // for title and filter matching
		};
	};
	handleChange = (targetKeys: Array<string>, direction: any, moveKeys: Array<PackageInOffering>) => {
		this.setState({ selectedKeys: targetKeys });
	};
	render() {
		const { selectedOffering, packages, updateItem, newItem, user, userMeta, visible, onCancel } = this.props;
		const { name, description, pageNumber, selectedKeys, error } = this.state;
		const generalIsInvalid = name === '';
		const selectedAreInvalid = selectedKeys.length === 0;

		return (
			<div className='uk-width-expand'>
				<Modal
					keyboard={false}
					centered
					bodyStyle={{ height: '90vh', borderRadius: 0 }}
					width={'90vw'}
					visible={visible}
					title={selectedOffering.key ? `${selectedOffering.name}` : 'Set up a new Offering'}
					okText='Create'
					footer={null}
					afterClose={() => {
						this.setState({ ...INITIAL_STATE });
						this.props.onClearSelectedOffering();
					}}
					onCancel={() => {
						onCancel();
					}}
					onOk={() => { }}
				>
					<div>
						<Steps size='small' current={this.state.pageNumber}>
							{steps.map((item) => <Step key={item.title} title={item.title} />)}
						</Steps>

						<div className='steps-content uk-margin-bottom'>
							<Form>
								<Spin
									indicator={<Icon type='loading' style={{ fontSize: 24 }} spin />}
									spinning={(newItem.loading as boolean) || (updateItem.loading as boolean)}
								>
									{pageNumber === 0 && (
										<div>
											<Form.Item label='Offering Name' {...formItemLayout}>
												<Input
													placeholder='Name'
													onChange={(e) => {
														if (e.target.value.length <= 30) {
															this.setState({ name: titleCase(e.target.value) });
														}
													}}
													value={name}
												/>
											</Form.Item>
											<Form.Item label='Offering Description' {...formItemLayout}>
												<TextArea
													placeholder='Enter Description...'
													value={description}
													onChange={(e) => {
														this.setState({ description: e.target.value });
													}}
													rows={4}
												/>
											</Form.Item>
										</div>
									)}

									{pageNumber === 1 && (
										<div className='uk-margin-bottom'>
											<Transfer
												titles={['All Packages', 'Selected Packages']}
												showSearch
												dataSource={packages as any}
												listStyle={{
													minWidth: 450,
													height: 450
												}}
												targetKeys={selectedKeys as any}
												onChange={this.handleChange as any}
												render={this.renderItem as any}
											/>
										</div>
									)}
								</Spin>
							</Form>
						</div>
						<div className='uk-position-bottom uk-padding-small'>
							<Divider />
							<div className='uk-flex uk-flex-row'>
								<div className='uk-flex uk-flex-left'>
									<Button onClick={() => onCancel()}>Cancel</Button>
								</div>
								<div className='uk-flex uk-flex-right uk-width-expand'>
									{this.state.pageNumber > 0 && (
										<Button onClick={() => this.previousPage()}>Previous</Button>
									)}
									{this.state.pageNumber < steps.length - 1 && (
										<Button
											style={{ marginLeft: 8 }}
											type='primary'
											onClick={() => {
												if (pageNumber === 0) {
													this.validateDetails();
												}
											}}
											disabled={
												(pageNumber === 0 && generalIsInvalid) ||
												(pageNumber === 1 && selectedAreInvalid)
											}
										>
											Next
										</Button>
									)}
									{this.state.pageNumber === steps.length - 1 && (
										<Button
											style={{ marginLeft: 8 }}
											type='primary'
											disabled={
												(newItem.loading as boolean) ||
												(updateItem.loading as boolean) ||
												selectedAreInvalid
											}
											onClick={() => {
												this.newOffering();
											}}
										>
											{selectedOffering.key ? 'Update' : 'Save'}
										</Button>
									)}
								</div>
							</div>
						</div>
					</div>
				</Modal>
			</div>
		);
	}
}

const condition = (authUser: any) => !!authUser;

export default compose<any, any>(
	withRouter,
	connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps)
)(OfferingModal);
