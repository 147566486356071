import { combineReducers } from 'redux';
import {
  createReducerWithTaskName,
  dispatchTaskWithState,
  createReducerForFirebaseArray,
  dispatchFirebaseArrayWithState
} from '../helpers/reducers';
import {
  INITIAL_STATE_EMPTY_TASK,
  TeamPicsLocation,
  TeamPicsFile
} from '../models/Commons';
import {
  StaffRoles,
  DivisionTypes,
  Subject,
  SubjectTypes,
  Timeslot,
  Group,
  Shoot,
  Event
} from '../models/EventsModel';
import {
  StepIntro,
  StepAuth,
  StepContact,
  StepTeamInfo
} from '../models/TeamBuilderModel';
import { User } from '../models/UsersModel';
import { firestore } from '../firebase/firebaseConfig';

/**
 * INITIAL STATES
 */
const INITIAL_STATE_EVENT = { key: '' };
const INITIAL_STATE_GROUP = { key: '' };
const INITIAL_STATE_SHOOT = { key: '' };
const INITIAL_STATE_TIMESLOT = { key: '' };
const INITIAL_STATE_STEP_INTRO: StepIntro = {
  disclaimer: false
};
export const INITIAL_STATE_STEP_AUTH: User = {
  userId: '',
  firstName: '',
  email: '',
  lastName: '',
  address: '',
  location: {},
  city: '',
  company: '',
  phoneNumber: '',
  postal: '',
  website: '',
  type: ''
};
const INITAL_STATE_STEP_CONTACT: StepContact = {
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  role: '' as StaffRoles,
  otherRole: '',
  address: '',
  location: {} as TeamPicsLocation,
  postalCode: ''
};
const INITIAL_STATE_STEP_TEAM_INFO: StepTeamInfo = {
  priColor: '#000000',
  secColor: '#000000',
  division: '' as DivisionTypes,
  otherDivision: '',
  subDivision: '',
  name: '',
  fullName: '',
  secondDisclaimer: false,
  logoUrl: '',
  logoFile: {} as TeamPicsFile
};
export const generateKey = () => {
  return firestore.collection('subjects').doc().id;
};
export const INITIAL_STATE_SUBJECT: Subject = {
  contactId: '',
  eventId: '',
  groupId: '',
  photographerId: '',
  shootId: '',
  timeslotId: '',
  friendlyId: '',
  firstName: '',
  lastName: '',
  age: 0,
  heightFt: 0,
  heightIn: 0,
  email: '',
  phoneNumber: '',
  jerseyNumber: '',
  division: '',
  role: '' as StaffRoles,
  position: '',
  type: '' as SubjectTypes,
  archived: false
};
const INITIAL_STATE_STEP_TIMESLOT: Timeslot = {
  key: '',
  eventId: '',
  groupId: '',
  photographerId: '',
  shootId: '',
  timestamp: 0,
  time: '',
  date: '',
  lengthMins: 0,
  taken: false,
  teamName: ''
};

/**
 * CONSTANTS
 */
const SET_STEP_INTRO = 'set step intro';
const SET_STEP_AUTH = 'set step auth';
const SET_STEP_CONTACT = 'set step contact';
const SET_STEP_TEAM_INFO = 'set step team info';
const SET_STEP_TIMESLOT = 'set step timeslots';
const SET_EVENT = 'set team builder event';
const SET_GROUP = 'set team builder group';
const SET_SHOOT = 'set team builder shoot';
const SET_TIMESLOT = 'set team builder timeslot';

const SET_PAGE_NUMBER = 'set page number teambuilder form';

const LOGIN_USER = 'login teambuilder user';
const LOAD_STAFF = 'load teambuilder subjects';
const LOAD_PLAYERS = 'load teambuilder players';
const LOAD_SUBJECTS = 'load teambuilder subjects';
const LOAD_ALL_GROUPS = 'load teambuilder all groups';
const LOAD_CONTACTS_GROUPS = 'load teambuilder contacts groups';
const LOAD_EVENT = 'load teambuilder event';
const LOAD_TIMESLOTS = 'load teambuilder timeslots';
const LOAD_SHOOTS = 'load teambuilder shoots';
const UPDATE_GROUP = 'update group team builder';
const TO_DELETE_SUBJECTS = 'to delete subjects';

const RESET_TEAMBUILDER = 'reset teambuilder';
const CLEAR_TEAMBUILDER_REDUCER = 'clear team builder reducer';

/**
 * ACTIONS
 */
export const setStepIntro = (stepIntro: StepIntro) => ({
  type: SET_STEP_INTRO,
  stepIntro
});
export const setStepAuth = (user: User) => ({
  type: SET_STEP_AUTH,
  user
});
export const setStepContact = (stepContact: StepContact) => ({
  type: SET_STEP_CONTACT,
  stepContact
});
export const setStepTeamInfo = (stepTeamInfo: StepTeamInfo) => ({
  type: SET_STEP_TEAM_INFO,
  stepTeamInfo
});
export const setStepTimeslot = (timeslot: Timeslot) => ({
  type: SET_STEP_TIMESLOT,
  timeslot
});
export const setShoot = (shoot: Shoot) => ({
  type: SET_SHOOT,
  shoot
});
export const setEvent = (event: Event) => ({
  type: SET_EVENT,
  event
});
export const setGroup = (group: Group) => ({
  type: SET_GROUP,
  group
});
export const setTimeslot = (timeslot: Timeslot) => ({
  type: SET_TIMESLOT,
  timeslot
});

export const modifyLoginUser = (taskState: string, value: string | boolean) => {
  return dispatchTaskWithState(LOGIN_USER, taskState, value);
};
export const modifyLoadGroups = (
  taskState: string,
  value: string | boolean
) => {
  return dispatchTaskWithState(LOAD_ALL_GROUPS, taskState, value);
};
export const modifyLoadEvent = (taskState: string, value: string | boolean) => {
  return dispatchTaskWithState(LOAD_EVENT, taskState, value);
};
export const modifyLoadTimeslots = (
  taskState: string,
  value: string | boolean
) => {
  return dispatchTaskWithState(LOAD_TIMESLOTS, taskState, value);
};
export const modifyLoadShoots = (
  taskState: string,
  value: string | boolean
) => {
  return dispatchTaskWithState(LOAD_SHOOTS, taskState, value);
};
export const modifyLoadSubjects = (
  taskState: string,
  value: string | boolean
) => {
  return dispatchTaskWithState(LOAD_SUBJECTS, taskState, value);
};
export const modifyUpdateGroup = (
  taskState: string,
  value: string | boolean
) => {
  return dispatchTaskWithState(UPDATE_GROUP, taskState, value);
};

export const modifyAllGroupsArray = (arrayState: string, group: Group) => {
  return dispatchFirebaseArrayWithState(LOAD_ALL_GROUPS, arrayState, group);
};
export const modifyContactsGroupsArray = (arrayState: string, group: Group) => {
  return dispatchFirebaseArrayWithState(
    LOAD_CONTACTS_GROUPS,
    arrayState,
    group
  );
};
export const modifyShootsArray = (arrayState: string, shoot: Shoot) => {
  return dispatchFirebaseArrayWithState(LOAD_SHOOTS, arrayState, shoot);
};
export const modifyPlayersArray = (arrayState: string, subject: Subject) => {
  return dispatchFirebaseArrayWithState(LOAD_PLAYERS, arrayState, subject);
};
export const modifyStaffArray = (arrayState: string, subject: Subject) => {
  return dispatchFirebaseArrayWithState(LOAD_STAFF, arrayState, subject);
};
export const modifyTimeslotsArray = (
  arrayState: string,
  timeslot: Timeslot
) => {
  return dispatchFirebaseArrayWithState(LOAD_TIMESLOTS, arrayState, timeslot);
};
export const modifyToDeleteSubjectsArray = (
  arrayState: string,
  subject: Subject
) => {
  return dispatchFirebaseArrayWithState(
    TO_DELETE_SUBJECTS,
    arrayState,
    subject
  );
};

export const setPageNumber = (pageNumber: number) => ({
  type: SET_PAGE_NUMBER,
  pageNumber
});

export const resetTeamBuilder = () => ({
  type: RESET_TEAMBUILDER
});
export const clearTeambuilderReducer = () => ({
  type: CLEAR_TEAMBUILDER_REDUCER
});

/**
 * REDUCERS
 */
function stepIntroReducer(state = INITIAL_STATE_STEP_INTRO, action: any) {
  switch (action.type) {
    case SET_STEP_INTRO: {
      return {
        ...state,
        ...action.stepIntro
      };
    }
    default:
      return state;
  }
}

function stepAuthReducer(state = INITIAL_STATE_STEP_AUTH, action: any) {
  switch (action.type) {
    case SET_STEP_AUTH: {
      return {
        ...state,
        ...action.user
      };
    }
    default:
      return state;
  }
}

function stepContactReducer(state = INITAL_STATE_STEP_CONTACT, action: any) {
  switch (action.type) {
    case SET_STEP_CONTACT: {
      return {
        ...state,
        ...action.stepContact
      };
    }
    default:
      return state;
  }
}

function stepTeamInfoReducer(
  state = INITIAL_STATE_STEP_TEAM_INFO,
  action: any
) {
  switch (action.type) {
    case SET_STEP_TEAM_INFO: {
      return {
        ...state,
        ...action.stepTeamInfo
      };
    }
    default:
      return state;
  }
}

function stepTimeslotReducer(state = INITIAL_STATE_STEP_TIMESLOT, action: any) {
  switch (action.type) {
    case SET_STEP_TIMESLOT: {
      return {
        ...state,
        ...action.timeslot
      };
    }
    default:
      return state;
  }
}

function eventReducer(state = INITIAL_STATE_EVENT, action: any) {
  switch (action.type) {
    case SET_EVENT: {
      return {
        ...state,
        ...action.event
      };
    }
    default:
      return state;
  }
}

function groupReducer(state = INITIAL_STATE_GROUP, action: any) {
  switch (action.type) {
    case SET_GROUP: {
      return {
        ...state,
        ...action.group
      };
    }
    default:
      return state;
  }
}

function shootReducer(state = INITIAL_STATE_GROUP, action: any) {
  switch (action.type) {
    case SET_SHOOT: {
      return {
        ...state,
        ...action.shoot
      };
    }
    default:
      return state;
  }
}

function timeslotReducer(state = INITIAL_STATE_TIMESLOT, action: any) {
  switch (action.type) {
    case SET_TIMESLOT: {
      return {
        ...state,
        ...action.timeslot
      };
    }
    default:
      return state;
  }
}

function pageNumberReducer(state = 0, action: any) {
  switch (action.type) {
    case SET_PAGE_NUMBER: {
      return action.pageNumber;
    }
    default:
      return state;
  }
}

const teambuilderRootReducer = combineReducers({
  event: eventReducer,
  group: groupReducer,
  shoot: shootReducer,
  timeslot: timeslotReducer,
  pageNumber: pageNumberReducer,
  stepIntro: stepIntroReducer,
  stepAuth: stepAuthReducer,
  stepContact: stepContactReducer,
  stepTeamInfo: stepTeamInfoReducer,
  stepTimeslot: stepTimeslotReducer,
  allGroups: createReducerForFirebaseArray(LOAD_ALL_GROUPS, []),
  contactsGroups: createReducerForFirebaseArray(LOAD_CONTACTS_GROUPS, []),
  players: createReducerForFirebaseArray(LOAD_PLAYERS, []),
  staff: createReducerForFirebaseArray(LOAD_STAFF, []),
  timeslots: createReducerForFirebaseArray(LOAD_TIMESLOTS, []),
  shoots: createReducerForFirebaseArray(LOAD_SHOOTS, []),
  toDeleteSubjects: createReducerForFirebaseArray(TO_DELETE_SUBJECTS, []),
  loginUser: createReducerWithTaskName(LOGIN_USER, INITIAL_STATE_EMPTY_TASK),
  updateGroup: createReducerWithTaskName(
    UPDATE_GROUP,
    INITIAL_STATE_EMPTY_TASK
  ),
  loadEvent: createReducerWithTaskName(LOAD_EVENT, INITIAL_STATE_EMPTY_TASK),
  loadSubjects: createReducerWithTaskName(
    LOAD_SUBJECTS,
    INITIAL_STATE_EMPTY_TASK
  ),
  loadShoots: createReducerWithTaskName(LOAD_SHOOTS, INITIAL_STATE_EMPTY_TASK),
  loadTimeslots: createReducerWithTaskName(
    LOAD_TIMESLOTS,
    INITIAL_STATE_EMPTY_TASK
  ),
  loadGroups: createReducerWithTaskName(
    LOAD_ALL_GROUPS,
    INITIAL_STATE_EMPTY_TASK
  )
});

const teambuilderReducer = (state: any, action: any) => {
  if (action.type === CLEAR_TEAMBUILDER_REDUCER) {
    state = {
      event: { ...INITIAL_STATE_EVENT },
      group: { ...INITIAL_STATE_GROUP },
      shoot: { ...INITIAL_STATE_SHOOT },
      timeslot: { ...INITIAL_STATE_TIMESLOT },
      pageNumber: 0,
      stepIntro: { ...INITIAL_STATE_STEP_INTRO },
      stepAuth: { ...INITIAL_STATE_STEP_AUTH },
      stepContact: { ...INITAL_STATE_STEP_CONTACT },
      stepTeamInfo: { ...INITIAL_STATE_STEP_TIMESLOT },
      stepTimeslot: { ...INITIAL_STATE_STEP_TIMESLOT },
      allGroups: [],
      contactsGroups: [],
      players: [],
      staff: [],
      timeslots: [],
      shoots: [],
      toDeleteSubjects: [],
      loginUser: { ...INITIAL_STATE_EMPTY_TASK },
      updateGroup: { ...INITIAL_STATE_EMPTY_TASK },
      loadEvent: { ...INITIAL_STATE_EMPTY_TASK },
      loadSubjects: { ...INITIAL_STATE_EMPTY_TASK },
      loadShoots: { ...INITIAL_STATE_EMPTY_TASK },
      loadTimeslots: { ...INITIAL_STATE_EMPTY_TASK },
      loadGroups: { ...INITIAL_STATE_EMPTY_TASK }
    };
  } else if (action.type === RESET_TEAMBUILDER) {
    state = {
      event: state.event,
      group: { ...INITIAL_STATE_GROUP },
      shoot: { ...INITIAL_STATE_SHOOT },
      timeslot: { ...INITIAL_STATE_TIMESLOT },
      pageNumber: 2,
      stepIntro: state.stepIntro,
      stepAuth: state.stepAuth,
      stepContact: state.stepContact,
      stepTeamInfo: { ...INITIAL_STATE_STEP_TIMESLOT },
      stepTimeslot: { ...INITIAL_STATE_STEP_TIMESLOT },
      allGroups: state.allGroups,
      contactsGroups: state.contactsGroups,
      players: [],
      staff: [],
      timeslots: [],
      shoots: state.shoots,
      toDeleteSubjects: [],
      loginUser: { ...INITIAL_STATE_EMPTY_TASK },
      updateGroup: { ...INITIAL_STATE_EMPTY_TASK },
      loadEvent: { ...INITIAL_STATE_EMPTY_TASK },
      loadSubjects: { ...INITIAL_STATE_EMPTY_TASK },
      loadShoots: { ...INITIAL_STATE_EMPTY_TASK },
      loadTimeslots: { ...INITIAL_STATE_EMPTY_TASK },
      loadGroups: { ...INITIAL_STATE_EMPTY_TASK }
    };
  }

  return teambuilderRootReducer(state, action);
};

export default teambuilderReducer;
