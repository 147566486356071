import { firestore } from "./firebaseConfig";
import * as rClients from "../reducers/clients";
import * as storage from "./storage";
import { ThunkResult, TeamPicsFile, Contact } from "../models/Commons";
import { Client } from "../models/ClientsModel";

//-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-//
//Loading "clients" object from firebase
//-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-//

let clientsListener: any;
export const subscribeToClients = (uid: string): ThunkResult<void> => {
  return dispatch => {
    const clientRef = firestore
      .collection("users")
      .doc(uid)
      .collection("clients")
      .orderBy("name", "desc");
    if (clientsListener) {
      return;
    }
    dispatch(rClients.modifyLoadClients("LOADING", true));
    clientsListener = clientRef.onSnapshot(
      (snapshot: any) => {
        if (snapshot.empty) {
          dispatch(rClients.modifyLoadClients("EMPTY", true));
        } else {
          dispatch(rClients.modifyLoadClients("SUCCESS", ""));
        }

        snapshot.docChanges().forEach((change: any) => {
          if (change.type === "added") {
            const client = {
              ...change.doc.data(),
              key: change.doc.id
            };
            dispatch(rClients.modifyClientsArray("ADD", client));
          }
          if (change.type === "modified") {
            const client = {
              ...change.doc.data(),
              key: change.doc.id
            };
            dispatch(rClients.modifyClientsArray("UPDATE", client));
          }
          if (change.type === "removed") {
            const client = {
              ...change.doc.data(),
              key: change.doc.id
            };
            dispatch(rClients.modifyClientsArray("REMOVE", client));
          }
        });
      },
      (error: any) => {
        if (error) {
          rClients.modifyLoadClients(
            "ERROR",
            "Something went wrong, please refresh the page"
          );
        }
      }
    );

    return clientsListener;
  };
};

export const unsubscribeClients = () => {
  if (clientsListener) {
    clientsListener();
    clientsListener = undefined;
  }
};

export const getContactsForEvent = (
  uid: string,
  clientId: string
): Promise<Array<Contact>> => {
  const contactsRef = firestore
    .collection("users")
    .doc(uid)
    .collection("clients")
    .doc(clientId)
    .collection("contacts");
  return contactsRef.get().then(contactsSnap => {
    const contacts: Array<Contact> = [];
    contactsSnap.forEach(contactSnap => {
      const contact = {
        key: contactSnap.id,
        ...(contactSnap.data() as Contact)
      };
      contacts.push(contact);
    });
    return contacts;
  });
};

export const getContacts = (
  uid: string,
  clientId: string
): ThunkResult<void> => {
  return dispatch => {
    const contactsRef = firestore
      .collection("users")
      .doc(uid)
      .collection("clients")
      .doc(clientId)
      .collection("contacts");
    dispatch(rClients.modifyLoadContacts("LOADING", true));

    return contactsRef
      .get()
      .then(contactSnaps => {
        if (contactSnaps.empty) {
          dispatch(rClients.modifyLoadContacts("EMPTY", true));
          throw Error("NO_CONTACTS");
        }

        contactSnaps.forEach(contactSnap => {
          const contact = {
            ...contactSnap.data(),
            key: contactSnap.id
          };
          dispatch(rClients.modifyContactsArray("ADD", contact as Contact));
        });
      })
      .then(() => {
        dispatch(
          rClients.modifyLoadContacts("SUCCESS", "Succesfully loaded contacts.")
        );
      })
      .catch(error => {
        dispatch(
          rClients.modifyLoadContacts(
            "ERROR",
            error.message
              ? error.message
              : "Something went wrong, please refresh the page"
          )
        );
      });
  };
};

export const getClient = (uid: string, clientId: string): ThunkResult<void> => {
  return dispatch => {
    const clientRef = firestore
      .collection("users")
      .doc(uid)
      .collection("clients")
      .doc(clientId);
    dispatch(rClients.modifyLoadClientDetails("LOADING", true));

    return clientRef
      .get()
      .then(clientSnap => {
        if (!clientSnap.exists) {
          dispatch(rClients.modifyLoadClientDetails("EMPTY", true));
          throw Error("NO_CLIENT");
        }
        const client = {
          ...clientSnap.data(),
          key: clientSnap.id
        };
        dispatch(rClients.setSelectedClient(client as Client));
      })
      .then(() => {
        dispatch(rClients.modifyLoadClientDetails("SUCCESS", ""));
      })
      .catch(error => {
        dispatch(
          rClients.modifyLoadClientDetails(
            "ERROR",
            error.message
              ? error.message
              : "Something went wrong, please refresh the page"
          )
        );
      });
  };
};

export const updateClient = (
  uid: string,
  clientId: string,
  client: Client,
  logoFile: TeamPicsFile
): ThunkResult<void> => {
  return dispatch => {
    dispatch(rClients.modifyUpdateClient("LOADING", true));
    const clientRef = firestore
      .collection("users")
      .doc(uid)
      .collection("clients")
      .doc(clientId);

    if (logoFile.blob) {
      const storagePath = `users/${uid}/clients/${clientId}/logo/client-logo-${clientId}`;

      return storage
        .uploadImage(logoFile, storagePath)
        .then(url => {
          return clientRef.update({
            ...client,
            logoUrl: url
          });
        })
        .then(() => {
          dispatch(
            rClients.modifyUpdateClient(
              "SUCCESS",
              "Client successfully updated."
            )
          );
        })
        .catch(error => {
          dispatch(
            rClients.modifyUpdateClient(
              "ERROR",
              "Something went wrong, please try again."
            )
          );
        });
    }

    return clientRef
      .update({
        ...client
      })
      .then(() => {
        dispatch(getClient(uid, clientId));
      })
      .then(() => {
        dispatch(
          rClients.modifyUpdateClient("SUCCESS", "Client successfully updated.")
        );
      })
      .catch(error => {
        dispatch(
          rClients.modifyUpdateClient(
            "ERROR",
            "Something went wrong, please try again."
          )
        );
      });
  };
};

export const updateContacts = (
  uid: string,
  clientId: string,
  contacts: Array<Contact>
): ThunkResult<void> => {
  return dispatch => {
    dispatch(rClients.modifyUpdateClient("LOADING", true));
    const batch = firestore.batch();

    contacts.forEach(contact => {
      if (contact.key) {
        const contactsRef = firestore
          .collection("users")
          .doc(uid)
          .collection("clients")
          .doc(clientId)
          .collection("contacts")
          .doc(contact.key);
        batch.update(contactsRef, contact);
      } else {
        const contactsRef = firestore
          .collection("users")
          .doc(uid)
          .collection("clients")
          .doc(clientId)
          .collection("contacts")
          .doc();
        batch.set(contactsRef, contact);
      }
    });

    return batch
      .commit()
      .then(() => {
        dispatch(rClients.modifyContactsArray("CLEAR", {} as Contact));
        dispatch(getContacts(uid, clientId));
      })
      .then(() => {
        dispatch(
          rClients.modifyUpdateClient(
            "SUCCESS",
            "Contacts were successfully updated."
          )
        );
      })
      .catch(error => {
        dispatch(
          rClients.modifyUpdateClient(
            "ERROR",
            "Something went wrong, please try again."
          )
        );
      });
  };
};

export const newClient = (
  uid: string,
  client: Client,
  contacts: Array<Contact>,
  logoFile: TeamPicsFile
): ThunkResult<void> => {
  return dispatch => {
    dispatch(rClients.modifyUpdateClient("LOADING", true));
    const clientRef = firestore
      .collection("users")
      .doc(uid)
      .collection("clients")
      .doc();
    const clientId = clientRef.id;
    const storagePath = `users/${uid}/clients/${clientId}/logo/client-logo-${clientId}`;

    return storage
      .uploadImage(logoFile, storagePath)
      .then(url => {
        return clientRef.set({
          ...client,
          logoUrl: url
        });
      })
      .then(() => {
        const batch = firestore.batch();
        contacts.forEach(contact => {
          const contactRef = firestore
            .collection("users")
            .doc(uid)
            .collection("clients")
            .doc(clientId)
            .collection("contacts")
            .doc();
          batch.set(contactRef, contact);
        });
        return batch.commit();
      })
      .then(() => {
        dispatch(
          rClients.modifyUpdateClient(
            "SUCCESS",
            "Client was successfully created."
          )
        );
      })
      .catch(error => {
        dispatch(
          rClients.modifyUpdateClient(
            "ERROR",
            "Something went wrong, please try again."
          )
        );
      });
  };
};