import * as reducerHelpers from '../helpers/reducers';

const INITIAL_STATE = {
  authUser: null,
  page: '/dashboard'
};

const SET_PAGE = 'set page';

const applySetAuthUser = (state: any, action: any) => ({
  ...state,
  authUser: action.authUser
});
export const setPage = (page: string) => ({ type: SET_PAGE, page });

function sessionReducer(state = INITIAL_STATE, action: any) {
  switch (action.type) {
    case 'AUTH_USER_SET': {
      return applySetAuthUser(state, action);
    }
    case SET_PAGE: {
      return {
        ...state,
        page: action.page
      };
    }
    default:
      return state;
  }
}

export default sessionReducer;
