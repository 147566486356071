export function capitalizeFirstLetter(string?: string) {
	if (string) {
		return string.charAt(0).toUpperCase() + string.slice(1);
	} else {
		return string;
	}
}

export function toUpperCase(string: string) {
	return string.replace(/\b\w/g, (l) => l.toUpperCase());
}

export const camelize = (string: string) => {
	return string.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function (match: any, index: number) {
		if (+match === 0) return ""; // or if (/\s+/.test(match)) for white spaces
		return index === 0 ? match.toLowerCase() : match.toUpperCase();
	});
}

export function validateEmail(email?: string) {
	if (email) {
		const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(String(email).trim().toLowerCase());
	} else {
		return false;
	}
}

export function checkLowerCaseCharacters(password: string) {
	const lowerCaseLetters = /[a-z]/g;
	const validChars = password.match(lowerCaseLetters);

	if (!validChars) {
		return false;
	} else if (validChars.length !== 0) {
		return true;
	} else {
		return false;
	}
}

export function checkUpperCaseCharacters(password: string) {
	const upperCaseLetters = /[A-Z]/g;
	const validChars = password.match(upperCaseLetters);

	if (!validChars) {
		return false;
	} else if (validChars.length !== 0) {
		return true;
	} else {
		return false;
	}
}

export function checkNumbers(password: string) {
	const numbers = /[0-9]/g;
	const validChars = password.match(numbers);

	if (!validChars) {
		return false;
	} else if (validChars.length !== 0) {
		return true;
	} else {
		return false;
	}
}

export function isWholeNumber(string: string) {
	const regex = /^[0-9]+$/;
	return string.match(regex);
}

export function isNumber(string: string) {
	const regex = /^[0-9, 0.9, ' ']+$/;
	return string.match(regex);
}

export function numberWithCommas(number: number) {
	return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function addAsterisk(string: string) {
	const superscript = '*'.sup();
	const finalString = `${string}${superscript}`;
	return finalString;
}

export function checkPrice(string: string) {
	const regex = /^\d+(\.\d{1,2})?$/;
	return string.trim().match(regex);
}

export function getInitials(string: string) {
	var names = string.split(' '),
		initials = names[0].substring(0, 1).toUpperCase();

	if (names.length > 1) {
		initials += names[names.length - 1].substring(0, 1).toUpperCase();
	}
	return initials;
}

export function getRandomItemFromArray(items: any[]) {
	return items[Math.floor(Math.random() * items.length)];
}

export const titleCase = (str: string) => {
	var splitStr = str.toLowerCase().split(' ');
	for (var i = 0; i < splitStr.length; i++) {
		// You do not need to check if i is larger than splitStr length, as your for does that for you
		// Assign it back to the array
		splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
	}
	// Directly return the joined string
	return splitStr.join(' ');
};

export function csvToArray(str: string, delimiter = ",") {
	// slice from start of text to the first \n index
	// use split to create an array from string by delimiter
	const headers = str.slice(0, str.indexOf("\n")).split(delimiter);

	// slice from \n index + 1 to the end of the text
	// use split to create an array of each csv value row
	const rows = str.slice(str.indexOf("\n") + 1).split("\n");

	// Map the rows
	// split values from each row into an array
	// use headers.reduce to create an object
	// object properties derived from headers:values
	// the object passed as an element of the array
	const arr = rows.map(function (row) {
		const values = row.split(delimiter);
		const el = headers.reduce(function (object: any, header, index) {
			object[header] = values[index];
			return object;
		}, {});
		return el;
	});

	// return the array
	return arr;
}