import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, Table, Spin, Empty, Tabs, List, Skeleton, Tag, Divider, Button, Alert, message, Icon } from 'antd';
import { UserMeta, User } from '../../models/UsersModel';
import { Subject, Group, Timeslot, Shoot } from '../../models/EventsModel';
import { TaskState } from '../../models/Commons';
import * as rTimeslots from '../../reducers/timeslots';
import { timeslots as fTimeslots } from '../../firebase';
import { getTimeHrsMin } from '../../helpers/time';
const { Column } = Table;
const TabPane = Tabs.TabPane;

interface OwnProps {
	visible: boolean;
	onCancel: Function;
}
interface StateProps {
	user: User;
	userMeta: UserMeta;
	timeslots: Array<Timeslot>;
	loadTimeslots: TaskState;
	selectedTimeslot: Timeslot;
	selectedShoot: Shoot;
	updateTimeslot: TaskState;
}
interface DispatchProps {
	onSetSelectedTimeslot: Function;
	onBookTimeslot: Function;
}
type Props = StateProps & DispatchProps & OwnProps;
type State = {};
const INITIAL_STATE = {};
const mapStateToProps = (state: any) => ({
	user: state.usersState.user,
	userMeta: state.usersState.userMeta,
	timeslots: state.timeslotsState.timeslots,
	loadTimeslots: state.timeslotsState.loadTimeslots,
	selectedTimeslot: state.timeslotsState.selectedTimeslot,
	selectedShoot: state.timeslotsState.selectedShoot,
	updateTimeslot: state.timeslotsState.updateTimeslot
});
const mapDispatchToProps = (dispatch: any) => ({
	onSetSelectedTimeslot: (timeslot: Timeslot) => {
		dispatch(rTimeslots.setSelectedTimeslot(timeslot));
	},
	onBookTimeslot: (timeslot: Timeslot) => {
		dispatch(fTimeslots.bookTimeslotPhotographer(timeslot));
		dispatch(rTimeslots.setSelectedTimeslot({ key: '' } as Timeslot));
	}
});
class SubjectsListModal extends Component<Props, State> {
	state = { ...INITIAL_STATE };
	componentWillReceiveProps(nextProps: Props) {
		if (nextProps.updateTimeslot.success && !this.props.updateTimeslot.success) {
			message.success(nextProps.updateTimeslot.success);
		}
		if (nextProps.updateTimeslot.error && !this.props.updateTimeslot.error) {
			message.error(nextProps.updateTimeslot.error);
		}
	}
	bookTimeslot = () => {
		const { user, selectedTimeslot, selectedShoot } = this.props;

		const newTimeslot: Timeslot = {
			...selectedTimeslot,
			groupId: user.userId,
			taken: true,
			teamName: 'Reserved'
		};
		this.props.onBookTimeslot(newTimeslot);
	};
	render() {
		const {
			visible,
			onCancel,
			timeslots,
			loadTimeslots,
			selectedTimeslot,
			selectedShoot,
			updateTimeslot
		} = this.props;

		return (
			<Modal
				centered
				bodyStyle={{
					height: '90vh',
					borderRadius: 0
				}}
				width={'60vw'}
				visible={visible}
				title={selectedShoot.key ? `${selectedShoot.name}'s Timeslots` : 'Timeslots'}
				footer={null}
				afterClose={() => {
					this.setState({ ...INITIAL_STATE });
				}}
				onCancel={() => {
					onCancel();
				}}
				onOk={() => {}}
			>
				<div className='single-step'>
					<Spin
						indicator={<Icon type='loading' style={{ fontSize: 24 }} spin />}
						spinning={updateTimeslot.loading as boolean}
					>
						{loadTimeslots.empty ? (
							<Empty
								image='https://gw.alipayobjects.com/mdn/miniapp_social/afts/img/A*pevERLJC9v0AAAAAAAAAAABjAQAAAQ/original'
								description={<span>No timeslots have been created yet.</span>}
							/>
						) : (
							<List
								locale={{ emptyText: 'No timeslots available.' }}
								className='small-list'
								itemLayout='horizontal'
								loading={{
									indicator: <Icon type='loading' style={{ fontSize: 36 }} spin />,
									spinning: loadTimeslots.loading as boolean
								}}
								dataSource={timeslots}
								renderItem={(timeslot: Timeslot) => (
									<List.Item
										style={{
											padding: '10px',
											borderRadius: '4px',
											backgroundColor:
												selectedTimeslot.key === timeslot.key
													? '#3CB371'
													: timeslot.teamName === 'Reserved'
														? '#3CB371'
														: timeslot.taken ? '#f08080' : undefined
										}}
										onClick={() => {
											if (!timeslot.taken) {
												this.props.onSetSelectedTimeslot(timeslot);
											}
										}}
										className={'list-item'}
									>
										<Skeleton avatar title={false} loading={(timeslot as any).loading} active>
											<List.Item.Meta
												title={
													<p>{`${getTimeHrsMin(timeslot.timestamp)}${timeslot.taken
														? ` - ${timeslot.teamName}`
														: ''}`}</p>
												}
											/>
											<div className='uk-flex uk-flex-center uk-flex-middle'>
												<span>
													<Tag color={timeslot.taken ? 'red' : 'green'} key={'active'}>
														{timeslot.taken ? 'Unavailable' : 'Available'}
													</Tag>
												</span>
											</div>
										</Skeleton>
									</List.Item>
								)}
							/>
						)}
					</Spin>

					<div className='uk-position-bottom uk-padding-small'>
						<Divider />
						<div className='uk-flex uk-flex-row'>
							<div className='uk-flex uk-flex-left'>
								<Button onClick={() => onCancel()}>Cancel</Button>
							</div>
							<div className='uk-flex uk-flex-right uk-width-expand'>
								<Button
									style={{ marginLeft: 8 }}
									type='primary'
									disabled={selectedTimeslot.key === ''}
									onClick={() => {
										this.bookTimeslot();
									}}
								>
									Save
								</Button>
							</div>
						</div>
					</div>
				</div>
			</Modal>
		);
	}
}
export default connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps)(SubjectsListModal);
