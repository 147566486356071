import React, { Component } from 'react';
import { connect } from 'react-redux';
import { User, UserMeta } from '../../models/UsersModel';
import { Timeslot, Shoot, Group } from '../../models/EventsModel';
import * as rTeambuilder from '../../reducers/teambuilder';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { TaskState, TeamPicsFile } from '../../models/Commons';
import PhoneInput from 'react-phone-number-input/basic-input';
import { getContactRoleOptions, getStaffPositions } from '../../constants/selectOptions';
import Animate from 'rc-animate';
import { Event } from '../../models/EventsModel';
import { Card, List, Skeleton, Tag, Spin, Icon } from 'antd';
import { getDate, getTimeHrsMin } from '../../helpers/time';
import { teambuilder as fTeambuilder, groups } from '../../firebase';
import { string } from 'prop-types';

interface OwnProps { }
interface StateProps {
	user: User;
	userMeta: UserMeta;
	event: Event;
	group: Group;
	shoot: Shoot;
	shoots: Array<Shoot>;
	timeslots: Array<Timeslot>;
	pageNumber: number;
	selectedTimeslot: Timeslot;
	loadTimeslots: TaskState;
}
interface DispatchProps {
	onSetSelectedShoot: Function;
	onSetSelectedTimeslot: Function;
}
type Props = StateProps & DispatchProps & OwnProps;
type State = {};

const mapStateToProps = (state: any) => ({
	user: state.usersState.user,
	userMeta: state.usersState.userMeta,
	group: state.teambuilderState.group,
	timeslots: state.teambuilderState.timeslots,
	pageNumber: state.teambuilderState.pageNumber,
	shoots: state.teambuilderState.shoots,
	event: state.teambuilderState.event,
	shoot: state.teambuilderState.shoot,
	selectedTimeslot: state.teambuilderState.timeslot,
	loadTimeslots: state.teambuilderState.loadTimeslots
});

const mapDispatchToProps = (dispatch: any) => ({
	onSetSelectedShoot: (shoot: Shoot, eventId: string) => {
		dispatch(rTeambuilder.setShoot(shoot));
		if (shoot.key) {
			dispatch(fTeambuilder.subscribeToTimeslots(eventId, shoot.key!));
		} else {
			dispatch(rTeambuilder.modifyTimeslotsArray('CLEAR', {} as Timeslot));
			fTeambuilder.unSubscribeFromTimeslots();
		}
	},
	onSetSelectedTimeslot: (timeslot: Timeslot) => {
		dispatch(rTeambuilder.setTimeslot(timeslot));
	}
});
const Div = (props: any) => {
	const childrenProps = { ...props };
	delete childrenProps.show;
	return <div {...childrenProps} />;
};
class StepTimeslotsComp extends Component<Props, State> {
	componentDidMount() { }

	render() {
		const { pageNumber, group, loadTimeslots, timeslots, shoots, event, shoot, selectedTimeslot } = this.props;

		return (
			<Animate transitionName='fade' transitionAppear>
				<Div style={{ height: '80%' }} show={pageNumber === 8}>
					<div style={{ height: '100%' }} className='uk-flex uk-flex-center uk-margin-top'>
						<Card
							style={{
								borderRadius: '5px',
								minWidth: '80vw',
								overflowY: 'auto'
							}}
						>
							<Spin
								className='uk-width-expand'
								indicator={<Icon type='loading' style={{ fontSize: 24 }} spin />}
								spinning={false}
							>
								<div className='uk-text-center'>
									<h2>Book your Photoshoot</h2>
									<p>Select a shoot and book a timeslot</p>
								</div>
								{shoot.key ? (
									<Animate transitionName='fade' transitionAppear>
										<Div style={{ height: '80%' }} show={shoot.key}>
											<div className='uk-margin-bottom'>
												<a
													onClick={() => {
														this.props.onSetSelectedShoot({ key: '' });
														this.props.onSetSelectedTimeslot({ key: '' });
													}}
												>
													{`< Select a different shoot`}
												</a>
											</div>
											<List
												locale={{ emptyText: 'No timeslots available.' }}
												className='small-list'
												itemLayout='horizontal'
												loading={{
													indicator: <Icon type='loading' style={{ fontSize: 36 }} spin />,
													spinning: loadTimeslots.loading as boolean
												}}
												dataSource={timeslots}
												renderItem={(timeslot: Timeslot) => (
													<List.Item
														className={'timeslot-item'}
														style={{
															padding: '10px',
															borderRadius: '4px',
															backgroundColor:
																selectedTimeslot.key === timeslot.key
																	? '#3CB371'
																	: timeslot.groupId === group.key &&
																		timeslot.groupId !== ''
																		? '#3CB371'
																		: timeslot.taken ? '#f08080' : undefined,
															cursor: !timeslot.taken ? 'pointer' : undefined
														}}
														onClick={() => {
															if (!timeslot.taken) {
																this.props.onSetSelectedTimeslot(timeslot);
															}
														}}
													>
														<Skeleton
															avatar
															title={false}
															loading={(timeslot as any).loading}
															active
														>
															<List.Item.Meta
																title={
																	<p>{`${getTimeHrsMin(
																		timeslot.timestamp
																	)}${timeslot.taken
																		? ` - ${timeslot.teamName}`
																		: ''}`}</p>
																}
															/>
															<div className='uk-flex uk-flex-center uk-flex-middle'>
																<span>
																	<Tag
																		color={
																			timeslot.groupId === group.key &&
																				timeslot.groupId !== '' ? (
																				'green'
																			) : timeslot.taken ? (
																				'red'
																			) : (
																				'green'
																			)
																		}
																		key={'active'}
																	>
																		{timeslot.groupId === group.key &&
																			timeslot.groupId !== '' ? (
																			'Yours'
																		) : timeslot.taken ? (
																			'Unavailable'
																		) : (
																			'Available'
																		)}
																	</Tag>
																</span>
															</div>
														</Skeleton>
													</List.Item>
												)}
											/>
										</Div>
									</Animate>
								) : (
									<div className='uk-flex uk-flex-center uk-width-expand'>
										<div className='uk-text-center uk-width-expand'>
											<Animate transitionName='fade' transitionAppear>
												<Div
													className='uk-width-expand'
													style={{ height: '80%' }}
													show={!shoot.key}
												>
													<List
														grid={{
															gutter: 16,
															xs: 1,
															sm: 2,
															md: 3,
															lg: 4,
															xl: 4,
															xxl: 4
														}}
														className='uk-width-expand'
														dataSource={shoots}
														renderItem={(shoot: Shoot) => (
															<List.Item>
																<Card
																	onClick={() => {
																		this.props.onSetSelectedShoot(shoot, event.key);
																	}}
																	className='list-item'
																>
																	<h4>{`${shoot.name}`}</h4>
																	<h4>{`${getDate(shoot.startTime)}`}</h4>
																</Card>
															</List.Item>
														)}
													/>
												</Div>
											</Animate>
										</div>
									</div>
								)}
							</Spin>
						</Card>
					</div>
				</Div>
			</Animate>
		);
	}
}

export default connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps)(StepTimeslotsComp);
