import React, { useState } from "react";
import { Button, message } from "antd";
import { useSelector } from "react-redux";
import { Event } from "../../models/EventsModel";
import { functions } from "../../firebase/firebaseConfig";

const onPopulateFriendlyIdsEvent = functions.httpsCallable(
  "onPopulateFriendlyIdsEvent"
);
const FriendlyIdButton = () => {
  const [loading, setLoading] = useState(false);
  const selectedEvent: Event = useSelector(
    (state: any) => state.eventsState.selectedEvent
  );
  const updateFriendlyIds = async () => {
    try {
      setLoading(true);
      message.loading("Updating Ids", 5);
      await onPopulateFriendlyIdsEvent({ eventId: selectedEvent.key });
      setLoading(false);
      message.destroy();
      message.success("Friendly Id's were updated");
    } catch (error) {
      console.log(error);
      setLoading(false);
      message.destroy();
      message.error("Something wrong, please try again");
    }
  };
  return (
    <div>
      <Button
        loading={loading}
        type="primary"
        onClick={updateFriendlyIds}
        disabled={!selectedEvent.key}
      >
        Update Friendly Ids
      </Button>
    </div>
  );
};

export default FriendlyIdButton;
