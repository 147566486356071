import { combineReducers } from 'redux';
import {
	createReducerWithTaskName,
	dispatchTaskWithState,
	createReducerForFirebaseArray,
	dispatchFirebaseArrayWithState
} from '../helpers/reducers';
import { INITIAL_STATE_EMPTY_TASK } from '../models/Commons';
import { Group, Subject } from '../models/EventsModel';

/**
 * INITIAL STATE
 */
const INITIAL_STATE_SELECTED_GROUP = {
	key: ''
};

/**
 * CONSTANTS
 */
const LOAD_GROUPS = 'load groups';
const LOAD_GROUP_DETAILS = 'load group details';
const LOAD_SUBJECTS = 'load subjects';
const UPDATE_GROUP = 'update group';
const SET_SELECTED_GROUP = 'set selected group';

const CLEAR_GROUPS_REDUCER = 'clear groups reducer';

/**
 * ACTIONS
 */
export const setSelectedGroup = (group: Group) => ({
	type: SET_SELECTED_GROUP,
	group
});

export const modifyLoadGroups = (taskState: string, value: string | boolean) => {
	return dispatchTaskWithState(LOAD_GROUPS, taskState, value);
};

export const modifyLoadGroupDetails = (taskState: string, value: string | boolean) => {
	return dispatchTaskWithState(LOAD_GROUP_DETAILS, taskState, value);
};

export const modifyLoadSubjects = (taskState: string, value: string | boolean) => {
	return dispatchTaskWithState(LOAD_SUBJECTS, taskState, value);
};

export const modifyUpdateEVent = (taskState: string, value: string | boolean) => {
	return dispatchTaskWithState(UPDATE_GROUP, taskState, value);
};

export const modifyGroupsArray = (arrayState: string, group: Group) => {
	return dispatchFirebaseArrayWithState(LOAD_GROUPS, arrayState, group);
};

export const modifySubjectsArray = (arrayState: string, subject: Subject) => {
	return dispatchFirebaseArrayWithState(LOAD_SUBJECTS, arrayState, subject);
};

export const clearGroupsReducer = () => ({
	type: CLEAR_GROUPS_REDUCER
});

/**
 * REDUCERS
 */
function selectedGroupReducer(state = INITIAL_STATE_SELECTED_GROUP, action: any) {
	switch (action.type) {
		case SET_SELECTED_GROUP: {
			return {
				...state,
				...action.group
			};
		}
		default:
			return state;
	}
}

const groupsRootReducer = combineReducers({
	selectedGroup: selectedGroupReducer,
	groups: createReducerForFirebaseArray(LOAD_GROUPS, []),
	subjects: createReducerForFirebaseArray(LOAD_SUBJECTS, []),
	loadSubjects: createReducerWithTaskName(LOAD_SUBJECTS, INITIAL_STATE_EMPTY_TASK),
	loadGroups: createReducerWithTaskName(LOAD_GROUPS, INITIAL_STATE_EMPTY_TASK),
	updateGroup: createReducerWithTaskName(UPDATE_GROUP, INITIAL_STATE_EMPTY_TASK),
	loadGroupDetails: createReducerWithTaskName(LOAD_GROUP_DETAILS, INITIAL_STATE_EMPTY_TASK)
});

const groupsReducer = (state: any, action: any) => {
	if (action.type === CLEAR_GROUPS_REDUCER) {
		state = {
			selectedClient: { ...INITIAL_STATE_SELECTED_GROUP },
			groups: [],
			subjects: [],
			loadSubjects: { ...INITIAL_STATE_EMPTY_TASK },
			loadGroups: { ...INITIAL_STATE_EMPTY_TASK },
			updateGroup: { ...INITIAL_STATE_EMPTY_TASK },
			loadGroupDetails: { ...INITIAL_STATE_EMPTY_TASK }
		};
	}

	return groupsRootReducer(state, action);
};

export default groupsReducer;
