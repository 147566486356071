import React, { Component } from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import {
  Table,
  Tag,
  Button,
  Switch,
  Steps,
  Divider,
  Empty,
  message,
  Icon,
} from "antd";
import { UserMeta, User } from "../../models/UsersModel";
import { TaskState, Season } from "../../models/Commons";

import * as rEvents from "../../reducers/events";
import { RouteComponentProps, withRouter } from "react-router";
import { getArrayItemByKey } from "../../helpers/arrays";
import NewEventModal from "./NewEventModal";
import { Event as mEvent } from "../../models/EventsModel";
import { getDate } from "../../helpers/time";
import { events as fEvents } from "../../firebase";
import emptyview from "../../assets/emptyview_events.png";

interface OwnProps extends RouteComponentProps {}

interface StateProps {
  user: User;
  userMeta: UserMeta;
  events: Array<mEvent>;
  loadEvents: TaskState;
  updateEvent: TaskState;
  exportEvent: TaskState;
  active: boolean;
}

interface DispatchProps {
  onResetUpdateEvent: Function;
  onSetSelectedEvent: Function;
  onExportEventGroups: Function;
  onExportEventSubjects: Function;
  onToggleActiveFilter: Function;
}

type Props = StateProps & DispatchProps & OwnProps;
type State = { visible: boolean };

const mapStateToProps = (state: any) => ({
  user: state.usersState.user,
  userMeta: state.usersState.userMeta,
  events: state.eventsState.events,
  active: state.eventsState.active,
  loadEvents: state.eventsState.loadEvents,
  updateEvent: state.eventsState.updateEvent,
  exportEvent: state.eventsState.exportEvent,
});

const mapDispatchToProps = (dispatch: any) => ({
  onResetUpdateEvent: () => {
    dispatch(rEvents.modifyUpdateEvent("LOADING", false));
  },
  onSetSelectedEvent: (event: mEvent) => {
    dispatch(rEvents.setSelectedEvent(event));
  },
  onExportEventGroups: (eventId: string, eventName: string) => {
    dispatch(fEvents.exportGroups(eventId, eventName));
  },
  onExportEventSubjects: (eventId: string, eventName: string) => {
    dispatch(fEvents.exportSubjects(eventId, eventName));
  },
  onToggleActiveFilter: (active: boolean) => {
    dispatch(rEvents.setActiveFilter(active));
  },
});

class EventsList extends Component<Props, State> {
  state = { visible: false };
  componentDidMount() {}
  componentWillReceiveProps(nextProps: Props) {
    if (
      nextProps.updateEvent.success &&
      !this.props.updateEvent.success &&
      !nextProps.updateEvent.loading
    ) {
      message.success(nextProps.updateEvent.success);
      this.setState({ visible: false });
    }

    if (nextProps.exportEvent.error && !this.props.exportEvent.error) {
      message.error(nextProps.exportEvent.error);
    }
    if (nextProps.exportEvent.loading) {
      message.loading("Fetching event data.");
    }
  }
  openModal = () => {
    this.props.onResetUpdateEvent();
    this.setState({ visible: true });
  };
  render() {
    const { user, userMeta, events, loadEvents } = this.props;
    const columns = [
      {
        title: "Logo",
        dataIndex: "leagueLogoUrl",
        className: "column-image",
        render: (logoUrl: string) => (
          <img className="col-image" src={logoUrl} />
        ),
      },
      {
        title: "Name",
        dataIndex: "name",
      },
      {
        title: "Type",
        dataIndex: "type",
      },
      {
        title: "Status",
        dataIndex: "active",
        render: (active: boolean) => (
          <span>
            <Tag color={active ? "green" : "red"} key={"active"}>
              {active ? "Active" : "Inactive"}
            </Tag>
          </span>
        ),
      },
      {
        title: "Action",
        dataIndex: "key",
        render: (key: string, event: mEvent, index: number) => (
          <div>
            {/* <a
							onClick={() => {
								this.props.onResetUpdateEvent();
								this.props.onSetSelectedEvent(getArrayItemByKey(key, events));
								this.props.history.push(`/events/${key}`);
							}}
						>
							Edit
						</a>
						<Divider type='vertical' /> */}
            <a
              onClick={() => {
                this.props.onExportEventGroups(event.key, event.name);
              }}
            >
              Export Groups
            </a>
            <Divider type="vertical" />
            <a
              onClick={() => {
                this.props.onExportEventSubjects(event.key, event.name);
              }}
            >
              Export Subjects
            </a>
          </div>
        ),
      },
    ];
    const header = (
      <div className="uk-flex uk-flex-row">
        <h2>Your Events</h2>
        <div className="uk-flex uk-flex-right uk-width-expand">
          <div className="uk-flex uk-flex-center" style={{ marginRight: 24 }}>
            <Switch
              checked={this.props.active}
              onChange={(e) => {
                this.props.onToggleActiveFilter(e);
              }}
              title="Active"
              checkedChildren="Active"
              unCheckedChildren="Archived"
            />
          </div>
          <Button
            onClick={() => {
              this.openModal();
            }}
            type="primary"
          >
            New
          </Button>
        </div>
      </div>
    );
    const empty = (
      <div className="uk-flex uk-flex-center uk-text-center uk-width-expand">
        <div className="uk-flex uk-flex-column uk-flex-center uk-width-large">
          <div className="uk-width-expand uk-flex uk-flex-center">
            <img src={emptyview} className="empty-view" />
          </div>
          <span className="uk-margin-top uk-margin-bottom">
            You haven't set up any events yet.
          </span>
          <Button
            onClick={() => {
              this.openModal();
            }}
            type="primary"
          >
            Set up your first Event
          </Button>
        </div>
      </div>
    );
    return (
      <div className="uk-width-expand">
        <Table
          locale={{ emptyText: loadEvents.empty && this.props.active && empty }}
          loading={{
            indicator: <Icon type="loading" style={{ fontSize: 36 }} spin />,
            spinning: (loadEvents.loading as boolean) && !loadEvents.empty,
          }}
          onRow={(event: mEvent, index: number) => {
            return {
              onClick: () => {
                this.props.onResetUpdateEvent();
                this.props.onSetSelectedEvent(
                  getArrayItemByKey(event.key!, events)
                );
                this.props.history.push(`/events/${event.key}`);
              }, // click row
            };
          }}
          className="table-row-clickable"
          columns={columns}
          dataSource={events}
          showHeader
          bordered
          title={() => header}
          pagination={{ pageSize: 10 }}
          scroll={{ x: "500px" }}
        />
        <NewEventModal
          visible={this.state.visible}
          onCancel={() => {
            this.setState({ visible: false });
          }}
        />
      </div>
    );
  }
}

export default compose<any, any>(
  withRouter,
  connect<StateProps, DispatchProps, OwnProps>(
    mapStateToProps,
    mapDispatchToProps
  )
)(EventsList);
