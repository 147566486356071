import React, { Component } from 'react';
import { connect } from 'react-redux';
import { User, UserMeta } from '../../models/UsersModel';
import { StepAuth, StepContact } from '../../models/TeamBuilderModel';
import { Card, Form, Input, Select } from 'antd';
import Animate from 'rc-animate';
import { Event } from '../../models/EventsModel';
import * as rTeambuilder from '../../reducers/teambuilder';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { TaskState, TeamPicsFile, TeamPicsLocation } from '../../models/Commons';
import PhoneInput from 'react-phone-number-input/basic-input';
import { getContactRoleOptions, getStaffPositions } from '../../constants/selectOptions';
import TeampicsPlaces from '../../components/TeampicsPlaces/TeampicsPlaces';
import { titleCase } from '../../helpers/string';

interface OwnProps {}
interface StateProps {
	user: User;
	userMeta: UserMeta;
	event: Event;
	stepAuth: StepAuth;
	stepContact: StepContact;
	pageNumber: number;
}
interface DispatchProps {
	onUpdateContactInfo: Function;
}
type Props = StateProps & DispatchProps & OwnProps;
type State = {};

const mapStateToProps = (state: any) => ({
	user: state.usersState.user,
	userMeta: state.usersState.userMeta,
	stepContact: state.teambuilderState.stepContact,
	stepAuth: state.teambuilderState.stepAuth,
	pageNumber: state.teambuilderState.pageNumber,
	event: state.teambuilderState.event
});
const mapDispatchToProps = (dispatch: any) => ({
	onUpdateContactInfo: (contactInfo: StepContact) => {
		dispatch(rTeambuilder.setStepContact(contactInfo));
	}
});
const Div = (props: any) => {
	const childrenProps = { ...props };
	delete childrenProps.show;
	return <div {...childrenProps} />;
};
const formItemLayout = {
	labelCol: {
		xs: { span: 24 },
		sm: { span: 8 },
		md: { span: 4 }
	},
	wrapperCol: {
		xs: { span: 24 },
		sm: { span: 16 }
	}
};
const tailFormItemLayout = {
	wrapperCol: {
		xs: {
			span: 24,
			offset: 0
		},
		sm: {
			span: 16,
			offset: 8
		},
		md: {
			span: 16,
			offset: 4
		}
	}
};
class StepContactInfoComp extends Component<Props, State> {
	componentDidMount() {}

	render() {
		const { pageNumber, event, stepAuth, stepContact } = this.props;

		return (
			<Animate transitionName='fade' transitionAppear>
				<Div style={{ height: '80%' }} show={pageNumber === 3}>
					<div style={{ height: '100%' }} className='uk-flex uk-flex-center uk-margin-top'>
						<Card
							style={{
								borderRadius: '5px',
								minWidth: '80vw',
								overflowY: 'auto'
							}}
						>
							<div className='uk-text-center'>
								<h2>Tell us about yourself</h2>
								<p>
									In the case that we need any information on your team, your orders, as well as
									shipping information please fill out your contact information.
								</p>
							</div>

							<Form>
								<Form.Item label='First Name' {...formItemLayout}>
									<Input
										placeholder='First Name'
										value={stepContact.firstName}
										onChange={(e) => {
											const newStepContact = {
												...stepContact,
												firstName: titleCase(e.target.value)
											};
											if (e.target.value.length <= 30) {
												this.props.onUpdateContactInfo(newStepContact);
											}
										}}
									/>
								</Form.Item>
								<Form.Item label='Last Name' {...formItemLayout}>
									<Input
										placeholder='Last Name'
										value={stepContact.lastName}
										onChange={(e) => {
											const newStepContact = {
												...stepContact,
												lastName: titleCase(e.target.value)
											};
											if (e.target.value.length <= 30) {
												this.props.onUpdateContactInfo(newStepContact);
											}
										}}
									/>
								</Form.Item>
								<Form.Item label='Address' {...formItemLayout}>
									<TeampicsPlaces
										setAddress={(address: string) => {
											const newStepContact = {
												...stepContact,
												address: address
											};
											this.props.onUpdateContactInfo(newStepContact);
										}}
										setLocation={(location: TeamPicsLocation) => {
											const newStepContact = {
												...stepContact,
												location: location
											};
											this.props.onUpdateContactInfo(newStepContact);
										}}
										searchOptions={[]}
										address={stepContact.address}
										disabled={false}
										placeholder='Search Location...'
									/>
								</Form.Item>
								<Form.Item label='Email' {...formItemLayout}>
									<Input
										placeholder='Email'
										value={stepContact.email}
										onChange={(e) => {
											const newStepContact = {
												...stepContact,
												email: e.target.value
											};
											this.props.onUpdateContactInfo(newStepContact);
										}}
									/>
								</Form.Item>
								<Form.Item label='Phone Number' {...formItemLayout}>
									<PhoneInput
										className='ant-input'
										country='CA'
										placeholder='Phone Number'
										value={stepContact.phoneNumber}
										onChange={(e: any) => {
											const newStepContact = {
												...stepContact,
												phoneNumber: e
											};
											this.props.onUpdateContactInfo(newStepContact);
										}}
									/>
								</Form.Item>
								<Form.Item label='Role' {...formItemLayout}>
									<Select
										placeholder={'Select your role..'}
										labelInValue
										value={stepContact.role ? { key: stepContact.role } : undefined}
										onChange={(e: any) => {
											const newStepContact = {
												...stepContact,
												role: e.key
											};
											this.props.onUpdateContactInfo(newStepContact);
										}}
									>
										{getStaffPositions()}
									</Select>
								</Form.Item>
								{stepContact.role === 'other' && (
									<Form.Item label='Specify Role' {...formItemLayout}>
										<Input
											placeholder='Please specify your role'
											value={stepContact.otherRole}
											onChange={(e) => {
												const newStepContact = {
													...stepContact,
													otherRole: e.target.value
												};
												if (e.target.value.length <= 30) {
													this.props.onUpdateContactInfo(newStepContact);
												}
											}}
										/>
									</Form.Item>
								)}

								{/* </Form.Item>
								<Form.Item label='Address' {...formItemLayout}>
									<Input
										placeholder='Address'
										value={stepContact.address}
										onChange={(e) => {
											const newStepContact = {
												...stepContact,
												address: e.target.value
											};
											this.props.onUpdateContactInfo(newStepContact);
										}}
									/>
								</Form.Item>
								<Form.Item label='Postal Code' {...formItemLayout}>
									<Input
										placeholder='Postal Code'
										value={stepContact.postalCode}
										onChange={(e) => {
											const newStepContact = {
												...stepContact,
												postalCode: e.target.value
											};
											this.props.onUpdateContactInfo(newStepContact);
										}}
									/>
								</Form.Item> */}
							</Form>
						</Card>
					</div>
				</Div>
			</Animate>
		);
	}
}

export default connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps)(StepContactInfoComp);
