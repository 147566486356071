import { auth, firestore, firebase, googleProvider, facebookProvider } from './firebaseConfig';
import { users, clients, products, purchases, photographers, events, groups, timeslots } from '../firebase';

import * as rRoot from '../reducers/index';
import { ThunkResult } from '../models/Commons';

//-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-//
//Firebase Authorization Functions
//-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-//

//Set auth listener that merges user data
export const onAuthUserListener = (next: Function, fallback: Function) =>
	auth.onAuthStateChanged((authUser) => {
		if (authUser) {
			next(authUser);
		} else {
			fallback();
		}
	});

// Sign In
export const doSignInWithEmailAndPasswordSession = (email: string, password: string) => {
	return auth
		.setPersistence(firebase.auth.Auth.Persistence.SESSION)
		.then(() => {
			return auth.signInWithEmailAndPassword(email, password);
		})
		.then((authUser) => {
			return authUser.user?.uid
		});
};

export const doSignInWithEmailAndPasswordLocal = (email: string, password: string) => {
	return auth
		.setPersistence(firebase.auth.Auth.Persistence.LOCAL)
		.then(() => {
			return auth.signInWithEmailAndPassword(email, password);
		})
		.then((authUser) => {
			return authUser.user?.uid
		});
};

// Sign out
export const doSignOut = (): ThunkResult<void> => {
	return (dispatch) => {
		onClearAllListeners();
		auth.signOut();
		dispatch(rRoot.userLogout());
	};
};

export const teambuilderSignout = (): ThunkResult<void> => {
	return (dispatch) => {
		auth.signOut();
	};
};

const onClearAllListeners = () => {
	users.unsubscribeFromUser();
	users.unsubscribeFromUserMeta();
	clients.unsubscribeClients();
	purchases.unSubscribeFromPurchases();
	events.unSubscribeFromEvents();
	products.unSubscribeProducts();
	products.unSubscribePacks();
	products.unSubscribeOfferings();
	photographers.unSubscribePhotographerRequests();
	groups.unSubscribeGroups();
	timeslots.unSubscribeFromTimeslots();
};

// Password Reset
export const doPasswordReset = (email: string) => auth.sendPasswordResetEmail(email);

// Password Change
export const doPasswordUpdate = (password: string) => auth.currentUser?.updatePassword(password);

// Fetch Sign In Methods
export const doFetchSignInMethods = (email: string) => {
	return auth.fetchSignInMethodsForEmail(email).then((signInMethods) => {
		return signInMethods;
	});
};

//Register with email and password
export const doRegisterWithEmailAndPassword = (
	email: string,
	password: string,
	firstName: string,
	lastName: string,
	isPhotographer: boolean,
	type?: "parent" | "subject"
) => {
	return auth.createUserWithEmailAndPassword(email, password).then((authUser) => {
		let userId = authUser.user?.uid;
		let batch = firestore.batch();

		let userRef = firestore.collection('users').doc(userId);
		let photographerRequestsRef = firestore.collection('photographerRequests').doc();

		const newUser: any = {
			email: email,
			firstName,
			lastName,
			address: '',
			city: '',
			company: '',
			logoUrl: '',
			phoneNumber: '',
			postal: '',
			website: ''
		}
		if (type) {
			newUser.type = type;
		}
		batch.set(userRef, newUser);

		if (isPhotographer) {
			batch.set(photographerRequestsRef, {
				userId,
				email,
				accepted: false,
				name: `${firstName} ${lastName}`,
				timestamp: new Date().getTime()
			});
		}

		return batch.commit().then(() => {
			return userId;
		});
	});
};

// Register with google
export const doRegisterWithGoogle = (type?: "parent" | "subject") => {
	return auth.signInWithPopup(googleProvider).then((result) => {
		if (!result.user) {
			return '';
		}
		var user = result.user;

		let userId = user.uid;
		let userRef = firestore.collection('users').doc(userId);

		const fullName = user?.displayName;
		const names = fullName?.split(' ');
		let lastName = '';
		let firstName = '';
		if (names && names.length > 1) {
			let firstName = names[0];
			lastName = names[names.length - 1];
		}

		if (user.metadata.creationTime === user.metadata.lastSignInTime) {
			const newUser: any = {
				email: user.email,
				firstName,
				lastName,
				address: '',
				city: '',
				company: '',
				logoUrl: '',
				phoneNumber: '',
				postal: '',
				website: ''
			}
			if (type) {
				newUser.type = type;
			}
			return userRef
				.set(newUser)
				.then(() => {
					return user.uid;
				});
		} else {
			return user.uid;
		}
	});
};

//Register with facebook
export const doRegisterWithFacebook = (type?: "parent" | "subject") => {
	return auth.signInWithPopup(facebookProvider).then((result) => {
		if (!result.user) {
			return '';
		}

		var user = result.user;

		let userId = user.uid;
		let userRef = firestore.collection('users').doc(userId);

		const fullName = user?.displayName;
		const names = fullName?.split(' ');
		let firstName = '';
		let lastName = '';
		if (names && names.length > 1) {
			firstName = names[0];
			lastName = names[names.length - 1];
		}

		if (user.metadata.creationTime === user.metadata.lastSignInTime) {
			const newUser: any = {
				googleId: 'empty',
				facebookId: 'empty',
				email: user.email,
				firstName,
				lastName,
				address: '',
				city: '',
				company: '',
				logoUrl: '',
				phoneNumber: '',
				postal: '',
				website: ''
			}
			if (type) {
				newUser.type = type;
			}
			return userRef
				.set(user)
				.then(() => {
					return user.uid;
				});
		} else {
			return user.uid;
		}
	});
};

//-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-//
//FIREBASE AUTH ERROR HANDLING
//-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-//
export const handleRegistrationError = (errorCode: string) => {
	switch (errorCode) {
		case 'auth/email-already-in-use':
			return 'This email is already in use by another account';
		case 'auth/invalid-email':
			return 'Please enter a valid email.';
		case 'auth/operation-not-allowed':
			return 'Something went wrong, please try again.';
		case 'auth/weak-password':
			return 'Passwords must contain at least 6 characters';
		default:
			return 'Something went wrong, please try again.';
	}
};

export const handleLoginError = (errorCode: string) => {
	switch (errorCode) {
		case 'auth/invalid-email':
			return 'Please enter a valid email.';
		case 'auth/user-disabled':
			return "This email's account has been disabled.";
		case 'auth/user-not-found':
			return 'This email is not associated with a Teampics account';
		case 'auth/wrong-password':
			return 'Incorrect Password';
		default:
			return 'Something went wrong, please try again.';
	}
};

export const handleForgotPasswordError = (errorCode: string) => {
	switch (errorCode) {
		case 'auth/invalid-email':
			return 'Please enter a valid email.';
		case 'auth/user-not-found':
			return 'This email is not associated with a Teampics account';
		default:
			return 'Something went wrong, please try again.';
	}
};

export const handleFacebookError = (errorCode: string, email: string) => {
	switch (errorCode) {
		case 'auth/account-exists-with-different-credential':
			const signInMethods: any = auth.fetchSignInMethodsForEmail(email);
			if (signInMethods[0] === 'password') {
				return 'This email is not already in use with an email and password account.';
			} else if (signInMethods[0] === 'google') {
				return 'This email is not already in use with a Google account.';
			}
			return 'This email is not already in use with an another account.';
		case 'auth/cancelled-popup-request':
			return '';
		case 'auth/operation-not-allowed':
			return 'Something went wrong, please try again.';
		case 'auth/popup-blocked':
			return 'Your browser has blocked the Facebook login, please enable pop-ups.';
		case 'auth/popup-closed-by-user':
			return '';
		default:
			return 'Something went wrong, please try again.';
	}
};

export const handleGoogleError = (errorCode: string, email: string) => {
	switch (errorCode) {
		case 'auth/account-exists-with-different-credential':
			const signInMethods: any = auth.fetchSignInMethodsForEmail(email);
			if (signInMethods[0] === 'password') {
				return 'This email is not already in use with an email and password account.';
			} else if (signInMethods[0] === 'facebook') {
				return 'This email is not already in use with a Facebook account.';
			}
			return 'This email is not already in use with an another account.';
		case 'auth/cancelled-popup-request':
			return '';
		case 'auth/operation-not-allowed':
			return 'Something went wrong, please try again.';
		case 'auth/popup-blocked':
			return 'Your browser has blocked the Google login, please enable pop-ups.';
		case 'auth/popup-closed-by-user':
			return '';
		default:
			return 'Something went wrong, please try again.';
	}
};
