import React, { useState } from "react";
import { Group, Subject, TeampicsImage } from "../../../models/EventsModel";
import { useDispatch, useSelector } from "react-redux";
import {
  Col,
  Row,
  Tabs,
  Button,
  Radio,
  Popconfirm,
  Table,
  message,
  Tooltip,
} from "antd";
import AssignImageModal from "./AssignImageModal";
import * as rImages from "../../../reducers/images";
import { firestore } from "../../../firebase/firebaseConfig";

const TabPane = Tabs.TabPane;
const { Column } = Table;

export const checkIfSubjectImageValid = (
  subject: Subject,
  images?: string[]
) => {
  if (checkIfMissing(subject.position) || checkIfDeleted(subject.position)) {
    if (!subject.primaryImageId && images && images.length <= 0) {
      return true;
    } else {
      return false;
    }
  } else {
    if (subject.primaryImageId && images && images.length > 0) {
      return true;
    } else {
      return false;
    }
  }
};
const checkIfMissing = (position?: string) => {
  if (position && position.charAt(0).toLowerCase() === "m") {
    return true;
  } else {
    return false;
  }
};
const checkIfDeleted = (position?: string) => {
  if (position && position.charAt(0).toLowerCase() === "x") {
    return true;
  } else {
    return false;
  }
};

const Subjects = () => {
  const dispatch = useDispatch();
  const groups: Group[] = useSelector((state: any) => state.imagesState.groups);
  const images: TeampicsImage[] = useSelector(
    (state: any) => state.imagesState.images
  );
  const subjects: Subject[] = useSelector(
    (state: any) => state.imagesState.subjects
  );
  const [selectedSubject, setSelectedSubject] = useState("");
  const [selectedGroup, setSelectedGroup] = useState(
    groups.length > 0 ? groups[0].key! : ""
  );
  const [visible, setVisible] = useState(false);
  const onTabChange = (e: string) => {
    setSelectedGroup(e);
  };
  const onToggleValid = (subject: Subject) => async (e: any) => {
    try {
      const updatedSubject: Subject = {
        ...subject,
        hasValidImage: !subject.hasValidImage,
      };
      dispatch(rImages.modifyImagesSubjectsArray("UPDATE", updatedSubject));
      await firestore
        .collection("subjects")
        .doc(subject.key)
        .update({
          hasValidImage: !subject.hasValidImage,
        } as Subject);
    } catch (error) {
      console.log(
        "🚀 ~ file: Subjects.tsx ~ line 88 ~ onPrimaryUpdated ~ error",
        error
      );
      message.error("Something went wrong, please try again");
    }
  };
  const onPrimaryUpdated = (subject: Subject) => async (e: any) => {
    try {
      const updatedSubject: Subject = {
        ...subject,
        primaryImageId:
          subject.primaryImageId === e.target.value ? "" : e.target.value,
      };
      if (checkIfSubjectImageValid(updatedSubject, updatedSubject.imageIds)) {
        updatedSubject.hasValidImage = true;
      } else {
        updatedSubject.hasValidImage = false;
      }
      dispatch(rImages.modifyImagesSubjectsArray("UPDATE", updatedSubject));
      await firestore
        .collection("subjects")
        .doc(subject.key)
        .update({
          primaryImageId: updatedSubject.primaryImageId,
          hasValidImage: updatedSubject.hasValidImage,
        } as Subject);
    } catch (error) {
      console.log(
        "🚀 ~ file: Subjects.tsx ~ line 88 ~ onPrimaryUpdated ~ error",
        error
      );
      message.error("Something went wrong, please try again");
    }
  };
  const onPrimaryUpdatedClick = (subject: Subject) => async (e: any) => {
    try {
      if (subject.primaryImageId) {
        const updatedSubject: Subject = {
          ...subject,
          primaryImageId: "",
          hasValidImage: false,
        };
        dispatch(rImages.modifyImagesSubjectsArray("UPDATE", updatedSubject));
        await firestore.collection("subjects").doc(subject.key).update({
          primaryImageId: "",
          hasValidImage: false,
        });
      }
    } catch (error) {
      console.log(
        "🚀 ~ file: Subjects.tsx ~ line 88 ~ onPrimaryUpdated ~ error",
        error
      );
      message.error("Something went wrong, please try again");
    }
  };
  const onRemoveImage =
    (subject: Subject, image: TeampicsImage) => async () => {
      const batch = firestore.batch();
      try {
        const updatedSubject: Subject = {
          ...subject,
          imageIds: subject.imageIds
            ? subject.imageIds.slice().filter((id) => id !== image.imageId)
            : [],
        };
        if (subject.primaryImageId === image.imageId) {
          updatedSubject.primaryImageId = "";
          updatedSubject.hasValidImage = false;
        }
        batch.update(
          firestore.collection("subjects").doc(subject.key),
          updatedSubject
        );
        dispatch(rImages.modifyImagesSubjectsArray("UPDATE", updatedSubject));
        const updatedImage: TeampicsImage = {
          ...image,
          hasSubject: false,
          subjects: image.subjects.slice().filter((id) => id !== subject.key),
        };
        batch.update(
          firestore.collection("uploadedImages").doc(image.imageId),
          updatedImage
        );
        dispatch(rImages.modifyImagesArray("UPDATE", updatedImage));
        await batch.commit();
      } catch (error) {
        console.log(
          "🚀 ~ file: Subjects.tsx ~ line 130 ~ onRemoveImage ~ error",
          error
        );
        message.error("Something went wrong, please try again");
      }
    };
  const renderImages = (group: Group) => {
    const subjectsToUse: Subject[] = subjects
      .slice()
      .filter((subject) => subject.groupId === group.key);
    return (
      <Table
        locale={{ emptyText: "No Images available." }}
        rowKey={(e) => e.key!}
        dataSource={subjectsToUse}
        pagination={false}
        bordered={true}
      >
        <Column
          title="Valid"
          dataIndex="primaryImageId"
          key="valid"
          align="center"
          width={75}
          render={(primaryImageId: string, subject: Subject) => {
            return (
              <Tooltip title={subject.hasValidImage ? "Valid" : "Missing"}>
                <Button
                  type="primary"
                  icon={subject.hasValidImage ? "check" : "close"}
                  size="small"
                  style={{
                    backgroundColor: subject.hasValidImage
                      ? "#30CE72"
                      : "#FC0202",
                    border: 0,
                  }}
                  onClick={onToggleValid(subject)}
                />
              </Tooltip>
            );
          }}
        />
        <Column
          title="Attach Image"
          dataIndex="primaryImageId"
          key="attachSubject"
          align="center"
          width={100}
          render={(primaryImageId: string, subject: Subject) => (
            <Button
              type="primary"
              shape="circle"
              icon={"plus"}
              onClick={onOpenManualModal(subject.key!)}
            />
          )}
        />
        <Column
          title="Status"
          dataIndex="position"
          key="status"
          align="center"
          width={100}
          render={(primaryImageId: string, subject: Subject) => (
            <div className="uk-flex uk-flex-middle uk-flex-center">
              <Tooltip title="Deleted">
                <Button
                  type="primary"
                  shape="circle"
                  icon={"close"}
                  disabled={!checkIfDeleted(subject.position)}
                  style={{
                    marginRight: 12,
                    backgroundColor: checkIfDeleted(subject.position)
                      ? "#cc3300"
                      : undefined,
                    border: 0,
                  }}
                />
              </Tooltip>
              <Tooltip title="Missing">
                <Button
                  type="primary"
                  shape="circle"
                  disabled={!checkIfMissing(subject.position)}
                  style={{
                    fontWeight: "bold",
                    backgroundColor: checkIfMissing(subject.position)
                      ? "#ff9966"
                      : undefined,
                    border: 0,
                  }}
                >
                  M
                </Button>
              </Tooltip>
            </div>
          )}
        />
        <Column
          title="Subject Name"
          dataIndex="firstName"
          key="firstName"
          align="center"
          render={(firstName: string, subject: Subject) => (
            <p>{`${subject.firstName} ${subject.lastName}`}</p>
          )}
        />
        <Column
          title="Primary"
          dataIndex="primaryImageId"
          key="primaryImageId"
          align="center"
          width={100}
          render={(primaryImageId: string, subject: Subject) => {
            const imagesToUse = images
              .slice()
              .filter(
                (image) =>
                  image.hasSubject && image.subjects.includes(subject.key!)
              );
            return (
              <Row>
                <Radio.Group value={subject.primaryImageId}>
                  {imagesToUse.map((image) => {
                    return (
                      <Col
                        key={image.key}
                        span={24}
                        style={{ height: 20, marginBottom: 8 }}
                      >
                        <Radio
                          className={
                            subject.hasValidImage
                              ? "radio-green-highlight"
                              : "radio-error-highlight"
                          }
                          onChange={onPrimaryUpdated(subject)}
                          onClick={onPrimaryUpdatedClick(subject)}
                          value={image.key!}
                        />
                      </Col>
                    );
                  })}
                </Radio.Group>
              </Row>
            );
          }}
        />
        <Column
          title="File Name"
          dataIndex="filename"
          key="filename"
          render={(primaryImageId: string, subject: Subject) => {
            const imagesToUse = images
              .slice()
              .filter(
                (image) =>
                  image.hasSubject && image.subjects.includes(subject.key!)
              );
            return (
              <Row>
                {imagesToUse.map((image) => {
                  return (
                    <Col
                      key={image.key}
                      span={24}
                      style={{
                        height: 20,
                        marginBottom: 8,
                        display: "flex",
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                      }}
                    >
                      <p
                        style={{
                          color: subject.hasValidImage ? "unset" : "#FC0202",
                          fontSize: 16,
                          lineHeight: "20px",
                          marginBottom: 0,
                        }}
                      >
                        {image.filename}
                      </p>
                    </Col>
                  );
                })}
              </Row>
            );
          }}
        />
        <Column
          title="Unmatch"
          dataIndex="primaryImageId"
          key="delete"
          align="center"
          width={100}
          render={(primaryImageId: string, subject: Subject) => {
            const imagesToUse = images
              .slice()
              .filter(
                (image) =>
                  image.hasSubject && image.subjects.includes(subject.key!)
              );
            return (
              <Row>
                {imagesToUse.map((image) => {
                  return (
                    <Col key={image.key} span={24} style={{ marginBottom: 4 }}>
                      <Popconfirm
                        title={`Are you sure to remove ${image.filename} from this group?`}
                        onConfirm={onRemoveImage(subject, image)}
                        okText="Yes"
                        cancelText="No"
                        placement="left"
                      >
                        <Button
                          shape="circle"
                          icon={"minus"}
                          type="danger"
                          size="small"
                        />
                      </Popconfirm>
                    </Col>
                  );
                })}
              </Row>
            );
          }}
        />
      </Table>
    );
  };
  const onOpenManualModal = (subjectId: string) => () => {
    setSelectedSubject(subjectId);
    setVisible(true);
  };
  const onCloseManualModal = () => {
    setSelectedSubject("");
    setVisible(false);
  };
  return (
    <div>
      <AssignImageModal
        visible={visible}
        onClose={onCloseManualModal}
        groupId={""}
        subjectId={selectedSubject}
      />
      {images.length === 0 && (
        <div className="uk-flex uk-flex-middle uk-flex-center">
          <p>No Images available.</p>
        </div>
      )}
      {images.length !== 0 && (
        <Tabs
          defaultActiveKey={groups.length > 0 ? groups[0].key! : ""}
          tabPosition={"left"}
          size="small"
          onChange={onTabChange}
          activeKey={selectedGroup}
        >
          {groups.map((group, index) => {
            return (
              <TabPane tab={group.fullName} key={group.key}>
                <div>{renderImages(group)}</div>
              </TabPane>
            );
          })}
        </Tabs>
      )}
    </div>
  );
};

export default Subjects;
