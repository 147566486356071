import React, { Component } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { User, UserMeta } from '../../models/UsersModel';
import { Table, Row, Col, message, Modal, Card } from 'antd';
import { Package } from '../../models/ProductsModel';
import { purchases as fPurchases } from '../../firebase';

import { Purchase, PurchaseProduct } from '../../models/PurchaseModel';
import Column from 'antd/lib/table/Column';
import { getDate } from '../../helpers/time';

interface OwnProps {
	visible: boolean;
	onCancel: Function;
}
interface StateProps {
	user: User;
	userMeta: UserMeta;
	selectedPurchase: Purchase;
}
interface DispatchProps {}
type Props = StateProps & DispatchProps & OwnProps;
type State = {};
const INITIAL_STATE = {};
const mapStateToProps = (state: any) => ({
	user: state.usersState.user,
	userMeta: state.usersState.userMeta,
	selectedPurchase: state.purchasesState.selectedPurchase
});

const mapDispatchToProps = (dispatch: any) => ({});

class PurchaseDetailModal extends Component<Props, State> {
	state = { ...INITIAL_STATE };
	componentDidMount() {}
	acceptPayment = () => {
		fPurchases
			.acceptPayment(this.props.selectedPurchase.key!, this.props.user.userId!)
			.then(() => {
				this.props.onCancel();
				message.success(`Payment status was updated to 'Paid'`);
			})
			.catch((error) => {
				message.success(`Something went wrong, please try again.`);
			});
	};
	render() {
		const { user, userMeta, visible, onCancel, selectedPurchase } = this.props;
		const { customerInfo, subject } = selectedPurchase;
		const expandedRowRender = (products: Array<PurchaseProduct>) => {
			return (
				<Table rowKey={(e) => e.productId!} dataSource={products} size='small' pagination={false}>
					<Column
						title='Product Name'
						dataIndex='productName'
						key='productName'
						render={(productName: string, pack: Package, index: number) => <span>{productName}</span>}
					/>
					<Column
						title='Product Id'
						dataIndex='productId'
						key='productId'
						render={(productId: string, pack: Package, index: number) => <span>{productId}</span>}
					/>
					<Column
						title='SKU'
						dataIndex='sku'
						key='sku'
						render={(sku: string, pack: Package, index: number) => <span>{sku}</span>}
					/>
					<Column
						title='Extras'
						dataIndex='extras'
						key='extras'
						render={(extras: any, pack: Package, index: number) => (
							<span>
								{Object.keys(extras).map((key, index) => {
									if (Object.keys(extras).length === 1) {
										return `${key}: ${extras[key]}`;
									} else if (index !== Object.keys(extras).length - 1) {
										return `${key}: ${extras[key]}, `;
									} else {
										return `${key}: ${extras[key]}`;
									}
								})}
							</span>
						)}
					/>
				</Table>
			);
		};
		return (
			<Modal
				centered
				// bodyStyle={{ height: '90vh', borderRadius: 0 }}
				width={'90vw'}
				visible={visible}
				title={selectedPurchase.key ? `${selectedPurchase.key} - Purchase Details` : 'Purchase Detail'}
				okText='Create'
				footer={null}
				afterClose={() => {
					this.setState({ ...INITIAL_STATE });
				}}
				onCancel={() => {
					onCancel();
				}}
				onOk={() => {}}
			>
				{selectedPurchase.key && (
					<div style={{ padding: '15px' }} className='single-step-no-footer uk-margin-bottom'>
						<Row gutter={{ sm: 8, md: 16 }}>
							<Col sm={24} md={8}>
								<Card title='Order Details'>
									<Row>
										<Col span={24}>
											<p>{`Order #: ${selectedPurchase.key}`}</p>
										</Col>
										<Col span={24}>
											<p>{`Square Id: ${selectedPurchase.transactionId}`}</p>
										</Col>
										<Col span={24}>
											<p>{`Purchase Amount: $${Math.round(selectedPurchase.totalCost) / 100}`}</p>
										</Col>
										<Col span={24}>
											<p>{`Purchase Date: ${getDate(selectedPurchase.timestamp)}`}</p>
										</Col>
									</Row>
								</Card>
							</Col>
							<Col sm={24} md={8}>
								<Card title='CustomerDetails'>
									<Col span={24}>
										<p>{`Name: ${customerInfo.firstName} ${customerInfo.lastName}`}</p>
									</Col>
									<Col span={24}>
										<p>{`Id: ${customerInfo.squareCustomerId}`}</p>
									</Col>
									<Col span={24}>
										<p>{`Email: ${customerInfo.email}`}</p>
									</Col>
									<Col span={24}>
										<p>{`Phone Number: ${customerInfo.phoneNumber}`}</p>
									</Col>
								</Card>
							</Col>
							<Col sm={24} md={8}>
								<Card title='Subject Details'>
									<Col span={24}>
										<p>{`Name: ${subject.fullName}`}</p>
									</Col>
									<Col span={24}>
										<p>{`Division: ${subject.teamName}`}</p>
									</Col>
									<Col span={24}>
										<p>{`Event ID: ${subject.eventId}`}</p>
									</Col>
									<Col span={24}>
										<p>{`Group Id: ${subject.groupId}`}</p>
									</Col>
								</Card>
							</Col>
						</Row>
						<Row className='uk-margin-top' gutter={{ sm: 8, md: 16 }}>
							<Col sm={24} md={12}>
								<Card title='Billing Address'>
									<Col span={24}>
										<p>{`Address Line 1: ${customerInfo.billingAddress.addressLine1}`}</p>
									</Col>
									<Col span={24}>
										<p>{`Address Line 2: ${customerInfo.billingAddress.addressLine2}`}</p>
									</Col>
									<Col span={24}>
										<p>{`Postal Code: ${customerInfo.billingAddress.postalCode}`}</p>
									</Col>
									<Col span={24}>
										<p>{`Province: ${customerInfo.billingAddress.province}`}</p>
									</Col>
									<Col span={24}>
										<p>{`City: ${customerInfo.billingAddress.city}`}</p>
									</Col>
									<Col span={24}>
										<p>{`Country: ${customerInfo.billingAddress.country}`}</p>
									</Col>
								</Card>
							</Col>
							<Col sm={24} md={12}>
								<Card title='Shipping Address'>
									<Col span={24}>
										<p>{`Address Line 1: ${customerInfo.shippingAddress.addressLine1}`}</p>
									</Col>
									<Col span={24}>
										<p>{`Address Line 2: ${customerInfo.shippingAddress.addressLine2}`}</p>
									</Col>
									<Col span={24}>
										<p>{`Postal Code: ${customerInfo.shippingAddress.postalCode}`}</p>
									</Col>
									<Col span={24}>
										<p>{`Province: ${customerInfo.shippingAddress.province}`}</p>
									</Col>
									<Col span={24}>
										<p>{`City: ${customerInfo.shippingAddress.city}`}</p>
									</Col>
									<Col span={24}>
										<p>{`Country: ${customerInfo.shippingAddress.country}`}</p>
									</Col>
								</Card>
							</Col>
						</Row>
						<Row className='uk-margin-top'>
							<Table
								rowKey={(e) => e.packageId!}
								dataSource={selectedPurchase.packages}
								showHeader
								bordered
								title={() => <h2>Packages</h2>}
								pagination={{ pageSize: 10 }}
								expandedRowRender={(pack, index, indent, expanded) => expandedRowRender(pack.products)}
							>
								<Column
									title='Package Name'
									dataIndex='packageName'
									key='packageName'
									render={(packageName: string, pack: Package, index: number) => (
										<span>{packageName}</span>
									)}
								/>
								<Column
									title='Package Id'
									dataIndex='packageId'
									key='packageId'
									render={(packageId: string, pack: Package, index: number) => (
										<span>{packageId}</span>
									)}
								/>
								<Column
									title='SKU'
									dataIndex='sku'
									key='sku'
									render={(sku: string, pack: Package, index: number) => <span>{sku}</span>}
								/>
								<Column
									title='Price'
									dataIndex='price'
									key='price'
									render={(price: number, pack: Package, index: number) => (
										<span>{`$${price / 100}`}</span>
									)}
								/>
								<Column
									title='Count'
									dataIndex='count'
									key='count'
									render={(count: number, pack: Package, index: number) => <span>{count}</span>}
								/>
							</Table>
						</Row>
					</div>
				)}
			</Modal>
		);
	}
}

export default compose<any, any>(connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps))(
	PurchaseDetailModal
);

// extra={
// 	<div>
// 		{selectedPurchase.paid ? (
// 			<p style={{ color: 'green', margin: 0 }}>Paid</p>
// 		) : (
// 			<Popconfirm
// 				title={'Change payment status to paid?'}
// 				onConfirm={() => {
// 					this.acceptPayment();
// 				}}
// 				okText='Yes'
// 				cancelText='No'
// 			>
// 				<Button type='primary'>Paid?</Button>
// 			</Popconfirm>
// 		)}
// 	</div>
// }
