import React, { Component } from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import {
  Modal,
  Steps,
  Button,
  Divider,
  Form,
  Input,
  Select,
  Radio,
  Upload,
  Icon,
  Table,
  Spin,
  Alert,
  message,
  Empty,
  InputNumber,
  DatePicker,
  TimePicker,
} from "antd";
import ColorPicker from "../../components/ColorPicker/ColorPicker";
import TeampicsPlaces from "../../components/TeampicsPlaces/TeampicsPlaces";
import {
  TeamPicsLocation,
  Season,
  TeamPicsFile,
  TaskState,
  Contact,
} from "../../models/Commons";
import { User } from "../../models/UsersModel";
import PhoneInput from "react-phone-number-input/basic-input";
import {
  getClientOptionsForEvent,
  getOfferingOptionsForEvent,
  getEventTypeOptions,
  getDivisionTypeOptions,
  getContactOptionsForEvent,
  getPackageOptionsForEvent,
} from "../../constants/selectOptions";
import {
  dummyRequest,
  getFileType,
  checkFileSize,
  getBase64,
  getMimeType,
} from "../../constants/fileTypes";
import { RcFile } from "antd/lib/upload/interface";
import { events as fEvents, clients as fClients } from "../../firebase";
import {
  Shoot,
  Event,
  DivisionTypes,
  EventTypes,
} from "../../models/EventsModel";

import { Client } from "../../models/ClientsModel";
import { Offering, Package } from "../../models/ProductsModel";
import { RouteComponentProps, withRouter } from "react-router";
import * as routeNames from "../../constants/routeNames";
import { getArrayItemByKey } from "../../helpers/arrays";
import moment from "moment";
import shortid from "shortid";
import emptyview from "../../assets/emptyview_clients.png";
import LocationNotesModal from "./LocationNotesModal";
import { titleCase } from "../../helpers/string";
const Step = Steps.Step;
const { TextArea } = Input;
const { Column } = Table;
const { Option } = Select;

interface OwnProps extends RouteComponentProps {
  visible: boolean;
  onCancel: Function;
}

interface StateProps {
  updateEvent: TaskState;
  user: User;
  clients: Array<Client>;
  offerings: Array<Offering>;
  packages: Array<Package>;
}

interface DispatchProps {
  onNewEvent: Function;
}

type Props = StateProps & DispatchProps & OwnProps;
type State = {
  active: boolean;
  name: string;
  selectedClient: string;
  groupsExpected: number;
  priColor: string;
  secColor: string;
  divisionType: DivisionTypes;
  type: EventTypes;
  selectedContact: string;
  contacts: Array<Contact>;
  selectedOffering: string;
  shoots: Array<Shoot>;
  logoFile: TeamPicsFile;
  leagueLogoUrl: string;
  kioskPassword: string;
  pageNumber: number;
  error: string;
  loading: boolean;
  locationNotesVisible: boolean;
  shootIndex: number;
  leagueBuySubjectsId: string;
  leagueBuyMissingSubjectsId: string;
  leagueBuyStaffId: string;
};
const INITIAL_STATE_EMPTY_SHOOT = {
  key: "shoot 0",
  photographerId: "",
  eventId: "",
  name: "",
  startTime: new Date(new Date().setHours(0, 0, 0, 0)).getTime(),
  endTime: new Date(new Date().setHours(0, 0, 0, 0)).getTime(),
  intervalMins: 10,
  address: "",
  location: "" as TeamPicsLocation,
  locationNotes: "",
};
const INITIAL_STATE = {
  active: true,
  name: "",
  selectedClient: "",
  groupsExpected: 0,
  priColor: "#000000",
  secColor: "#000000",
  divisionType: "" as DivisionTypes,
  type: "" as EventTypes,
  contacts: [] as Array<Contact>,
  selectedContact: "",
  selectedOffering: "",
  shoots: [
    {
      ...INITIAL_STATE_EMPTY_SHOOT,
    },
  ],
  logoFile: { blob: "" } as TeamPicsFile,
  leagueLogoUrl: "",
  kioskPassword: "",
  pageNumber: 0,
  error: "",
  loading: false,
  locationNotesVisible: false,
  shootIndex: 0,
  leagueBuySubjectsId: "",
  leagueBuyMissingSubjectsId: "",
  leagueBuyStaffId: "",
};
const mapStateToProps = (state: any) => ({
  user: state.usersState.user,
  updateEvent: state.eventsState.updateEvent,
  clients: state.clientsState.clients,
  offerings: state.productsState.offerings,
  packages: state.productsState.packages,
});
const mapDispatchToProps = (dispatch: any) => ({
  onNewEvent: (
    uid: string,
    event: Event,
    shoots: Array<Shoot>,
    logoFile: TeamPicsFile
  ) => {
    dispatch(fEvents.newEvent(event, shoots, uid, logoFile));
  },
});
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
    md: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};
const formColorLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
    md: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 6 },
  },
};
const steps = [
  {
    title: "General",
  },
  {
    title: "Contact",
  },
  {
    title: "Logo",
  },
  {
    title: "Shoots",
  },
];
class NewEventModal extends Component<Props, State> {
  state = { ...INITIAL_STATE };
  componentDidMount() {}
  componentWillReceiveProps(nextProps: Props) {
    if (nextProps.updateEvent.error && !this.props.updateEvent.error) {
      message.error(nextProps.updateEvent.error);
    }
  }
  next() {
    const pageNumber = this.state.pageNumber + 1;
    this.setState({ pageNumber });
  }
  prev() {
    const pageNumber = this.state.pageNumber - 1;
    this.setState({ pageNumber });
  }
  cancel() {
    this.setState({ ...INITIAL_STATE });
    this.props.onCancel();
  }
  handleUploadChange = (file: any) => {};
  beforeUpload = (
    file: RcFile,
    FileList: RcFile[]
  ): boolean | PromiseLike<any> => {
    const fileType = getFileType(file.name);
    if (fileType !== "jpeg" && fileType !== "jpg" && fileType !== "png") {
      this.setState({ error: "Please upload a jpeg or png!" });

      message.error("Please upload a jpeg or png!");
      return false;
    }

    if (!checkFileSize(file.size, 2)) {
      this.setState({ error: "Max file size is 2MB" });

      message.error("Max file size is 2MB");
      return false;
    }

    getBase64(file, (imageUrl: string) =>
      this.setState({
        leagueLogoUrl: imageUrl,
        logoFile: {
          fileType: fileType,
          mimeType: getMimeType(fileType),
          blob: imageUrl,
          fileSize: file.size,
        },
      })
    );
    return true;
  };
  calculateNumberTimeslots = (
    startTime: number,
    endTime: number,
    interval: number
  ) => {
    const diff = endTime - startTime;
    let intervalMS = 60000 * interval;
    let numberOfShoots = diff / intervalMS;
    numberOfShoots = Math.round(numberOfShoots);

    return numberOfShoots;
  };
  addShoot = () => {
    const { shoots } = this.state;
    const newShoots: Array<Shoot> = [
      ...shoots,
      {
        ...INITIAL_STATE_EMPTY_SHOOT,
        key: `shoot ${this.state.shoots.length}`,
      },
    ];
    this.setState({ shoots: newShoots });
  };
  removeShoot = (index: number) => {
    const { shoots } = this.state;
    const newShoots = shoots.filter((shoot, newIndex) => newIndex !== index);
    this.setState({ shoots: newShoots });
  };
  updateShoot = (newShoot: Shoot, index: number) => {
    const newShoots = this.state.shoots.map((oldShoot, oldIndex) => {
      if (index !== oldIndex) {
        // This isn't the item we care about - keep it as-is
        return oldShoot;
      }
      // Otherwise, this is the one we want - return an updated value
      return {
        ...oldShoot,
        ...newShoot,
      };
    });
    this.setState({ shoots: newShoots });
  };
  loadContacts = () => {
    this.setState({ loading: true, error: "" });
    fClients
      .getContactsForEvent(this.props.user.userId!, this.state.selectedClient)
      .then((contacts) => {
        this.setState({ contacts, loading: false, error: "" });
        this.next();
      })
      .catch((error) => {
        message.error("Something went wrong, please try again");
        this.setState({
          loading: false,
          error: "Something went wrong, please try again",
        });
      });
  };
  newEvent = () => {
    const {
      active,
      name,
      selectedClient,
      selectedOffering,
      groupsExpected,
      priColor,
      secColor,
      divisionType,
      type,
      contacts,
      selectedContact,
      shoots,
      logoFile,
      leagueLogoUrl,
      kioskPassword,
      leagueBuyMissingSubjectsId,
      leagueBuySubjectsId,
      leagueBuyStaffId,
      pageNumber,
      loading,
      error,
    } = this.state;
    this.setState({ error: "" });

    let shootError = "";
    shoots.some((shoot, index) => {
      const startTime = shoot.startTime;
      const endTime = shoot.endTime;
      const interval = shoot.intervalMins;

      if (shoot.name === "") {
        shootError = `Shoot ${index + 1}: Please enter a name for the shoot.`;
        return !!shootError;
      } else if (shoot.address === "") {
        shootError = `Shoot ${
          index + 1
        }: Please enter a location for the shoot.`;
        return !!shootError;
      } else if (shoot.intervalMins < 0 || shoot.intervalMins > 999) {
        shootError = `Shoot ${
          index + 1
        }: Interval time must be between 0 and 999 mins`;
        return !!shootError;
      } else if (startTime === endTime) {
        shootError = `Shoot ${
          index + 1
        }: Start time can't be same as end time.`;
        return !!shootError;
      } else if (startTime > endTime) {
        shootError = `Shoot ${index + 1}: Start time can't be after end time.`;
        return !!shootError;
      } else {
        const diff = endTime - startTime;
        let intervalMS = 60000 * interval;

        if (intervalMS > diff) {
          shootError = `Shoot ${
            index + 1
          }: The interval does not fit in between the start and end time.`;
          return !!shootError;
        }
      }
      shoot.photographerId = this.props.user.userId!;
      return false;
    });
    if (shootError) {
      message.error(shootError);
      return;
    }

    const newEvent: Event = {
      photographerId: this.props.user.userId!,
      active,
      name,
      groupsExpected: groupsExpected,
      offeringId: selectedOffering,
      priColor,
      secColor,
      type,
      divisionType,
      contact: getArrayItemByKey(selectedContact, contacts),
      clientId: selectedClient,
      clientName: (
        getArrayItemByKey(selectedClient, this.props.clients) as Client
      ).name,
      kioskPassword,
      timestamp: new Date().getTime(),
      leagueLogoUrl: leagueLogoUrl,
      friendlyId: "",
      leagueBuyPackageIds: {
        subjects: leagueBuySubjectsId,
        staff: leagueBuyStaffId,
        missingSubjects: leagueBuyMissingSubjectsId,
      },
    };

    this.props.onNewEvent(this.props.user.userId, newEvent, shoots, logoFile);
  };

  render() {
    const { visible, onCancel } = this.props;
    const {
      active,
      name,
      selectedClient,
      selectedOffering,
      groupsExpected,
      priColor,
      secColor,
      divisionType,
      type,
      contacts,
      selectedContact,
      shoots,
      logoFile,
      leagueLogoUrl,
      kioskPassword,
      pageNumber,
      locationNotesVisible,
      leagueBuyMissingSubjectsId,
      leagueBuyStaffId,
      leagueBuySubjectsId,
      shootIndex,
      loading,
      error,
    } = this.state;
    const { updateEvent, offerings, packages, clients } = this.props;
    const generalIsInvalid =
      name === "" ||
      groupsExpected === 0 ||
      selectedClient === "" ||
      priColor === "" ||
      secColor === "" ||
      type === "" ||
      divisionType === "" ||
      selectedOffering === "";
    const contactIsInvalid = selectedContact === "";
    const uploadIsInvalid = leagueLogoUrl === "";
    const shootsAreInvalid = shoots[0].name === "";

    const uploadButton = (
      <div>
        <Icon type={"plus"} />
        <div className="ant-upload-text">Upload Event Logo</div>
      </div>
    );

    return (
      <Modal
        keyboard={false}
        centered
        bodyStyle={{
          height:
            offerings.length === 0 || clients.length === 0 ? "70vh" : "90vh",
          borderRadius: 0,
        }}
        width={"90vw"}
        visible={visible}
        title="Set up a new Shoot"
        okText="Create"
        footer={null}
        afterClose={() => {
          this.setState({ ...INITIAL_STATE });
        }}
        onCancel={() => {
          onCancel();
        }}
        onOk={() => {}}
      >
        <div>
          {clients.length === 0 ? (
            <div className="uk-flex uk-flex-center uk-text-center uk-width-expand">
              <div className="uk-flex uk-flex-column uk-flex-center uk-width-large">
                <div className="uk-width-expand uk-flex uk-flex-center">
                  <img src={emptyview} className="empty-view" />
                </div>
                <span className="uk-margin-top uk-margin-bottom">
                  You haven't set up any clients yet.
                </span>
                <Button
                  onClick={() => {
                    this.props.history.push(routeNames.CLIENTS);
                  }}
                  type="primary"
                >
                  Set up your first Client
                </Button>
              </div>
            </div>
          ) : offerings.length === 0 ? (
            <div className="uk-flex uk-flex-center uk-text-center uk-width-expand">
              <div className="uk-flex uk-flex-column uk-flex-center uk-width-large">
                <div className="uk-width-expand uk-flex uk-flex-center">
                  <img src={emptyview} className="empty-view" />
                </div>
                <span className="uk-margin-top uk-margin-bottom">
                  You haven't set up any offerings yet.
                </span>
                <Button
                  onClick={() => {
                    this.props.history.push(routeNames.PRODUCTS);
                  }}
                  type="primary"
                >
                  Set up your first Offering
                </Button>
              </div>
            </div>
          ) : (
            <div>
              <Steps size="small" current={this.state.pageNumber}>
                {steps.map((item) => (
                  <Step key={item.title} title={item.title} />
                ))}
              </Steps>

              <div className="steps-content-events">
                <Form>
                  <Spin
                    indicator={
                      <Icon type="loading" style={{ fontSize: 24 }} spin />
                    }
                    spinning={(updateEvent.loading as boolean) || loading}
                  >
                    {pageNumber === 0 && (
                      <div>
                        <Form.Item label="Event Name" {...formItemLayout}>
                          <Input
                            placeholder="Name"
                            onChange={(e) => {
                              if (e.target.value.length <= 30) {
                                this.setState({
                                  name: titleCase(e.target.value),
                                });
                              }
                            }}
                            value={name}
                          />
                        </Form.Item>
                        <Form.Item label="Client" {...formItemLayout}>
                          <Select
                            placeholder={"Select Client"}
                            labelInValue
                            value={
                              selectedClient
                                ? { key: selectedClient }
                                : undefined
                            }
                            onChange={(e: any) => {
                              const client: Client = getArrayItemByKey(
                                e.key,
                                clients
                              );
                              this.setState({
                                selectedClient: e.key,
                                priColor: client.priColor,
                                secColor: client.secColor,
                                leagueLogoUrl: client.logoUrl,
                              });
                            }}
                          >
                            {getClientOptionsForEvent(clients)}
                          </Select>
                        </Form.Item>
                        <Form.Item label="Primary Color" {...formColorLayout}>
                          <ColorPicker
                            setHex={(color: any) => {
                              this.setState({ priColor: color.hex });
                            }}
                            hex={priColor}
                          />
                        </Form.Item>
                        <Form.Item label="Secondary Color" {...formColorLayout}>
                          <ColorPicker
                            setHex={(color: any) => {
                              this.setState({ secColor: color.hex });
                            }}
                            hex={secColor}
                          />
                        </Form.Item>
                        <Form.Item label="Offering" {...formItemLayout}>
                          <Select
                            placeholder={"Select Offering"}
                            labelInValue
                            value={
                              selectedOffering
                                ? { key: selectedOffering }
                                : undefined
                            }
                            onChange={(e: any) => {
                              this.setState({
                                selectedOffering: e.key,
                              });
                            }}
                          >
                            {getOfferingOptionsForEvent(offerings)}
                          </Select>
                        </Form.Item>
                        <Form.Item
                          label="League Buy for Subjects"
                          {...formItemLayout}
                        >
                          <Select
                            placeholder={"Select Package"}
                            labelInValue
                            value={
                              leagueBuySubjectsId
                                ? { key: leagueBuySubjectsId }
                                : undefined
                            }
                            onChange={(e: any) => {
                              this.setState({
                                leagueBuySubjectsId: e.key,
                              });
                            }}
                          >
                            <Option key={"blank"} value={""}>
                              None
                            </Option>
                            {getPackageOptionsForEvent(packages)}
                          </Select>
                        </Form.Item>
                        <Form.Item
                          label="League Buy for Missing Subjects"
                          {...formItemLayout}
                        >
                          <Select
                            placeholder={"Select Package"}
                            labelInValue
                            value={
                              leagueBuyMissingSubjectsId
                                ? { key: leagueBuyMissingSubjectsId }
                                : undefined
                            }
                            onChange={(e: any) => {
                              this.setState({
                                leagueBuyMissingSubjectsId: e.key,
                              });
                            }}
                          >
                            <Option key={"blank"} value={""}>
                              None
                            </Option>
                            {getPackageOptionsForEvent(packages)}
                          </Select>
                        </Form.Item>
                        <Form.Item
                          label="League Buy for Staff"
                          {...formItemLayout}
                        >
                          <Select
                            placeholder={"Select Package"}
                            labelInValue
                            value={
                              leagueBuyStaffId
                                ? { key: leagueBuyStaffId }
                                : undefined
                            }
                            onChange={(e: any) => {
                              this.setState({
                                leagueBuyStaffId: e.key,
                              });
                            }}
                          >
                            <Option key={"blank"} value={""}>
                              None
                            </Option>
                            {getPackageOptionsForEvent(packages)}
                          </Select>
                        </Form.Item>
                        <Form.Item label="Type" {...formItemLayout}>
                          <Select
                            placeholder={"Select Event Type"}
                            labelInValue
                            value={type ? { key: type } : undefined}
                            onChange={(e: any) => {
                              this.setState({
                                type: e.key,
                              });
                            }}
                          >
                            {getEventTypeOptions()}
                          </Select>
                        </Form.Item>
                        <Form.Item label="Division" {...formItemLayout}>
                          <Select
                            placeholder={"Select Division Type"}
                            labelInValue
                            value={
                              divisionType ? { key: divisionType } : undefined
                            }
                            onChange={(e: any) => {
                              this.setState({
                                divisionType: e.key,
                              });
                            }}
                          >
                            {getDivisionTypeOptions()}
                          </Select>
                        </Form.Item>
                        <Form.Item label="Groups Expected" {...formItemLayout}>
                          <InputNumber
                            style={{ width: "100%" }}
                            min={0}
                            max={100}
                            step={1}
                            placeholder="# of Groups"
                            onChange={(e) => {
                              this.setState({ groupsExpected: e as number });
                            }}
                            value={groupsExpected}
                          />
                        </Form.Item>
                        <Form.Item label="Kiosk Password" {...formItemLayout}>
                          <Input
                            placeholder="Enter an easy password.."
                            onChange={(e) => {
                              if (e.target.value.length <= 30) {
                                this.setState({
                                  kioskPassword: e.target.value.trim(),
                                });
                              }
                            }}
                            value={kioskPassword}
                          />
                        </Form.Item>
                      </div>
                    )}
                    {pageNumber === 1 && (
                      <div>
                        <Form.Item label="Assigned Contact" {...formItemLayout}>
                          <Select
                            placeholder={"Select Contact"}
                            labelInValue
                            value={
                              selectedContact
                                ? { key: selectedContact }
                                : undefined
                            }
                            onChange={(e: any) => {
                              this.setState({
                                selectedContact: e.key,
                              });
                            }}
                          >
                            {getContactOptionsForEvent(contacts)}
                          </Select>
                        </Form.Item>
                      </div>
                    )}
                    {pageNumber === 2 && (
                      <div
                        style={{ minHeight: "500px" }}
                        className="uk-flex uk-flex-center uk-flex-middle"
                      >
                        <Upload
                          name="avatar"
                          listType="picture-card"
                          className="avatar-uploader"
                          showUploadList={false}
                          beforeUpload={this.beforeUpload}
                          onChange={this.handleUploadChange}
                          customRequest={dummyRequest}
                          style={{
                            height: "300px",
                            minWidth: "300px",
                            width: "unset",
                          }}
                        >
                          {leagueLogoUrl ? (
                            <img
                              style={{ maxWidth: "800px" }}
                              src={leagueLogoUrl}
                              alt="avatar"
                            />
                          ) : (
                            uploadButton
                          )}
                        </Upload>
                      </div>
                    )}
                    {pageNumber === 3 && (
                      <div
                        style={{ minHeight: "400px" }}
                        className="uk-padding-small"
                      >
                        <Table
                          rowKey={(e) => e.key}
                          pagination={false}
                          dataSource={shoots}
                        >
                          <Column
                            title="#"
                            dataIndex="key"
                            key="number"
                            render={(name, shoot, index) => (
                              <span>{index + 1}</span>
                            )}
                          />
                          <Column
                            title="Name"
                            dataIndex="name"
                            key="name"
                            render={(name, shoot, index) => (
                              <Input
                                placeholder="Name"
                                value={name}
                                onChange={(e) => {
                                  const newShoot = {
                                    ...(shoot as Shoot),
                                    name: titleCase(e.target.value),
                                  };
                                  this.updateShoot(newShoot, index);
                                }}
                              />
                            )}
                          />
                          <Column
                            title="Location"
                            dataIndex="address"
                            key="address"
                            render={(address, shoot, index) => (
                              <TeampicsPlaces
                                setAddress={(address: string) => {
                                  const newShoot = {
                                    ...(shoot as Shoot),
                                    address,
                                  };
                                  this.updateShoot(newShoot, index);
                                }}
                                setLocation={(location: TeamPicsLocation) => {
                                  const newShoot = {
                                    ...(shoot as Shoot),
                                    location,
                                  };
                                  this.updateShoot(newShoot, index);
                                }}
                                address={address}
                                disabled={false}
                                placeholder="Search Location..."
                              />
                            )}
                          />
                          <Column
                            title="Location Notes"
                            dataIndex="key"
                            key="locationNotes"
                            render={(locationNotes, shoot, index) => (
                              <a
                                onClick={() => {
                                  this.setState({
                                    locationNotesVisible: true,
                                    shootIndex: index,
                                  });
                                }}
                              >
                                Notes
                              </a>
                            )}
                          />
                          <Column
                            title="Date"
                            dataIndex="startTime"
                            key="startTimeDate"
                            render={(startTime, shoot, index) => (
                              <DatePicker
                                style={{ minWidth: "150px" }}
                                allowClear={false}
                                value={moment(startTime)}
                                onChange={(e) => {
                                  if (e) {
                                    const newShoot = {
                                      ...(shoot as Shoot),
                                      startTime: e.valueOf(),
                                      endTime: e.valueOf(),
                                    };
                                    this.updateShoot(newShoot, index);
                                  }
                                }}
                              />
                            )}
                          />
                          <Column
                            title="Start Time"
                            dataIndex="startTime"
                            key="startTime"
                            render={(startTime, shoot, index) => (
                              <TimePicker
                                allowClear={false}
                                onChange={(e) => {
                                  const newShoot = {
                                    ...(shoot as Shoot),
                                    startTime: e.valueOf(),
                                  };
                                  this.updateShoot(newShoot, index);
                                }}
                                value={moment(startTime)}
                                placeholder={"Start Time"}
                                minuteStep={10}
                                secondStep={60}
                                format={"HH:mm"}
                              />
                            )}
                          />
                          <Column
                            title="End Time"
                            dataIndex="endTime"
                            key="endTime"
                            render={(endTime, shoot, index) => (
                              <TimePicker
                                allowClear={false}
                                onChange={(e) => {
                                  const newShoot = {
                                    ...(shoot as Shoot),
                                    endTime: e.valueOf(),
                                  };
                                  this.updateShoot(newShoot, index);
                                }}
                                value={moment(endTime)}
                                placeholder={"Start Time"}
                                minuteStep={10}
                                secondStep={60}
                                format={"HH:mm"}
                              />
                            )}
                          />
                          <Column
                            width={"100px"}
                            title="Interval (mins)"
                            dataIndex="intervalMins"
                            key="intervalMins"
                            render={(intervalMins, shoot: Shoot, index) => (
                              <InputNumber
                                style={{ width: "100%" }}
                                min={1}
                                max={999}
                                step={1}
                                placeholder="Intervals (mins)"
                                onChange={(e) => {
                                  const newShoot = {
                                    ...(shoot as Shoot),
                                    intervalMins: e!,
                                  };
                                  this.updateShoot(newShoot, index);
                                }}
                                value={intervalMins}
                              />
                            )}
                          />
                          <Column
                            width={"100px"}
                            title="# of Groups"
                            dataIndex="endTime"
                            key="numberGroups"
                            render={(endTime, shoot: Shoot, index) => (
                              <InputNumber
                                style={{ width: "100%" }}
                                min={0}
                                max={999}
                                step={10}
                                placeholder="Intervals (mins)"
                                onChange={(e) => {}}
                                value={this.calculateNumberTimeslots(
                                  shoot.startTime!,
                                  shoot.endTime!,
                                  shoot.intervalMins
                                )}
                              />
                            )}
                          />
                          {shoots.length > 1 ? (
                            <Column
                              title="Action"
                              dataIndex="key"
                              key="action"
                              render={(endTime, shoot: Shoot, index) => (
                                <div className="uk-padding-small">
                                  {shoots.length > 1 ? (
                                    <Icon
                                      className="dynamic-delete-button"
                                      type="minus-circle-o"
                                      onClick={() => this.removeShoot(index)}
                                    />
                                  ) : null}
                                </div>
                              )}
                            />
                          ) : null}
                        </Table>
                        <div className="uk-flex uk-flex-center uk-margin-top uk-margin-bottom">
                          <Button
                            type="dashed"
                            onClick={this.addShoot}
                            style={{ width: "60%" }}
                          >
                            <Icon type="plus" /> Add Shoot
                          </Button>
                        </div>
                      </div>
                    )}
                  </Spin>
                </Form>
              </div>

              <div className="uk-position-bottom uk-padding-small">
                <Divider />
                <div className="uk-flex uk-flex-row">
                  <div className="uk-flex uk-flex-left">
                    <Button onClick={() => onCancel()}>Cancel</Button>
                  </div>
                  <div className="uk-flex uk-flex-right uk-width-expand">
                    {this.state.pageNumber > 0 && (
                      <Button onClick={() => this.prev()}>Previous</Button>
                    )}
                    {this.state.pageNumber < steps.length - 1 && (
                      <Button
                        style={{ marginLeft: 8 }}
                        type="primary"
                        onClick={() => {
                          if (pageNumber === 0) {
                            this.loadContacts();
                          } else {
                            this.next();
                          }
                        }}
                        disabled={
                          (pageNumber === 0 && generalIsInvalid) ||
                          (pageNumber === 1 && contactIsInvalid) ||
                          (pageNumber === 2 && uploadIsInvalid) ||
                          (pageNumber === 3 && shootsAreInvalid)
                        }
                      >
                        Next
                      </Button>
                    )}
                    {this.state.pageNumber === steps.length - 1 && (
                      <Button
                        style={{ marginLeft: 8 }}
                        type="primary"
                        disabled={
                          (updateEvent.loading as boolean) ||
                          loading ||
                          shootsAreInvalid
                        }
                        onClick={() => {
                          this.newEvent();
                        }}
                      >
                        Done
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <LocationNotesModal
          disabled={false}
          visible={locationNotesVisible}
          onCancel={() => {
            this.setState({ locationNotesVisible: false });
          }}
          locationNotes={shoots[shootIndex].locationNotes}
          onUpdateLocationNotes={(value: string) => {
            const newShoot = {
              ...shoots[shootIndex],
              locationNotes: value,
            };
            this.updateShoot(newShoot, shootIndex);
          }}
        />
      </Modal>
    );
  }
}

export default compose<any, any>(
  withRouter,
  connect<StateProps, DispatchProps, OwnProps>(
    mapStateToProps,
    mapDispatchToProps
  )
)(NewEventModal);
