export const HOME = '/';
export const DASHBOARD = '/dashboard';
export const LOGIN = '/login';
export const REGISTER = '/register';
export const EVENTS = '/events';
export const CLIENTS = '/clients';
export const GROUPS = '/groups';
export const PHOTOGRAPHERS = '/photographers';
export const SETTINGS = '/settings';
export const PRODUCTS = '/products';
export const TEAM_BUILDER = '/team-builder';
export const PURCHASES = '/purchases';
