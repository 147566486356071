import * as roles from '../constants/roles';

var dashRoutes = [
	{
		path: '/dashboard',
		name: 'Home',
		roles: roles.ALL,
		component: 'Dashboard',
		icon: 'home',
		show: true,
		key: '1'
	},
	{
		path: '/clients',
		name: 'Clients',
		roles: roles.PHOTOGRAPHER,
		component: 'Clients',
		icon: 'user-add',
		show: true,
		key: '2'
	},
	{
		path: '/products',
		name: 'Products',
		roles: roles.PHOTOGRAPHER,
		component: 'Products',
		icon: 'shopping-cart',
		show: true,
		key: '3'
	},
	{
		path: '/events',
		name: 'Events',
		roles: roles.PHOTOGRAPHER,
		component: 'Events',
		icon: 'camera',
		show: true,
		key: '4'
	},
	{
		path: '/purchases',
		name: 'Purchases',
		roles: roles.PHOTOGRAPHER,
		component: 'Purchases',
		icon: 'barcode',
		show: true,
		key: '5'
	},
	{
		path: '/photographers',
		name: 'Requests',
		roles: roles.ADMIN,
		component: 'Photographers',
		icon: 'unlock',
		show: true,
		key: '6'
	},
	{
		path: '/settings',
		name: 'Profile',
		roles: roles.ALL,
		component: 'Settings',
		icon: 'cog',
		show: false,
		key: '7'
	}
];

export default dashRoutes;
