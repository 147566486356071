import { firestore } from './firebaseConfig';
import * as rProducts from '../reducers/products';
import * as storage from './storage';
import { ThunkResult, TeamPicsFile } from '../models/Commons';
import { Package, Product, Offering } from '../models/ProductsModel';

let packsListener: any;
export const subscribePacks = (uid: string): ThunkResult<void> => {
	return (dispatch) => {
		const packRef = firestore.collection('users').doc(uid).collection('packages').orderBy('sku')
		if (packsListener) {
			return;
		}

		dispatch(rProducts.modifyLoadPackages('LOADING', true));

		packsListener = packRef.onSnapshot((snapshot) => {
			if (snapshot.empty) {
				dispatch(rProducts.modifyLoadPackages('EMPTY', true));
			} else {
				dispatch(rProducts.modifyLoadPackages('SUCCESS', ''));
			}

			snapshot.docChanges().forEach(
				(change) => {
					if (change.type === 'added') {
						const pack = {
							...change.doc.data() as Package,
							key: change.doc.id
						};

						dispatch(rProducts.modifyPackagesArray('ADD', pack));
					}
					if (change.type === 'modified') {
						const pack = {
							...change.doc.data() as Package,
							key: change.doc.id
						};

						dispatch(rProducts.modifyPackagesArray('UPDATE', pack));
					}
					if (change.type === 'removed') {
						const pack = {
							...change.doc.data() as Package,
							key: change.doc.id
						};

						dispatch(rProducts.modifyPackagesArray('REMOVE', pack));
					}
				},
				(error: any) => {
					if (error) {
						dispatch(rProducts.modifyLoadPackages('Something went wrong, please try again.', true));
					}
				}
			);
		});

		return packsListener;
	};
};

export const unSubscribePacks = () => {
	if (packsListener) {
		packsListener();
		packsListener = undefined;
	}
};

let productsListener: any;
export const subscribeProducts = (uid: string): ThunkResult<void> => {
	return (dispatch) => {
		const productsRef = firestore.collection('users').doc(uid).collection('products').orderBy('name')
		if (productsListener) {
			return;
		}

		dispatch(rProducts.modifyLoadProducts('LOADING', true));

		productsListener = productsRef.onSnapshot((snapshot) => {
			if (snapshot.empty) {
				dispatch(rProducts.modifyLoadProducts('EMPTY', true));
			} else {
				dispatch(rProducts.modifyLoadProducts('SUCCESS', ''));
			}

			snapshot.docChanges().forEach(
				(change) => {
					if (change.type === 'added') {
						const product = {
							...change.doc.data() as Product,
							key: change.doc.id
						};

						dispatch(rProducts.modifyProductsArray('ADD', product));
					}
					if (change.type === 'modified') {
						const product = {
							...change.doc.data() as Product,
							key: change.doc.id
						};

						dispatch(rProducts.modifyProductsArray('UPDATE', product));
					}
					if (change.type === 'removed') {
						const product = {
							...change.doc.data() as Product,
							key: change.doc.id
						};

						dispatch(rProducts.modifyProductsArray('REMOVE', product));
					}
				},
				(error: any) => {
					if (error) {
						dispatch(rProducts.modifyLoadProducts('Something went wrong, please try again.', true));
					}
				}
			);
		});

		return productsListener;
	};
};

export const unSubscribeProducts = () => {
	if (productsListener) {
		productsListener();
		productsListener = undefined;
	}
};

let offeringsListener: any;
export const subscribeOfferings = (uid: string): ThunkResult<void> => {
	return (dispatch) => {
		const offeringRef = firestore.collection('users').doc(uid).collection('offerings').orderBy('name')
		if (offeringsListener) {
			return;
		}

		dispatch(rProducts.modifyLoadOfferings('LOADING', true));

		offeringsListener = offeringRef.onSnapshot((snapshot) => {
			if (snapshot.empty) {
				dispatch(rProducts.modifyLoadOfferings('EMPTY', true));
			} else {
				dispatch(rProducts.modifyLoadOfferings('SUCCESS', ''));
			}

			snapshot.docChanges().forEach(
				(change) => {
					if (change.type === 'added') {
						const offering = {
							...change.doc.data() as Offering,
							key: change.doc.id
						};

						dispatch(rProducts.modifyOfferingsArray('ADD', offering));
					}
					if (change.type === 'modified') {
						const offering = {
							...change.doc.data() as Offering,
							key: change.doc.id
						};

						dispatch(rProducts.modifyOfferingsArray('UPDATE', offering));
					}
					if (change.type === 'removed') {
						const offering = {
							...change.doc.data() as Offering,
							key: change.doc.id
						};

						dispatch(rProducts.modifyOfferingsArray('REMOVE', offering));
					}
				},
				(error: any) => {
					if (error) {
						dispatch(rProducts.modifyLoadOfferings('Something went wrong, please try again.', true));
					}
				}
			);
		});

		return offeringsListener;
	};
};

export const unSubscribeOfferings = () => {
	if (offeringsListener) {
		offeringsListener();
		offeringsListener = undefined;
	}
};

export const updateItem = (
	uid: string,
	collectionKey: string,
	imageFile: TeamPicsFile,
	item: Package | Offering | Product
): ThunkResult<void> => {
	return (dispatch) => {
		dispatch(rProducts.modifyUpdateItem('LOADING', true));
		const itemRef = firestore.collection('users').doc(uid).collection(collectionKey).doc(item.key);

		if (imageFile.blob) {
			const storagePath = `users/${uid}/${collectionKey}/${item.key}/image-${collectionKey}-${item.key}`;

			return storage.uploadImage(imageFile, storagePath).then((url) => {
				return itemRef
					.update({
						...item,
						imageUrl: url
					})
					.then(() => {
						dispatch(
							rProducts.modifyUpdateItem(
								'SUCCESS',
								`${getItemType(collectionKey)} was successfully updated`
							)
						);
					})
					.catch((error) => {
						dispatch(rProducts.modifyUpdateItem('ERROR', 'Something went wrong, please try again'));
					});
			});
		}

		return itemRef
			.update({
				...item
			})
			.then(() => {
				dispatch(
					rProducts.modifyUpdateItem('SUCCESS', `${getItemType(collectionKey)} was successfully updated`)
				);
			})
			.catch((error) => {
				dispatch(rProducts.modifyUpdateItem('ERROR', 'Something went wrong, please try again'));
			});
	};
};

export const newItem = (
	uid: string,
	collectionKey: string,
	imageFile: TeamPicsFile,
	item: Package | Offering | Product
): ThunkResult<void> => {
	return (dispatch) => {
		dispatch(rProducts.modifyNewItem('LOADING', true));
		const itemRef = firestore.collection('users').doc(uid).collection(collectionKey).doc();
		const itemId = itemRef.id;

		if (imageFile.blob) {
			const storagePath = `users/${uid}/${collectionKey}/${itemId}/image-${collectionKey}-${itemId}`;
			return storage.uploadImage(imageFile, storagePath).then((url) => {
				return itemRef
					.set({
						...item,
						imageUrl: url
					})
					.then(() => {
						dispatch(
							rProducts.modifyNewItem('SUCCESS', `${getItemType(collectionKey)} was successfully created`)
						);
					})
					.catch((error) => {
						dispatch(rProducts.modifyNewItem('ERROR', `Something went wrong, please try again`));
					});
			});
		}

		return itemRef
			.set({
				...item
			})
			.then(() => {
				dispatch(rProducts.modifyNewItem('SUCCESS', `${getItemType(collectionKey)} was successfully created`));
			})
			.catch((error) => {
				dispatch(rProducts.modifyNewItem('ERROR', `Something went wrong, please try again`));
			});
	};
};

const getItemType = (collectionKey: string): string => {
	if (collectionKey === 'products') {
		return 'Product';
	} else if (collectionKey === 'packages') {
		return 'Package';
	} else if (collectionKey === 'offerings') {
		return 'Offering';
	}
	return '';
};
