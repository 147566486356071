import { firestore } from './firebaseConfig';
import * as rTimeslots from '../reducers/timeslots';
import * as storage from './storage';
import { ThunkResult } from '../models/Commons';
import { Timeslot } from '../models/EventsModel';

let timeslotsListener: any;
export const subscribeToTimeslots = (
  eventId: string,
  shootId: string
): ThunkResult<void> => {
  return dispatch => {
    const timeslotsRef = firestore
      .collection('events')
      .doc(eventId)
      .collection('shoots')
      .doc(shootId)
      .collection('timeslots')
      .orderBy('timestamp', 'desc');

    if (timeslotsListener) {
      return;
    }
    dispatch(rTimeslots.modifyLoadTimeslots('LOADING', true));

    timeslotsListener = timeslotsRef.onSnapshot(
      snapshot => {
        if (snapshot.empty) {
          dispatch(rTimeslots.modifyLoadTimeslots('EMPTY', true));
        } else {
          dispatch(rTimeslots.modifyLoadTimeslots('SUCCESS', true));
        }

        snapshot.docChanges().forEach(change => {
          if (change.type === 'added') {
            const timeslot = {
              ...(change.doc.data() as Timeslot),
              key: change.doc.id
            };
            dispatch(rTimeslots.modifyTimeslotsArray('ADD', timeslot));
          }
          if (change.type === 'modified') {
            const timeslot = {
              ...(change.doc.data() as Timeslot),
              key: change.doc.id
            };
            dispatch(rTimeslots.modifyTimeslotsArray('UPDATE', timeslot));
          }
          if (change.type === 'removed') {
            const timeslot = {
              ...(change.doc.data() as Timeslot),
              key: change.doc.id
            };
            dispatch(rTimeslots.modifyTimeslotsArray('REMOVE', timeslot));
          }
        });
      },
      (error: any) => {
        if (error) {
           
          dispatch(
            rTimeslots.modifyLoadTimeslots(
              'ERROR',
              'Something went wrong, please refresh the page'
            )
          );
        }
      }
    );
    return timeslotsListener;
  };
};

export const unSubscribeFromTimeslots = () => {
  if (timeslotsListener) {
    timeslotsListener();
    timeslotsListener = undefined;
  }
};

export const bookTimeslotPhotographer = (
  timeslot: Timeslot
): ThunkResult<void> => {
  return dispatch => {
    const timeslotRef = firestore
      .collection('events')
      .doc(timeslot.eventId)
      .collection('shoots')
      .doc(timeslot.shootId)
      .collection('timeslots')
      .doc(timeslot.key);

    dispatch(rTimeslots.modifyUpdateTimeslot('LOADING', true));
    timeslotRef
      .update(timeslot)
      .then(() => {
        dispatch(
          rTimeslots.modifyUpdateTimeslot(
            'SUCCESS',
            'Timeslot was successfully booked by photographer.'
          )
        );
      })
      .catch(error => {
         
        dispatch(
          rTimeslots.modifyUpdateTimeslot(
            'ERROR',
            'Something went wrong, please try again.'
          )
        );
      });
  };
};
