import { firestore } from './firebaseConfig';
import * as rUsers from '../reducers/users';
import * as storage from './storage';
import { ThunkResult, TeamPicsFile } from '../models/Commons';
import { UserMeta, User } from '../models/UsersModel';
import * as roles from '../constants/roles';

//-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-//
//Loading "users" object from firebase
//-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-//

let userListener: any;
export const subscribeToUser = (uid: string): ThunkResult<void> => {
	return (dispatch) => {
		const userRootRef = firestore.collection('users').doc(uid);

		userListener = userRootRef.onSnapshot(
			(snapshot: any) => {
				if (snapshot.exists) {
					dispatch(rUsers.modifyLoadUser('EMPTY', true));
				} else {
					dispatch(rUsers.modifyLoadUser('SUCCESS', ''));
				}

				const updatedUser = snapshot.data();
				dispatch(rUsers.setUser({ userId: snapshot.id, ...updatedUser }));
				dispatch(rUsers.modifyLoadUser('SUCCESS', ''));
			},
			(error: any) => {
				if (error) {
					dispatch(rUsers.modifyLoadUser('ERROR', 'Something went wrong, please refresh the page.'));
				}
			}
		);

		return userListener;
	};
};
export const unsubscribeFromUser = () => {
	if (userListener) {
		userListener();
		userListener = undefined;
	}
};

let userMetaListener: any;
export const subscribeToUserMeta = (uid: string): ThunkResult<void> => {
	return (dispatch) => {
		const userMetaRef = firestore.collection('users').doc(uid).collection('metadata').doc('private');

		userListener = userMetaRef.onSnapshot(
			(snapshot: any) => {
				if (snapshot.exists) {
					let userRoles: Array<string> = [];
					userRoles.push(roles.ALL);
					dispatch(rUsers.modifyLoadUserMeta('EMPTY', true));
					dispatch(
						rUsers.setUserMeta({
							roles: userRoles,
							photographer: false,
							admin: false
						})
					);
				} else {
					dispatch(rUsers.modifyLoadUserMeta('SUCCESS', ''));
				}

				const updatedUserMeta = snapshot.data();
				if (updatedUserMeta) {
					let userRoles: Array<string> = [];
					userRoles.push(roles.ALL);
					if (updatedUserMeta.photographer) {
						userRoles.push(roles.PHOTOGRAPHER);
					}

					if (updatedUserMeta.admin) {
						userRoles.push(roles.ADMIN);
					}

					dispatch(
						rUsers.setUserMeta({
							key: snapshot.id,
							...updatedUserMeta,
							roles: userRoles
						})
					);
					dispatch(rUsers.modifyLoadUserMeta('SUCCESS', ''));
				}
			},
			(error: any) => {
				if (error) {
					dispatch(rUsers.modifyLoadUserMeta('ERROR', 'Something went wrong, please refresh the page.'));
				}
			}
		);

		return userListener;
	};
};
export const unsubscribeFromUserMeta = () => {
	if (userMetaListener) {
		userMetaListener();
		userMetaListener = undefined;
	}
};

export const getUserForTeamBuilder = (uid: string) => {
	const userRootRef = firestore.collection('users').doc(uid);
	return userRootRef.get().then((userSnap) => {
		return { ...userSnap.data(), userId: userSnap.id };
	});
};

//-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-//
//Updating "users" object from firebase
//-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-//

// Update user details
export const updateUser = (user: User, logoFile: TeamPicsFile): ThunkResult<void> => {
	return (dispatch) => {
		dispatch(rUsers.modifyUpdateUser('LOADING', true));
		const userRef = firestore.collection('users').doc(user.userId);

		//storage
		if (logoFile.blob) {
			const storagePath = `users/${user.userId}/logo/logo-photographer-${user.userId}`;
			return storage
				.uploadImage(logoFile, storagePath)
				.then((url: any) => {
					delete user.logoFile;
					 

					return userRef.update({
						...user,
						logoUrl: url
					});
				})
				.then(() => {
					return dispatch(rUsers.modifyUpdateUser('SUCCESS', 'Your profile was successfully updated!'));
				})
				.catch((error) => {
					 
					dispatch(rUsers.modifyUpdateUser('ERROR', 'Something went wrong, please try again'));
				});
		}

		return userRef
			.update({
				...user
			})
			.then(() => {
				return dispatch(rUsers.modifyUpdateUser('SUCCESS', 'Your profile was successfully updated!'));
			})
			.catch((error) => {
				 
				dispatch(rUsers.modifyUpdateUser('ERROR', 'Something went wrong, please try again'));
			});
	};
};

// Send photographer requests
export const sendPhotographerRequest = (user: User): ThunkResult<void> => {
	return (dispatch) => {
		dispatch(rUsers.modifySendRequest('LOADING', true));
		const requestRef: any = firestore.collection('photographerRequests');
		const request = {
			userId: user.userId,
			accepted: false,
			timestamp: new Date().getTime(),
			name: `${user.firstName} ${user.lastName}`,
			email: user.email
		};

		return requestRef
			.where('userId', '==', user.userId)
			.get()
			.then((snapshot: any) => {
				if (snapshot.empty) {
					return requestRef.add(request);
				} else {
					dispatch(
						rUsers.modifySendRequest(
							'ERROR',
							'Your request has already been sent, please wait for approval.'
						)
					);

					throw Error('Your request has already been sent, please wait for approval.');
				}
			})
			.then(() => {
				return dispatch(
					rUsers.modifySendRequest(
						'SUCCESS',
						'Your request has been successfully sent, please wait for approval.'
					)
				);
			})
			.catch((error: any) => {
				 
				return dispatch(
					rUsers.modifySendRequest(
						'ERROR',
						error.message ? error.message : 'Something went wrong, please try again.'
					)
				);
			});
	};
};
