import React, { Component } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import {
	Table,
	Tag,
	Button,
	Modal,
	Steps,
	Divider,
	Empty,
	message,
	Icon
} from 'antd';
import { UserMeta, User } from '../../models/UsersModel';
import { Client } from '../../models/ClientsModel';
import { TaskState, Season } from '../../models/Commons';
import NewClient from './NewClient';

import * as rClients from '../../reducers/clients';
import { RouteComponentProps, withRouter } from 'react-router';
import { getArrayItemByKey } from '../../helpers/arrays';

import emptyview from '../../assets/emptyview_clients.png';

interface OwnProps extends RouteComponentProps { }

interface StateProps {
	user: User;
	userMeta: UserMeta;
	clients: Array<Client>;
	loadClients: TaskState;
	updateClient: TaskState;
}

interface DispatchProps {
	onResetUpdateClient: Function;
	onSetSelectedClient: Function;
}

type Props = StateProps & DispatchProps & OwnProps;
type State = { visible: boolean };

const mapStateToProps = (state: any) => ({
	user: state.usersState.user,
	userMeta: state.usersState.userMeta,
	clients: state.clientsState.clients,
	loadClients: state.clientsState.loadClients,
	updateClient: state.clientsState.updateClient
});

const mapDispatchToProps = (dispatch: any) => ({
	onResetUpdateClient: () => {
		dispatch(rClients.modifyUpdateClient('LOADING', false));
	},
	onSetSelectedClient: (client: Client) => {
		dispatch(rClients.setSelectedClient(client));
	}
});

class ClientsList extends Component<Props, State> {
	state = { visible: false };
	componentDidMount() { }
	componentWillReceiveProps(nextProps: Props) {
		if (nextProps.updateClient.success && !this.props.updateClient.success && !nextProps.updateClient.loading) {
			message.success(nextProps.updateClient.success);
			this.setState({ visible: false });
		}
	}
	openModal = () => {
		this.props.onResetUpdateClient();
		this.setState({ visible: true });
	};
	render() {
		const { user, userMeta, clients, loadClients } = this.props;
		const columns = [
			{
				title: 'Logo',
				dataIndex: 'logoUrl',
				className: 'column-image',
				render: (logoUrl: string) => <img className='col-image' src={logoUrl} />
			},
			{
				title: 'Name',
				dataIndex: 'name'
			},
			{
				title: 'Season',
				dataIndex: 'seasons',
				render: (seasons: Season) => (
					<span>
						{seasons.fall && (
							<Tag color={'gold'} key={'fall'}>
								{seasons.fall ? 'Fall' : ''}
							</Tag>
						)}
						{seasons.spring && (
							<Tag color={'green'} key={'spring'}>
								{seasons.spring ? 'Spring' : ''}
							</Tag>
						)}
						{seasons.summer && (
							<Tag color={'red'} key={'summer'}>
								{seasons.summer ? 'Summer' : ''}
							</Tag>
						)}
						{seasons.winter && (
							<Tag color={'blue'} key={'winter'}>
								{seasons.winter ? 'Winter' : ''}
							</Tag>
						)}
					</span>
				)
			},
			{
				title: 'Type',
				dataIndex: 'type'
			},
			{
				title: 'Status',
				dataIndex: 'active',
				render: (active: boolean) => (
					<span>
						<Tag color={active ? 'green' : 'red'} key={'active'}>
							{active ? 'Active' : 'Inactive'}
						</Tag>
					</span>
				)
			}
			// {
			// 	title: 'Action',
			// 	dataIndex: 'key',
			// 	render: (key: string) => (
			// 		<a
			// 			onClick={() => {
			// 				this.props.onResetUpdateClient();
			// 				this.props.onSetSelectedClient(getArrayItemByKey(key, clients));
			// 				this.props.history.push(`/clients/${key}`);
			// 			}}
			// 		>
			// 			Edit
			// 		</a>
			// 	)
			// }
		];
		const header = (
			<div className='uk-flex uk-flex-row'>
				<h2>Your Clients</h2>
				<div className='uk-flex uk-flex-right uk-width-expand'>
					<Button
						onClick={() => {
							this.openModal();
						}}
						type='primary'
					>
						New
					</Button>
				</div>
			</div>
		);
		const empty = (
			<div className='uk-flex uk-flex-center uk-text-center uk-width-expand'>
				<div className='uk-flex uk-flex-column uk-flex-center uk-width-large'>
					<div className='uk-width-expand uk-flex uk-flex-center'>
						<img src={emptyview} className='empty-view' />
					</div>
					<span className='uk-margin-top uk-margin-bottom'>You haven't set up any clients yet.</span>
					<Button
						onClick={() => {
							this.openModal();
						}}
						type='primary'
					>
						Set up your first Client
					</Button>
				</div>
			</div>
		);
		return (
			<div className='uk-width-expand'>
				<Table
					locale={{ emptyText: loadClients.empty && empty }}
					loading={{
						indicator: <Icon type='loading' style={{ fontSize: 36 }} spin />,
						spinning: loadClients.loading as boolean
					}}
					onRow={(client: Client, index: number) => {
						return {
							onClick: (event: any) => {
								this.props.onResetUpdateClient();
								this.props.onSetSelectedClient(getArrayItemByKey(client.key!, clients));
								this.props.history.push(`/clients/${client.key}`);
							} // click row
						};
					}}
					className='table-row-clickable'
					columns={columns}
					dataSource={clients}
					showHeader
					bordered
					title={() => header}
					pagination={{ pageSize: 10 }}
					scroll={{ x: '500px' }}
				/>
				<NewClient
					visible={this.state.visible}
					onCancel={() => {
						this.setState({ visible: false });
					}}
				/>
			</div>
		);
	}
}

export default compose<any, any>(
	withRouter,
	connect<StateProps, DispatchProps, OwnProps>(
		mapStateToProps,
		mapDispatchToProps
	)
)(ClientsList);
