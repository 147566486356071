import { combineReducers } from 'redux';
import {
	createReducerWithTaskName,
	dispatchTaskWithState,
	createReducerForFirebaseArray,
	dispatchFirebaseArrayWithState
} from '../helpers/reducers';
import { INITIAL_STATE_EMPTY_TASK } from '../models/Commons';
import { Product, Package, Offering } from '../models/ProductsModel';

/**
 * INITIAL STATES
 */
const INITIAL_STATE_SELECTED = {
	selectedPackage: { key: '' },
	selectedProduct: { key: '' },
	selectedOffering: { key: '' }
};

/**
 * CONSTANTS
 */
const LOAD_PRODUCTS = 'load products';
const SET_SELECTED_PRODUCT = 'set selected product';

const LOAD_PACKAGES = 'load packages';
const SET_SELECTED_PACKAGE = 'set selected package';

const LOAD_OFFERINGS = 'load offerings';
const SET_SELECTED_OFFERING = 'set selected offering';

const NEW_ITEM = 'new item';
const UPDATE_ITEM = 'update item';

const CLEAR_PRODUCTS_REDUCER = 'clear products reducer';

/**
 * ACTIONS
 */
export const setSelectedProduct = (product: Product) => ({
	type: SET_SELECTED_PRODUCT,
	product
});

export const setSelectedPackage = (pack: Package) => ({
	type: SET_SELECTED_PACKAGE,
	pack
});

export const setSelectedOffering = (offering: Offering) => ({
	type: SET_SELECTED_OFFERING,
	offering
});

export const modifyLoadProducts = (taskState: string, value: string | boolean) => {
	return dispatchTaskWithState(LOAD_PRODUCTS, taskState, value);
};

export const modifyLoadPackages = (taskState: string, value: string | boolean) => {
	return dispatchTaskWithState(LOAD_PACKAGES, taskState, value);
};

export const modifyLoadOfferings = (taskState: string, value: string | boolean) => {
	return dispatchTaskWithState(LOAD_OFFERINGS, taskState, value);
};

export const modifyNewItem = (taskState: string, value: string | boolean) => {
	return dispatchTaskWithState(NEW_ITEM, taskState, value);
};

export const modifyUpdateItem = (taskState: string, value: string | boolean) => {
	return dispatchTaskWithState(UPDATE_ITEM, taskState, value);
};

export const modifyProductsArray = (arrayState: string, product: Product) => {
	return dispatchFirebaseArrayWithState(LOAD_PRODUCTS, arrayState, product);
};

export const modifyPackagesArray = (arrayState: string, pack: Package) => {
	return dispatchFirebaseArrayWithState(LOAD_PACKAGES, arrayState, pack);
};

export const modifyOfferingsArray = (arrayState: string, offering: Offering) => {
	return dispatchFirebaseArrayWithState(LOAD_OFFERINGS, arrayState, offering);
};

/**
 * REDUCERS
 */
function selectedReducer(state = INITIAL_STATE_SELECTED, action: any) {
	switch (action.type) {
		case SET_SELECTED_PRODUCT: {
			return {
				...state,
				selectedProduct: { ...action.product }
			};
		}
		case SET_SELECTED_PACKAGE: {
			return {
				...state,
				selectedPackage: { ...action.pack }
			};
		}
		case SET_SELECTED_OFFERING: {
			return {
				...state,
				selectedOffering: { ...action.offering }
			};
		}
		default:
			return state;
	}
}

const productsRootReducer = combineReducers({
	selected: selectedReducer,
	newItem: createReducerWithTaskName(NEW_ITEM, INITIAL_STATE_EMPTY_TASK),
	updateItem: createReducerWithTaskName(UPDATE_ITEM, INITIAL_STATE_EMPTY_TASK),
	loadProducts: createReducerWithTaskName(LOAD_PRODUCTS, INITIAL_STATE_EMPTY_TASK),
	loadPackages: createReducerWithTaskName(LOAD_PACKAGES, INITIAL_STATE_EMPTY_TASK),
	loadOfferings: createReducerWithTaskName(LOAD_OFFERINGS, INITIAL_STATE_EMPTY_TASK),
	products: createReducerForFirebaseArray(LOAD_PRODUCTS, []),
	packages: createReducerForFirebaseArray(LOAD_PACKAGES, []),
	offerings: createReducerForFirebaseArray(LOAD_OFFERINGS, [])
});

const productsReducer = (state: any, action: any) => {
	if (action.type === CLEAR_PRODUCTS_REDUCER) {
		state = {
			selected: { ...INITIAL_STATE_SELECTED },
			newItem: { ...INITIAL_STATE_EMPTY_TASK },
			updateItem: { ...INITIAL_STATE_EMPTY_TASK },
			loadProducts: { ...INITIAL_STATE_EMPTY_TASK },
			loadPackages: { ...INITIAL_STATE_EMPTY_TASK },
			loadOfferings: { ...INITIAL_STATE_EMPTY_TASK },
			products: [],
			packages: [],
			offerings: []
		};
	}

	return productsRootReducer(state, action);
};

export default productsReducer;
