export const getDate = (timestamp) => {
	const date = new Date(timestamp);
	const months = [ 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec' ];
	const year = date.getFullYear();
	const month = months[date.getMonth()];
	const day = date.getDate();

	const time = month + ' ' + day + ', ' + year;
	return time;
};

export const getTimeHrsMin = (timestamp) => {
	const date = new Date(timestamp);
	let hrs = date.getHours();
	let mins = date.getMinutes();

	let ampm = 'AM';
	if (hrs >= 12) {
		ampm = 'PM';
		hrs = hrs - 12;
	}

	if (mins === 0) {
		mins = '00';
	}

	if (mins > 0 && mins < 10) {
		mins = `0${mins}`;
	}

	if (hrs === 0) {
		hrs = '12';
	}

	return `${hrs}:${mins}:${ampm}`;
};

export function getLastWeek() {
	const today = new Date();
	const lastWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7);
	return lastWeek.getTime();
}

export function getEndOfToday() {
	const today = new Date();
	today.setHours(23, 59, 59, 999);
	return today.getTime();
}
