import React, { Component } from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import {
  Button,
  Form,
  Input,
  Select,
  Upload,
  Icon,
  Spin,
  Alert,
  Checkbox,
  InputNumber,
} from "antd";
import { UserMeta, User } from "../../models/UsersModel";
import {
  TaskState,
  TeamPicsFile,
  TeamPicsLocation,
  Season,
  Contact,
} from "../../models/Commons";
import { events as fEvents } from "../../firebase";
import {
  getOfferingOptionsForEvent,
  getEventTypeOptions,
  getDivisionTypeOptions,
  contactRoleMap,
  getPackageOptionsForEvent,
} from "../../constants/selectOptions";
import ColorPicker from "../../components/ColorPicker/ColorPicker";
import { RcFile } from "antd/lib/upload/interface";
import {
  getFileType,
  checkFileSize,
  getBase64,
  getMimeType,
  dummyRequest,
  checkIfValidImage,
} from "../../constants/fileTypes";
import * as rEvents from "../../reducers/events";
import { DivisionTypes, EventTypes, Event } from "../../models/EventsModel";
import { Offering, Package } from "../../models/ProductsModel";
import { getArrayItemByKey } from "../../helpers/arrays";
import { titleCase } from "../../helpers/string";
const { TextArea } = Input;
const Option = Select.Option;

let eventUrl = "https://teampics-staging.firebaseapp.com/team-builder/";

if (process.env.REACT_APP_ENV === "development") {
  eventUrl = "https://teampics-staging.firebaseapp.com/team-builder/";
} else {
  eventUrl = "https://photographer.teampics.org/team-builder/";
}

interface OwnProps {}

interface StateProps {
  user: User;
  userMeta: UserMeta;
  updateEvent: TaskState;
  loadEventDetails: TaskState;
  selectedEvent: Event;
  offerings: Array<Offering>;
  packages: Array<Package>;
}

interface DispatchProps {
  onUpdateEvent: Function;
  onClearUpdateEvent: Function;
}

type Props = StateProps & DispatchProps & OwnProps;
type State = {
  active: boolean;
  name: string;
  groupsExpected: number;
  offeringId: string;
  priColor: string;
  secColor: string;
  type: EventTypes;
  divisionType: DivisionTypes;
  contact: Contact;
  clientId: string;
  clientName: string;
  logoFile: TeamPicsFile;
  leagueLogoUrl: string;
  kioskPassword: string;
  error: string;
  loading: boolean;
  leagueBuyMissingSubjectsId: string;
  leagueBuySubjectsId: string;
  leagueBuyStaffId: string;
};
const INITIAL_STATE = {
  offeringId: "",
  contact: {} as Contact,
  clientId: "",
  clientName: "",
  active: true,
  name: "",
  groupsExpected: 0,
  priColor: "#000000",
  secColor: "#000000",
  divisionType: "" as DivisionTypes,
  type: "" as EventTypes,
  contacts: [] as Array<Contact>,
  logoFile: { blob: "" } as TeamPicsFile,
  kioskPassword: "",
  leagueLogoUrl: "",
  pageNumber: 0,
  error: "",
  loading: false,
  leagueBuyMissingSubjectsId: "",
  leagueBuySubjectsId: "",
  leagueBuyStaffId: "",
};
const mapStateToProps = (state: any) => ({
  user: state.usersState.user,
  userMeta: state.usersState.userMeta,
  updateEvent: state.eventsState.updateEvent,
  loadEventDetails: state.eventsState.loadEventDetails,
  selectedEvent: state.eventsState.selectedEvent,
  offerings: state.productsState.offerings,
  packages: state.productsState.packages,
});
const mapDispatchToProps = (dispatch: any) => ({
  onUpdateEvent: (
    uid: string,
    eventId: string,
    event: Event,
    logoFile: TeamPicsFile
  ) => {
    dispatch(fEvents.updateEventGeneral(event, eventId, uid, logoFile));
  },
  onClearUpdateEvent: () => {
    dispatch(rEvents.modifyUpdateEvent("LOADING", false));
  },
});
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
    md: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
    md: {
      span: 16,
      offset: 4,
    },
  },
};
class ClientGeneralForm extends Component<Props, State> {
  state = { ...INITIAL_STATE };
  componentDidMount() {
    if (this.props.selectedEvent.key) {
      this.setState({
        ...(this.props.selectedEvent as any),
        eventId: this.props.selectedEvent.key,
        leagueBuyMissingSubjectsId:
          this.props.selectedEvent.leagueBuyPackageIds?.missingSubjects,
        leagueBuySubjectsId:
          this.props.selectedEvent.leagueBuyPackageIds?.subjects,
        leagueBuyStaffId: this.props.selectedEvent.leagueBuyPackageIds?.staff,
      });
    }
  }
  componentWillReceiveProps(nextProps: Props) {
    if (
      nextProps.selectedEvent.key &&
      !nextProps.updateEvent.loading &&
      !nextProps.updateEvent.error &&
      !nextProps.loadEventDetails.loading
    ) {
      this.setState({
        ...(nextProps.selectedEvent as any),
        leagueBuyMissingSubjectsId:
          nextProps.selectedEvent.leagueBuyPackageIds?.missingSubjects,
        leagueBuySubjectsId:
          nextProps.selectedEvent.leagueBuyPackageIds?.subjects,
        leagueBuyStaffId: nextProps.selectedEvent.leagueBuyPackageIds?.staff,
        eventId: this.props.selectedEvent.key,
      });
    }
  }
  handleUploadChange = (file: any) => {};
  beforeUpload = (
    file: RcFile,
    FileList: RcFile[]
  ): boolean | PromiseLike<any> => {
    const fileType = getFileType(file.name);
    if (!checkFileSize(file.size, 2)) {
      this.setState({ error: "Max file size is 2MB" });
      return false;
    }
    var fileReader = new FileReader();
    fileReader.onloadend = (e: any) => {
      var arr = new Uint8Array(e.target.result).subarray(0, 4);
      var header = "";
      for (var i = 0; i < arr.length; i++) {
        header += arr[i].toString(16);
      }
      // Check the file signature against known types
      if (!checkIfValidImage(header, getMimeType(fileType))) {
        this.setState({ error: "Please upload a jpeg or png!" });
      } else {
        getBase64(file, (imageUrl: string) =>
          this.setState({
            leagueLogoUrl: imageUrl,
            logoFile: {
              fileType: fileType,
              mimeType: getMimeType(fileType),
              blob: imageUrl,
              fileSize: file.size,
            },
          })
        );
      }
    };
    fileReader.readAsArrayBuffer(file.slice(0, 4));
    return true;
  };
  updateEventGeneral = () => {
    const {
      offeringId,
      contact,
      clientId,
      clientName,
      active,
      name,
      groupsExpected,
      priColor,
      secColor,
      divisionType,
      type,
      contacts,
      leagueBuyMissingSubjectsId,
      leagueBuySubjectsId,
      leagueBuyStaffId,
      logoFile,
      leagueLogoUrl,
      kioskPassword,
      pageNumber,
      error,
      loading,
    } = this.state;
    const { user, selectedEvent } = this.props;
    this.setState({ error: "" });
    this.props.onClearUpdateEvent();

    if (name === "") {
      this.setState({
        error: "Please enter a name for the event.",
      });
      return;
    }
    if (groupsExpected === 0) {
      this.setState({
        error: "Please enter a name for the event.",
      });
      return;
    }

    const newEvent: Event = {
      photographerId: user.userId!,
      active,
      name,
      groupsExpected: groupsExpected,
      offeringId: offeringId,
      priColor,
      secColor,
      type,
      divisionType,
      contact: selectedEvent.contact,
      clientId: selectedEvent.clientId,
      clientName: selectedEvent.clientName,
      kioskPassword,
      timestamp: new Date().getTime(),
      leagueLogoUrl: leagueLogoUrl,
      friendlyId: selectedEvent.friendlyId,
      leagueBuyPackageIds: {
        subjects: leagueBuySubjectsId,
        staff: leagueBuyStaffId,
        missingSubjects: leagueBuyMissingSubjectsId,
      },
    };

    if (logoFile.blob) {
      this.props.onUpdateEvent(
        user.userId,
        selectedEvent.key,
        newEvent,
        logoFile
      );
    } else {
      this.props.onUpdateEvent(user.userId, selectedEvent.key, newEvent, {});
    }
  };
  render() {
    const {
      user,
      userMeta,
      updateEvent,
      loadEventDetails,
      selectedEvent,
      offerings,
      packages,
    } = this.props;
    const {
      offeringId,
      contact,
      clientId,
      clientName,
      active,
      name,
      groupsExpected,
      priColor,
      secColor,
      divisionType,
      type,
      contacts,
      logoFile,
      leagueLogoUrl,
      kioskPassword,
      leagueBuyMissingSubjectsId,
      leagueBuyStaffId,
      leagueBuySubjectsId,
      pageNumber,
      error,
      loading,
    } = this.state;
    const generalIsInvalid =
      name === "" ||
      groupsExpected === 0 ||
      priColor === "" ||
      secColor === "" ||
      type === "" ||
      divisionType === "" ||
      leagueLogoUrl === "";

    const offering = getArrayItemByKey(offeringId, offerings);
    let displayedOffering = "";
    if (offering) {
      displayedOffering = offering.name;
    }
    const uploadButton = (
      <div>
        <Icon type={"plus"} />
        <div className="ant-upload-text">Upload Event Logo</div>
      </div>
    );
    return (
      <div>
        <Spin
          indicator={<Icon type="loading" style={{ fontSize: 24 }} spin />}
          spinning={
            (updateEvent.loading as boolean) ||
            (loadEventDetails.loading as boolean)
          }>
          <Form className="settings-form">
            <Form.Item label="Active Event" {...formItemLayout}>
              <Checkbox
                checked={active}
                onChange={(e) => {
                  this.setState({ active: e.target.checked });
                }}
              />
            </Form.Item>
            <Form.Item label="Event Name" {...formItemLayout}>
              <Input
                autoCapitalize={"on"}
                onChange={(e) => {
                  if (e.target.value.length <= 30) {
                    this.setState({ name: titleCase(e.target.value) });
                  }
                }}
                placeholder="Name"
                value={name}
              />
            </Form.Item>
            <Form.Item label="Client Name" {...formItemLayout}>
              <span>{clientName ? clientName : ""}</span>
            </Form.Item>
            <Form.Item label="Contact Name" {...formItemLayout}>
              <span>
                {contact.firstName
                  ? `${contact.firstName} ${contact.lastName}`
                  : ""}
              </span>
            </Form.Item>
            <Form.Item label="Contact Role" {...formItemLayout}>
              <span>{contact.role ? contactRoleMap[contact.role] : ""}</span>
            </Form.Item>
            <Form.Item label="Offering" {...formItemLayout}>
              <span>{offeringId ? displayedOffering : ""}</span>
            </Form.Item>
            <Form.Item label="Event Link" {...formItemLayout}>
              <a
                onChange={() => {}}
                href={`${eventUrl}${selectedEvent.key}`}
                target="_blank"
                rel="noreferrer">{`${eventUrl}${selectedEvent.key}`}</a>
            </Form.Item>
            <Form.Item label="Type" {...formItemLayout}>
              <Select
                placeholder={"Select Event Type"}
                labelInValue
                value={type ? { key: type } : undefined}
                onChange={(e: any) => {
                  this.setState({
                    type: e.key,
                  });
                }}>
                {getEventTypeOptions()}
              </Select>
            </Form.Item>
            <Form.Item label="Division" {...formItemLayout}>
              <Select
                placeholder={"Select Division Type"}
                labelInValue
                value={divisionType ? { key: divisionType } : undefined}
                onChange={(e: any) => {
                  this.setState({
                    divisionType: e.key,
                  });
                }}>
                {getDivisionTypeOptions()}
              </Select>
            </Form.Item>
            <Form.Item label="Groups Expected" {...formItemLayout}>
              <InputNumber
                style={{ width: "100%" }}
                min={0}
                max={100}
                step={1}
                placeholder="# of Groups"
                onChange={(e) => {
                  this.setState({ groupsExpected: e as number });
                }}
                value={groupsExpected}
              />
            </Form.Item>
            <Form.Item label="Kiosk Password" {...formItemLayout}>
              <Input
                placeholder="Enter an easy password.."
                onChange={(e) => {
                  if (e.target.value.length <= 30) {
                    this.setState({ kioskPassword: e.target.value.trim() });
                  }
                }}
                value={kioskPassword}
              />
            </Form.Item>
            {/* <Form.Item label='Location Notes' {...formItemLayout}>
							<TextArea
								placeholder='Enter Notes...'
								value={locationNotes}
								onChange={(e) => {
									this.setState({ locationNotes: e.target.value });
								}}
								rows={4}
							/>
						</Form.Item> */}
            <Form.Item label="Primary Color" {...formItemLayout}>
              <ColorPicker
                setHex={(color: any) => {
                  this.setState({ priColor: color.hex });
                }}
                hex={priColor}
              />
            </Form.Item>
            <Form.Item label="Secondary Color" {...formItemLayout}>
              <ColorPicker
                setHex={(color: any) => {
                  this.setState({ secColor: color.hex });
                }}
                hex={secColor}
              />
            </Form.Item>
            <Form.Item label="League Buy for Subjects" {...formItemLayout}>
              <Select
                placeholder={"Select Package"}
                labelInValue
                value={
                  leagueBuySubjectsId ? { key: leagueBuySubjectsId } : undefined
                }
                onChange={(e: any) => {
                  this.setState({
                    leagueBuySubjectsId: e.key,
                  });
                }}>
                <Option key={"blank"} value={""}>
                  None
                </Option>
                {getPackageOptionsForEvent(packages)}
              </Select>
            </Form.Item>
            <Form.Item
              label="League Buy for Missing Subjects"
              {...formItemLayout}>
              <Select
                placeholder={"Select Package"}
                labelInValue
                value={
                  leagueBuyMissingSubjectsId
                    ? { key: leagueBuyMissingSubjectsId }
                    : undefined
                }
                onChange={(e: any) => {
                  this.setState({
                    leagueBuyMissingSubjectsId: e.key,
                  });
                }}>
                <Option key={"blank"} value={""}>
                  None
                </Option>
                {getPackageOptionsForEvent(packages)}
              </Select>
            </Form.Item>
            <Form.Item label="League Buy for Staff" {...formItemLayout}>
              <Select
                placeholder={"Select Package"}
                labelInValue
                value={leagueBuyStaffId ? { key: leagueBuyStaffId } : undefined}
                onChange={(e: any) => {
                  this.setState({
                    leagueBuyStaffId: e.key,
                  });
                }}>
                <Option key={"blank"} value={""}>
                  None
                </Option>
                {getPackageOptionsForEvent(packages)}
              </Select>
            </Form.Item>
            <Form.Item label="Event Logo" {...formItemLayout}>
              <Upload
                name="avatar"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                beforeUpload={this.beforeUpload}
                onChange={this.handleUploadChange}
                customRequest={dummyRequest}
                style={{ height: "300px", minWidth: "300px" }}>
                {leagueLogoUrl ? (
                  <img
                    style={{ maxWidth: "800px" }}
                    src={leagueLogoUrl}
                    alt="avatar"
                  />
                ) : (
                  uploadButton
                )}
              </Upload>
            </Form.Item>
            <Form.Item />
            {(updateEvent.error || error) && (
              <Form.Item {...tailFormItemLayout}>
                <Alert
                  className="uk-text-center"
                  message={updateEvent.error || error}
                  type="error"
                />
              </Form.Item>
            )}
            {updateEvent.success && (
              <Form.Item {...tailFormItemLayout}>
                <Alert
                  className="uk-text-center"
                  message={updateEvent.success}
                  type="success"
                />
              </Form.Item>
            )}
            <Form.Item {...tailFormItemLayout}>
              <Button
                onClick={this.updateEventGeneral}
                disabled={generalIsInvalid || (updateEvent.loading as boolean)}
                type="primary">
                Update
              </Button>
            </Form.Item>
          </Form>
        </Spin>
      </div>
    );
  }
}

export default compose<any, any>(
  connect<StateProps, DispatchProps, OwnProps>(
    mapStateToProps,
    mapDispatchToProps
  )
)(ClientGeneralForm);
