import React, { Component } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { compose } from "recompose";
import { connect } from "react-redux";
import { User, UserMeta } from "../../models/UsersModel";
import { purchases as fPurchases } from "../../firebase";
import * as rPurchases from "../../reducers/purchases";
import { camelize, capitalizeFirstLetter } from "../../helpers/string";
import { Purchase, PurchasePackage } from "../../models/PurchaseModel";
import {
  Spin,
  Select,
  Input,
  Button,
  DatePicker,
  Row,
  Col,
  List,
  message,
  Radio,
  Form,
  Divider,
  Icon,
} from "antd";
import PurchaseDetailModal from "./PurchaseDetailModal";
import {
  getDate,
  getLastWeek,
  getTimeHrsMin,
  getEndOfToday,
} from "../../helpers/time";
import converter from "json-2-csv";
import fileDownload from "js-file-download";
import emptyview from "../../assets/emptyview_clients.png";
import { TableRowSelection } from "antd/lib/table";
import moment from "moment";
import {
  WindowScroller,
  AutoSizer,
  List as VList,
  InfiniteLoader,
  CellMeasurerCache,
} from "react-virtualized";
import InfiniteScroll from "react-infinite-scroller";
import square from "../../assets/square.jpg";
import { firestore, firebase } from "../../firebase/firebaseConfig";
import { Subject } from "../../models/EventsModel";
import { format } from "date-fns";
const Option = Select.Option;
const Search = Input.Search;

const RadioGroup = Radio.Group;
const { loadNextPurchases }: { loadNextPurchases: any } = fPurchases;

interface OwnProps extends RouteComponentProps {}
interface StateProps {
  user: User;
  userMeta: UserMeta;
}
interface DispatchProps {
  onSetSelectedPurchase: Function;
}
type Props = StateProps & DispatchProps & OwnProps;
type State = {
  error: string;
  visible: boolean;
  loading: boolean;
  exporting: boolean;
  purchases: Array<Purchase>;
  startTime: number;
  endTime: number;
  lastTimestamp: number;
  paymentType: string;
  searchType: string;
  search: string;
  hasMore: boolean;
};
const INITIAL_STATE = {
  error: "",
  visible: false,
  purchases: [],
  loading: true,
  startTime: getLastWeek(),
  endTime: getEndOfToday(),
  lastTimestamp: getEndOfToday(),
  paymentType: "all",
  search: "",
  searchType: "Select...",
  hasMore: true,
  exporting: false,
};
const mapStateToProps = (state: any) => ({
  user: state.usersState.user,
  userMeta: state.usersState.userMeta,
});
const mapDispatchToProps = (dispatch: any) => ({
  onSetSelectedPurchase: (purchase: Purchase) => {
    dispatch(rPurchases.setSelectedPurchase(purchase));
  },
});
class PurchaseList extends Component<Props, State> {
  state = { ...INITIAL_STATE };
  componentDidMount() {
    this.fetchPurchases(
      this.props.user.userId!,
      this.state.endTime,
      this.state.startTime,
      this.state.paymentType,
      this.state.searchType,
      this.state.search
    );
  }
  fetchPurchases = (
    userId: string,
    startTime: number,
    endTime: number,
    paymentType: string,
    searchType: string,
    search: string
  ) => {
    this.setState({
      loading: true,
      hasMore: true,
      purchases: [],
      lastTimestamp: startTime,
    });
    fPurchases
      .loadInitialPurchases(
        userId,
        startTime,
        endTime,
        paymentType,
        searchType,
        search
      )
      .then((purchases) => {
        if (purchases.length === 0) {
          this.setState({
            purchases: [],
            loading: false,
            //lastTimestamp: getEndOfToday()
          });
        } else if (purchases.length < 20) {
          this.setState({
            purchases: [...this.state.purchases, ...purchases],
            loading: false,
            lastTimestamp: purchases[purchases.length - 1].timestamp,
            hasMore: false,
          });
        } else {
          this.setState({
            purchases: [...this.state.purchases, ...purchases],
            loading: false,
            lastTimestamp: purchases[purchases.length - 1].timestamp,
          });
        }
      })
      .catch((error) => {
        console.log(
          "🚀 ~ file: PurchaseList.tsx ~ line 143 ~ PurchaseList ~ error",
          error
        );
        this.setState({ loading: false });
        message.error("Something went wrong, please try again");
      });
  };
  fetchMorePurchases = (
    userId: string,
    startTime: number,
    endTime: number,
    paymentType: string,
    searchType: string,
    search: string
  ) => {
    fPurchases
      .loadNextPurchases(
        userId,
        startTime,
        endTime,
        paymentType,
        searchType,
        search
      )
      .then((purchases) => {
        if (purchases.length === 0) {
          this.setState({
            loading: false,
          });
        } else {
          this.setState({
            purchases: [...this.state.purchases, ...purchases],
            loading: false,
            lastTimestamp: purchases[purchases.length - 1].timestamp,
            hasMore:
              purchases[purchases.length - 1].timestamp ===
              this.state.lastTimestamp
                ? false
                : true,
          });
        }
      })
      .catch((error: any) => {
        console.log(error);
        this.setState({ loading: false });
        message.error("Something went wrong, please try again");
      });
  };
  handleInfiniteOnLoad = () => {
    let purchases: Array<Purchase> = this.state.purchases;
    this.setState({
      loading: true,
    });
    if (
      this.state.startTime > this.state.lastTimestamp ||
      (purchases.length > 0 && purchases.length < 20)
    ) {
      this.setState({ loading: false, hasMore: false });
      return Promise.resolve({});
    } else {
      this.fetchMorePurchases(
        this.props.user.userId!,
        this.state.lastTimestamp,
        this.state.startTime,
        this.state.paymentType,
        this.state.searchType,
        this.state.search
      );
    }
  };
  clearFilters = () => {
    this.setState({
      search: "",
      searchType: "Select...",
      paymentType: "all",
      startTime: getLastWeek(),
      endTime: getEndOfToday(),
      lastTimestamp: getEndOfToday(),
      hasMore: true,
    });
    this.fetchPurchases(
      this.props.user.userId!,
      getEndOfToday(),
      getLastWeek(),
      "all",
      "Select...",
      ""
    );
  };
  filter = (
    userId: string,
    startTime: number,
    endTime: number,
    paymentType: string,
    searchType: string,
    search: string
  ) => {
    this.fetchPurchases(
      userId!,
      startTime,
      endTime,
      paymentType,
      searchType,
      search
    );
  };
  export = () => {
    this.setState({ exporting: true });
    message.loading("Downloading purchases", 5);
    let purchases: Array<Purchase> = [];
    fPurchases
      .getAllPurchases(
        this.props.user.userId!,
        "",
        this.state.endTime,
        this.state.startTime,
        this.state.paymentType,
        this.state.searchType,
        this.state.search
      )
      .then((selectedPurchases) => {
        purchases = selectedPurchases;
        if (purchases.length === 0) {
          throw Error("no purchases");
        }
        let exportArray: any = [];
        purchases.forEach((selectedPurchase, index) => {
          const { customerInfo, packages } = selectedPurchase;
          const fullSubject = selectedPurchase.fullSubject;

          let amountPackages: Array<PurchasePackage> = [];
          packages.forEach((pack) => {
            const flattenedPacks = Array(pack.count).fill(pack);
            amountPackages = [...flattenedPacks, ...amountPackages];
          });

          const newPackages = amountPackages.map((pack) => {
            // const newPack = { ...pack, ...subject, ...customerInfo };
            const extras: any = {};
            pack.products.forEach((product) => {
              Object.keys(product.extras).forEach((key) => {
                extras[`order${capitalizeFirstLetter(camelize(key))}`] =
                  product.extras[key];
              });
            });

            const newPack = {
              orderSku: pack.sku,
              orderOfferingId: selectedPurchase.offeringId,
              orderPaid: selectedPurchase.paid,
              orderPaymentType: selectedPurchase.paymentType,
              orderPhotographerId: selectedPurchase.photographerId,
              orderShipping: selectedPurchase.shipping,
              orderDate: format(
                new Date(selectedPurchase.timestamp),
                "MM, d, yyyy"
              ),
              orderTransactionId: selectedPurchase.transactionId,
              orderCost: selectedPurchase.totalCost / 100,
              ...extras,
              customerFirstName: customerInfo.firstName,
              customerLastName: customerInfo.lastName,
              customerEmail: customerInfo.email,
              customerPhoneNumber: customerInfo.phoneNumber,
              customerAddressLine1: customerInfo.shippingAddress.addressLine1,
              customerAddressLine2: customerInfo.shippingAddress.addressLine2,
              customerCity: customerInfo.shippingAddress.city,
              customerCountry: customerInfo.shippingAddress.country,
              customerPostalCode: customerInfo.shippingAddress.postalCode,
              customerProvince: customerInfo.shippingAddress.province,
            };

            if (selectedPurchase.fullSubject) {
              Object.keys(selectedPurchase.fullSubject!).forEach((key) => {
                newPack[`subject${capitalizeFirstLetter(key)}`] = (
                  selectedPurchase as any
                ).fullSubject[key];
              });
            }
            newPack.subjectMissing = fullSubject?.position
              ? this.checkIfMissing(fullSubject.position!)
              : "no";
            newPack.subjectRowNumber = fullSubject?.position
              ? this.getSubjectRowNumber(fullSubject!.position!)
              : 0;

            if (selectedPurchase.fullGroup) {
              Object.keys(selectedPurchase.fullGroup!).forEach((key) => {
                newPack[`group${capitalizeFirstLetter(key)}`] = (
                  selectedPurchase as any
                ).fullGroup[key];
              });
            }

            newPack[`groupFirstRow`] = selectedPurchase.subjectRows?.row1;
            newPack[`groupSecondRow`] = selectedPurchase.subjectRows?.row2;
            newPack[`groupThirdRow`] = selectedPurchase.subjectRows?.row3;
            newPack[`groupFourthRow`] = selectedPurchase.subjectRows?.row4;
            newPack[`groupFifthRow`] = selectedPurchase.subjectRows?.row5;
            newPack[`groupMissingDeletedRow`] =
              selectedPurchase.subjectRows?.row0;

            return newPack;
          });
          exportArray = [...newPackages, ...exportArray];
        });
        converter.json2csv(
          [...exportArray],
          (err: any, csv: any) => {
            fileDownload(csv, `purchases.csv`);
            message.destroy();
          },
          { expandArrayObjects: true, emptyFieldValue: "" }
        );
        this.setState({ exporting: false });
      })
      .catch((error) => {
        console.log(error);
        this.setState({ exporting: false });
        message.destroy();
        message.error("Something went wrong, please try again.");
      });
  };

  getSubjectRowNumber = (position: string) => {
    switch (position.charAt(0)) {
      case "A":
        return 1;
      case "B":
        return 2;
      case "C":
        return 3;
      case "D":
        return 4;
      case "E":
        return 5;
      case "M":
        return 0;
      case "X":
        return 0;
    }
  };

  checkIfMissing = (position: string) => {
    switch (position.charAt(0)) {
      case "M":
        return "yes";
      case "X":
        return "yes";
      default:
        return "no";
    }
  };

  render() {
    const empty = (
      <div className="uk-flex uk-flex-center uk-text-center uk-width-expand">
        <div className="uk-flex uk-flex-column uk-flex-center uk-width-large">
          <div className="uk-width-expand uk-flex uk-flex-center">
            <img src={emptyview} className="empty-view" />
          </div>
          <span className="uk-margin-top uk-margin-bottom">
            No purchase available.
          </span>
        </div>
      </div>
    );
    const selectBefore = (
      <Select
        defaultValue="Select..."
        style={{
          width: "200px",
          padding: "0px 11px",
          margin: 0,
          border: "1px solid #d9d9d9",
          borderTopLeftRadius: "2px",
          borderBottomLeftRadius: "2px",
        }}
        onChange={(e: string) => {
          this.setState({ searchType: e });
        }}
        value={this.state.searchType}
      >
        <Option value="customerFirstName">Customer First Name</Option>
        <Option value="customerLastName">Customer Last Name</Option>
        <Option value="subjectFullName">Subject Full Name</Option>
        <Option value="eventName">Event Name</Option>
        <Option value="groupName">Group Name</Option>
      </Select>
    );
    return (
      <div
        style={{
          border: "1px solid #DCDCDC",
          borderRadius: "4px",
        }}
      >
        <Row
          style={{
            paddingLeft: "10px",
            paddingRight: "10px",
            paddingTop: "10px",
          }}
          className="uk-margin-small-bottom"
        >
          <Col sm={24} md={4}>
            <h2>Purchases</h2>
          </Col>
          <Col>
            <Row type="flex" justify="end">
              <Button
                className="uk-margin-small-right"
                onClick={this.clearFilters}
              >
                Clear filters
              </Button>
              <Button
                loading={this.state.exporting}
                disabled={this.state.purchases.length === 0}
                onClick={this.export}
              >
                Export
              </Button>
            </Row>
          </Col>
        </Row>
        <Divider style={{ marginTop: "0px" }} />
        <Row style={{ paddingLeft: "10px", paddingRight: "10px" }}>
          <Col
            className="uk-margin-small-bottom uk-margin-small-right"
            sm={24}
            md={8}
          >
            <Form.Item label="Start Time">
              <DatePicker
                className="uk-width-expand"
                style={{ minWidth: "150px" }}
                allowClear={false}
                value={moment(this.state.startTime)}
                onChange={(e) => {
                  if (e && e.toDate().getTime() < this.state.endTime) {
                    this.setState({
                      startTime: e.toDate().getTime(),
                    });
                    this.filter(
                      this.props.user.userId!,
                      this.state.endTime,
                      e.toDate().getTime(),
                      this.state.paymentType,
                      this.state.searchType,
                      this.state.search
                    );
                  }
                }}
              />
            </Form.Item>
          </Col>
          <Col
            className="uk-margin-small-bottom uk-margin-small-right"
            sm={24}
            md={8}
          >
            <Form.Item label="End Time">
              <DatePicker
                className="uk-width-expand"
                style={{ minWidth: "150px" }}
                allowClear={false}
                value={moment(this.state.endTime)}
                onChange={(e) => {
                  if (
                    e &&
                    e.toDate().getTime() < new Date().getTime() &&
                    e.toDate().getTime() > this.state.startTime
                  ) {
                    this.setState({
                      endTime: e.toDate().getTime(),
                    });
                    this.filter(
                      this.props.user.userId!,
                      e.toDate().getTime(),
                      this.state.startTime,
                      this.state.paymentType,
                      this.state.searchType,
                      this.state.search
                    );
                  }
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row
          style={{ paddingLeft: "10px", paddingRight: "10px" }}
          type="flex"
          align="middle"
          justify="start"
        >
          <Col className="uk-margin-right">
            <Form.Item label="Search:">
              <Search
                value={this.state.search}
                style={{ marginTop: "5px" }}
                addonBefore={selectBefore}
                placeholder="Search..."
                onChange={(e) => {
                  this.setState({ search: e.target.value });
                }}
                onSearch={(value) => {
                  this.filter(
                    this.props.user.userId!,
                    this.state.endTime,
                    this.state.startTime,
                    this.state.paymentType,
                    this.state.searchType,
                    value
                  );
                }}
                enterButton
              />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item label="Payment Type:">
              <Radio.Group
                onChange={(e) => {
                  this.setState({ paymentType: e.target.value });
                  this.filter(
                    this.props.user.userId!,
                    this.state.endTime,
                    this.state.startTime,
                    e.target.value,
                    this.state.searchType,
                    this.state.search
                  );
                }}
                defaultValue="all"
                buttonStyle="solid"
                value={this.state.paymentType}
              >
                <Radio.Button value="all">All</Radio.Button>
                <Radio.Button value="card">Card</Radio.Button>
                <Radio.Button value="cash">Cash</Radio.Button>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
        <Divider style={{ marginBottom: "0px" }} />
        <div
          style={{
            padding: "10px",
            borderBottom: "1px solid #DCDCDC",
            background: "#fafafa",
          }}
        >
          <Row
            style={{
              height: "100%",
              padding: "10px",
            }}
            type="flex"
            align="middle"
            justify="space-between"
            className="uk-text-center"
          >
            <Col className="table-header uk-text-truncate" span={4}>
              Event Name
            </Col>
            <Col className="table-header uk-text-truncate" span={4}>
              Customer Name
            </Col>
            <Col className="table-header uk-text-truncate" span={4}>
              Subject Name
            </Col>
            <Col className="table-header uk-text-truncate" span={5}>
              Group Name
            </Col>
            <Col className="table-header uk-text-truncate" span={2}>
              Amount
            </Col>
            <Col className="table-header uk-text-truncate" span={2}>
              Type
            </Col>
            <Col className="table-header uk-text-truncate" span={3}>
              Date
            </Col>
          </Row>
        </div>
        <div className="demo-infinite-container">
          <InfiniteScroll
            initialLoad={false}
            pageStart={0}
            loadMore={this.handleInfiniteOnLoad}
            hasMore={!this.state.loading && this.state.hasMore}
            useWindow={false}
          >
            <List
              locale={{
                emptyText: (<span>{empty}</span>) as any,
              }}
              loading={{
                indicator: (
                  <Icon type="loading" style={{ fontSize: 36 }} spin />
                ),
                spinning:
                  this.state.loading && this.state.purchases.length === 0,
              }}
              dataSource={this.state.purchases}
              renderItem={(purchase: Purchase) => (
                <li
                  key={purchase.key}
                  style={{
                    cursor: "pointer",
                    borderBottom: "1px solid #DCDCDC",
                  }}
                  onClick={() => {
                    this.props.onSetSelectedPurchase(purchase);
                    this.setState({ visible: true });
                  }}
                  className="list-item uk-text-truncate"
                >
                  <Row
                    style={{ height: "100%", padding: "10px" }}
                    type="flex"
                    align="middle"
                    justify="space-between"
                    className="uk-text-center"
                  >
                    {purchase.transactionId && (
                      <Col className="uk-text-truncate" span={1}>
                        <img style={{ width: "30px" }} src={square} />
                      </Col>
                    )}
                    <Col className="uk-text-truncate" span={3}>
                      {purchase.subject.eventName}
                    </Col>
                    <Col className="uk-text-truncate" span={4}>
                      {`${purchase.customerInfo.firstName} ${purchase.customerInfo.lastName}`}
                    </Col>
                    <Col
                      className="uk-text-truncate"
                      span={4}
                    >{`${purchase.subject.fullName}`}</Col>
                    <Col
                      className="uk-text-truncate"
                      span={5}
                    >{`${purchase.subject.teamName}`}</Col>
                    <Col className="uk-text-truncate" span={2}>{`$${
                      Math.round(purchase.totalCost) / 100
                    }`}</Col>
                    <Col
                      className="uk-text-truncate"
                      span={2}
                    >{`${purchase.paymentType}`}</Col>
                    <Col className="uk-text-truncate" span={3}>
                      {getDate(purchase.timestamp)}
                    </Col>
                  </Row>
                </li>
              )}
            >
              {this.state.loading &&
                this.state.purchases.length > 0 &&
                this.state.hasMore && (
                  <div className="demo-loading-container">
                    <Spin
                      indicator={
                        <Icon type="loading" style={{ fontSize: 36 }} spin />
                      }
                    />
                  </div>
                )}
            </List>
          </InfiniteScroll>
          <PurchaseDetailModal
            visible={this.state.visible}
            onCancel={() => {
              this.setState({ visible: false });
            }}
          />
        </div>
      </div>
    );
  }
}

export default compose<any, any>(
  withRouter,
  connect<StateProps, DispatchProps, OwnProps>(
    mapStateToProps,
    mapDispatchToProps
  )
)(PurchaseList);
