import React, { Component } from 'react';
import { connect } from 'react-redux';
import { User, UserMeta } from '../../models/UsersModel';
import { StepAuth, StepTeamInfo } from '../../models/TeamBuilderModel';
import { Group, Event } from '../../models/EventsModel';
import * as rTeambuilder from '../../reducers/teambuilder';
import Animate from 'rc-animate';
import { Card, Form, Input, Avatar, Select, Checkbox, message, Upload, Icon } from 'antd';
import ColorPicker from '../../components/ColorPicker/ColorPicker';
import {
	getDivisionNameOptions,
	getDivisionUOptions,
	getDivisionYearsOptions,
	getDivisionText
} from '../../constants/selectOptions';
import { capitalizeFirstLetter } from '../../helpers/string';
import teamphoto from '../../assets/teamphoto.png';
import { RcFile } from 'antd/lib/upload/interface';
import {
	getFileType,
	checkFileSize,
	getBase64,
	getMimeType,
	dummyRequest,
	checkIfValidImage
} from '../../constants/fileTypes';

interface OwnProps { }
interface StateProps {
	user: User;
	userMeta: UserMeta;
	stepTeamInfo: StepTeamInfo;
	stepAuth: StepAuth;
	event: Event;
	allGroups: Array<Group>;
	pageNumber: number;
}
interface DispatchProps {
	onUpdateStepTeamInfo: Function;
}
type Props = StateProps & DispatchProps & OwnProps;
type State = {};

const mapStateToProps = (state: any) => ({
	user: state.usersState.user,
	userMeta: state.usersState.userMeta,
	event: state.teambuilderState.event,
	stepTeamInfo: state.teambuilderState.stepTeamInfo,
	stepAuth: state.teambuilderState.stepAuth,
	allGroups: state.teambuilderState.allGroups,
	pageNumber: state.teambuilderState.pageNumber
});

const mapDispatchToProps = (dispatch: any) => ({
	onUpdateStepTeamInfo: (stepTeamInfo: StepTeamInfo) => {
		dispatch(rTeambuilder.setStepTeamInfo(stepTeamInfo));
	}
});
const Div = (props: any) => {
	const childrenProps = { ...props };
	delete childrenProps.show;
	return <div {...childrenProps} />;
};
const formItemLayout = {
	labelCol: {
		xs: { span: 24 },
		sm: { span: 8 },
		md: { span: 8 }
	},
	wrapperCol: {
		xs: { span: 24 },
		sm: { span: 14 }
	}
};
const tailFormItemLayout = {
	wrapperCol: {
		xs: {
			span: 24,
			offset: 0
		},
		sm: {
			span: 16,
			offset: 8
		},
		md: {
			span: 16,
			offset: 4
		}
	}
};
class StepTeamInfoComp extends Component<Props, State> {
	componentDidMount() { }
	handleUploadChange = (file: any) => { };
	beforeUpload = (file: RcFile, FileList: RcFile[]): boolean | PromiseLike<any> => {
		const { stepTeamInfo } = this.props;
		const fileType = getFileType(file.name);
		if (!checkFileSize(file.size, 1)) {
			message.error('Max file size is 1MB');
			return false;
		}

		var fileReader = new FileReader();
		fileReader.onloadend = (e: any) => {
			var arr = new Uint8Array(e.target.result).subarray(0, 4);
			var header = '';
			for (var i = 0; i < arr.length; i++) {
				header += arr[i].toString(16);
			}
			// Check the file signature against known types
			if (!checkIfValidImage(header, getMimeType(fileType))) {
				message.error('Please upload a png or a jpg/jpeg');
			} else {
				getBase64(file, (imageUrl: string) => {
					const newStepTeamInfo = {
						...stepTeamInfo,
						logoUrl: imageUrl,
						logoFile: {
							fileType: fileType,
							mimeType: getMimeType(fileType),
							blob: imageUrl,
							fileSize: file.size
						}
					};
					this.props.onUpdateStepTeamInfo(newStepTeamInfo);
				});
			}
		};
		return true;
	};

	render() {
		const { pageNumber, stepAuth, stepTeamInfo, event } = this.props;
		const uploadButton = (
			<div>
				<Icon type={'plus'} />
				<div className='ant-upload-text'>Upload Team Logo</div>
			</div>
		);
		return (
			<Animate transitionName='fade' transitionAppear>
				<Div style={{ height: '80%' }} show={pageNumber === 5}>
					<div style={{ height: '100%' }} className='uk-flex uk-flex-center uk-margin-top'>
						<Card
							style={{
								borderRadius: '5px',
								minWidth: '80vw',
								overflowY: 'auto'
							}}
						>
							<div className='uk-text-center'>
								<h2>Tell us about your team</h2>
							</div>
							<Form>
								<Form.Item label='Is this your team logo?' {...formItemLayout}>
									<Upload
										name='avatar'
										listType='picture-card'
										className='avatar-uploader'
										showUploadList={false}
										beforeUpload={this.beforeUpload}
										onChange={this.handleUploadChange}
										customRequest={dummyRequest}
									>
										<img style={{ maxWidth: '300px' }} src={stepTeamInfo.logoUrl} alt='avatar' />
										{uploadButton}
									</Upload>
								</Form.Item>
								<Form.Item label='Are these your team colours?' {...formItemLayout}>
									<div className='uk-flex uk-flex-row uk-flex-wrap'>
										<ColorPicker
											style={{ width: '100px' }}
											setHex={(color: any) => {
												const newStepTeamInfo = {
													...stepTeamInfo,
													priColor: color.hex
												};
												this.props.onUpdateStepTeamInfo(newStepTeamInfo);
											}}
											hex={stepTeamInfo.priColor}
										/>
										<ColorPicker
											style={{ width: '100px' }}
											className='uk-margin-left'
											setHex={(color: any) => {
												const newStepTeamInfo = {
													...stepTeamInfo,
													secColor: color.hex
												};
												this.props.onUpdateStepTeamInfo(newStepTeamInfo);
											}}
											hex={stepTeamInfo.secColor}
										/>
									</div>
								</Form.Item>
								{event.divisionType !== 'other' ? (
									<Form.Item label='Division' {...formItemLayout}>
										<Select
											placeholder={'Select Division'}
											labelInValue
											value={stepTeamInfo.division ? { key: stepTeamInfo.division } : undefined}
											onChange={(e: { key: string }) => {
												const newStepTeamInfo = {
													...stepTeamInfo,
													division: e.key,
													fullName: `${capitalizeFirstLetter(
														e.key !== 'other'
															? getDivisionText(e.key, event.divisionType)
															: stepTeamInfo.otherDivision
													)} ${capitalizeFirstLetter(
														stepTeamInfo.subDivision
													)} ${capitalizeFirstLetter(stepTeamInfo.name)}`
												};
												this.props.onUpdateStepTeamInfo(newStepTeamInfo);
											}}
										>
											{event.divisionType === 'named' && getDivisionNameOptions()}
											{event.divisionType === 'year' && getDivisionYearsOptions()}
											{event.divisionType === 'u##' && getDivisionUOptions()}
										</Select>
									</Form.Item>
								) : (
									<Form.Item label='Division' {...formItemLayout}>
										<Input
											placeholder='Division'
											value={stepTeamInfo.division}
											onChange={(e) => {
												const newStepTeamInfo = {
													...stepTeamInfo,
													division: e.target.value,
													fullName: `${capitalizeFirstLetter(
														getDivisionText(e.target.value, event.divisionType)
													)} ${capitalizeFirstLetter(
														stepTeamInfo.subDivision
													)} ${capitalizeFirstLetter(stepTeamInfo.name)}`
												};
												this.props.onUpdateStepTeamInfo(newStepTeamInfo);
											}}
										/>
									</Form.Item>
								)}
								{stepTeamInfo.division === 'other' && (
									<Form.Item label='Specify Division' {...formItemLayout}>
										<Input
											placeholder='Division'
											value={stepTeamInfo.otherDivision}
											onChange={(e) => {
												const newStepTeamInfo = {
													...stepTeamInfo,
													otherDivision: e.target.value,
													fullName: `${capitalizeFirstLetter(
														getDivisionText(e.target.value, event.divisionType)
													)} ${capitalizeFirstLetter(
														stepTeamInfo.subDivision
													)} ${capitalizeFirstLetter(stepTeamInfo.name)}`
												};
												if (e.target.value.length <= 40) {
													this.props.onUpdateStepTeamInfo(newStepTeamInfo);
												}
											}}
										/>
									</Form.Item>
								)}
								<Form.Item label='Sub Division' {...formItemLayout}>
									<Input
										placeholder='Example) Girls League'
										value={stepTeamInfo.subDivision}
										onChange={(e) => {
											const newStepTeamInfo = {
												...stepTeamInfo,
												subDivision: e.target.value,
												fullName: `${capitalizeFirstLetter(
													stepTeamInfo.division !== 'other'
														? getDivisionText(stepTeamInfo.division, event.divisionType)
														: stepTeamInfo.otherDivision
												)} ${capitalizeFirstLetter(e.target.value)} ${capitalizeFirstLetter(
													stepTeamInfo.name
												)}`
											};
											if (e.target.value.length <= 40) {
												this.props.onUpdateStepTeamInfo(newStepTeamInfo);
											}
										}}
									/>
								</Form.Item>
								<Form.Item label='Team Name' {...formItemLayout}>
									<Input
										placeholder='Name'
										value={stepTeamInfo.name}
										onChange={(e) => {
											const newStepTeamInfo = {
												...stepTeamInfo,
												name: e.target.value,
												fullName: `${capitalizeFirstLetter(
													stepTeamInfo.division !== 'other'
														? getDivisionText(stepTeamInfo.division, event.divisionType)
														: stepTeamInfo.otherDivision
												)} ${capitalizeFirstLetter(
													stepTeamInfo.subDivision
												)} ${capitalizeFirstLetter(e.target.value)}`
											};
											if (e.target.value.length <= 40) {
												this.props.onUpdateStepTeamInfo(newStepTeamInfo);
											}
										}}
									/>
								</Form.Item>
								<div className='uk-flex uk-flex-center uk-flex-column uk-text-center'>
									<div className='uk-flex uk-flex-center uk-flex-middle'>
										<img src={teamphoto} style={{ width: '300px' }} />
									</div>
									<h3>Full Team Name:</h3>
									<h2 className='uk-margin-remove-top'>
										{stepTeamInfo.fullName ? stepTeamInfo.fullName : '...'}
									</h2>
									<Checkbox
										checked={stepTeamInfo.secondDisclaimer}
										onChange={(e) => {
											const newStepTeamInfo = {
												...stepTeamInfo,
												secondDisclaimer: e.target.checked
											};
											this.props.onUpdateStepTeamInfo(newStepTeamInfo);
										}}
									>
										Is your full team name correct? It will be printed on all products*
									</Checkbox>
								</div>
							</Form>
						</Card>
					</div>
				</Div>
			</Animate>
		);
	}
}

export default connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps)(StepTeamInfoComp);
