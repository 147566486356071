import React, { Component } from 'react';
import withAuthorization from '../../hoc/withAuthorization';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import {
  Modal,
  Steps,
  Button,
  Divider,
  Form,
  Input,
  Select,
  Radio,
  Upload,
  Icon,
  Table,
  Row,
  Col,
  Spin,
  Alert,
  message,
  Empty,
  InputNumber,
  DatePicker,
  TimePicker,
  Tag
} from 'antd';
import { UserMeta, User } from '../../models/UsersModel';
import {
  TaskState,
  TeamPicsFile,
  TeamPicsLocation,
  Season,
  Contact
} from '../../models/Commons';
import { groups as fGroups } from '../../firebase';
import TeampicsPlaces from '../../components/TeampicsPlaces/TeampicsPlaces';
import * as rGroups from '../../reducers/groups';
import { Event, Shoot, Group, Subject } from '../../models/EventsModel';
import moment from 'moment';
import { getDate, getTimeHrsMin } from '../../helpers/time';
import SubjectsListModal from './SubjectsListModal';
import emptyview from '../../assets/emptyview_groups.png';
const { Column } = Table;
const confirm = Modal.confirm;

interface OwnProps { }
interface StateProps {
  user: User;
  userMeta: UserMeta;
  loadGroups: TaskState;
  groups: Array<Group>;
  selectedGroup: Group;
  selectedEvent: Event;
}
interface DispatchProps {
  onSetSelectedGroup: Function;
  onClearSubjects: Function;
}
type Props = StateProps & DispatchProps & OwnProps;
type State = {
  eventId: string;
  error: string;
  visible: boolean;
};
const INITIAL_STATE = {
  eventId: '',
  error: '',
  visible: false
};
const mapStateToProps = (state: any) => ({
  user: state.usersState.user,
  userMeta: state.usersState.userMeta,
  loadGroups: state.groupsState.loadGroups,
  groups: state.groupsState.groups,
  selectedGroup: state.groupsState.selectedGroup,
  selectedEvent: state.eventsState.selectedEvent
});
const mapDispatchToProps = (dispatch: any) => ({
  onSetSelectedGroup: (group: Group) => {
    dispatch(rGroups.setSelectedGroup(group));
    dispatch(fGroups.loadSubjects(group.key!));
  },
  onClearSubjects: () => {
    dispatch(rGroups.modifySubjectsArray('CLEAR', {} as Subject));
  }
});
class GroupsList extends Component<Props, State> {
  state = { ...INITIAL_STATE };
  componentDidMount() {
    this.setState({
      eventId: this.props.selectedGroup.key!
    });
  }
  componentWillReceiveProps(nextProps: Props) {
    if (nextProps.selectedEvent.key && !this.props.selectedEvent.key) {
      this.setState({
        eventId: nextProps.selectedEvent.key!
      });
    }
  }
  componentWillUnmount() {
    fGroups.unSubscribeGroups();
  }
  deleteGroup = (group: Group) => {
    message.loading('Deleting Group', 2);
    fGroups
      .deleteGroup(group.eventId, group.key!, group.shootId, group.timeslotId)
      .then(() => {
        message.success('Group successfully deleted');
      })
      .catch(error => {
        console.log(error);
        message.error('Something went wrong, please try again.');
      });
  };
  render() {
    const { user, userMeta, loadGroups, groups } = this.props;
    const empty = (
      <div className='uk-flex uk-flex-center uk-text-center uk-width-expand'>
        <div className='uk-flex uk-flex-column uk-flex-center uk-width-large'>
          <div className='uk-width-expand uk-flex uk-flex-center'>
            <img src={emptyview} className='empty-view' />
          </div>
          <span className='uk-margin-top uk-margin-bottom'>
            There are no groups set up yet.
          </span>
        </div>
      </div>
    );
    return (
      <div>
        <Spin
          indicator={<Icon type='loading' style={{ fontSize: 24 }} spin />}
          spinning={loadGroups.loading as boolean}
        >
          <Table
            className='table-row-clickable'
            scroll={{ x: '500px' }}
            locale={{ emptyText: loadGroups.empty && empty }}
            rowKey={e => e.key!}
            dataSource={groups}
            pagination={{ pageSize: 10 }}
          >
            <Column
              title='Logo'
              dataIndex='logoUrl'
              key='logoUrl'
              render={(logoUrl: string) => (
                <img className='col-image' src={logoUrl} />
              )}
            />
            <Column
              title='Pri Color'
              dataIndex='priColor'
              key='priColor'
              render={(priColor: string) => (
                <div
                  style={{
                    height: '25px',
                    width: '60px',
                    borderRadius: '4px',
                    backgroundColor: priColor
                  }}
                />
              )}
            />
            <Column
              title='Sec Color'
              dataIndex='secColor'
              key='secColor'
              render={(secColor: string) => (
                <div
                  style={{
                    height: '25px',
                    width: '60px',
                    borderRadius: '4px',
                    backgroundColor: secColor
                  }}
                />
              )}
            />
            <Column title='Full name' dataIndex='fullName' key='fullName' />
            <Column
              title='Date'
              dataIndex='timeslotDate'
              key='date'
              render={(timeslotDate: number, group: Group, index: number) => (
                <span>{getDate(timeslotDate)}</span>
              )}
            />
            <Column
              title='Start Time'
              dataIndex='timeslotDate'
              key='startTime'
              render={(timeslotDate: number, group: Group, index: number) => (
                <span>{getTimeHrsMin(timeslotDate)}</span>
              )}
            />
            <Column
              title='Completed'
              dataIndex='completed'
              key='completed'
              render={(completed: boolean, group: Group, index: number) => (
                <span>
                  <Tag color={completed ? 'green' : 'gold'} key={'completed'}>
                    {completed ? 'Completed' : 'In Progress'}
                  </Tag>
                </span>
              )}
            />
            <Column
              title='Number of Subjects'
              dataIndex='subjectsCount'
              key='subjectsCount'
            />
            <Column
              title='Action'
              dataIndex='key'
              key='action'
              render={(groupId: string, group: Group, index: number) => (
                <div>
                  <a
                    onClick={() => {
                      confirm({
                        title: `Delete Group?`,
                        content: `Are you sure you want to delete this group and all of it's subjects?`,
                        onOk: () => {
                          this.deleteGroup(group);
                        },
                        onCancel() { }
                      });
                    }}
                  >
                    Delete
                  </a>{' '}
                  |{' '}
                  <a
                    onClick={() => {
                      this.props.onClearSubjects();
                      this.props.onSetSelectedGroup(group);
                      this.setState({ visible: true });
                    }}
                  >
                    View
                  </a>
                </div>
              )}
            />
          </Table>
        </Spin>
        <SubjectsListModal
          visible={this.state.visible}
          onCancel={() => {
            this.setState({ visible: false });
          }}
        />
      </div>
    );
  }
}

const condition = (authUser: any) => !!authUser;

export default compose<any, any>(
  withAuthorization(condition),
  connect<StateProps, DispatchProps, OwnProps>(
    mapStateToProps,
    mapDispatchToProps
  )
)(GroupsList);
