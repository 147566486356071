import React, { Component } from "react";
import { connect } from "react-redux";
import { User, UserMeta } from "../../models/UsersModel";
import { Group, StaffRoles, Event, Subject } from "../../models/EventsModel";
import { Card, Button, List, Spin, Icon, Divider, Avatar } from "antd";
import * as rTeambuilder from "../../reducers/teambuilder";
import { StepContact, StepTeamInfo } from "../../models/TeamBuilderModel";
import Animate from "rc-animate";
import { teambuilder as fTeambuilder } from "../../firebase";
import { TaskState, TeamPicsFile } from "../../models/Commons";
import { getDate, getTimeHrsMin } from "../../helpers/time";
import emptyview from "../../assets/emptyview_photo.png";
import { RouteComponentProps, Redirect, withRouter } from "react-router-dom";
import { compose } from "recompose";

interface OwnProps extends RouteComponentProps {}
interface StateProps {
  authUser: any;
  user: User;
  userMeta: UserMeta;
  event: Event;
  allGroups: Array<Group>;
  contactsGroups: Array<Group>;
  stepAuth: User;
  pageNumber: number;
  loadGroups: TaskState;
}
interface DispatchProps {
  onSetPageNumber: Function;
  onSetUpNewGroup: Function;
  onSetSelectedGroup: Function;
}
type Props = StateProps & DispatchProps & OwnProps;
type State = {};

const mapStateToProps = (state: any) => ({
  authUser: state.sessionState.authUser,
  user: state.usersState.user,
  userMeta: state.usersState.userMeta,
  stepAuth: state.teambuilderState.stepAuth,
  event: state.teambuilderState.event,
  allGroups: state.teambuilderState.allGroups,
  contactsGroups: state.teambuilderState.contactsGroups,
  pageNumber: state.teambuilderState.pageNumber,
  loadGroups: state.teambuilderState.loadGroups,
});

const mapDispatchToProps = (dispatch: any) => ({
  onSetPageNumber: (pageNumber: number) => {
    dispatch(rTeambuilder.setPageNumber(pageNumber));
  },
  onSetUpNewGroup: (event: Event, user: User) => {
    const stepContactInfo: StepContact = {
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      phoneNumber: user.phoneNumber,
      role: "" as StaffRoles,
      otherRole: "",
      address: user.address,
      location: user.location,
      postalCode: user.postal,
    };
    const stepTeamInfo: StepTeamInfo = {
      priColor: event.priColor,
      secColor: event.secColor,
      division: "",
      subDivision: "",
      otherDivision: "",
      name: "",
      fullName: "",
      secondDisclaimer: false,
      logoUrl: event.leagueLogoUrl,
      logoFile: { blob: "" } as TeamPicsFile,
    };
    const firstStaff: Subject = {
      key: rTeambuilder.generateKey(),
      contactId: "",
      eventId: "",
      groupId: "",
      photographerId: "",
      shootId: "",
      timeslotId: "",
      friendlyId: "",
      firstName: user.firstName,
      lastName: user.lastName,
      age: 0,
      heightFt: 0,
      heightIn: 0,
      email: user.email,
      phoneNumber: user.phoneNumber,
      jerseyNumber: "",
      division: "",
      role: "" as StaffRoles,
      position: "",
      type: "staff",
      createdDate: 0,
      archived: false,
    };
    dispatch(rTeambuilder.modifyStaffArray("CLEAR", firstStaff));
    dispatch(rTeambuilder.modifyPlayersArray("CLEAR", firstStaff));
    Array(6)
      .fill(0)
      .forEach((index) => {
        dispatch(
          rTeambuilder.modifyPlayersArray("ADD", {
            key: rTeambuilder.generateKey(),
            ...rTeambuilder.INITIAL_STATE_SUBJECT,
          })
        );
      });
    dispatch(rTeambuilder.modifyStaffArray("ADD", firstStaff));
    dispatch(rTeambuilder.setStepContact(stepContactInfo));
    dispatch(rTeambuilder.setStepTeamInfo(stepTeamInfo));
    dispatch(rTeambuilder.setGroup({ key: "" } as Group));
  },
  onSetSelectedGroup: (event: Event, group: Group, user: User) => {
    const stepContactInfo: StepContact = {
      firstName: group.contact.firstName,
      lastName: group.contact.lastName,
      email: group.contact.email,
      phoneNumber: group.contact.phoneNumber,
      role: group.contact.role as StaffRoles,
      otherRole: group.contact.otherRole ? group.contact.otherRole : "",
      address: user.address,
      location: user.location,
      postalCode: user.postal,
    };
    const stepTeamInfo: StepTeamInfo = {
      priColor: group.priColor,
      secColor: group.secColor,
      division: group.division,
      subDivision: group.subDivision,
      otherDivision: group.otherDivision,
      name: group.name,
      fullName: group.fullName,
      secondDisclaimer: group.disclaimer,
      logoUrl: group.logoUrl,
      logoFile: { blob: "" } as TeamPicsFile,
    };
    dispatch(rTeambuilder.modifyStaffArray("CLEAR", {} as Subject));
    dispatch(rTeambuilder.modifyPlayersArray("CLEAR", {} as Subject));
    dispatch(rTeambuilder.setStepContact(stepContactInfo));
    dispatch(rTeambuilder.setStepTeamInfo(stepTeamInfo));
    dispatch(rTeambuilder.setGroup(group));
    dispatch(fTeambuilder.loadSubjects(group.key!));
  },
});
const Div = (props: any) => {
  const childrenProps = { ...props };
  delete childrenProps.show;
  return <div {...childrenProps} />;
};
class StepGroupsComp extends Component<Props, State> {
  componentDidMount() {
    if (!this.props.authUser) {
      this.props.onSetPageNumber(0);
    }
  }
  newGroup = () => {
    this.props.onSetUpNewGroup(this.props.event, this.props.stepAuth);
    this.props.onSetPageNumber(3);
  };
  updateGroup = (group: Group) => {
    this.props.onSetSelectedGroup(this.props.event, group, this.props.stepAuth);
    this.props.onSetPageNumber(3);
  };
  render() {
    const { allGroups, contactsGroups, stepAuth, pageNumber, loadGroups } =
      this.props;

    return (
      <div style={{ height: "100%" }} className="uk-flex uk-flex-center">
        <Animate transitionName="fade" transitionAppear>
          <Div style={{ height: "100%" }} show={pageNumber === 2}>
            <div
              style={{ height: "100%" }}
              className="uk-flex uk-flex-center uk-margin-top"
            >
              <Card
                style={{
                  overflowY: "auto",
                  marginTop: "20px",
                  borderRadius: "5px",
                  minWidth: "80vw",
                }}
              >
                <Spin
                  indicator={
                    <Icon type="loading" style={{ fontSize: 24 }} spin />
                  }
                  spinning={loadGroups.loading as boolean}
                >
                  {contactsGroups.length === 0 && !loadGroups.loading && (
                    <div className="uk-flex uk-flex-center uk-text-center uk-width-expand">
                      <div className="uk-flex uk-flex-column uk-flex-center uk-width-large">
                        <div className="uk-width-expand uk-flex uk-flex-center">
                          <img src={emptyview} className="empty-view" />
                        </div>
                        <span className="uk-margin-top uk-margin-bottom">
                          You haven't set up any teams yet..
                        </span>
                        <Button
                          type="primary"
                          onClick={() => {
                            this.newGroup();
                          }}
                        >
                          Set up your first team!
                        </Button>
                      </div>
                    </div>
                  )}
                  {contactsGroups.length !== 0 && (
                    <Animate transitionName="fade" transitionAppear>
                      <Div
                        style={{ height: "100%", overflowY: "auto" }}
                        show={!(contactsGroups.length === 0)}
                      >
                        <div className="uk-flex uk-flex-row">
                          <h2>Your Teams</h2>
                          <div className="uk-width-expand uk-flex uk-flex-right">
                            <Button
                              type="primary"
                              onClick={() => {
                                this.newGroup();
                              }}
                            >
                              New Team
                            </Button>
                          </div>
                        </div>
                        <Divider className="uk-margin-remove-top uk-margin-remove-bottom" />
                        <List
                          locale={{ emptyText: "No teams available." }}
                          className="small-list"
                          itemLayout="horizontal"
                          dataSource={contactsGroups}
                          renderItem={(group: Group) => (
                            <List.Item
                              className="list-item"
                              style={{
                                padding: "10px",
                                borderRadius: "4px",
                              }}
                              onClick={() => {
                                this.updateGroup(group);
                              }}
                              actions={[
                                <a
                                  onClick={() => {
                                    this.updateGroup(group);
                                  }}
                                >
                                  Edit
                                </a>,
                              ]}
                            >
                              <List.Item.Meta
                                avatar={<Avatar src={group.logoUrl} />}
                                title={<span>{group.fullName}</span>}
                                description={
                                  getDate(group.timeslotDate) +
                                  " - " +
                                  getTimeHrsMin(group.timeslotDate)
                                }
                              />
                            </List.Item>
                          )}
                        />
                      </Div>
                    </Animate>
                  )}
                </Spin>
              </Card>
            </div>
          </Div>
        </Animate>
      </div>
    );
  }
}

// export default connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps)(StepGroupsComp);

export default compose<any, any>(
  withRouter,
  connect<StateProps, DispatchProps, OwnProps>(
    mapStateToProps,
    mapDispatchToProps
  )
)(StepGroupsComp);
