import React, { Component } from "react";
import { RouteComponentProps, Redirect } from "react-router-dom";
import { compose } from "recompose";
import { connect } from "react-redux";
import { setPage } from "../../reducers/session";
import * as routes from "../../constants/routeNames";
import { User, UserMeta } from "../../models/UsersModel";
import {
  StepIntro,
  StepAuth,
  StepContact,
  StepTeamInfo,
} from "../../models/TeamBuilderModel";
import {
  Timeslot,
  Group,
  Subject,
  Shoot,
  Event,
  StaffRoles,
} from "../../models/EventsModel";
import { TaskState, TeamPicsFile } from "../../models/Commons";
import { Layout, Button, Spin, Modal, Steps, message, Icon } from "antd";
import * as rTeambuilder from "../../reducers/teambuilder";
import * as rUsers from "../../reducers/users";
import { teambuilder as fTeambuilder, auth as fAuth } from "../../firebase";
import StepIntroComp from "./StepIntroComp";
import StepAuthComp from "./StepAuthComp";
import StepGroupsComp from "./StepGroupsComp";
import StepContactInfoComp from "./StepContactInfoComp";
import StepStaffComp from "./StepStaffComp";
import StepTeamInfoComp from "./StepTeamInfoComp";
import StepPlayersComp from "./StepPlayersComp";
import StepTimeslotsComp from "./StepTimeslotsComp";
import { validateEmail } from "../../helpers/string";
import { isValidPhoneNumber } from "react-phone-number-input";
import { firestore } from "../../firebase/firebaseConfig";
import shortid from "shortid";
import emptyview from "../../assets/emptyview_clients.png";
import { persistor } from "../../base/store";
import { isSafari } from "react-device-detect";
import { getDate, getTimeHrsMin } from "../../helpers/time";
const { Header, Content, Footer } = Layout;
const Step = Steps.Step;

interface OwnProps extends RouteComponentProps {}
interface StateProps {
  authUser: any;
  user: User;
  userMeta: UserMeta;
  pageNumber: number;
  event: Event;
  group: Group;
  timeslot: Timeslot;
  shoot: Shoot;
  stepIntro: StepIntro;
  stepAuth: StepAuth;
  stepContact: StepContact;
  stepTeamInfo: StepTeamInfo;
  stepTimeslot: Timeslot;
  allGroups: Array<Group>;
  contactsGroups: Array<Group>;
  timeslots: Array<Timeslot>;
  players: Array<Subject>;
  staff: Array<Subject>;
  toDeleteSubjects: Array<Subject>;
  shoots: Array<Shoot>;
  updateGroup: TaskState;
  loadEvent: TaskState;
  loadSubjects: TaskState;
  loadShoots: TaskState;
  loadTimeslots: TaskState;
  loadGroups: TaskState;
}
interface DispatchProps {
  onSetPage: Function;
  onSetPageNumber: Function;
  onLoadEvent: Function;
  onSetFirstStaff: Function;
  onLogOut: Function;
  onLoadGroups: Function;
  onLoadShoots: Function;
  onSetError: Function;
  onNewGroup: Function;
  onResetTeamBuilder: Function;
  onUpdateGroup: Function;
  onUpdateGroupNoTimeslot: Function;
  onClearTeamBuilder: Function;
  onLoadUser: Function;
}
type Props = StateProps & DispatchProps & OwnProps;
type State = {};

const groupSteps = [
  {
    title: "Contact",
  },
  {
    title: "Team",
  },
  {
    title: "Players",
  },
  {
    title: "Staff",
  },
  {
    title: "Booking",
  },
];
const mapStateToProps = (state: any) => ({
  authUser: state.sessionState.authUser,
  user: state.usersState.user,
  userMeta: state.usersState.userMeta,
  event: state.teambuilderState.event,
  group: state.teambuilderState.group,
  shoot: state.teambuilderState.shoot,
  timeslot: state.teambuilderState.timeslot,
  pageNumber: state.teambuilderState.pageNumber,
  stepIntro: state.teambuilderState.stepIntro,
  stepAuth: state.teambuilderState.stepAuth,
  stepContact: state.teambuilderState.stepContact,
  stepTeamInfo: state.teambuilderState.stepTeamInfo,
  stepTimeslot: state.teambuilderState.stepTimeslot,
  allGroups: state.teambuilderState.allGroups,
  players: state.teambuilderState.players,
  staff: state.teambuilderState.staff,
  toDeleteSubjects: state.teambuilderState.toDeleteSubjects,
  contactsGroups: state.teambuilderState.contactsGroups,
  timeslots: state.teambuilderState.timeslots,
  shoots: state.teambuilderState.shoots,
  updateGroup: state.teambuilderState.updateGroup,
  loadEvent: state.teambuilderState.loadEvent,
  loadSubjects: state.teambuilderState.loadSubjects,
  loadShoots: state.teambuilderState.loadShoots,
  loadTimeslots: state.teambuilderState.loadTimeslots,
  loadGroups: state.teambuilderState.loadGroups,
});
const mapDispatchToProps = (dispatch: any) => ({
  onSetPage: (page: any) => {
    dispatch(setPage(page));
  },
  onSetPageNumber: (pageNumber: number) => {
    dispatch(rTeambuilder.setPageNumber(pageNumber));
  },
  onLoadEvent: (eventId: string) => {
    dispatch(fTeambuilder.loadEvent(eventId));
  },
  onLogOut: () => {
    dispatch(fAuth.teambuilderSignout());
  },
  onLoadGroups: (eventId: string, contactId: string) => {
    dispatch(fTeambuilder.subscribeToGroups(eventId, contactId));
  },
  onLoadShoots: (eventId: string) => {
    dispatch(fTeambuilder.loadShoots(eventId));
  },
  onSetFirstStaff: (stepContact: StepContact) => {
    const firstStaff: Subject = {
      key: rTeambuilder.generateKey(),
      contactId: "",
      eventId: "",
      groupId: "",
      photographerId: "",
      shootId: "",
      timeslotId: "",
      friendlyId: "",
      firstName: stepContact.firstName,
      lastName: stepContact.lastName,
      age: 0,
      heightFt: 0,
      heightIn: 0,
      email: stepContact.email,
      phoneNumber: stepContact.phoneNumber,
      jerseyNumber: "",
      division: "",
      role: stepContact.role,
      position: "",
      type: "staff",
      createdDate: 0,
      archived: false,
    };
    dispatch(rTeambuilder.modifyStaffArray("CLEAR", firstStaff));
    dispatch(rTeambuilder.modifyStaffArray("ADD", firstStaff));
  },
  onSetError: (error: string) => {
    dispatch(rTeambuilder.modifyUpdateGroup("ERROR", error));
  },
  onNewGroup: (
    eventId: string,
    shootId: string,
    group: Group,
    players: Array<Subject>,
    staff: Array<Subject>,
    timeslot: Timeslot,
    newUser: StepAuth,
    logoFile: TeamPicsFile
  ) => {
    dispatch(
      fTeambuilder.newGroup(
        eventId,
        shootId,
        group,
        players,
        staff,
        timeslot,
        newUser,
        logoFile
      )
    );
  },
  onUpdateGroup: (
    eventId: string,
    shootId: string,
    oldShootId: string,
    oldTimeslotId: string,
    group: Group,
    players: Array<Subject>,
    staff: Array<Subject>,
    toDeleteSubjects: Array<Subject>,
    timeslot: Timeslot,
    newUser: StepAuth,
    logoFile: TeamPicsFile
  ) => {
    dispatch(
      fTeambuilder.updateGroup(
        eventId,
        shootId,
        oldShootId,
        oldTimeslotId,
        group,
        players,
        staff,
        toDeleteSubjects,
        timeslot,
        newUser,
        logoFile
      )
    );
  },
  onUpdateGroupNoTimeslot: (
    eventId: string,
    group: Group,
    players: Array<Subject>,
    staff: Array<Subject>,
    toDeleteSubjects: Array<Subject>,
    newUser: StepAuth,
    logoFile: TeamPicsFile
  ) => {
    dispatch(
      fTeambuilder.updateGroupNoTimeslot(
        eventId,
        group,
        players,
        staff,
        toDeleteSubjects,
        newUser,
        logoFile
      )
    );
  },
  onResetTeamBuilder: () => {
    dispatch(rTeambuilder.resetTeamBuilder());
  },
  onClearTeamBuilder: () => {
    dispatch(rTeambuilder.clearTeambuilderReducer());
    dispatch(fAuth.doSignOut());
    dispatch(rUsers.clearUsersReducer());
  },
  onLoadUser: (userId: string) => {
    dispatch(fTeambuilder.loadUser(userId));
  },
});

class TeamBuilderPage extends Component<Props, State> {
  componentDidMount() {
    this.props.onSetPage(routes.TEAM_BUILDER);

    //if current saved event id doesnt match the url's id restart the teambuilder
    if (
      this.props.event.key !== (this.props.match.params as any).eventId &&
      this.props.event.key !== ""
    ) {
      this.props.onResetTeamBuilder();
    }

    //load the event from url
    this.props.onLoadEvent((this.props.match.params as any).eventId);

    //If the user is already logged in
    if (this.props.authUser) {
      this.props.onLoadUser(this.props.authUser.uid);
      //If they havent already loaded the shoots, load the shoots
      if (this.props.shoots.length === 0) {
        this.props.onLoadShoots((this.props.match.params as any).eventId);
      }

      //If theyre already logged in, load the teams they made
      this.props.onLoadGroups(
        (this.props.match.params as any).eventId,
        this.props.authUser.uid
      );

      //If theyre already logged in, prevent them from going to the disclaimer or login page
      if (this.props.authUser.uid) {
        if (this.props.pageNumber < 2) {
          this.props.onSetPageNumber(2);
        }
      }
    }
  }
  componentWillReceiveProps(nextProps: Props) {
    //If the user has just logged in and they weren't logged in before
    if (nextProps.authUser && !this.props.authUser) {
      //Load all the shoots
      if (this.props.shoots.length === 0) {
        this.props.onLoadShoots((this.props.match.params as any).eventId);
      }
      //Load all of the users teams
      this.props.onLoadGroups(nextProps.event.key, nextProps.authUser.uid);
      //move them past the login and disclaimer page if they somehow eneded up there
      if (this.props.pageNumber < 2) {
        this.props.onSetPageNumber(2);
      }
    }

    if (!nextProps.authUser && this.props.authUser) {
      this.props.onSetPageNumber(0);
      //this.props.onClearTeamBuilder();
      //this.props.history.push(routes.LOGIN);
      //<Redirect to={'/team-builder/' + (this.props.match.params as any).eventId} />;
    }

    if (nextProps.updateGroup.success && !this.props.updateGroup.success) {
      this.props.onResetTeamBuilder();
      this.props.onLoadGroups(
        (this.props.match.params as any).eventId,
        this.props.authUser.uid
      );
      this.props.onLoadUser(this.props.authUser.uid);
      message.success(nextProps.updateGroup.success);
    }

    if (
      nextProps.updateGroup.error &&
      this.props.updateGroup.error !== nextProps.updateGroup.error
    ) {
      message.error(nextProps.updateGroup.error, 5);
    }
  }
  componentWillUnmount() {
    fTeambuilder.unSubscribeFromTimeslots();
    fTeambuilder.unSubscribeGroups();
  }
  componentDidUpdate() {
    // if (this.props.stepIntro.disclaimer) {
    //   window.onbeforeunload = () => true;
    // } else {
    //   window.onbeforeunload = () => undefined;
    // }
  }
  nextPage = () => {
    const { pageNumber } = this.props;
    if (pageNumber < 7) {
      this.props.onSetPageNumber(pageNumber + 1);
    }
  };
  previousPage = () => {
    const { pageNumber } = this.props;
    if (pageNumber > 0) {
      this.props.onSetPageNumber(pageNumber - 1);
    }
  };
  populateFirstStaff = () => {
    const { stepContact, group } = this.props;
    if (!group.key) {
      this.props.onSetFirstStaff(stepContact);
    }
    this.nextPage();
  };
  validatePlayers = () => {
    const { players } = this.props;
    this.props.onSetError("");

    let error = "";
    let firstNameRepeats = 1;
    let lastNameRepeats = 1;
    let lastFirstName = "";
    let lastLastName = "";
    players
      .slice()
      .reverse()
      .some((player, index): boolean => {
        if (lastFirstName === player.firstName) {
          firstNameRepeats = firstNameRepeats + 1;
        }
        if (lastLastName === player.lastName) {
          lastNameRepeats = lastNameRepeats + 1;
        }
        lastFirstName = player.firstName;
        lastLastName = player.lastName;
        if (player.firstName === "") {
          error = `Player ${index + 1}: Please enter their first name`;
          return !!error;
        }
        if (player.lastName === "") {
          error = `Player ${index + 1}: Please enter their last name`;
          return !!error;
        }
        if (!validateEmail(player.email)) {
          error = `Player ${index + 1}: Please enter a valid email`;
          return !!error;
        }

        return false;
      });

    if (players.length < 6) {
      error = "At least 6 players are required.";
      message.error(error);
      return;
    } else if (lastNameRepeats >= players.length - 1) {
      error = "Please fill out information accurately and don't repeat names.";
      message.error(error);
      return;
    } else if (firstNameRepeats >= players.length - 1) {
      error = "Please fill out information accurately and don't repeat names.";
      message.error(error);
      return;
    } else if (error) {
      message.error(error);
      return;
    }

    this.nextPage();
  };
  validateStaff = () => {
    const { staff } = this.props;
    this.props.onSetError("");

    let error = "";
    let firstNameRepeats = 1;
    let lastNameRepeats = 1;
    let lastFirstName = "";
    let lastLastName = "";
    staff
      .slice()
      .reverse()
      .some((staffMember, index): boolean => {
        if (lastFirstName === staffMember.firstName) {
          firstNameRepeats = firstNameRepeats + 1;
        }
        if (lastLastName === staffMember.lastName) {
          lastNameRepeats = lastNameRepeats + 1;
        }
        lastFirstName = staffMember.firstName;
        lastLastName = staffMember.lastName;
        if (staffMember.firstName === "") {
          error = `Staff member ${index + 1}: Please enter their first name`;
          return !!error;
        }
        if (staffMember.lastName === "") {
          error = `Staff member ${index + 1}: Please enter their last name`;
          return !!error;
        }
        if (staffMember.email === "") {
          error = `Staff member ${index + 1}: Please enter their email`;
          return !!error;
        }
        if (!validateEmail(staffMember.email)) {
          error = `Staff member ${index + 1}: Please enter a valid email`;
          return !!error;
        }
        if (
          staffMember.phoneNumber !== "" &&
          !isValidPhoneNumber(`+1${staffMember.phoneNumber}`)
        ) {
          error = `Staff member ${
            index + 1
          }: Please enter a valid phone number`;
          return !!error;
        }
        if (staffMember.role === "") {
          error = `Staff member ${index + 1}: Please select their role`;
          return !!error;
        }
        return false;
      });

    if (staff.length < 1) {
      error = "At least one staff member is required.";
      message.error(error);
      return;
    } else if (lastNameRepeats >= staff.length - 1 && staff.length > 5) {
      error = "Please fill out information accurately and don't repeat names.";
      message.error(error);
      return;
    } else if (firstNameRepeats >= staff.length - 1 && staff.length > 5) {
      error = "Please fill out information accurately and don't repeat names.";
      message.error(error);
      return;
    } else if (error) {
      message.error(error);
      return;
    }

    this.nextPage();
  };
  newGroup = () => {
    const {
      event,
      group,
      shoot,
      timeslot,
      players,
      staff,
      stepContact,
      stepTeamInfo,
      stepAuth,
      authUser,
    } = this.props;
    //eventid
    const eventId = event.key!;
    //shootid
    const shootId = shoot.key!;
    //group
    const groupId = firestore
      .collection("events")
      .doc(event.key)
      .collection("groups")
      .doc().id;
    const newGroup: Group = {
      key: groupId,
      name: stepTeamInfo.name,
      division: stepTeamInfo.division,
      subDivision: stepTeamInfo.subDivision,
      otherDivision: stepTeamInfo.otherDivision,
      fullName: stepTeamInfo.fullName,
      logoUrl: event.leagueLogoUrl,
      contactId: authUser.uid,
      contact: {
        email: stepContact.email,
        firstName: stepContact.firstName,
        lastName: stepContact.lastName,
        role: stepContact.role,
        otherRole: stepContact.otherRole,
        phoneNumber: stepContact.phoneNumber,
      },
      eventId: event.key!,
      shootId: shoot.key!,
      timeslotId: timeslot.key!,
      timeslotDate: timeslot.timestamp,
      timestamp: new Date().getTime(),
      localDate: getDate(timeslot.timestamp),
      localTime: getTimeHrsMin(timeslot.timestamp),
      photographerId: event.photographerId,
      priColor: stepTeamInfo.priColor,
      secColor: stepTeamInfo.secColor,
      disclaimer: stepTeamInfo.secondDisclaimer,
      completed: false,
      subjectsCount: players.length + staff.length,
      friendlyId: "",
    };
    //players
    const newPlayers = players.slice().map((player) => {
      const newPlayer: Subject = {
        key: player.key!,
        contactId: authUser.uid,
        eventId: event.key!,
        groupId: groupId,
        photographerId: event.photographerId,
        shootId: shoot.key!,
        timeslotId: timeslot.key!,
        friendlyId: "",
        firstName: player.firstName,
        lastName: player.lastName,
        age: 0,
        heightFt: 0,
        heightIn: 0,
        email: player.email,
        phoneNumber: player.phoneNumber,
        jerseyNumber: player.jerseyNumber,
        role: player.role,
        division: stepTeamInfo.fullName,
        position: "",
        type: "player",
        createdDate: new Date().getTime(),
        archived: false,
      };
      return newPlayer;
    });
    //staff
    const newStaff = staff.slice().map((staffMember) => {
      const newStaffNumber: Subject = {
        key: staffMember.key!,
        contactId: authUser.uid,
        eventId: event.key!,
        groupId: groupId,
        photographerId: event.photographerId,
        shootId: shoot.key!,
        timeslotId: timeslot.key!,
        friendlyId: "",
        firstName: staffMember.firstName,
        lastName: staffMember.lastName,
        age: 0,
        heightFt: 0,
        heightIn: 0,
        email: staffMember.email,
        phoneNumber: staffMember.phoneNumber,
        jerseyNumber: "",
        division: stepTeamInfo.fullName,
        role: staffMember.role,
        position: "",
        type: "staff",
        createdDate: new Date().getTime(),
        archived: false,
      };
      return newStaffNumber;
    });
    //timeslot
    const newTimeslot: Timeslot = {
      ...timeslot,
      groupId,
      taken: true,
      teamName: stepTeamInfo.fullName,
      contactId: authUser.uid,
    };
    //user
    const newUser = {
      userId: authUser.uid,
      location: stepContact.location,
      address: stepContact.address,
      phoneNumber: stepContact.phoneNumber,
      firstName: stepContact.firstName,
      lastName: stepContact.lastName,
    };

    this.props.onNewGroup(
      eventId,
      shootId,
      newGroup,
      newPlayers,
      newStaff,
      newTimeslot,
      newUser,
      stepTeamInfo.logoFile
    );
  };
  updateGroup = () => {
    const {
      event,
      group,
      shoot,
      timeslot,
      players,
      staff,
      toDeleteSubjects,
      stepContact,
      stepTeamInfo,
      authUser,
    } = this.props;
    //eventId,
    const eventId = event.key!;
    //shootId,
    const shootId = shoot.key!;
    //oldTimeslotId,
    const oldTimeslotId = group.timeslotId;
    const oldShootId = group.shootId;
    //group,
    const newGroup: Group = {
      ...group,
      key: group.key!,
      name: stepTeamInfo.name,
      division: stepTeamInfo.division,
      subDivision: stepTeamInfo.subDivision,
      otherDivision: stepTeamInfo.otherDivision
        ? stepTeamInfo.otherDivision
        : "",
      fullName: stepTeamInfo.fullName,
      logoUrl: group.logoUrl,
      contactId: authUser.uid,
      contact: {
        email: stepContact.email,
        firstName: stepContact.firstName,
        lastName: stepContact.lastName,
        role: stepContact.role,
        otherRole: stepContact.otherRole ? stepContact.otherRole : "",
        phoneNumber: stepContact.phoneNumber,
      },
      eventId: event.key!,
      shootId: shoot.key!,
      timeslotId: timeslot.key!,
      timeslotDate: timeslot.timestamp,
      timestamp: new Date().getTime(),
      localDate: getDate(timeslot.timestamp),
      localTime: getTimeHrsMin(timeslot.timestamp),
      photographerId: event.photographerId,
      priColor: stepTeamInfo.priColor,
      secColor: stepTeamInfo.secColor,
      disclaimer: stepTeamInfo.secondDisclaimer,
      completed: false,
      subjectsCount: players.length + staff.length,
    };
    //players,
    const newPlayers = players.slice().map((player) => {
      const newPlayer: Subject = {
        ...player,
        key: player.key!,
        contactId: authUser.uid,
        eventId: event.key!,
        groupId: group.key!,
        photographerId: event.photographerId,
        shootId: shoot.key!,
        timeslotId: timeslot.key!,
        friendlyId: "",
        firstName: player.firstName,
        lastName: player.lastName,
        age: 0,
        heightFt: 0,
        heightIn: 0,
        email: player.email,
        phoneNumber: player.phoneNumber,
        jerseyNumber: player.jerseyNumber ? player.jerseyNumber : "",
        role: player.role,
        division: stepTeamInfo.fullName,
        position: "",
        type: "player",
        archived: false,
      };
      return newPlayer;
    });
    //staff,
    const newStaff = staff.slice().map((staffMember) => {
      const newStaffNumber: Subject = {
        ...staffMember,
        key: staffMember.key!,
        contactId: authUser.uid,
        eventId: event.key!,
        groupId: group.key!,
        photographerId: event.photographerId,
        shootId: shoot.key!,
        timeslotId: timeslot.key!,
        friendlyId: "",
        firstName: staffMember.firstName,
        lastName: staffMember.lastName,
        age: 0,
        heightFt: 0,
        heightIn: 0,
        email: staffMember.email,
        phoneNumber: staffMember.phoneNumber,
        jerseyNumber: "",
        role: staffMember.role,
        division: stepTeamInfo.fullName,
        position: "",
        type: "staff",
        archived: false,
      };
      return newStaffNumber;
    });
    //timeslot,
    const newTimeslot: Timeslot = {
      ...timeslot,
      groupId: group.key,
      taken: true,
      teamName: stepTeamInfo.fullName,
      contactId: authUser.uid,
    };

    //newUser
    const newUser = {
      userId: authUser.uid,
      location: stepContact.location,
      address: stepContact.address,
      phoneNumber: stepContact.phoneNumber,
      firstName: stepContact.firstName,
      lastName: stepContact.lastName,
    };
    delete (newUser as any).email;
    this.props.onUpdateGroup(
      eventId,
      shootId,
      oldShootId,
      oldTimeslotId,
      newGroup,
      newPlayers,
      newStaff,
      toDeleteSubjects,
      newTimeslot,
      newUser,
      stepTeamInfo.logoFile
    );
  };
  updateGroupNoTimeslot = () => {
    const {
      event,
      group,
      players,
      staff,
      toDeleteSubjects,
      stepContact,
      stepTeamInfo,
      authUser,
    } = this.props;
    //eventId,
    const eventId = event.key!;
    //group
    const newGroup: Group = {
      ...group,
      key: group.key!,
      name: stepTeamInfo.name,
      division: stepTeamInfo.division,
      subDivision: stepTeamInfo.subDivision,
      otherDivision: stepTeamInfo.otherDivision
        ? stepTeamInfo.otherDivision
        : "",
      fullName: stepTeamInfo.fullName,
      logoUrl: group.logoUrl,
      contactId: authUser.uid,
      contact: {
        email: stepContact.email,
        firstName: stepContact.firstName,
        lastName: stepContact.lastName,
        role: stepContact.role,
        otherRole: stepContact.otherRole ? stepContact.otherRole : "",
        phoneNumber: stepContact.phoneNumber,
      },
      eventId: event.key!,
      shootId: group.shootId,
      timeslotId: group.timeslotId,
      timeslotDate: group.timeslotDate,
      timestamp: new Date().getTime(),
      localDate: getDate(group.timeslotDate),
      localTime: getTimeHrsMin(group.timeslotDate),
      photographerId: event.photographerId,
      priColor: stepTeamInfo.priColor,
      secColor: stepTeamInfo.secColor,
      disclaimer: stepTeamInfo.secondDisclaimer,
      completed: false,
      subjectsCount: players.length + staff.length,
    };
    //players,
    const newPlayers = players.slice().map((player) => {
      const newPlayer: Subject = {
        ...player,
        key: player.key!,
        contactId: authUser.uid,
        eventId: event.key!,
        groupId: group.key!,
        photographerId: event.photographerId,
        shootId: group.shootId!,
        timeslotId: group.timeslotId,
        friendlyId: "",
        firstName: player.firstName,
        lastName: player.lastName,
        age: 0,
        heightFt: 0,
        heightIn: 0,
        email: player.email,
        phoneNumber: player.phoneNumber,
        jerseyNumber: player.jerseyNumber ? player.jerseyNumber : "",
        division: stepTeamInfo.fullName,
        role: player.role,
        position: "",
        type: "player",
        archived: false,
      };
      return newPlayer;
    });
    //staff,
    const newStaff = staff.slice().map((staffMember) => {
      const newStaffNumber: Subject = {
        ...staffMember,
        key: staffMember.key!,
        contactId: authUser.uid,
        eventId: event.key!,
        groupId: group.key!,
        photographerId: event.photographerId,
        shootId: group.shootId!,
        timeslotId: group.timeslotId,
        friendlyId: "",
        firstName: staffMember.firstName,
        lastName: staffMember.lastName,
        age: 0,
        heightFt: 0,
        heightIn: 0,
        email: staffMember.email,
        phoneNumber: staffMember.phoneNumber,
        jerseyNumber: "",
        division: stepTeamInfo.fullName,
        role: staffMember.role,
        position: "",
        type: "staff",
        archived: false,
      };
      return newStaffNumber;
    });
    //newUser
    const newUser = {
      userId: authUser.uid,
      location: stepContact.location,
      address: stepContact.address,
      phoneNumber: stepContact.phoneNumber,
      firstName: stepContact.firstName,
      lastName: stepContact.lastName,
    };
    delete (newUser as any).email;
    this.props.onUpdateGroupNoTimeslot(
      eventId,
      newGroup,
      newPlayers,
      newStaff,
      toDeleteSubjects,
      newUser,
      stepTeamInfo.logoFile
    );
  };
  render() {
    const {
      user,
      userMeta,
      event,
      group,
      timeslot,
      shoot,
      pageNumber,
      stepIntro,
      stepAuth,
      stepContact,
      stepTeamInfo,
      stepTimeslot,
      allGroups,
      players,
      staff,
      contactsGroups,
      timeslots,
      shoots,
      updateGroup,
      loadEvent,
      loadSubjects,
      loadShoots,
      loadTimeslots,
      loadGroups,
    } = this.props;

    const steps = [
      {
        title: "Get Started",
        component: <StepIntroComp />,
      },
      {
        title: "Sign In",
        component: <StepAuthComp />,
      },
      {
        title: event.key ? `Teams for ${event.name}` : "Teams",
        component: <StepGroupsComp />,
      },
      {
        title: "Contact Info",
        component: <StepContactInfoComp />,
      },
      {
        title: "Team Info",
        component: <StepTeamInfoComp />,
      },
      {
        title: stepTeamInfo.fullName
          ? `${stepTeamInfo.fullName}'s Players`
          : "Players",
        component: <StepPlayersComp />,
      },
      {
        title: stepTeamInfo.fullName
          ? `${stepTeamInfo.fullName}'s Staff`
          : "Staff",
        component: <StepStaffComp />,
      },
      {
        title: stepTeamInfo.fullName
          ? `Book a photoshoot for ${stepTeamInfo.fullName}`
          : "Book a photoshoot",
        component: <StepTimeslotsComp />,
      },
    ];

    const contactInfoIsInvalid =
      stepContact.address === "" ||
      stepContact.email === "" ||
      stepContact.firstName === "" ||
      stepContact.lastName === "" ||
      stepContact.phoneNumber === "" ||
      stepContact.role === "" ||
      stepContact.location.address === "";

    const teamInfoIsInvalid =
      stepTeamInfo.name === "" ||
      stepTeamInfo.division === "" ||
      (stepTeamInfo.division === "other" &&
        stepTeamInfo.otherDivision === "") ||
      stepTeamInfo.fullName === "" ||
      stepTeamInfo.priColor === "" ||
      stepTeamInfo.priColor === "" ||
      stepTeamInfo.secColor === "" ||
      stepTeamInfo.secondDisclaimer === false;

    let playersAreInvalid: boolean = false;
    if (players.length > 6) {
      playersAreInvalid =
        players[0].firstName === "" ||
        players[1].firstName === "" ||
        players[2].firstName === "" ||
        players[3].firstName === "" ||
        players[4].firstName === "" ||
        players[5].firstName === "";
    } else {
      playersAreInvalid = false;
    }

    let staffAreInvalid: boolean = false;
    if (staff.length > 0) {
      staffAreInvalid = staff[0].firstName === "";
    } else {
      staffAreInvalid = false;
    }

    const timeslotsAreInvalid = timeslot.key === "" || shoot.key === "";

    if (loadEvent.loading || loadEvent.empty) {
      return (
        <div
          style={{ height: "100vh", backgroundColor: "#f0fffee8" }}
          className="uk-flex uk-flex-middle uk-flex-center"
        >
          <Spin
            indicator={<Icon type="loading" style={{ fontSize: 24 }} spin />}
            tip="Getting Event Details..."
            size={"large"}
            spinning={loadEvent.loading as boolean}
          >
            {loadEvent.empty && (
              <div className="uk-flex uk-flex-column uk-text-center">
                <h2>Teampics</h2>
                <div className="uk-flex uk-flex-center uk-text-center uk-width-expand">
                  <div className="uk-flex uk-flex-column uk-flex-center uk-width-large">
                    <div className="uk-width-expand uk-flex uk-flex-center">
                      <img src={emptyview} className="empty-view" />
                    </div>
                    <span className="uk-margin-top uk-margin-bottom">
                      This event is no longer available
                    </span>
                  </div>
                </div>
              </div>
            )}
          </Spin>
        </div>
      );
    }

    if (pageNumber === 0) {
      return (
        <Layout style={{ height: "100vh" }}>
          <Content>
            <div>{steps[0]!.component}</div>
          </Content>
        </Layout>
      );
    }

    if (pageNumber === 1) {
      return (
        <Layout style={{ height: "100vh" }}>
          <Content>
            <div>{steps[1]!.component}</div>
          </Content>
        </Layout>
      );
    }

    return (
      <Spin
        indicator={<Icon type="loading" style={{ fontSize: 64 }} spin />}
        spinning={false}
        size="large"
      >
        {/* style={isSafari ? { minHeight: '100vh' } : { height: '100vh' }} */}
        <Layout style={{ minHeight: "100vh" }}>
          <Header
            style={{
              background: "#f8fafb",
              zIndex: 200,
              boxShadow: "0 1px 3px 0 rgba(0,0,0,.15)",
              minHeight: "64px",
              height: "auto",
            }}
            className="uk-position-top"
          >
            <div className="uk-flex uk-flex-row uk-flex-wrap">
              <h2 className="uk-margin-remove uk-text-truncate">
                {steps[pageNumber]!.title}
              </h2>
              <div className="uk-width-expand uk-flex uk-flex-right uk-flex-middle">
                {pageNumber === 2 && (
                  <Button
                    type={"primary"}
                    onClick={() => {
                      this.props.onLogOut();
                      //this.props.onResetTeamBuilder();
                      //this.props.history.push(routes.LOGIN);
                      this.props.onSetPageNumber(0);
                      fTeambuilder.unSubscribeFromTimeslots();
                      fTeambuilder.unSubscribeGroups();
                      persistor.purge();
                      // window.onbeforeunload = () => undefined;
                      //this.props.onLoadEvent((this.props.match.params as any).eventId);
                      // this.props.onLoadShoots((this.props.match.params as any).eventId);
                      // this.props.onLoadGroups(
                      // 	(this.props.match.params as any).eventId,
                      // 	this.props.authUser.uid
                      // );
                      // <Redirect
                      // 	to={'/team-builder/' + (this.props.match.params as any).eventId}
                      // />;
                    }}
                    className="uk-margin-small-bottom uk-margin-small-top"
                  >
                    Log Out
                  </Button>
                )}
              </div>
            </div>
          </Header>
          <Content
            style={{ marginTop: 64, marginBottom: 64, paddingBottom: 64 }}
          >
            <div>
              {pageNumber > 2 && (
                <Steps
                  size="small"
                  className="uk-margin-top steps-disappear"
                  style={{ minHeight: "40px", padding: "0 25px" }}
                  current={pageNumber - 3}
                >
                  {groupSteps.map((item) => (
                    <Step key={item.title} title={item.title} />
                  ))}
                </Steps>
              )}

              {steps[pageNumber]!.component}
            </div>
          </Content>
        </Layout>
        {pageNumber !== 2 && (
          <div
            style={{
              background: "#151b26",
              position: "fixed",
              bottom: 0,
              left: 0,
              right: 0,
            }}
            className={`uk-padding-small`}
          >
            <div className="uk-flex uk-flex-row">
              <div className="uk-flex uk-flex-right uk-width-expand">
                {pageNumber > 2 && (
                  <Button
                    onClick={() => {
                      this.previousPage();
                    }}
                    disabled={updateGroup.loading as boolean}
                  >
                    Previous
                  </Button>
                )}
                {pageNumber < steps.length - 1 && (
                  <Button
                    style={{ marginLeft: 8 }}
                    type="primary"
                    onClick={() => {
                      if (pageNumber === 3) {
                        this.populateFirstStaff();
                      } else if (pageNumber === 5) {
                        this.validatePlayers();
                      } else if (pageNumber === 6) {
                        this.validateStaff();
                      } else {
                        this.nextPage();
                      }
                    }}
                    disabled={
                      (pageNumber === 3 && contactInfoIsInvalid) ||
                      (pageNumber === 4 && teamInfoIsInvalid) ||
                      (pageNumber === 5 && playersAreInvalid) ||
                      (pageNumber === 6 && staffAreInvalid)
                    }
                    loading={updateGroup.loading as boolean}
                  >
                    Next
                  </Button>
                )}
                {pageNumber === steps.length - 1 && (
                  <Button
                    style={{ marginLeft: 8 }}
                    type="primary"
                    disabled={
                      pageNumber === 7 && timeslotsAreInvalid && !group.key
                    }
                    onClick={() => {
                      if (group.key && timeslot.key) {
                        this.updateGroup();
                      } else if (group.key && !timeslot.key) {
                        this.updateGroupNoTimeslot();
                      } else {
                        this.newGroup();
                      }
                    }}
                    loading={updateGroup.loading as boolean}
                  >
                    Save
                  </Button>
                )}
              </div>
            </div>
          </div>
        )}
      </Spin>
    );
  }
}

export default compose<any, any>(
  connect<StateProps, DispatchProps, OwnProps>(
    mapStateToProps,
    mapDispatchToProps
  )
)(TeamBuilderPage);
