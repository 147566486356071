import React, { Component } from 'react';
import withAuthorization from '../../hoc/withAuthorization';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { setPage } from '../../reducers/session';
import * as routes from '../../constants/routeNames';
import { Row, Col, Card, Button, BackTop } from 'antd';
import { UserMeta, User } from '../../models/UsersModel';

interface OwnProps extends RouteComponentProps { }

interface StateProps {
	user: User;
	userMeta: UserMeta;
}

interface DispatchProps {
	onSetPage: Function;
}

type Props = StateProps & DispatchProps & OwnProps;
type State = {};

const mapStateToProps = (state: any) => ({
	user: state.usersState.user,
	userMeta: state.usersState.userMeta
});

const mapDispatchToProps = (dispatch: any) => ({
	onSetPage: (page: any) => {
		dispatch(setPage(page));
	}
});

class DashboardPage extends Component<Props, State> {
	componentDidMount() {
		this.props.onSetPage(routes.DASHBOARD);
		window.scrollTo(0, 0);
	}

	render() {
		const { user, userMeta } = this.props;

		return (
			<div id='dashboard' className='uk-width-expand uk-padding' style={{ minHeight: '1px', overflowY: 'auto' }}>
				<BackTop />
				<Row>
					<Col md={{ span: 12, offset: 6 }} sm={{ span: 24, offset: 0 }}>
						<Card>
							<div className='uk-flex uk-flex-center uk-flex-middle'>
								<div className='uk-flex uk-flex-column'>
									<h3>{`Welcome back ${user?.firstName?.toUpperCase()}!`}</h3>
								</div>
							</div>
						</Card>
					</Col>
				</Row>
				{userMeta.photographer && (
					<Row className='uk-margin-top' gutter={16}>
						<Col md={8} sm={24} className='uk-margin-small-top'>
							<Card title='Clients'>
								<div className='uk-flex uk-flex-center uk-flex-middle'>
									<div className='uk-flex uk-flex-column'>
										<p>{`Manage your clients.`}</p>
										<Button
											type='primary'
											onClick={() => {
												this.props.history.push(routes.CLIENTS);
											}}
										>
											Go to Clients
										</Button>
									</div>
								</div>
							</Card>
						</Col>
						<Col md={8} sm={24} className='uk-margin-small-top'>
							<Card title='Events'>
								<div className='uk-flex uk-flex-center uk-flex-middle'>
									<div className='uk-flex uk-flex-column'>
										<p>{`Set up your events.`}</p>
										<Button
											type='primary'
											onClick={() => {
												this.props.history.push(routes.EVENTS);
											}}
										>
											Go To Events
										</Button>
									</div>
								</div>
							</Card>
						</Col>
						<Col md={8} sm={24} className='uk-margin-small-top'>
							<Card title='Products'>
								<div className='uk-flex uk-flex-center uk-flex-middle'>
									<div className='uk-flex uk-flex-column'>
										<p>{`Create your products.`}</p>
										<Button
											type='primary'
											onClick={() => {
												this.props.history.push(routes.PRODUCTS);
											}}
										>
											Go To Products
										</Button>
									</div>
								</div>
							</Card>
						</Col>
					</Row>
				)}
				{!userMeta.photographer && (
					<Row className='uk-margin-top' gutter={16}>
						<Col offset={6} md={12} sm={24} className='uk-margin-small-top'>
							<Card title='Photographer'>
								<div className='uk-flex uk-flex-center uk-flex-middle'>
									<div className='uk-flex uk-flex-column'>
										<p>{`Request to become a photographer`}</p>
										<Button
											type='primary'
											onClick={() => {
												this.props.history.push(routes.SETTINGS);
											}}
										>
											Go to your settings
										</Button>
									</div>
								</div>
							</Card>
						</Col>
					</Row>
				)}
			</div>
		);
	}
}

const condition = (authUser: any) => !!authUser;

export default compose<any, any>(
	withRouter,
	withAuthorization(condition),
	connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps)
)(DashboardPage);
