import React, { Component } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { UserMeta } from '../../models/UsersModel';
import { TaskState } from '../../models/Commons';
import { Button, Table, Icon } from 'antd';
import { Offering } from '../../models/ProductsModel';
import OfferingModal from './OfferingModal';
import * as rProducts from '../../reducers/products';
import { getArrayItemByKey } from '../../helpers/arrays';
import emptyview from '../../assets/emptyview_photo.png';
import { firebase } from '../../firebase'

interface OwnProps extends RouteComponentProps { }

interface StateProps {
	user: firebase.firebase.User;
	userMeta: UserMeta;
	loadOfferings: TaskState;
	offerings: Array<Offering>;
	newItem: TaskState;
	updateItem: TaskState;
}

interface DispatchProps {
	onSetSelectedOffering: Function;
	onClearItemState: Function;
}

type Props = StateProps & DispatchProps & OwnProps;
type State = { visible: boolean };

const mapStateToProps = (state: any) => ({
	user: state.usersState.user,
	userMeta: state.usersState.userMeta,
	loadOfferings: state.productsState.loadOfferings,
	offerings: state.productsState.offerings,
	newItem: state.productsState.newItem,
	updateItem: state.productsState.updateItem
});

const mapDispatchToProps = (dispatch: any) => ({
	onSetSelectedOffering: (offering: Offering) => {
		dispatch(rProducts.setSelectedOffering(offering));
	},
	onClearItemState: () => {
		dispatch(rProducts.modifyNewItem('LOADING', false));
		dispatch(rProducts.modifyUpdateItem('LOADING', false));
	}
});

const INITIAL_STATE = {
	visible: false
};

class OfferingsList extends Component<Props, State> {
	state = { ...INITIAL_STATE };
	componentDidMount() { }
	openModal = () => {
		this.props.onClearItemState();
		this.setState({ visible: true });
	};
	componentWillReceiveProps(nextProps: Props) {
		if (nextProps.newItem.success && !this.props.newItem.success) {
			this.setState({ visible: false });
		}
		if (nextProps.updateItem.success && !this.props.updateItem.success) {
			this.setState({ visible: false });
		}
	}
	render() {
		const { offerings, loadOfferings, user, userMeta } = this.props;
		const columns = [
			{
				title: 'Name',
				dataIndex: 'name'
			},
			{
				title: 'Description',
				dataIndex: 'description'
			}
			// {
			// 	title: 'Action',
			// 	dataIndex: 'key',
			// 	render: (key: string) => (
			// 		<a
			// 			onClick={() => {
			// 				this.props.onSetSelectedOffering(getArrayItemByKey(key, offerings));
			// 				this.openModal();
			// 			}}
			// 		>
			// 			Edit
			// 		</a>
			// 	)
			// }
		];
		const empty = (
			<div className='uk-flex uk-flex-center uk-text-center uk-width-expand'>
				<div className='uk-flex uk-flex-column uk-flex-center uk-width-large'>
					<div className='uk-width-expand uk-flex uk-flex-center'>
						<img src={emptyview} className='empty-view' />
					</div>
					<span className='uk-margin-top uk-margin-bottom'>You haven't set up any offerings yet.</span>
					<Button
						onClick={() => {
							this.openModal();
						}}
						type='primary'
					>
						Set up your first Offering
					</Button>
				</div>
			</div>
		);
		const header = (
			<div className='uk-flex uk-flex-right uk-width-expand'>
				<Button
					onClick={() => {
						this.openModal();
					}}
					type='primary'
				>
					New
				</Button>
			</div>
		);
		return (
			<div className='uk-width-expand'>
				<Table
					locale={{ emptyText: loadOfferings.empty && empty }}
					loading={{
						indicator: <Icon type='loading' style={{ fontSize: 36 }} spin />,
						spinning: loadOfferings.loading as boolean
					}}
					onRow={(offering: Offering, index: number) => {
						return {
							onClick: (event: any) => {
								this.props.onSetSelectedOffering(getArrayItemByKey(offering.key!, offerings));
								this.openModal();
							} // click row
						};
					}}
					className='table-row-clickable'
					columns={columns}
					dataSource={offerings}
					showHeader
					bordered
					title={() => header}
					pagination={{ pageSize: 10 }}
					scroll={{ x: '500px' }}
				/>
				<OfferingModal
					visible={this.state.visible}
					onCancel={() => {
						this.setState({ visible: false });
					}}
				/>
			</div>
		);
	}
}

export default compose<any, any>(
	withRouter,
	connect<StateProps, DispatchProps, OwnProps>(
		mapStateToProps,
		mapDispatchToProps
	)
)(OfferingsList);
