import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
	Modal,
	Table,
	Spin,
	Empty,
	Tabs,
	Icon,
	Form,
	Input,
	InputNumber,
	Select,
	Divider,
	Button,
	message
} from 'antd';
import { UserMeta } from '../../models/UsersModel';
import { Subject, Group } from '../../models/EventsModel';
import { TaskState } from '../../models/Commons';
import * as rGroups from '../../reducers/groups';
import { INITIAL_STATE_SUBJECT } from '../../reducers/teambuilder';
import { getStaffPositions } from '../../constants/selectOptions';
import PhoneInput from 'react-phone-number-input/basic-input';
import { validateEmail, titleCase } from '../../helpers/string';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { groups as fGroups, firebase } from '../../firebase';

interface OwnProps {
	visible: boolean;
	onCancel: Function;
	subject: Subject;
}
interface StateProps {
	user: firebase.firebase.User;
	userMeta: UserMeta;
}
interface DispatchProps { }
type Props = StateProps & DispatchProps & OwnProps;
type State = {
	selectedSubject: Subject;
	loading: boolean;
};
const INITIAL_STATE = {
	selectedSubject: INITIAL_STATE_SUBJECT,
	loading: false
};
const mapStateToProps = (state: any) => ({
	user: state.usersState.user,
	userMeta: state.usersState.userMeta
});
const mapDispatchToProps = (dispatch: any) => ({});
const formItemLayout = {
	labelCol: {
		xs: { span: 24 },
		sm: { span: 8 },
		md: { span: 4 }
	},
	wrapperCol: {
		xs: { span: 24 },
		sm: { span: 16 }
	}
};
class EditSubjectModal extends Component<Props, State> {
	state = { ...INITIAL_STATE };
	componentWillReceiveProps(nextProps: Props) {
		if (nextProps.subject.key !== this.props.subject.key) {
			this.setState({ selectedSubject: { ...nextProps.subject } });
		}
	}
	validateSubject = () => {
		const { selectedSubject } = this.state;

		if (selectedSubject.firstName === '') {
			message.error(`Please enter their first name`);
			return;
		}
		if (selectedSubject.lastName === '') {
			message.error(`Please enter their last name`);
			return;
		}
		if (!validateEmail(selectedSubject.email)) {
			message.error(`Please enter a valid email`);
			return;
		}
		if (selectedSubject.phoneNumber !== '' && !isValidPhoneNumber(`+1${selectedSubject.phoneNumber}`)) {
			message.error(`Please enter a valid phone number`);
			return;
		}
		if (selectedSubject.role === '' && selectedSubject.type === 'staff') {
			message.error(`Please select their role`);
			return;
		}
		this.updateSubject();
	};
	updateSubject = () => {
		const { selectedSubject } = this.state;
		this.setState({ loading: true });
		fGroups
			.updateSubject(selectedSubject)
			.then(() => {
				this.setState({ loading: false });
				message.success('Subject was successfully updated.');
				this.props.onCancel();
			})
			.catch((error) => {
				this.setState({ loading: false });
				message.error('Something went wrong, please try again.');
			});
	};

	render() {
		const { visible, onCancel, subject } = this.props;
		const { selectedSubject, loading } = this.state;
		return (
			<Modal
				keyboard={false}
				centered
				bodyStyle={{
					height: '90vh',
					borderRadius: 0
				}}
				width={'90vw'}
				visible={visible}
				title={`${selectedSubject.firstName} ${selectedSubject.lastName}`}
				footer={null}
				afterClose={() => {
					this.setState({ ...INITIAL_STATE });
				}}
				onCancel={() => {
					onCancel();
				}}
				onOk={() => { }}
			>
				<Form className='steps-content'>
					<Spin indicator={<Icon type='loading' style={{ fontSize: 24 }} spin />} spinning={loading}>
						<Form.Item label='First Name' {...formItemLayout}>
							<Input
								placeholder='First Name'
								value={selectedSubject.firstName}
								onChange={(e) => {
									this.setState({
										selectedSubject: { ...subject, firstName: titleCase(e.target.value) }
									});
								}}
							/>
						</Form.Item>
						<Form.Item label='Last Name' {...formItemLayout}>
							<Input
								placeholder='Last Name'
								value={selectedSubject.lastName}
								onChange={(e) => {
									this.setState({
										selectedSubject: { ...subject, lastName: titleCase(e.target.value) }
									});
								}}
							/>
						</Form.Item>
						<Form.Item label='Email' {...formItemLayout}>
							<Input
								name='q'
								type='text'
								autoComplete='new-password'
								placeholder='Email'
								value={selectedSubject.email}
								onChange={(e) => {
									this.setState({
										selectedSubject: { ...subject, email: titleCase(e.target.value) }
									});
								}}
							/>
						</Form.Item>
						{selectedSubject.type === 'staff' && (
							<Form.Item label='Phone Number' {...formItemLayout}>
								<PhoneInput
									autoComplete='new-password'
									className='ant-input'
									country='CA'
									placeholder='Phone Number'
									value={selectedSubject.phoneNumber}
									onChange={(e: any) => {
										this.setState({ selectedSubject: { ...subject, phoneNumber: e } });
									}}
								/>
							</Form.Item>
						)}
						{selectedSubject.type === 'player' && (
							<Form.Item label='Jersey Number' {...formItemLayout}>
								<Input
									name='q'
									type='text'
									autoComplete='new-password'
									placeholder='Jersey Number'
									value={selectedSubject.jerseyNumber}
									onChange={(e) => {
										this.setState({
											selectedSubject: { ...subject, jerseyNumber: e.target.value }
										});
									}}
								/>
								{/* <InputNumber
									max={200}
									min={0}
									step={1}
									style={{ width: '100%' }}
									placeholder='Jersey Number'
									value={selectedSubject.jerseyNumber}
									onChange={(e) => {
										this.setState({ selectedSubject: { ...subject, jerseyNumber: e } });
									}}
								/> */}
							</Form.Item>
						)}
						{selectedSubject.type === 'staff' && (
							<Form.Item label='Role' {...formItemLayout}>
								<Select
									style={{ minWidth: '150px' }}
									placeholder={'Select Role..'}
									labelInValue
									value={selectedSubject.role ? { key: selectedSubject.role } : undefined}
									onChange={(e: any) => {
										this.setState({ selectedSubject: { ...subject, role: e.key } });
									}}
								>
									{getStaffPositions()}
								</Select>
							</Form.Item>
						)}
					</Spin>
				</Form>
				<div className='uk-position-bottom uk-padding-small'>
					<Divider />
					<div className='uk-flex uk-flex-row'>
						<div className='uk-flex uk-flex-left'>
							<Button onClick={() => onCancel()}>Cancel</Button>
						</div>
						<div className='uk-flex uk-flex-right uk-width-expand'>
							<Button
								style={{ marginLeft: 8 }}
								type='primary'
								onClick={() => {
									this.validateSubject();
								}}
							>
								Save
							</Button>
						</div>
					</div>
				</div>
			</Modal>
		);
	}
}
export default connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps)(EditSubjectModal);
