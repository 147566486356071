import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import { Layout, Menu, Icon, Popconfirm } from 'antd';
import * as routes from '../constants/routeNames';
import dashRoutes from '../routes/dashboard';
import IconHolder from './IconHolder';
import { auth as fAuth } from '../firebase';
import TitleHolder from './TitleHolder';
import logo from '../assets/logo_sidebar.png';

const { Header, Content, Footer, Sider } = Layout;

// {
//   xs: '480px',
//   sm: '576px',
//   md: '768px',
//   lg: '992px',
//   xl: '1200px',
//   xxl: '1600px',
// }

const mapStateToProps = (state) => ({
	authUser: state.sessionState.authUser,
	page: state.sessionState.page,
	userMeta: state.usersState.userMeta
});

const mapDispatchToProps = (dispatch) => ({
	onLogOut: () => {
		dispatch(fAuth.doSignOut());
	}
});

const mql = window.matchMedia(`(min-width: 992px)`);

class Dashboard extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			collapsed: false,
			mobile: false
		};
		this.mediaQueryChanged = this.mediaQueryChanged.bind(this);
	}
	componentDidMount() {
		mql.addListener(this.mediaQueryChanged);
		this.setState({
			mobile: !mql.matches,
			collapsed: !mql.matches ? true : false
		});
	}
	componentWillUnmount() {
		mql.removeListener(this.mediaQueryChanged);
	}
	mediaQueryChanged() {
		this.setState({ mobile: !mql.matches });
	}
	toggleMobile = () => {
		if (this.state.mobile && !this.state.collapsed) {
			this.setState({
				collapsed: true
			});
		}
	};
	toggle = () => {
		this.setState({
			collapsed: !this.state.collapsed
		});
	};
	logout = () => {
		this.props.onLogOut();
	};
	render() {
		const { page } = this.props;
		let selectedKey = '1';
		let pageTitle = 'Home';
		dashRoutes.forEach((route, index) => {
			if (route.path === page) {
				selectedKey = route.key;
				pageTitle = route.name;
			}
		});

		return (
			<Layout>
				<Sider
					breakpoint={'lg'}
					width={256}
					trigger={null}
					onBreakpoint={() => {}}
					collapsible
					collapsedWidth={this.state.mobile ? '0' : '80'}
					collapsed={this.state.collapsed}
				>
					<div className='logo uk-flex uk-flex-row'>
						<div
							style={{ width: this.state.collapsed ? '48px' : undefined }}
							className='uk-flex uk-flex-center uk-flex-middle'
						>
							<img src={logo} />
						</div>
						{!this.state.collapsed && (
							<div className='uk-flex uk-flex-middle uk-flex-right'>
								<p
									style={{ fontWeight: '500', fontSize: '18px', color: 'white' }}
									className='uk-margin-remove'
								>
									Teampics
								</p>
							</div>
						)}
					</div>
					<Menu theme='dark' mode='inline' selectedKeys={[ selectedKey ]} defaultSelectedKeys={[ '1' ]}>
						{dashRoutes.map((route, index) => {
							if (this.props.userMeta.roles) {
								if (route.show && this.props.userMeta.roles.includes(route.roles)) {
									return (
										<Menu.Item
											onClick={() => {
												this.props.history.push(route.path);
												this.toggleMobile();
											}}
											key={route.key}
										>
											<Icon type={route.icon} />
											<span className='nav-text'>{route.name}</span>
										</Menu.Item>
									);
								}
							}
							return null;
						})}
					</Menu>
				</Sider>
				<Layout style={{ minHeight: '100vh', backgroundColor: '#f8f8f8' }}>
					<div className='uk-inline uk-width-expand'>
						<Header
							style={{
								top: '0px',
								position: 'relative',
								zIndex: 1,
								width: '100%',
								padding: 0
							}}
						>
							<Menu mode='horizontal' selectedKeys={[ selectedKey ]} style={{ lineHeight: '64px' }}>
								<IconHolder collapsed={this.state.collapsed} toggle={this.toggle} />
								<TitleHolder pageTitle={pageTitle} />
								<Menu.Item
									style={{
										float: 'right',
										display: 'flex',
										justifyContent: 'space-around',
										alignItems: 'center'
									}}
									key='20'
								>
									<Popconfirm
										placement='topLeft'
										title={'Are you sure you want to log out?'}
										onConfirm={this.logout}
										okText='Yes'
										cancelText='No'
									>
										<Icon type='logout' />
										<span style={{ fontSize: '12px' }} className='nav-text'>
											Log Out
										</span>{' '}
									</Popconfirm>
								</Menu.Item>
								<Menu.Item
									style={{
										float: 'right',
										display: 'flex',
										justifyContent: 'space-around',
										alignItems: 'center'
									}}
									onClick={() => {
										this.props.history.push(routes.SETTINGS);
									}}
									key='8'
								>
									<Icon type='setting' />
									<span style={{ fontSize: '12px' }} className='nav-text'>
										Profile
									</span>
								</Menu.Item>
							</Menu>
						</Header>
						<div
							style={{
								background: 'rgba(34, 34, 34, 0.9)',
								marginTop: '66px',
								zIndex: '50',
								display: this.state.mobile && !this.state.collapsed ? '' : 'none'
							}}
							className='uk-overlay-primary uk-position-cover'
						/>
						<Content
							style={{
								margin: '24px 16px 0',
								overflow: 'initial'
							}}
						>
							<div>
								<div>{this.props.children}</div>
							</div>
						</Content>
						<Footer style={{ textAlign: 'center', backgroundColor: '#f8f8f8' }}>Teampics © 2019</Footer>
					</div>
				</Layout>
			</Layout>
		);
	}
}

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(Dashboard);
