import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";

import { auth as fAuth } from "../firebase";
import * as routes from "../constants/routeNames";
import { Spin, Icon } from "antd";

// https://www.robinwieruch.de/react-firebase-authorization-roles-permissions/

const withAuthorization = (condition) => (Component) => {
  class WithAuthorization extends React.Component {
    componentDidMount() {
      this.authListener = fAuth.onAuthUserListener(
        (authUser) => {
          if (!condition(authUser)) {
            this.props.history.push(routes.LOGIN);
          }
        },
        () => {
          this.props.history.push(routes.LOGIN);
        }
      );
    }
    componentWillUnmount() {
      this.authListener();
    }

    render() {
      return this.props.authUser &&
        this.props.user.userId &&
        this.props.userMeta.key ? (
        <Component {...this.props} />
      ) : (
        <Spin indicator={<Icon type="loading" style={{ fontSize: 24 }} spin />}>
          <div style={{ height: "100vh", width: "100vw" }} />
        </Spin>
      );
    }
  }

  const mapStateToProps = (state) => ({
    authUser: state.sessionState.authUser,
    user: state.usersState.user,
    userMeta: state.usersState.userMeta,
  });

  const mapDispatchToProps = (dispatch) => ({
    onSetAuthUser: (authUser) => dispatch({ type: "AUTH_USER_SET", authUser }),
  });

  return compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
  )(WithAuthorization);
};

export default withAuthorization;
