import React, { Component } from 'react';
import withAuthorization from '../../hoc/withAuthorization';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { setPage } from '../../reducers/session';
import * as routes from '../../constants/routeNames';
import { User, UserMeta } from '../../models/UsersModel';

import { events as fEvents, products as fProducts, clients as fClients } from '../../firebase';
import EventsList from './EventsList';
import { Row, Col, BackTop } from 'antd';

interface OwnProps extends RouteComponentProps { }

interface StateProps {
	user: User;
	userMeta: UserMeta;
	active: boolean;
}

interface DispatchProps {
	onSetPage: Function;
	onLoadEvents: Function;
	onLoadClients: Function;
	onLoadOfferings: Function;
	onLoadPackages: Function;
}

type Props = StateProps & DispatchProps & OwnProps;
type State = {};

const mapStateToProps = (state: any) => ({
	user: state.usersState.user,
	userMeta: state.usersState.userMeta,
	active: state.eventsState.active
});

const mapDispatchToProps = (dispatch: any) => ({
	onSetPage: (page: any) => {
		dispatch(setPage(page));
	},
	onLoadEvents: (uid: string, active: boolean) => {
		dispatch(fEvents.subscribeToEvents(uid, active));
	},
	onLoadClients: (uid: string) => {
		dispatch(fClients.subscribeToClients(uid));
	},
	onLoadOfferings: (uid: string) => {
		dispatch(fProducts.subscribeOfferings(uid));
	},
	onLoadPackages: (uid: string) => {
		dispatch(fProducts.subscribePacks(uid));
	}
});

class EventsPage extends Component<Props, State> {
	componentDidMount() {
		this.props.onSetPage(routes.EVENTS);
		this.props.onLoadEvents(this.props.user.userId, this.props.active);
		this.props.onLoadClients(this.props.user.userId);
		this.props.onLoadOfferings(this.props.user.userId);
		this.props.onLoadPackages(this.props.user.userId);
		window.scrollTo(0, 0);
	}

	componentDidUpdate(prevProps: Props) {
		if (prevProps.active !== this.props.active) {
			this.props.onLoadEvents(this.props.user.userId, this.props.active);
		}
	}


	render() {
		return (
			<div className='uk-width-expand'>
				<BackTop />
				<div
					style={{
						minHeight: 'calc(100vh - 157px)',
						padding: '10px',
						backgroundColor: 'white'
					}}
				>
					<Row>
						<Col span={24}>
							<EventsList />
						</Col>
					</Row>
				</div>
			</div>
		);
	}
}

const condition = (authUser: any) => !!authUser;

export default compose<any, any>(
	withRouter,
	withAuthorization(condition),
	connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps)
)(EventsPage);
