import React, { Component } from "react";
import withAuthorization from "../../hoc/withAuthorization";
import { compose } from "recompose";
import { connect } from "react-redux";
import { Tabs, Card, BackTop, Row, Col, Button } from "antd";
import { RouteComponentProps } from "react-router";
import { User, UserMeta } from "../../models/UsersModel";
import { Event, Shoot, Group } from "../../models/EventsModel";
import { TaskState, Contact } from "../../models/Commons";
import {
  events as fEvents,
  products as fProducts,
  groups as fGroups,
  images as fImages,
} from "../../firebase";
import * as rEvents from "../../reducers/events";
import * as rGroups from "../../reducers/groups";
import EventsGeneralForm from "./EventsGeneralForm";
import ShootsList from "./ShootsList";
import GroupsList from "./GroupsList";
import ImagesRoot from "./Images";
import UploadCSVButton from "./Images/UploadCSVButton";
import ExportPurchasesButton from "./ExportPurchasesButton";
import FriendlyIdButton from "./FriendlyIdButton";

const TabPane = Tabs.TabPane;

interface OwnProps extends RouteComponentProps {}

interface StateProps {
  user: User;
  selectedEvent: Event;
}

interface DispatchProps {
  onLoadShoots: Function;
  onLoadOfferings: Function;
  onLoadPackages: Function;
  onLoadEvent: Function;
  onLoadGroups: Function;
  onClearEventsAndShoots: Function;
  onRefreshImages: Function;
}

type Props = StateProps & DispatchProps & OwnProps;
type State = {
  currentTab: string;
};

const mapStateToProps = (state: any) => ({
  user: state.usersState.user,
  selectedEvent: state.eventsState.selectedEvent,
});

const mapDispatchToProps = (dispatch: any) => ({
  onLoadShoots: (eventId: string) => {
    dispatch(fEvents.loadShoots(eventId));
  },
  onLoadGroups: (eventId: string) => {
    dispatch(fGroups.subscribeToGroups(eventId));
  },
  onLoadEvent: (eventId: string) => {
    dispatch(fEvents.loadEvent(eventId));
  },
  onLoadOfferings: (userId: string) => {
    dispatch(fProducts.subscribeOfferings(userId));
  },
  onLoadPackages: (userId: string) => {
    dispatch(fProducts.subscribePacks(userId));
  },
  onClearEventsAndShoots: () => {
    dispatch(rEvents.setSelectedEvent({ key: "" } as Event));
    dispatch(rEvents.modifyShootsArray("CLEAR", {} as Shoot));
    dispatch(rGroups.modifyGroupsArray("CLEAR", {} as Group));
    dispatch(rEvents.modifyUpdateEvent("LOADING", false));
    dispatch(rEvents.modifyLoadEventDetails("LOADING", false));
    dispatch(rEvents.modifyLoadShoots("LOADING", true));
  },
  onRefreshImages: (eventId: string, userId: string) => {
    dispatch(fImages.fetchImages(eventId, userId));
  },
});

class EventDetail extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { currentTab: "1" };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.onLoadShoots((this.props.match.params as any).eventId);
    if (!this.props.selectedEvent.key) {
      this.props.onLoadEvent((this.props.match.params as any).eventId);
    }
    this.props.onLoadGroups((this.props.match.params as any).eventId);
    this.props.onLoadOfferings(this.props.user.userId);
    this.props.onLoadPackages(this.props.user.userId);
  }
  componentWillUnmount() {
    this.props.onClearEventsAndShoots();
  }

  onTabChanged = (key: string) => {
    this.setState({ currentTab: key });
  };
  refreshImages = () => {
    if (this.props.selectedEvent.key && this.props.user.userId) {
      this.props.onRefreshImages(
        this.props.selectedEvent.key!,
        this.props.user.userId
      );
    }
  };

  render() {
    const { selectedEvent } = this.props;

    return (
      <div
        className="uk-width-expand"
        style={{ minHeight: "1px", overflowY: "auto" }}
      >
        <BackTop />
        <Card>
          <div>
            <a
              onClick={() => {
                this.props.history.push("/events");
              }}
            >{`< Back to Events List`}</a>
          </div>
          <Row
            type="flex"
            align="middle"
            justify="space-between"
            className="uk-margin-top"
          >
            <Col>
              <h2>{selectedEvent.key ? selectedEvent.name : ""}</h2>
            </Col>
            <Col className="uk-flex uk-flex-row">
              {this.state.currentTab === "4" && (
                <div className="uk-flex uk-flex-row">
                  <Button
                    style={{ marginRight: 12 }}
                    onClick={this.refreshImages}
                  >
                    Refresh
                  </Button>
                  <UploadCSVButton />
                </div>
              )}
              {this.state.currentTab === "1" && (
                <div
                  className="uk-flex uk-flex-row"
                  style={{ marginRight: 12 }}
                >
                  <FriendlyIdButton />
                </div>
              )}
              {this.state.currentTab === "1" && (
                <div className="uk-flex uk-flex-row">
                  <ExportPurchasesButton />
                </div>
              )}
            </Col>
          </Row>
          <Tabs
            defaultActiveKey="1"
            tabPosition={"top"}
            onChange={this.onTabChanged}
            activeKey={this.state.currentTab}
          >
            <TabPane tab="General" key="1">
              <EventsGeneralForm />
            </TabPane>
            <TabPane tab="Shoots" key="2">
              <ShootsList />
            </TabPane>
            <TabPane tab="Groups" key="3">
              <GroupsList />
            </TabPane>
            <TabPane tab="Images" key="4">
              <ImagesRoot />
            </TabPane>
          </Tabs>
        </Card>
      </div>
    );
  }
}

const condition = (authUser: any) => !!authUser;

export default compose<any, any>(
  withAuthorization(condition),
  connect<StateProps, DispatchProps, OwnProps>(
    mapStateToProps,
    mapDispatchToProps
  )
)(EventDetail);
