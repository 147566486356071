import { firestore } from './firebaseConfig';
import * as rPhotographers from '../reducers/photographers';
import { ThunkResult } from '../models/Commons';
import { PhotographerRequest } from '../models/PhotographerRequestModel';

//-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-//
//Loading "photographerRequests" object from firebase
//-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-//

let photographersListener: any;
export const subscribePhotographerRequests = (): ThunkResult<void> => {
	return (dispatch) => {
		const photographerRequestsRef = firestore.collection('photographerRequests').orderBy('timestamp').limit(50);

		if (photographersListener) {
			return;
		}

		photographersListener = photographerRequestsRef.onSnapshot((snapshot) => {
			if (snapshot.empty) {
				dispatch(rPhotographers.modifyLoadRequests('EMPTY', true));
			} else {
				dispatch(rPhotographers.modifyLoadRequests('SUCCESS', ''));
			}

			snapshot.docChanges().forEach(
				(change) => {
					if (change.type === 'added') {
						const request = {
							...change.doc.data() as PhotographerRequest,
							key: change.doc.id
						};
						dispatch(rPhotographers.modifyRequestsArray('ADD', request));
					}
					if (change.type === 'modified') {
						const request = {
							...change.doc.data() as PhotographerRequest,
							key: change.doc.id
						};
						dispatch(rPhotographers.modifyRequestsArray('UPDATE', request));
					}
					if (change.type === 'removed') {
						const request = {
							...change.doc.data() as PhotographerRequest,
							key: change.doc.id
						};
						dispatch(rPhotographers.modifyRequestsArray('REMOVE', request));
					}
				},
				(error: any) => {
					if (error) {
						rPhotographers.modifyLoadRequests('ERROR', 'Something went wrong, please refresh the page');
					}
				}
			);
		});

		return photographersListener;
	};
};

export const unSubscribePhotographerRequests = () => {
	if (photographersListener) {
		photographersListener();
		photographersListener = undefined;
	}
};

export const updatePhotographerStatus = (
	photographerId: string,
	requestId: string,
	currentValue: boolean
): ThunkResult<void> => {
	return (dispatch) => {
		const requestRef = firestore.collection('photographerRequests').doc(requestId);
		const photographerRef = firestore.collection('users').doc(photographerId).collection('metadata').doc('private');
		const batch = firestore.batch();
		dispatch(rPhotographers.modifyApproveRequest('LOADING', true));

		batch.update(requestRef, {
			accepted: !currentValue
		});

		batch.update(photographerRef, {
			photographer: !currentValue
		});

		return batch
			.commit()
			.then(() => {
				dispatch(rPhotographers.modifyApproveRequest('SUCCESS', 'Successfully updated request.'));
			})
			.catch((error) => {
				dispatch(rPhotographers.modifyApproveRequest('ERROR', 'Something went wrong, please try again'));
			});
	};
};
