import React, { Component } from 'react';
import { connect } from 'react-redux';
import { User, UserMeta } from '../../models/UsersModel';
import { Subject, Event } from '../../models/EventsModel';
import * as rTeambuilder from '../../reducers/teambuilder';
import Animate from 'rc-animate';
import { Card, Table, Icon, Input, Button, InputNumber, Spin } from 'antd';
import { TaskState } from '../../models/Commons';
import { dedupe } from '../../helpers/arrays';
import { titleCase } from '../../helpers/string';
const { Column } = Table;

interface OwnProps {}
interface StateProps {
	user: User;
	userMeta: UserMeta;
	players: Array<Subject>;
	event: Event;
	pageNumber: number;
	loadSubjects: TaskState;
}
interface DispatchProps {
	onAddPlayer: Function;
	onRemovePlayer: Function;
	onUpdatePlayer: Function;
	onAddToDeletePlayer: Function;
}
type Props = StateProps & DispatchProps & OwnProps;
type State = {};

const mapStateToProps = (state: any) => ({
	user: state.usersState.user,
	userMeta: state.usersState.userMeta,
	players: state.teambuilderState.players,
	toDeleteSubjects: state.teambuilderState.toDeleteSubjects,
	event: state.teambuilderState.event,
	pageNumber: state.teambuilderState.pageNumber,
	loadSubjects: state.teambuilderState.loadSubjects
});

const mapDispatchToProps = (dispatch: any) => ({
	onAddPlayer: (player: Subject) => {
		dispatch(rTeambuilder.modifyPlayersArray('ADD', player));
	},
	onUpdatePlayer: (player: Subject) => {
		dispatch(rTeambuilder.modifyPlayersArray('UPDATE', player));
	},
	onRemovePlayer: (player: Subject) => {
		dispatch(rTeambuilder.modifyPlayersArray('REMOVE', player));
	},
	onAddToDeletePlayer: (player: Subject) => {
		dispatch(rTeambuilder.modifyToDeleteSubjectsArray('ADD', player));
	}
});
const Div = (props: any) => {
	const childrenProps = { ...props };
	delete childrenProps.show;
	return <div {...childrenProps} />;
};
const formItemLayout = {
	labelCol: {
		xs: { span: 24 },
		sm: { span: 8 },
		md: { span: 4 }
	},
	wrapperCol: {
		xs: { span: 24 },
		sm: { span: 16 }
	}
};
const tailFormItemLayout = {
	wrapperCol: {
		xs: {
			span: 24,
			offset: 0
		},
		sm: {
			span: 16,
			offset: 8
		},
		md: {
			span: 16,
			offset: 4
		}
	}
};
class StepPlayersComp extends Component<Props, State> {
	componentDidMount() {}

	render() {
		const { players, event, pageNumber, loadSubjects } = this.props;

		return (
			<Animate transitionName='fade' transitionAppear>
				<Div style={{ height: '80%' }} show={pageNumber === 6}>
					<div style={{ height: '100%' }} className='uk-flex uk-flex-center uk-margin-top'>
						<Card
							style={{
								borderRadius: '5px',
								minWidth: '80vw',
								overflowY: 'auto'
							}}
						>
							<Spin
								indicator={<Icon type='loading' style={{ fontSize: 24 }} spin />}
								spinning={loadSubjects.loading as boolean}
							>
								<div className='uk-text-center'>
									<h2>Who's on your team?</h2>
									<span>
										All players need to be entered here in order to be photographed.{' '}
										<p style={{ fontWeight: 500 }}>
											Email information is critical for reminders and notifications of your
											appointment, as well as ensuring all photo orders can be processed properly.
										</p>
									</span>
								</div>

								<div style={{ minHeight: '400px' }} className='uk-padding-small'>
									<Table
										rowKey={(e) => e.key!}
										pagination={false}
										dataSource={dedupe(players.slice().reverse(), 'key')}
									>
										<Column
											title='#'
											dataIndex='key'
											key='number'
											render={(name, player, index) => <span>{index + 1}</span>}
										/>
										<Column
											title='First Name*'
											dataIndex='firstName'
											key='firstName'
											render={(firstName, player: Subject, index) => (
												<Input
													placeholder='First Name'
													autoComplete='new-password'
													value={firstName}
													onChange={(e) => {
														const newPlayer = {
															...player as Subject,
															firstName: titleCase(e.target.value)
														};
														if (e.target.value.length <= 30) {
															this.props.onUpdatePlayer(newPlayer);
														}
													}}
												/>
											)}
										/>
										<Column
											title='Last Name*'
											dataIndex='lastName'
											key='lastName'
											render={(lastName, player: Subject, index) => (
												<Input
													placeholder='Last Name'
													autoComplete='new-password'
													value={lastName}
													onChange={(e) => {
														const newPlayer = {
															...player as Subject,
															lastName: titleCase(e.target.value)
														};
														if (e.target.value.length <= 30) {
															this.props.onUpdatePlayer(newPlayer);
														}
													}}
												/>
											)}
										/>
										<Column
											title='Email*'
											dataIndex='email'
											key='email'
											render={(email, player: Subject, index) => (
												<Input
													name='q'
													type='text'
													autoComplete='new-password'
													placeholder='Email'
													value={email}
													onChange={(e) => {
														const newPlayer = {
															...player as Subject,
															email: e.target.value.trim()
														};
														this.props.onUpdatePlayer(newPlayer);
													}}
												/>
											)}
										/>
										<Column
											title='Jersey Number'
											dataIndex='jerseyNumber'
											key='jerseyNumber'
											render={(jerseyNumber, player: Subject, index) => (
												<Input
													type='text'
													autoComplete='new-password'
													placeholder='Jersey Number'
													value={jerseyNumber}
													onChange={(e) => {
														const newPlayer = {
															...player as Subject,
															jerseyNumber: e.target.value
														};
														this.props.onUpdatePlayer(newPlayer);
													}}
												/>
												// <InputNumber
												// 	max={200}
												// 	min={0}
												// 	step={1}
												// 	placeholder='Jersey Number'
												// 	value={jerseyNumber}
												// 	onChange={(e) => {
												// 		if (e && typeof e === 'number') {
												// 			const newPlayer = {
												// 				...player as Subject,
												// 				jerseyNumber: e
												// 			};
												// 			this.props.onUpdatePlayer(newPlayer);
												// 		} else {
												// 			const newPlayer = {
												// 				...player as Subject,
												// 				jerseyNumber: 0
												// 			};
												// 			this.props.onUpdatePlayer(newPlayer);
												// 		}
												// 	}}
												// />
											)}
										/>
										{players.length > 6 ? (
											<Column
												title='Action'
												dataIndex='key'
												key='action'
												render={(endTime, player: Subject, index) => (
													<div className='uk-padding-small'>
														{players.length > 6 ? (
															<Icon
																className='dynamic-delete-button'
																type='minus-circle-o'
																onClick={() => {
																	this.props.onRemovePlayer(player);
																	this.props.onAddToDeletePlayer(player);
																}}
															/>
														) : null}
													</div>
												)}
											/>
										) : null}
									</Table>
									<div className='uk-flex uk-flex-center uk-margin-top uk-margin-bottom'>
										<Button
											type='dashed'
											onClick={() =>
												this.props.onAddPlayer({
													key: rTeambuilder.generateKey(),
													...rTeambuilder.INITIAL_STATE_SUBJECT
												})}
											style={{ width: '60%' }}
										>
											<Icon type='plus' /> Add Player
										</Button>
									</div>
								</div>
							</Spin>
						</Card>
					</div>
				</Div>
			</Animate>
		);
	}
}

export default connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps)(StepPlayersComp);
