import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, Input } from 'antd';
import { UserMeta } from '../../models/UsersModel';
import { firebase } from '../../firebase'
const { TextArea } = Input;

interface OwnProps {
	visible: boolean;
	onCancel: Function;
	locationNotes: string;
	onUpdateLocationNotes: Function;
	disabled: boolean;
}
interface StateProps {
	user: firebase.firebase.User;
	userMeta: UserMeta;
}
interface DispatchProps { }
type Props = StateProps & DispatchProps & OwnProps;
type State = {};
const INITIAL_STATE = {};
const mapStateToProps = (state: any) => ({
	user: state.usersState.user,
	userMeta: state.usersState.userMeta
});
const mapDispatchToProps = (dispatch: any) => ({});

class LocationNotesModal extends Component<Props, State> {
	state = { ...INITIAL_STATE };
	componentWillReceiveProps(nextProps: Props) { }
	render() {
		const { visible, onCancel, locationNotes, onUpdateLocationNotes, disabled } = this.props;

		return (
			<Modal
				keyboard={false}
				centered
				bodyStyle={{
					height: '25vh',
					borderRadius: 0
				}}
				width={'50vw'}
				visible={visible}
				title={'Location Notes'}
				footer={null}
				afterClose={() => {
					this.setState({ ...INITIAL_STATE });
				}}
				onCancel={() => {
					onCancel();
				}}
				onOk={() => { }}
			>
				<TextArea
					disabled={disabled}
					placeholder='Enter Notes...'
					value={locationNotes}
					onChange={(e) => {
						onUpdateLocationNotes(e.target.value);
					}}
					rows={4}
				/>
			</Modal>
		);
	}
}
export default connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps)(LocationNotesModal);
