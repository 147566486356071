export type SortDirection = "asc" | "desc";
export function dynamicSort(property: string, nestedProperty: string, sort: SortDirection) {
	const sortOrder = sort === "asc" ? 1 : -1;
	return function (a: any, b: any) {
		const result =
			nestedProperty
				? a[property][nestedProperty] < b[property][nestedProperty] ? -1 : a[property][nestedProperty] > b[property][nestedProperty] ? 1 : 0
				: a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
		return result * sortOrder;
	};
}

export function getArrayItemByKey(key: string, array: Array<any>): any {
	return array.filter((item) => item.key === key)[0];
}

export function dedupe(arr: Array<any>, key: string): Array<any> {
	return arr.reduce(
		function (p, c) {
			// create an identifying id from the object values
			var id = [c[key], c[key]].join('|');

			// if the id is not found in the temp array
			// add the object to the output array
			// and add the key to the temp array
			if (p.temp.indexOf(id) === -1) {
				p.out.push(c);
				p.temp.push(id);
			}
			return p;

			// return the deduped array
		},
		{ temp: [], out: [] }
	).out;
}

export function arrayUnionStringArray(string: string, array: string[]) {
	if (array.includes(string)) {
		return array;
	} else {
		return [...array, string]
	}
}

export const chunkArray = (array: Array<any>, size: any): Array<Array<any>> => {
	const chunked_arr = [];
	const copied = [...array]; // ES6 destructuring
	const numOfChild = Math.ceil(copied.length / size); // Round up to the nearest integer
	for (let i = 0; i < numOfChild; i++) {
		chunked_arr.push(copied.splice(0, size));
	}
	return chunked_arr;
};