export const hexToRgb = (hex) =>
	hex
		.replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i, (m, r, g, b) => '#' + r + r + g + g + b + b)
		.substring(1)
		.match(/.{2}/g)
		.map((x) => parseInt(x, 16));

export function hexToRgbA(hex) {
	var c;
	if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
		c = hex.substring(1).split('');
		if (c.length === 3) {
			c = [ c[0], c[0], c[1], c[1], c[2], c[2] ];
		}
		c = '0x' + c.join('');
		return 'rgba(' + [ (c >> 16) & 255, (c >> 8) & 255, c & 255 ].join(',') + ',1)';
	}
	throw new Error('Bad Hex');
}
