import { firestore } from "./firebaseConfig";
import * as rImages from "../reducers/images";
import { ThunkResult } from "../models/Commons";
import { Group, Subject, TeampicsImage } from "../models/EventsModel";

export const fetchImages = (
  eventId: string,
  photographerId: string
): ThunkResult<void> => {
  return (dispatch) => {
    dispatch(rImages.modifyLoadImages("LOADING", true));
    const promiseArray = [];
    promiseArray.push(
      firestore
        .collection("uploadedImages")
        .where("photographerId", "==", photographerId)
        .where("eventId", "==", eventId)
        .get()
    );
    promiseArray.push(
      firestore.collection("events").doc(eventId).collection("groups").get()
    );
    promiseArray.push(
      firestore.collection("subjects").where("eventId", "==", eventId).get()
    );
    Promise.all(promiseArray)
      .then((results) => {
        const imagesSnap = results[0];
        const groupsSnap = results[1];
        const subjectsSnap = results[2];

        const images: TeampicsImage[] = [];
        const groups: Group[] = [];
        const subjects: Subject[] = [];

        if (imagesSnap.docs.length <= 0) {
          dispatch(rImages.modifyImagesArray("CLEAR", []));
          dispatch(rImages.modifyImagesGroupsArray("CLEAR", []));
          dispatch(rImages.modifyImagesSubjectsArray("CLEAR", []));
          dispatch(rImages.modifyLoadImages("EMPTY", true));
        } else {
          imagesSnap.forEach((snap) => {
            images.push({
              key: snap.id,
              ...snap.data(),
            } as TeampicsImage);
          });
          groupsSnap.forEach((snap) => {
            groups.push({
              key: snap.id,
              ...snap.data(),
            } as Group);
          });
          subjectsSnap.forEach((snap) => {
            subjects.push({
              key: snap.id,
              ...snap.data(),
            } as Subject);
          });
          dispatch(rImages.modifyImagesArray("CLEAR", []));
          dispatch(rImages.modifyImagesArray("ADD_ARRAY", images));
          dispatch(rImages.modifyImagesGroupsArray("CLEAR", []));
          dispatch(rImages.modifyImagesGroupsArray("ADD_ARRAY", groups));
          dispatch(rImages.modifyImagesSubjectsArray("CLEAR", []));
          dispatch(rImages.modifyImagesSubjectsArray("ADD_ARRAY", subjects));
          dispatch(rImages.modifyLoadImages("SUCCESS", true));
        }
      })
      .catch((error) => {
        dispatch(rImages.modifyLoadImages("ERROR", true));
      });
  };
};
