import { firestore } from './firebaseConfig';
import * as rGroups from '../reducers/groups';
import * as storage from './storage';
import { ThunkResult } from '../models/Commons';
import { Group, Subject } from '../models/EventsModel';

let groupsListener: any;
export const subscribeToGroups = (eventId: string): ThunkResult<void> => {
	return (dispatch) => {
		const groupsRef = firestore.collection('events').doc(eventId).collection('groups').orderBy('name');

		if (groupsListener) {
			return;
		}
		dispatch(rGroups.modifyLoadGroups('LOADING', true));

		groupsListener = groupsRef.onSnapshot(
			(snapshot) => {
				if (snapshot.empty) {
					dispatch(rGroups.modifyLoadGroups('EMPTY', true));
				} else {
					dispatch(rGroups.modifyLoadGroups('SUCCESS', true));
				}

				snapshot.docChanges().forEach((change) => {
					if (change.type === 'added') {
						const group = {
							...change.doc.data() as Group,
							key: change.doc.id
						};
						dispatch(rGroups.modifyGroupsArray('ADD', group));
					}
					if (change.type === 'modified') {
						const group = {
							...change.doc.data() as Group,
							key: change.doc.id
						};
						dispatch(rGroups.modifyGroupsArray('UPDATE', group));
					}
					if (change.type === 'removed') {
						const group = {
							...change.doc.data() as Group,
							key: change.doc.id
						};
						dispatch(rGroups.modifyGroupsArray('REMOVE', group));
					}
				});
			},
			(error: any) => {
				if (error) {
					dispatch(rGroups.modifyLoadGroups('ERROR', 'Something went wrong, please refresh the page'));
				}
			}
		);
		return groupsListener;
	};
};

export const unSubscribeGroups = () => {
	if (groupsListener) {
		groupsListener();
		groupsListener = undefined;
	}
};

export const loadSubjects = (groupId: string): ThunkResult<void> => {
	return (dispatch) => {
		const subjectsRef = firestore
			.collection('subjects')
			.where('groupId', '==', groupId)
			.orderBy('firstName', 'desc');
		dispatch(rGroups.modifyLoadSubjects('LOADING', true));

		return subjectsRef
			.get()
			.then((subjectsSnap) => {
				if (subjectsSnap.empty) {
					dispatch(rGroups.modifyLoadSubjects('EMPTY', true));
				}
				subjectsSnap.forEach((subjectSnap) => {
					const subject = {
						key: subjectSnap.id,
						...subjectSnap.data() as Subject
					};
					dispatch(rGroups.modifySubjectsArray('ADD', subject));
				});
				return dispatch(rGroups.modifyLoadSubjects('SUCCESS', true));
			})
			.catch((error) => {
				dispatch(rGroups.modifyLoadSubjects('ERROR', 'Something went wrong, please try again'));
			});
	};
};

export const updateSubject = (subject: Subject) => {
	const subjectRef = firestore.collection('subjects').doc(subject.key);
	return subjectRef.update(subject);
};

export const deleteGroup = (eventId: string, groupId: string, shootId: string, timeslotId: string) => {
	const groupRef = firestore.collection('events').doc(eventId).collection('groups').doc(groupId);
	const timeslotRef = firestore
		.collection('events')
		.doc(eventId)
		.collection('shoots')
		.doc(shootId)
		.collection('timeslots')
		.doc(timeslotId);
	const subjectsRef = firestore.collection('subjects').where('groupId', '==', groupId);

	return subjectsRef.get().then((subjectSnaps) => {
		const batch = firestore.batch();
		if (timeslotId !== '') {
			batch.update(timeslotRef, {
				taken: false,
				teamName: '',
				groupId: '',
				contactId: ''
			});
		}
		subjectSnaps.forEach((snap) => {
			const subjectRef = firestore.collection('subjects').doc(snap.id);
			batch.delete(subjectRef);
		});
		batch.delete(groupRef);
		return batch.commit();
	});
};
