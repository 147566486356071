import React, { useState } from "react";
import { Modal, Button, message, Form, Input } from "antd";
import { firestore } from "../../../firebase/firebaseConfig";
import { useDispatch, useSelector } from "react-redux";
import {
  Event,
  Group,
  Subject,
  TeampicsImage,
} from "../../../models/EventsModel";
import { User } from "../../../models/UsersModel";
import * as rImages from "../../../reducers/images";
import { checkIfSubjectImageValid } from "./Subjects";

interface Props {
  groupId: string;
  subjectId: string;
  visible: boolean;
  onClose(): any;
}

const AssignImageModal: React.FC<Props> = ({
  groupId,
  subjectId,
  visible,
  onClose,
}) => {
  const [loading, setLoading] = useState(false);
  const selectedEvent: Event = useSelector(
    (state: any) => state.eventsState.selectedEvent
  );
  const dispatch = useDispatch();
  const user: User = useSelector((state: any) => state.usersState.user);
  const groups: Group[] = useSelector((state: any) => state.imagesState.groups);
  const subjects: Subject[] = useSelector(
    (state: any) => state.imagesState.subjects
  );
  const [filename, setFilename] = useState("");
  const checkIfMissing = (position: string) => {
    switch (position.charAt(0)) {
      case "M":
        return "yes";
      case "X":
        return "yes";
      default:
        return "no";
    }
  };
  const onSubmit = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    try {
      if (groupId !== "") {
        const selectedGroup: Group | undefined = groups.find(
          (group) => group.key === groupId
        );
        if (selectedGroup) {
          const imageRef = firestore.collection("uploadedImages").doc();
          const imageId = imageRef.id;
          const newImage: TeampicsImage = {
            type: "groups",
            eventId: selectedEvent.key!,
            dateCreated: new Date().getTime(),
            filename: filename,
            friendlyId: selectedGroup.friendlyId,
            groups: [groupId],
            subjects: [],
            hasGroup: true,
            hasSubject: false,
            imageId: imageId,
            photographerId: user.userId!,
            key: imageId,
            isVerified: false,
          };
          const updatedGroup: Group = {
            ...selectedGroup,
          };
          if (!updatedGroup.imageIds) {
            updatedGroup.imageIds = [imageId];
          } else {
            updatedGroup.imageIds.push(imageId);
          }
          if (updatedGroup.imageIds.length > 1) {
            updatedGroup.primaryImageId = "";
            updatedGroup.hasValidImage = false;
          } else if (
            !updatedGroup.primaryImageId ||
            updatedGroup.primaryImageId === ""
          ) {
            updatedGroup.primaryImageId = imageId;
            updatedGroup.hasValidImage = true;
          } else {
            updatedGroup.primaryImageId = "";
            updatedGroup.hasValidImage = false;
          }
          const batch = firestore.batch();
          batch.set(imageRef, newImage);
          batch.update(
            firestore
              .collection("events")
              .doc(selectedEvent.key!)
              .collection("groups")
              .doc(groupId),
            updatedGroup
          );
          await batch.commit();
          dispatch(rImages.modifyImagesGroupsArray("UPDATE", updatedGroup));
          dispatch(rImages.modifyImagesArray("ADD", newImage));
        }
      } else if (subjectId !== "") {
        const selectedSubject: Subject | undefined = subjects.find(
          (subject) => subject.key === subjectId
        );
        if (selectedSubject) {
          const imageRef = firestore.collection("uploadedImages").doc();
          const imageId = imageRef.id;
          const newImage: TeampicsImage = {
            type: "subjects",
            eventId: selectedEvent.key!,
            dateCreated: new Date().getTime(),
            filename: filename,
            friendlyId: selectedSubject.friendlyId,
            groups: [],
            subjects: [subjectId],
            hasGroup: false,
            hasSubject: true,
            imageId: imageId,
            photographerId: user.userId!,
            key: imageId,
            isVerified: false,
          };
          const updatedSubject: Subject = {
            ...selectedSubject,
          };
          if (!updatedSubject.imageIds) {
            updatedSubject.imageIds = [imageId];
          } else {
            updatedSubject.imageIds.push(imageId);
          }
          if (updatedSubject.imageIds.length > 1) {
            updatedSubject.primaryImageId = "";
          } else if (
            (!updatedSubject.primaryImageId ||
              updatedSubject.primaryImageId === "") &&
            selectedSubject.position &&
            !checkIfMissing(selectedSubject.position)
          ) {
            updatedSubject.primaryImageId = imageId;
          } else {
            updatedSubject.primaryImageId = "";
          }
          if (
            checkIfSubjectImageValid(updatedSubject, updatedSubject.imageIds)
          ) {
            updatedSubject.hasValidImage = true;
          } else {
            updatedSubject.hasValidImage = false;
          }
          const batch = firestore.batch();
          batch.set(imageRef, newImage);
          batch.update(
            firestore.collection("subjects").doc(subjectId),
            updatedSubject
          );
          await batch.commit();
          dispatch(rImages.modifyImagesArray("ADD", newImage));
          dispatch(rImages.modifyImagesSubjectsArray("UPDATE", updatedSubject));
        }
      }
      setLoading(false);
      onClose();
    } catch (error) {
      console.log(
        "🚀 ~ file: AssignImageModal.tsx ~ line 40 ~ onSubmit ~ error",
        error
      );
      message.error("Something went wrong, please try again");
      setLoading(false);
    }
  };
  const onChange = (e: any) => {
    setFilename(e.target.value);
  };
  const onAfterClose = () => {
    setFilename("");
  };
  return (
    <Modal
      afterClose={onAfterClose}
      title="Assign Image"
      visible={visible}
      onOk={undefined}
      onCancel={onClose}
      footer={null}
    >
      <Form onSubmit={onSubmit}>
        <Form.Item label="Please enter the file name" required>
          <Input placeholder="File name" value={filename} onChange={onChange} />
        </Form.Item>
        <Form.Item>
          <Button
            loading={loading}
            type="primary"
            htmlType="submit"
            disabled={filename === ""}
          >
            Assign
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AssignImageModal;
