import React, { Component } from 'react';
import withAuthorization from '../../hoc/withAuthorization';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { Tabs, Card, BackTop } from 'antd';
import ClientGeneralForm from './ClientGeneralForm';
import ClientContactsList from './ClientContactsList';
import { RouteComponentProps } from 'react-router';
import { User } from '../../models/UsersModel';
import { Client } from '../../models/ClientsModel';
import { TaskState, Contact } from '../../models/Commons';
import { clients as fClients } from '../../firebase';
import * as rClients from '../../reducers/clients';

const TabPane = Tabs.TabPane;

interface OwnProps extends RouteComponentProps {}

interface StateProps {
	user: User;
	selectedClient: Client;
}

interface DispatchProps {
	onLoadContacts: Function;
	onLoadClient: Function;
	onClearClientsAndContacts: Function;
	onSetSelectedClient: Function;
}

type Props = StateProps & DispatchProps & OwnProps;
type State = {};

const mapStateToProps = (state: any) => ({
	user: state.usersState.user,
	selectedClient: state.clientsState.selectedClient
});

const mapDispatchToProps = (dispatch: any) => ({
	onLoadContacts: (uid: string, clientId: string) => {
		dispatch(fClients.getContacts(uid, clientId));
	},
	onLoadClient: (uid: string, clientId: string) => {
		dispatch(fClients.getClient(uid, clientId));
	},
	onClearClientsAndContacts: () => {
		dispatch(rClients.setSelectedClient({ key: '' } as Client));
		dispatch(rClients.modifyContactsArray('CLEAR', {} as Contact));
		dispatch(rClients.modifyUpdateClient('LOADING', false));
		dispatch(rClients.modifyLoadClientDetails('LOADING', false));
		dispatch(rClients.modifyLoadContacts('LOADING', true));
	},
	onSetSelectedClient: (client: Client) => {
		dispatch(rClients.setSelectedClient({ key: '' } as Client));
	}
});

class ClientsDetail extends Component<Props, State> {
	componentDidMount() {
		window.scrollTo(0, 0);
		this.props.onLoadContacts(this.props.user.userId, (this.props.match.params as any).clientId);
		if (!this.props.selectedClient.key) {
			this.props.onLoadClient(this.props.user.userId, (this.props.match.params as any).clientId);
		}
	}
	componentWillUnmount() {
		this.props.onClearClientsAndContacts();
	}

	render() {
		const { selectedClient } = this.props;

		return (
			<div className='uk-width-expand' style={{ minHeight: '1px', overflowY: 'auto' }}>
				<BackTop />
				<Card>
					<div>
						<a
							onClick={() => {
								this.props.history.push('/clients');
								this.props.onSetSelectedClient(rClients.INITIAL_STATE_SELECTED_CLIENT);
							}}
						>{`< Back to clients List`}</a>
					</div>
					<div className='uk-margin-top'>
						<h2>{selectedClient.key ? selectedClient.name : ''}</h2>
					</div>
					<Tabs defaultActiveKey='1' tabPosition={'top'}>
						<TabPane tab='General' key='1'>
							<ClientGeneralForm />
						</TabPane>
						<TabPane tab='Contacts' key='2'>
							<ClientContactsList />
						</TabPane>
					</Tabs>
				</Card>
			</div>
		);
	}
}

const condition = (authUser: any) => !!authUser;

export default compose<any, any>(
	withAuthorization(condition),
	connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps)
)(ClientsDetail);
