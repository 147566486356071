import React, { Component } from 'react';
import withAuthorization from '../../hoc/withAuthorization';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { setPage } from '../../reducers/session';
import * as routes from '../../constants/routeNames';
import { Card, Row, Col, message, BackTop } from 'antd';
import ClientsList from './ClientsList';
import ClientsDetail from './ClientsDetail';
import { clients as fClients } from '../../firebase';
import { UserMeta, User } from '../../models/UsersModel';
import { TaskState } from '../../models/Commons';

interface OwnProps extends RouteComponentProps {}

interface StateProps {
	user: User;
	userMeta: UserMeta;
}

interface DispatchProps {
	onSetPage: Function;
	onLoadClients: Function;
}

type Props = StateProps & DispatchProps & OwnProps;
type State = {};

const mapStateToProps = (state: any) => ({
	user: state.usersState.user,
	userMeta: state.usersState.userMeta
});

const mapDispatchToProps = (dispatch: any) => ({
	onSetPage: (page: any) => {
		dispatch(setPage(page));
	},
	onLoadClients: (uid: string) => {
		dispatch(fClients.subscribeToClients(uid));
	}
});

class ClientsPage extends Component<Props, State> {
	componentDidMount() {
		this.props.onSetPage(routes.CLIENTS);
		this.props.onLoadClients(this.props.user.userId);
		window.scrollTo(0, 0);
	}
	render() {
		const {} = this.props;

		return (
			<div className='uk-width-expand'>
				<BackTop />
				<div
					style={{
						minHeight: 'calc(100vh - 157px)',
						padding: '10px',
						backgroundColor: 'white'
					}}
				>
					<Row>
						<Col span={24}>
							<ClientsList />
						</Col>
					</Row>
				</div>
			</div>
		);
	}
}

const condition = (authUser: any) => !!authUser;

export default compose<any, any>(
	withRouter,
	withAuthorization(condition),
	connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps)
)(ClientsPage);
