import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, Table, Spin, Tabs, Icon, Form, Upload } from 'antd';
import { UserMeta } from '../../models/UsersModel';
import { Subject, Group } from '../../models/EventsModel';
import { TaskState } from '../../models/Commons';
import emptyview from '../../assets/emptyview_photo.png';
import { getDate, getTimeHrsMin } from '../../helpers/time';
import EditSubjectModal from './EditSubjectModal';
import { INITIAL_STATE_SUBJECT } from '../../reducers/teambuilder';
import { dynamicSort } from '../../helpers/arrays';
import { firebase } from '../../firebase'
const { Column } = Table;
const TabPane = Tabs.TabPane;

interface OwnProps {
	visible: boolean;
	onCancel: Function;
}
interface StateProps {
	user: firebase.firebase.User;
	userMeta: UserMeta;
	subjects: Array<Subject>;
	loadSubjects: TaskState;
	selectedGroup: Group;
}
interface DispatchProps { }
type Props = StateProps & DispatchProps & OwnProps;
type State = {
	staff: Array<Subject>;
	players: Array<Subject>;
	selectedSubject: Subject;
	subjectModalVisible: boolean;
};
const INITIAL_STATE = {
	staff: [] as Array<Subject>,
	players: [] as Array<Subject>,
	selectedSubject: INITIAL_STATE_SUBJECT,
	subjectModalVisible: false
};
const mapStateToProps = (state: any) => ({
	user: state.usersState.user,
	userMeta: state.usersState.userMeta,
	subjects: state.groupsState.subjects,
	loadSubjects: state.groupsState.loadSubjects,
	selectedGroup: state.groupsState.selectedGroup
});
const mapDispatchToProps = (dispatch: any) => ({});
const formItemLayout = {
	labelCol: {
		xs: { span: 24 },
		sm: { span: 4 },
		md: { span: 4 }
	},
	wrapperCol: {
		xs: { span: 24 },
		sm: { span: 16 }
	}
};

class SubjectsListModal extends Component<Props, State> {
	state = { ...INITIAL_STATE };
	componentWillReceiveProps(nextProps: Props) { }
	sortLegend = (letter: string, subjects: Array<Subject>) => {
		let workingSet: Array<Subject> = [];
		if (letter === 'M') {
			workingSet = subjects.filter((subject) => subject.position!.includes(letter) || subject.position! === '');
		} else {
			workingSet = subjects.filter((subject) => subject.position!.includes(letter));
		}
		const sortedSet = workingSet.sort(dynamicSort('position', "", "asc"));
		return (
			<p>
				{sortedSet.map((subject, index) => {
					if (index === sortedSet.length - 1) {
						return `${subject.firstName} ${subject.lastName}`;
					} else {
						return `${subject.firstName} ${subject.lastName}, `;
					}
				})}
			</p>
		);
	};
	render() {
		const { visible, onCancel, subjects, loadSubjects, selectedGroup } = this.props;
		const { selectedSubject, subjectModalVisible } = this.state;
		let staff: Array<Subject> = [];
		let players: Array<Subject> = [];

		if (subjects.length !== 0) {
			subjects.forEach((subject) => {
				if (subject.type === 'staff') {
					staff.push(subject);
				} else if (subject.type === 'player') {
					players.push(subject);
				}
			});
		}
		const noData = (
			<div className='uk-flex uk-flex-center uk-text-center uk-width-expand'>
				<div className='uk-flex uk-flex-column uk-flex-center uk-width-large'>
					<div className='uk-width-expand uk-flex uk-flex-center'>
						<img src={emptyview} className='empty-view' />
					</div>
					<span className='uk-margin-top uk-margin-bottom'>No subjects have been registered yet.</span>
				</div>
			</div>
		);
		return (
			<Modal
				keyboard={false}
				centered
				bodyStyle={{
					height: '90vh',
					borderRadius: 0
				}}
				width={'90vw'}
				visible={visible}
				title={selectedGroup.key ? `${selectedGroup.name}'s Subjects` : 'Subjects'}
				footer={null}
				afterClose={() => {
					this.setState({ ...INITIAL_STATE });
				}}
				onCancel={() => {
					onCancel();
				}}
				onOk={() => { }}
			>
				<div style={{ padding: '10px' }} className='single-step'>
					<Spin
						indicator={<Icon type='loading' style={{ fontSize: 24 }} spin />}
						spinning={loadSubjects.loading as boolean}
					>
						{loadSubjects.empty ? (
							<div className='uk-flex uk-flex-center uk-text-center uk-width-expand'>
								<div className='uk-flex uk-flex-column uk-flex-center uk-width-large'>
									<div className='uk-width-expand uk-flex uk-flex-center'>
										<img src={emptyview} className='empty-view' />
									</div>
									<span className='uk-margin-top uk-margin-bottom'>
										No subjects have been registered yet.
									</span>
								</div>
							</div>
						) : (
							<Tabs defaultActiveKey='1' tabPosition={'top'}>
								<TabPane tab='Group' key='1'>
									<Form.Item label='Full Team Name' {...formItemLayout}>
										<span>{selectedGroup.fullName}</span>
									</Form.Item>
									<Form.Item label='Team Name' {...formItemLayout}>
										<span>{selectedGroup.name}</span>
									</Form.Item>
									<Form.Item label='Division' {...formItemLayout}>
										<span>{selectedGroup.division}</span>
									</Form.Item>
									<Form.Item label='Sub-Division' {...formItemLayout}>
										<span>{selectedGroup.subDivision}</span>
									</Form.Item>
									<Form.Item label='Booking Date' {...formItemLayout}>
										<span>{getDate(selectedGroup.timeslotDate)}</span>
									</Form.Item>
									<Form.Item label='Booking Time' {...formItemLayout}>
										<span>{getTimeHrsMin(selectedGroup.timeslotDate)}</span>
									</Form.Item>
									{selectedGroup.logoUrl ? (
										<Form.Item label='Group Logo' {...formItemLayout}>
											<Upload
												name='avatar'
												disabled={true}
												listType='picture-card'
												className='avatar-uploader'
												showUploadList={false}
												beforeUpload={(() => { }) as any}
												onChange={() => { }}
											>
												<img
													style={{ maxWidth: '800px' }}
													src={selectedGroup.logoUrl}
													alt='avatar'
												/>
											</Upload>
										</Form.Item>
									) : null}
								</TabPane>
								<TabPane tab='Players' key='2'>
									<Table
										rowKey={(e) => e.key!}
										loading={{
											indicator: <Icon type='loading' style={{ fontSize: 36 }} spin />,
											spinning: loadSubjects.loading as boolean
										}}
										onRow={(subject: Subject, index: number) => {
											return {
												onClick: (event: any) => {
													this.setState({
														selectedSubject: subject,
														subjectModalVisible: true
													});
												} // click row
											};
										}}
										scroll={{ x: '500px' }}
										className='table-row-clickable'
										pagination={false}
										dataSource={players}
										locale={{ emptyText: noData }}
									>
										<Column
											title='#'
											dataIndex='key'
											key='number'
											render={(subjectId: string, subject: Subject, index: number) => (
												<span>{index + 1}</span>
											)}
										/>
										<Column title='First Name' dataIndex='firstName' key='firstName' />
										<Column title='Last Name' dataIndex='lastName' key='lastName' />
										<Column title='Email' dataIndex='email' key='email' />
										<Column title='Position' dataIndex='position' key='position' />
									</Table>
								</TabPane>
								<TabPane tab='Staff' key='3'>
									<Table
										rowKey={(e) => e.key!}
										onRow={(subject: Subject, index: number) => {
											return {
												onClick: (event: any) => {
													this.setState({
														selectedSubject: subject,
														subjectModalVisible: true
													});
												} // click row
											};
										}}
										className='table-row-clickable'
										scroll={{ x: '500px' }}
										pagination={false}
										dataSource={staff}
										locale={{ emptyText: noData }}
									>
										<Column
											title='#'
											dataIndex='key'
											key='number'
											render={(subjectId: string, subject: Subject, index: number) => (
												<span>{index + 1}</span>
											)}
										/>
										<Column title='First Name' dataIndex='firstName' key='firstName' />
										<Column title='Last Name' dataIndex='lastName' key='lastName' />
										<Column title='Email' dataIndex='email' key='email' />
										<Column title='Position' dataIndex='position' key='position' />
										<Column title='Role' dataIndex='role' key='role' />
										<Column title='Jersey Number' dataIndex='jerseyNumber' key='jerseyNumber' />
									</Table>
								</TabPane>
								{selectedGroup.contact && (
									<TabPane tab='Contact' key='4'>
										<Form.Item label='Contact Name' {...formItemLayout}>
											<span>{`${selectedGroup.contact.firstName} ${selectedGroup.contact
												.lastName}`}</span>
										</Form.Item>
										<Form.Item label='Contact Email' {...formItemLayout}>
											<span>{selectedGroup.contact.email}</span>
										</Form.Item>
										<Form.Item label='Contact Phone' {...formItemLayout}>
											<span>{selectedGroup.contact.phoneNumber}</span>
										</Form.Item>
										<Form.Item label='Contact Role' {...formItemLayout}>
											<span>{selectedGroup.contact.role}</span>
										</Form.Item>
									</TabPane>
								)}
								{selectedGroup.completed && (
									<TabPane tab='Team Legend' key='5'>
										<div className='uk-margin-left'>
											<div className='uk-flex uk-flex-row'>
												<h4 className='uk-margin-right'>A:</h4> {this.sortLegend('A', subjects)}
											</div>
											<div className='uk-flex uk-flex-row'>
												<h4 className='uk-margin-right'>B:</h4> {this.sortLegend('B', subjects)}
											</div>
											<div className='uk-flex uk-flex-row'>
												<h4 className='uk-margin-right'>C:</h4> {this.sortLegend('C', subjects)}
											</div>
											<div className='uk-flex uk-flex-row'>
												<h4 className='uk-margin-right'>D:</h4> {this.sortLegend('D', subjects)}
											</div>
											<div className='uk-flex uk-flex-row'>
												<h4 className='uk-margin-right'>E:</h4> {this.sortLegend('E', subjects)}
											</div>
											<div className='uk-flex uk-flex-row'>
												<h4 className='uk-margin-right'>M:</h4> {this.sortLegend('M', subjects)}
											</div>
											<div className='uk-flex uk-flex-row'>
												<h4 className='uk-margin-right'>X:</h4> {this.sortLegend('X', subjects)}
											</div>
										</div>
									</TabPane>
								)}
							</Tabs>
						)}
					</Spin>
				</div>
				<EditSubjectModal
					subject={selectedSubject}
					visible={subjectModalVisible}
					onCancel={() => {
						this.setState({ subjectModalVisible: false });
					}}
				/>
			</Modal>
		);
	}
}
export default connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps)(SubjectsListModal);
