import { firestore, functions } from "./firebaseConfig";
import * as rPurchases from "../reducers/purchases";
import * as storage from "./storage";
import { ThunkResult } from "../models/Commons";
import { Purchase } from "../models/PurchaseModel";
import { Group, Subject } from "../models/EventsModel";

let purchasesListener: any;
export const subscribeToPurchases = (
  uid: string,
  startTime: number,
  endTime: number
): ThunkResult<void> => {
  return (dispatch) => {
    const purchasesRef = firestore
      .collection("purchases")
      .orderBy("timestamp", "asc")
      .where("photographerId", "==", uid)
      .startAt(startTime)
      .endAt(endTime)
      .limit(20);
    if (purchasesListener) {
      return;
    }
    dispatch(rPurchases.modifyLoadPurchases("LOADING", true));
    purchasesListener = purchasesRef.onSnapshot((snapshot) => {
      if (snapshot.empty || snapshot.size === 0) {
        dispatch(rPurchases.modifyLoadPurchases("EMPTY", true));
      } else {
        dispatch(
          rPurchases.modifyLoadPurchases("SUCCESS", "Loaded purchases.")
        );
      }

      snapshot.docChanges().forEach(
        (change) => {
          if (change.type === "added") {
            const pack = {
              ...(change.doc.data() as Purchase),
              key: change.doc.id,
            };
            dispatch(rPurchases.modifyPurchasesArray("ADD", pack));
          }
          if (change.type === "modified") {
            const pack = {
              ...(change.doc.data() as Purchase),
              key: change.doc.id,
            };
            dispatch(rPurchases.modifyPurchasesArray("UPDATE", pack));
          }
          if (change.type === "removed") {
            const pack = {
              ...(change.doc.data() as Purchase),
              key: change.doc.id,
            };
            dispatch(rPurchases.modifyPurchasesArray("REMOVE", pack));
          }
        },
        (error: any) => {
          if (error) {
            dispatch(
              rPurchases.modifyLoadPurchases(
                "ERROR",
                "Something went wrong, please try again."
              )
            );
          }
        }
      );
    });
    return purchasesListener;
  };
};

export const unSubscribeFromPurchases = () => {
  if (purchasesListener) {
    purchasesListener();
    purchasesListener = undefined;
  }
};

export const loadInitialPurchases = async (
  uid: string,
  startTime: number,
  endTime: number,
  paymentType: string,
  searchType: string,
  search: string
): Promise<Array<Purchase>> => {
  let purchaseRef: any = firestore
    .collection("purchases")
    .where("photographerId", "==", uid);
  if (paymentType !== "all") {
    purchaseRef = purchaseRef.where("paymentType", "==", paymentType);
  }
  console.log(search);
  if (search !== "") {
    switch (searchType) {
      case "customerFirstName": {
        purchaseRef = purchaseRef.where("customerInfo.firstName", "==", search);
        break;
      }
      case "customerLastName": {
        purchaseRef = purchaseRef.where("customerInfo.lastName", "==", search);
        break;
      }
      case "subjectFullName": {
        purchaseRef = purchaseRef.where("subject.fullName", "==", search);
        break;
      }
      case "eventName": {
        purchaseRef = purchaseRef.where("subject.eventName", "==", search);
        break;
      }
      case "groupName": {
        purchaseRef = purchaseRef.where("subject.teamName", "==", search);
        break;
      }
    }
  }
  purchaseRef = purchaseRef
    .orderBy("timestamp", "desc")
    .startAt(startTime)
    .endAt(endTime)
    .limit(20);

  return purchaseRef.get().then(async (purchaseSnaps: any) => {
    const purchases: Array<Purchase> = [];
    if (purchaseSnaps.empty) {
      return [];
    } else {
      purchaseSnaps.forEach((snap: any) => {
        purchases.push({
          ...(snap.data() as Purchase),
          key: snap.id,
        });
      });

      return purchases;
    }
  });
};

export const loadNextPurchases = async (
  uid: string,
  startTime: number,
  endTime: number,
  paymentType: string,
  searchType: string,
  search: string
): Promise<Array<Purchase>> => {
  let purchaseRef: any = firestore
    .collection("purchases")
    .where("photographerId", "==", uid);
  purchaseRef = purchaseRef
    .orderBy("timestamp", "desc")
    .startAfter(startTime)
    .endAt(endTime)
    .limit(20);

  if (paymentType !== "all") {
    purchaseRef = purchaseRef.where("paymentType", "==", paymentType);
  }
  if (search !== "") {
    switch (searchType) {
      case "customerFirstName": {
        purchaseRef = purchaseRef.where("customerInfo.firstName", "==", search);
        break;
      }
      case "customerLastName": {
        purchaseRef = purchaseRef.where("customerInfo.lastName", "==", search);
        break;
      }
      case "subjectFullName": {
        purchaseRef = purchaseRef.where("subject.fullName", "==", search);
        break;
      }
      case "eventName": {
        purchaseRef = purchaseRef.where("subject.eventName", "==", search);
        break;
      }
      case "groupName": {
        purchaseRef = purchaseRef.where("subject.teamName", "==", search);
        break;
      }
    }
  }

  return purchaseRef.get().then(async (purchaseSnaps: any) => {
    const purchases: Array<Purchase> = [];
    if (purchaseSnaps.empty) {
      return [];
    } else {
      purchaseSnaps.forEach((snap: any) => {
        purchases.push({
          ...(snap.data() as Purchase),
          key: snap.id,
        });
      });

      return purchases;
    }
  });
};

const onLoadAllPurchases = functions.httpsCallable("onLoadAllPurchases");
export const getAllPurchases = (
  uid: string,
  eventId: string,
  startTime: number,
  endTime: number,
  paymentType: string,
  searchType: string,
  search: string
): Promise<Array<Purchase>> => {
  return onLoadAllPurchases({
    uid,
    eventId,
    startTime,
    endTime,
    paymentType,
    searchType,
    search,
  }).then((results) => {
    return results.data as Purchase[];
  });
};

export const acceptPayment = (purchaseId: string, userId: string) => {
  const purchaseRef = firestore.collection("purchases").doc(purchaseId);
  return purchaseRef.update({ paid: true });
};
