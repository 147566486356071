import { firestore, auth, functions } from "./firebaseConfig";
import * as rTeambuilder from "../reducers/teambuilder";
import { ThunkResult, TeamPicsFile, Contact } from "../models/Commons";
import { Timeslot, Group, Shoot, Subject, Event } from "../models/EventsModel";
import { User } from "../models/UsersModel";
import { StepAuth } from "../models/TeamBuilderModel";
import * as fStorage from "./storage";

export const loadUser = (uid: string): ThunkResult<void> => {
  return (dispatch) => {
    const userRef = firestore.collection("users").doc(uid);
    dispatch(rTeambuilder.modifyLoginUser("LOADING", true));
    return userRef
      .get()
      .then((userSnap) => {
        const user = { ...userSnap.data(), userId: userSnap.id };
        dispatch(rTeambuilder.setStepAuth(user as User));
        return dispatch(
          rTeambuilder.modifyLoginUser("SUCCESS", "Successfully loaded user.")
        );
      })
      .catch((error) => {
        console.log(error);
        dispatch(
          rTeambuilder.modifyLoginUser(
            "ERROR",
            "Something went wrong, please try again."
          )
        );
      });
  };
};

export const loadEvent = (eventId: string): ThunkResult<void> => {
  return (dispatch) => {
    const eventRef = firestore.collection("events").doc(eventId);
    dispatch(rTeambuilder.modifyLoadEvent("LOADING", true));
    return eventRef
      .get()
      .then((eventSnap) => {
        if (!eventSnap.exists || !(eventSnap.data() as Event).active) {
          dispatch(rTeambuilder.modifyLoadEvent("EMPTY", true));
          return;
        }
        const event = { key: eventSnap.id, ...(eventSnap.data() as Event) };
        dispatch(rTeambuilder.setEvent(event));
        return dispatch(rTeambuilder.modifyLoadEvent("SUCCESS", true));
      })
      .catch((error) => {
        console.log(error);
        return dispatch(
          rTeambuilder.modifyLoadEvent(
            "ERROR",
            "Something went wrong please try again."
          )
        );
      });
  };
};

export const loadShoots = (eventId: string): ThunkResult<void> => {
  return (dispatch) => {
    const shootRef = firestore
      .collection("events")
      .doc(eventId)
      .collection("shoots")
      .orderBy("startTime");
    dispatch(rTeambuilder.modifyLoadShoots("LOADING", true));
    return shootRef
      .get()
      .then((snapshots) => {
        snapshots.forEach((snapshot) => {
          const shoot = {
            ...(snapshot.data() as Shoot),
            key: snapshot.id,
          };
          dispatch(rTeambuilder.modifyShootsArray("ADD", shoot));
        });
        return dispatch(rTeambuilder.modifyLoadShoots("SUCCESS", true));
      })
      .catch((error) => {
        console.log(error);
        dispatch(
          rTeambuilder.modifyLoadShoots(
            "ERROR",
            "Something went wrong, please try again."
          )
        );
      });
  };
};

export const loadSubjects = (groupId: string): ThunkResult<void> => {
  return (dispatch) => {
    const subjectsRef = firestore
      .collection("subjects")
      .where("groupId", "==", groupId)
      .orderBy("firstName");
    dispatch(rTeambuilder.modifyLoadSubjects("LOADING", true));
    dispatch(rTeambuilder.modifyPlayersArray("CLEAR", {} as Subject));
    dispatch(rTeambuilder.modifyStaffArray("CLEAR", {} as Subject));

    return subjectsRef
      .get()
      .then((subjectsSnap) => {
        if (subjectsSnap.empty) {
          dispatch(rTeambuilder.modifyLoadSubjects("EMPTY", true));
        }
        subjectsSnap.forEach((subjectSnap) => {
          const subject: Subject = {
            key: subjectSnap.id,
            ...(subjectSnap.data() as Subject),
          };
          if (subject.type === "player") {
            dispatch(rTeambuilder.modifyPlayersArray("ADD", subject));
          } else if (subject.type === "staff") {
            dispatch(rTeambuilder.modifyStaffArray("ADD", subject));
          }
        });
        return dispatch(rTeambuilder.modifyLoadSubjects("SUCCESS", true));
      })
      .catch((error) => {
        console.log(error);
        dispatch(
          rTeambuilder.modifyLoadSubjects(
            "ERROR",
            "Something went wrong, please try again"
          )
        );
      });
  };
};

let groupsListener: any;
export const subscribeToGroups = (
  eventId: string,
  contactId: string
): ThunkResult<void> => {
  return (dispatch) => {
    const groupsRef = firestore
      .collection("events")
      .doc(eventId)
      .collection("groups")
      .orderBy("timeslotDate", "desc")
      .where("contactId", "==", contactId);

    if (groupsListener) {
      return;
    }
    dispatch(rTeambuilder.modifyLoadGroups("LOADING", true));
    dispatch(rTeambuilder.modifyContactsGroupsArray("CLEAR", {} as Group));

    groupsListener = groupsRef.onSnapshot(
      (snapshot) => {
        if (snapshot.empty) {
          dispatch(rTeambuilder.modifyLoadGroups("EMPTY", true));
          //groupsListener();
        } else {
          dispatch(rTeambuilder.modifyLoadGroups("SUCCESS", true));
        }

        snapshot.docChanges().forEach((change) => {
          if (change.type === "added") {
            const group: Group = {
              ...(change.doc.data() as Group),
              key: change.doc.id,
            };
            // if (group.contactId === contactId) {
            // 	dispatch(rTeambuilder.modifyContactsGroupsArray('ADD', group));
            // }
            // dispatch(rTeambuilder.modifyAllGroupsArray('ADD', group));]
            //if (!group.completed) {
            dispatch(rTeambuilder.modifyContactsGroupsArray("ADD", group));
            //}
          }
          if (change.type === "modified") {
            const group: Group = {
              ...(change.doc.data() as Group),
              key: change.doc.id,
            };
            // if (group.contactId === contactId) {
            // 	dispatch(rTeambuilder.modifyContactsGroupsArray('UPDATE', group));
            // }
            // dispatch(rTeambuilder.modifyAllGroupsArray('UPDATE', group));
            if (!group.completed) {
              dispatch(rTeambuilder.modifyContactsGroupsArray("UPDATE", group));
            } else {
              dispatch(rTeambuilder.modifyContactsGroupsArray("REMOVE", group));
            }
          }
          if (change.type === "removed") {
            const group: Group = {
              ...(change.doc.data() as Group),
              key: change.doc.id,
            };
            // if (group.contactId === contactId) {
            // 	dispatch(rTeambuilder.modifyContactsGroupsArray('REMOVE', group));
            // }
            // dispatch(rTeambuilder.modifyAllGroupsArray('REMOVE', group));
            dispatch(rTeambuilder.modifyContactsGroupsArray("REMOVE", group));
          }
        });
      },
      (error: any) => {
        if (error) {
          console.log(error);
          dispatch(
            rTeambuilder.modifyLoadGroups(
              "ERROR",
              "Something went wrong, please refresh the page"
            )
          );
        }
      }
    );
    return groupsListener;
  };
};

export const unSubscribeGroups = () => {
  if (groupsListener) {
    groupsListener();
    groupsListener = undefined;
  }
};

let timeslotsListener: any;
export const subscribeToTimeslots = (
  eventId: string,
  shootId: string
): ThunkResult<void> => {
  return (dispatch) => {
    const groupsRef = firestore
      .collection("events")
      .doc(eventId)
      .collection("shoots")
      .doc(shootId)
      .collection("timeslots")
      .orderBy("timestamp", "desc");

    if (timeslotsListener) {
      return;
    }
    dispatch(rTeambuilder.modifyLoadTimeslots("LOADING", true));
    dispatch(rTeambuilder.modifyTimeslotsArray("CLEAR", {} as Timeslot));

    timeslotsListener = groupsRef.onSnapshot(
      (snapshot) => {
        if (snapshot.empty) {
          dispatch(rTeambuilder.modifyLoadTimeslots("EMPTY", true));
        } else {
          dispatch(rTeambuilder.modifyLoadTimeslots("SUCCESS", true));
        }

        snapshot.docChanges().forEach((change) => {
          if (change.type === "added") {
            const timeslot = {
              ...(change.doc.data() as Timeslot),
              key: change.doc.id,
            };
            dispatch(rTeambuilder.modifyTimeslotsArray("ADD", timeslot));
          }
          if (change.type === "modified") {
            const timeslot = {
              ...(change.doc.data() as Timeslot),
              key: change.doc.id,
            };
            dispatch(rTeambuilder.modifyTimeslotsArray("UPDATE", timeslot));
          }
          if (change.type === "removed") {
            const timeslot = {
              ...(change.doc.data() as Timeslot),
              key: change.doc.id,
            };
            dispatch(rTeambuilder.modifyTimeslotsArray("REMOVE", timeslot));
          }
        });
      },
      (error: any) => {
        if (error) {
          console.log(error);
          dispatch(
            rTeambuilder.modifyLoadTimeslots(
              "ERROR",
              "Something went wrong, please refresh the page"
            )
          );
        }
      }
    );
    return timeslotsListener;
  };
};

export const unSubscribeFromTimeslots = () => {
  if (timeslotsListener) {
    timeslotsListener();
    timeslotsListener = undefined;
  }
};

const onPopulateFriendlyIdsGroup = functions.httpsCallable(
  "onPopulateFriendlyIdsGroup"
);
export const newGroup = (
  eventId: string,
  shootId: string,
  group: Group,
  players: Array<Subject>,
  staff: Array<Subject>,
  timeslot: Timeslot,
  newUser: StepAuth,
  logoFile: TeamPicsFile
): ThunkResult<void> => {
  return (dispatch) => {
    const timeslotRef = firestore
      .collection("events")
      .doc(eventId)
      .collection("shoots")
      .doc(shootId)
      .collection("timeslots")
      .doc(timeslot.key);
    dispatch(rTeambuilder.modifyUpdateGroup("LOADING", true));

    if (logoFile.blob) {
      const storagePath = `events/${eventId}/${group.photographerId}/groups/${group.key}}/logos/group-logo-${group.key}`;
      return fStorage
        .uploadImageGroup(logoFile, storagePath, newUser.userId)
        .then((url: string) => {
          return firestore
            .runTransaction((transaction) => {
              return transaction.get(timeslotRef).then((timeslotSnap) => {
                //CHECK IF TIMESLOT IS TAKEN
                const timeslotCheck = timeslotSnap.data() as Timeslot;
                if (timeslotCheck.taken) {
                  throw new Error("TIMESLOT_TAKEN");
                }

                //CREATE GROUP FIRST
                const groupRef = firestore
                  .collection("events")
                  .doc(eventId)
                  .collection("groups")
                  .doc(group.key);
                transaction.set(groupRef, { ...group, logoUrl: url });

                //CREATE ALL THE SUBJECTS
                players.forEach((player) => {
                  const subjectsRef = firestore
                    .collection("subjects")
                    .doc(player.key);
                  transaction.set(subjectsRef, player);
                });

                staff.forEach((staffMember) => {
                  const subjectsRef = firestore
                    .collection("subjects")
                    .doc(staffMember.key);
                  transaction.set(subjectsRef, staffMember);
                });

                const userRef = firestore
                  .collection("users")
                  .doc(newUser.userId);
                transaction.update(userRef, newUser);

                //UPDATE THE TIMESLOT
                transaction.update(timeslotRef, timeslot);
              });
            })
            .then(() => {
              return onPopulateFriendlyIdsGroup({
                eventId,
                groupId: group.key,
              });
            })
            .then(() => {
              unSubscribeFromTimeslots();
              return dispatch(
                rTeambuilder.modifyUpdateGroup(
                  "SUCCESS",
                  "Team was successfully created"
                )
              );
            })
            .catch((error) => {
              console.log(error);
              if (error === "TIMESLOT_TAKEN") {
                dispatch(
                  rTeambuilder.modifyUpdateGroup(
                    "ERROR",
                    "Your chosen timeslot is no longer available"
                  )
                );
              } else {
                dispatch(
                  rTeambuilder.modifyUpdateGroup(
                    "ERROR",
                    "Something went wrong, please try again"
                  )
                );
              }
            });
        });
    }

    return firestore
      .runTransaction((transaction) => {
        return transaction.get(timeslotRef).then((timeslotSnap) => {
          //CHECK IF TIMESLOT IS TAKEN
          const timeslotCheck = timeslotSnap.data() as Timeslot;
          if (timeslotCheck.taken) {
            throw new Error("TIMESLOT_TAKEN");
          }

          //CREATE GROUP FIRST
          const groupRef = firestore
            .collection("events")
            .doc(eventId)
            .collection("groups")
            .doc(group.key);
          transaction.set(groupRef, group);

          //CREATE ALL THE SUBJECTS
          players.forEach((player) => {
            const subjectsRef = firestore
              .collection("subjects")
              .doc(player.key);
            transaction.set(subjectsRef, player);
          });

          staff.forEach((staffMember) => {
            const subjectsRef = firestore
              .collection("subjects")
              .doc(staffMember.key);
            transaction.set(subjectsRef, staffMember);
          });

          const userRef = firestore.collection("users").doc(newUser.userId);
          transaction.update(userRef, newUser);

          //UPDATE THE TIMESLOT
          transaction.update(timeslotRef, timeslot);
        });
      })
      .then(() => {
        return onPopulateFriendlyIdsGroup({
          eventId,
          groupId: group.key,
        });
      })
      .then(() => {
        unSubscribeFromTimeslots();
        return dispatch(
          rTeambuilder.modifyUpdateGroup(
            "SUCCESS",
            "Team was successfully created"
          )
        );
      })
      .catch((error) => {
        console.log(error);
        if (error === "TIMESLOT_TAKEN") {
          dispatch(
            rTeambuilder.modifyUpdateGroup(
              "ERROR",
              "Your chosen timeslot is no longer available"
            )
          );
        } else {
          dispatch(
            rTeambuilder.modifyUpdateGroup(
              "ERROR",
              "Something went wrong, please try again"
            )
          );
        }
      });
  };
};

export const updateGroupNoTimeslot = (
  eventId: string,
  group: Group,
  players: Array<Subject>,
  staff: Array<Subject>,
  toDeleteSubjects: Array<Subject>,
  newUser: StepAuth,
  logoFile: TeamPicsFile
): ThunkResult<void> => {
  return (dispatch) => {
    dispatch(rTeambuilder.modifyUpdateGroup("LOADING", true));
    const batch = firestore.batch();
    const groupRef = firestore
      .collection("events")
      .doc(eventId)
      .collection("groups")
      .doc(group.key!);

    //UPDATE GROUP
    batch.update(groupRef, { ...group });

    players.forEach((player) => {
      const subjectsRef = firestore.collection("subjects").doc(player.key!);
      batch.set(subjectsRef, player);
    });

    staff.forEach((staffMember) => {
      const subjectsRef = firestore
        .collection("subjects")
        .doc(staffMember.key!);
      batch.set(subjectsRef, staffMember);
    });

    //DELETE OUTDATED SUBJECTS
    toDeleteSubjects.forEach((subject) => {
      const subjectRef = firestore.collection("subjects").doc(subject.key!);
      batch.delete(subjectRef);
    });

    const userRef = firestore.collection("users").doc(newUser.userId);
    batch.update(userRef, newUser);

    if (logoFile.blob) {
      const storagePath = `events/${eventId}/${group.photographerId}/groups/${group.key}}/logos/group-logo-${group.key}`;
      return fStorage
        .uploadImageGroup(logoFile, storagePath, newUser.userId)
        .then((url: string) => {
          batch.update(groupRef, { ...group, logoUrl: url });
          return batch
            .commit()
            .then(() => {
              unSubscribeFromTimeslots();
              return dispatch(
                rTeambuilder.modifyUpdateGroup(
                  "SUCCESS",
                  "Team was successfully updated"
                )
              );
            })
            .catch((error) => {
              console.log(error);
              dispatch(
                rTeambuilder.modifyUpdateGroup(
                  "ERROR",
                  "Something went wrong, please try again"
                )
              );
            });
        });
    }

    return batch
      .commit()
      .then(() => {
        unSubscribeFromTimeslots();
        return dispatch(
          rTeambuilder.modifyUpdateGroup(
            "SUCCESS",
            "Team was successfully updated"
          )
        );
      })
      .catch((error) => {
        console.log(error);
        dispatch(
          rTeambuilder.modifyUpdateGroup(
            "ERROR",
            "Something went wrong, please try again"
          )
        );
      });
  };
};

export const updateGroup = (
  eventId: string,
  shootId: string,
  oldShootId: string,
  oldTimeslotId: string,
  group: Group,
  players: Array<Subject>,
  staff: Array<Subject>,
  toDeleteSubjects: Array<Subject>,
  timeslot: Timeslot,
  newUser: StepAuth,
  logoFile: TeamPicsFile
): ThunkResult<void> => {
  return (dispatch) => {
    const timeslotRef = firestore
      .collection("events")
      .doc(eventId)
      .collection("shoots")
      .doc(shootId)
      .collection("timeslots")
      .doc(timeslot.key);
    dispatch(rTeambuilder.modifyUpdateGroup("LOADING", true));

    if (logoFile.blob) {
      const storagePath = `events/${eventId}/${group.photographerId}/groups/${group.key}}/logos/group-logo-${group.key}`;
      return fStorage
        .uploadImageGroup(logoFile, storagePath, newUser.userId)
        .then((url: string) => {
          return firestore
            .runTransaction((transaction) => {
              return transaction.get(timeslotRef).then((timeslotSnap) => {
                //CHECK IF TIMESLOT IS TAKEN
                const timeslotCheck = timeslotSnap.data() as Timeslot;
                if (timeslotCheck.taken) {
                  throw new Error("TIMESLOT_TAKEN");
                }
                const oldTimeslotRef = firestore
                  .collection("events")
                  .doc(eventId)
                  .collection("shoots")
                  .doc(oldShootId)
                  .collection("timeslots")
                  .doc(oldTimeslotId);

                //CREATE GROUP FIRST
                const groupRef = firestore
                  .collection("events")
                  .doc(eventId)
                  .collection("groups")
                  .doc(group.key!);
                transaction.update(groupRef, { ...group, logoUrl: url });

                //CREATE ALL THE SUBJECTS
                players.forEach((player) => {
                  const subjectsRef = firestore
                    .collection("subjects")
                    .doc(player.key!);
                  transaction.set(subjectsRef, player);
                });

                staff.forEach((staffMember) => {
                  const subjectsRef = firestore
                    .collection("subjects")
                    .doc(staffMember.key!);
                  transaction.set(subjectsRef, staffMember);
                });

                //DELETE OUTDATED SUBJECTS
                toDeleteSubjects.forEach((subject) => {
                  const subjectRef = firestore
                    .collection("subjects")
                    .doc(subject.key!);
                  transaction.delete(subjectRef);
                });

                const userRef = firestore
                  .collection("users")
                  .doc(newUser.userId);
                transaction.update(userRef, newUser);

                //UPDATE THE TIMESLOT
                transaction.update(oldTimeslotRef, {
                  taken: false,
                  teamName: "",
                  groupId: "",
                  contactId: "",
                });
                transaction.update(timeslotRef, timeslot);
              });
            })
            .then(() => {
              return onPopulateFriendlyIdsGroup({
                eventId,
                groupId: group.key,
              });
            })
            .then(() => {
              unSubscribeFromTimeslots();
              return dispatch(
                rTeambuilder.modifyUpdateGroup(
                  "SUCCESS",
                  "Team was successfully updated"
                )
              );
            })
            .catch((error) => {
              console.log(error);
              if (error === "TIMESLOT_TAKEN") {
                dispatch(
                  rTeambuilder.modifyUpdateGroup(
                    "ERROR",
                    "Your chosen timeslot is no longer available"
                  )
                );
              } else {
                dispatch(
                  rTeambuilder.modifyUpdateGroup(
                    "ERROR",
                    "Something went wrong, please try again"
                  )
                );
              }
            });
        });
    }

    return firestore
      .runTransaction((transaction) => {
        return transaction.get(timeslotRef).then((timeslotSnap) => {
          //CHECK IF TIMESLOT IS TAKEN
          const timeslotCheck = timeslotSnap.data() as Timeslot;
          if (timeslotCheck.taken) {
            throw new Error("TIMESLOT_TAKEN");
          }
          const oldTimeslotRef = firestore
            .collection("events")
            .doc(eventId)
            .collection("shoots")
            .doc(oldShootId)
            .collection("timeslots")
            .doc(oldTimeslotId);

          //CREATE GROUP FIRST
          const groupRef = firestore
            .collection("events")
            .doc(eventId)
            .collection("groups")
            .doc(group.key!);
          transaction.update(groupRef, group);

          //CREATE ALL THE SUBJECTS
          players.forEach((player) => {
            const subjectsRef = firestore
              .collection("subjects")
              .doc(player.key!);
            transaction.set(subjectsRef, player);
          });

          staff.forEach((staffMember) => {
            const subjectsRef = firestore
              .collection("subjects")
              .doc(staffMember.key!);
            transaction.set(subjectsRef, staffMember);
          });

          const userRef = firestore.collection("users").doc(newUser.userId);
          transaction.update(userRef, newUser);

          //UPDATE THE TIMESLOT
          transaction.update(oldTimeslotRef, {
            taken: false,
            teamName: "",
            groupId: "",
            contactId: "",
          });
          transaction.update(timeslotRef, timeslot);
        });
      })
      .then(() => {
        return onPopulateFriendlyIdsGroup({
          eventId,
          groupId: group.key,
        });
      })
      .then(() => {
        unSubscribeFromTimeslots();
        return dispatch(
          rTeambuilder.modifyUpdateGroup(
            "SUCCESS",
            "Team was successfully updated"
          )
        );
      })
      .catch((error) => {
        console.log(error);
        if (error === "TIMESLOT_TAKEN") {
          dispatch(
            rTeambuilder.modifyUpdateGroup(
              "ERROR",
              "Your chosen timeslot is no longer available"
            )
          );
        } else {
          dispatch(
            rTeambuilder.modifyUpdateGroup(
              "ERROR",
              "Something went wrong, please try again"
            )
          );
        }
      });
  };
};
