import React, { useEffect, useState } from "react";
import {
  Modal,
  Button,
  Upload,
  message,
  Radio,
  Row,
  Col,
  Form,
  Alert,
} from "antd";
import {
  checkFileSize,
  dummyRequest,
  getFileType,
} from "../../../constants/fileTypes";
import { TaskState } from "../../../models/Commons";
import { RcFile } from "antd/lib/upload";
import { functions } from "../../../firebase/firebaseConfig";
import { useDispatch, useSelector } from "react-redux";
import { Event, ImageType, IncomingImages } from "../../../models/EventsModel";
import Papa from "papaparse";
import { User } from "../../../models/UsersModel";
import { images as fImages } from "../../../firebase";

const onUploadImageIds = functions.httpsCallable("onUploadImageIds");
const UploadCSVButton = () => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [csvFile, setCsvFile] = useState<any>();
  const [csvType, setCsvType] = useState<ImageType>("");
  const dispatch = useDispatch();
  const selectedEvent: Event = useSelector(
    (state: any) => state.eventsState.selectedEvent
  );
  const user: User = useSelector((state: any) => state.usersState.user);
  const loadImagesState: TaskState = useSelector(
    (state: any) => state.imagesState.loadImages
  );
  const fetchImages = async () => {
    if (user.userId && selectedEvent.key) {
      dispatch(fImages.fetchImages(selectedEvent.key, user.userId));
    }
  };
  useEffect(() => {
    if (loadImagesState.error) {
      message.error("Unable to load images, please try again");
    }
  }, [loadImagesState.error]);
  const toggleModal = () => {
    setOpen(!open);
  };
  const handleUploadChange = (file: any) => {};
  const beforeUpload = (
    file: RcFile,
    FileList: RcFile[]
  ): boolean | PromiseLike<any> => {
    const fileType = getFileType(file.name);
    if (fileType !== "csv") {
      setError("Please upload a csv");
      message.error("Please upload a csv");
      return false;
    }
    if (!checkFileSize(file.size, 5)) {
      setError("Max file size is 2MB");
      message.error("Max file size is 2MB");
      return false;
    }

    const reader = new FileReader();
    reader.onload = () => {
      if (reader.result) {
        setCsvFile(reader.result);
      } else {
        message.error("Invalid File");
      }
    };
    reader.onerror = () => {
      message.error("Invalid File");
    };
    reader.readAsBinaryString(file);
    return true;
  };
  const onTypeChange = (e: any) => {
    setCsvType(e.target.value);
  };
  const onRemove = () => {
    setCsvFile(undefined);
  };
  const onSubmit = (e: any) => {
    e.preventDefault();
    setLoading(true);
    let errored = false;
    if (!csvFile) {
      setError("Please upload a csv");
      errored = true;
    }
    if (csvType === "") {
      setError("Please select an upload type");
      errored = true;
    }
    if (errored) {
      setLoading(false);
      return;
    } else {
      setError("");

      const images: any[] = [];
      Papa.parse(csvFile, {
        worker: true,
        step: function ({ data }: { data: any }) {
          const image = {
            filename: data[0]?.trim(),
            friendlyId: data[1]?.trim().toLowerCase(),
          };
          if (image.filename && image.friendlyId) {
            images.push(image);
          }
        },
        complete: function () {
          if (images.length > 0) {
            const outgoingImages: IncomingImages = {
              eventId: selectedEvent.key!,
              images: images,
              type: csvType,
            };

            onUploadImageIds(outgoingImages)
              .then((result) => {
                fetchImages();
                setLoading(false);
                toggleModal();
                message.success("Images were successfully uploaded");
              })
              .catch((error) => {
                console.log(
                  "🚀 ~ file: UploadCSVButton.tsx ~ line 117 ~ .then ~ error",
                  error
                );
                setLoading(false);
                message.error("Something went wrong, please try again");
              });
          } else {
            message.error("Invalid File");
            setLoading(false);
            setError("");
          }
        },
      });
    }
  };
  return (
    <div>
      <Button
        type="primary"
        onClick={toggleModal}
        disabled={!selectedEvent.key}
      >
        Upload CSV
      </Button>
      <Modal
        afterClose={() => {
          setError("");
          setCsvFile(undefined);
          setCsvType("");
        }}
        title="Upload CSV"
        visible={open}
        onOk={undefined}
        onCancel={toggleModal}
        footer={null}
      >
        <Form onSubmit={onSubmit}>
          <Row>
            <Col span={24}>
              <Upload
                onRemove={onRemove}
                showUploadList={false}
                beforeUpload={beforeUpload}
                onChange={handleUploadChange}
                customRequest={dummyRequest}
                multiple={false}
              >
                <Button>Click to Upload</Button>
              </Upload>
              {csvFile && (
                <p
                  style={{
                    marginTop: 12,
                    marginBottom: 0,
                    marginLeft: 8,
                    color: "green",
                  }}
                >
                  Valid CSV File
                </p>
              )}
            </Col>
            <Col span={24} style={{ marginTop: 24 }}>
              <Radio.Group onChange={onTypeChange} value={csvType}>
                <Radio value={"groups"}>Groups</Radio>
                <Radio value={"subjects"}>Subjects</Radio>
              </Radio.Group>
            </Col>
            <Col span={24} style={{ marginTop: 24 }}>
              <Button loading={loading} type="primary" htmlType="submit">
                Submit
              </Button>
            </Col>
            <Col span={24} style={{ marginTop: 24 }}>
              {error && (
                <Form.Item>
                  <Alert message={error} type="error" />
                </Form.Item>
              )}
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
};

export default UploadCSVButton;
