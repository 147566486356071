import React, { Component } from 'react';
import { Route, Redirect, withRouter, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import withAuthentication from '../hoc/withAuthentication';
import * as routes from '../constants/routeNames';

import {
	DashboardPage,
	ClientsPage,
	GroupsPage,
	EventsPage,
	LoginPage,
	PhotographersPage,
	RegisterPage,
	ProductsPage,
	SettingsPage,
	TeamBuilderPage,
	PurchasesPage
} from '../pages';

import EventDetail from '../pages/Events/EventDetail';
import ClientDetail from '../pages/Clients/ClientsDetail';
import websiteRoutes from '../routes/website';
import dashRoutes from '../routes/dashboard';
import authRoutes from '../routes/auth';
import Dashboard from '../layouts/Dashboard';
import Website from '../layouts/Website';
import Auth from '../layouts/Auth';

const COMPONENT_MAP = {
	Dashboard: DashboardPage,
	Clients: ClientsPage,
	Events: EventsPage,
	Login: LoginPage,
	Photographers: PhotographersPage,
	Register: RegisterPage,
	Products: ProductsPage,
	Settings: SettingsPage,
	TeamBuilder: TeamBuilderPage,
	Purchases: PurchasesPage
};

const mapStateToProps = (state) => ({
	authUser: state.sessionState.authUser,
	userCompany: state.usersState.userCompany,
	user: state.usersState.user
});

const mapDispatchToProps = (dispatch) => ({});

class Routes extends Component {
	renderLayout = () => {
		if (this.props.location.pathname === '/') {
			return <Redirect to={routes.LOGIN} />;
		} else if (this.props.location.pathname.includes(routes.TEAM_BUILDER)) {
			if (this.props.location.pathname === '/team-builder/') {
				return <Redirect to={routes.LOGIN} />;
			} else {
				return (
					<Website>
						<Switch>
							{websiteRoutes.map((prop, key) => {
								return <Route path={prop.path} component={COMPONENT_MAP[prop.component]} key={key} />;
							})}
						</Switch>
					</Website>
				);
			}
		} else if (this.props.location.pathname === routes.LOGIN || this.props.location.pathname === routes.REGISTER) {
			return (
				<Auth>
					<Switch>
						{authRoutes.map((prop, key) => {
							return <Route exact path={prop.path} component={prop.component} key={key} />;
						})}
					</Switch>
				</Auth>
			);
		} else if (
			this.props.location.pathname === routes.DASHBOARD ||
			this.props.location.pathname === routes.EVENTS ||
			this.props.location.pathname === routes.PHOTOGRAPHERS ||
			this.props.location.pathname === routes.CLIENTS ||
			this.props.location.pathname === routes.GROUPS ||
			this.props.location.pathname === routes.PHOTOGRAPHERS ||
			this.props.location.pathname === routes.SETTINGS ||
			this.props.location.pathname === routes.PURCHASES ||
			this.props.location.pathname === routes.PRODUCTS
		) {
			return (
				<Dashboard>
					<Switch>
						{dashRoutes.map((route) => {
							return (
								<Route
									exact
									path={route.path}
									component={COMPONENT_MAP[route.component]}
									key={route.path}
								/>
							);
						})}
					</Switch>
				</Dashboard>
			);
		} else if (this.props.location.pathname.includes(routes.CLIENTS)) {
			if (this.props.location.pathname === '/clients/') {
				return <Redirect to={routes.CLIENTS} />;
			} else {
				return (
					<Dashboard>
						<Switch>
							return (
							<Route
								exact
								path={'/clients/:clientId'}
								component={ClientDetail}
								key={'/clients/:clientId'}
							/>
							);
						</Switch>
					</Dashboard>
				);
			}
		} else if (this.props.location.pathname.includes(routes.EVENTS)) {
			if (this.props.location.pathname === '/events/') {
				return <Redirect to={routes.EVENTS} />;
			} else {
				return (
					<Dashboard>
						<Switch>
							return (
							<Route exact path={'/events/:eventId'} component={EventDetail} key={'/events/:eventId'} />
							);
						</Switch>
					</Dashboard>
				);
			}
		} else {
			return <Redirect to={routes.LOGIN} />;
		}
	};

	render() {
		return this.renderLayout();
	}
}

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps), withAuthentication)(Routes);
