import React from 'react';
import { connect } from 'react-redux';
import { auth, users, clients, products, purchases, photographers, events, groups, timeslots } from '../firebase';
import * as rUsers from '../reducers/users';
import { notification, message } from 'antd';

import { isEdge, isIE, isSafari, isOpera } from 'react-device-detect';
import { persistor } from '../base/store';

const args = {
	message: 'Info',
	description: 'For the best experience please, use Google Chrome or Firefox.',
	duration: 0
};

const withAuthentication = (Component) => {
	class WithAuthentication extends React.Component {
		state = { showedNotification: false };
		componentDidMount() {
			const { onSetAuthUser, onSubscribeUser, clearUsers, onClearAllListeners } = this.props;
			message.config({
				maxCount: 3
			});
			this.listener = auth.onAuthUserListener(
				(authUser) => {
					onSetAuthUser({
						uid: authUser.uid,
						emailVerified: authUser.emailVerified
					});
					onSubscribeUser(authUser.uid);
				},
				() => {
					onSetAuthUser(null);
					onClearAllListeners();
					clearUsers();
					persistor.purge();
				}
			);
		}
		componentWillUnmount() {
			this.listener();
			this.props.onClearAllListeners();
		}

		checkBrowser = () => {
			if ((isIE || isEdge || isSafari || isOpera) && !this.state.showedNotification) {
				notification.open(args);
				this.setState({ showedNotification: true });
			}
		};
		render() {
			this.checkBrowser();
			return <Component {...this.props} />;
		}
	}

	const mapDispatchToProps = (dispatch) => ({
		onSetAuthUser: (authUser) => dispatch({ type: 'AUTH_USER_SET', authUser }),
		onSubscribeUser: (uid) => {
			dispatch(users.subscribeToUser(uid));
			dispatch(users.subscribeToUserMeta(uid));
		},
		clearUsers: () => {
			dispatch(rUsers.clearUsersReducer());
		},
		onClearAllListeners: () => {
			users.unsubscribeFromUser();
			users.unsubscribeFromUserMeta();
			clients.unsubscribeClients();
			purchases.unSubscribeFromPurchases();
			events.unSubscribeFromEvents();
			products.unSubscribeProducts();
			products.unSubscribePacks();
			products.unSubscribeOfferings();
			photographers.unSubscribePhotographerRequests();
			groups.unSubscribeGroups();
			timeslots.unSubscribeFromTimeslots();
		}
	});

	return connect(null, mapDispatchToProps)(WithAuthentication);
};

export default withAuthentication;
